import { useState, useEffect, useRef } from 'react';
import { t } from "../../../pages/i18nFunctions";
import Select from 'react-select';

// images
import iconSave from "../../../assets/img/icons/icon-save.png";

export const PopupDuo = ({ missionList, duo, defineDuo }) => {

    const [mission, setMission] = useState(null);

    const btnRef = useRef(null);

    useEffect(() => {
        setMission(duo.value ? {
            value: duo.value,
            label: duo.label,
            pds: duo.pds
        }
        : null);
    }, [duo]);

    const handleSubmit = e => {
        e.preventDefault();

        if(mission) {
            defineDuo(mission.value);
            btnRef.current.click();
        }
    }

    return(
        <div className="modal fade in" id="modalAddEditDuo" tabIndex="-1" role="dialog" aria-labelledby="modalAddEditDuoLabel" animate="animate">
            <div className="modal-dialog" role="document">
                <div className="modal-content">

                    <div className="modal-header">
                        <h1 className="modal-title" id="myModalLabel">{t('PLANNING.DUO.TITLE')}</h1>
                    </div>

                    <div className="modal-body">
                        <form id="formDuo">
                            <label>{t('PLANNING.DUO.MISSION')}</label>
                            <Select
                                options={missionList.map((m) => ({
                                    value: m.id,
                                    label: `${m.name} (${m.delegue.fullNameDelegue})`,
                                    pds: `${m.delegue.fullNameDelegue}`,
                                    mission: m.name
                                }))}
                                value={mission}
                                onChange={(newValue) => setMission(newValue)}
                                placeholder="Sélection"
                                formatOptionLabel={(option, { context }) => {
                                    /* context can be either `menu` or `value`
                                        menu - dropdown
                                        value - value displayed
                                    */
                                    return context === 'menu' ? (
                                        <div className="select-react-option">
                                            <p>{option.mission}</p>
                                            <small>{option.pds}</small>
                                        </div>
                                    ) : `${option.label}`;
                                }}
                                className="react-select-container"
                            />
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button type="submit" form="formDuo" className="btn-save" onClick={handleSubmit}>
                            <img src={iconSave} alt=""/>
                            {t('COMMON.SAVE')}
                        </button>

                        <button
                            type="button"
                            className="btn-cancel"
                            data-dismiss="modal"
                            ref={btnRef}
                        >{t('COMMON.CANCEL')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}