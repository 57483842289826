import { useState, useEffect, useContext } from 'react';
import "./ZonesGeo.scss";
import { t } from "../../i18nFunctions";
import { NotificationManager } from 'react-notifications';
import { StoreContext } from "../../../store/store";

// components
import { CustomSelect } from '../../../components/UI/CustomSelect/CustomSelect';

// api
import {
    getAllCountries,
    getZonesByCountry,
    editZoneGeo
} from '../../../store/index.service';

export const ZonesGeo = () => {

    const [countryList, setCountryList] = useState([]);
    const [zoneGeo, setZoneGeo] = useState({});
    const [displaySearch, setDisplaySearch] = useState([{}]);
    const [initialInput, setInitialInput] = useState("");
    const [searchZone, setSearchZone] = useState("");
    const [country, setCountry] = useState("");

    const { setLoadingStore } = useContext(StoreContext);

    // mounted
    useEffect(() => {
        setLoadingStore(true);

        getAllCountries().then(res => {
            const data = JSON.parse(res);
            if(data) setCountryList(data);

            setLoadingStore(false)
        });
    }, []);

    // updates
    useEffect(() => {
        if(searchZone !== "")
            searchHandle(getAllData(zoneGeo)[0]);
        else
            setDisplaySearch(getAllData(zoneGeo));
    }, [searchZone]);

    const handleGetZonesByCountry = (n) => {
        setLoadingStore(true);

        getZonesByCountry(n).then(res => {
            const data = JSON.parse(res);

            if(data) {
                setZoneGeo(data);
                setDisplaySearch(getAllData(data));
            } else {
                setZoneGeo({});
                setDisplaySearch([{}]);
            }

            setLoadingStore(false);
        });
    }

    const handleEditZoneGeo = (id, value) => {
        editZoneGeo(id, value).then(() => {
            NotificationManager.success('', "Zone modifiée");
            handleGetZonesByCountry(country);
        }).catch(() => NotificationManager.error('', "Error"));
    }

    // functions

    const getAllData = displayZone => {

        let Zon = {};

        Object.keys(displayZone).map(item => {

            let Vil = {};

            Object.keys(displayZone[item]).map(ville => {

                let Communes = {};

                if(ville !== "name" && ville !== "id") {

                    Object.keys(displayZone[item][ville]).map(com => {

                        let Quartier = {};

                        if(com !== "id" && com !== "name") {

                            Object.keys(displayZone[item][ville][com]).map(quart => {

                                let Quar = {};

                                if(quart !== "id" && quart !== "name") {

                                    Object.assign(Quar, {
                                        "id" : displayZone[item][ville][com][quart].id, 
                                        "name": displayZone[item][ville][com][quart].name
                                    });
                                }

                                Object.assign(Quartier, {
                                    [quart]: Quar,
                                    "id" : displayZone[item][ville][com].id, 
                                    "name": displayZone[item][ville][com].name
                                });
                            });      

                            Object.assign(Communes, {
                                [com]: Quartier,
                                "id" : displayZone[item][ville].id, 
                                "name": displayZone[item][ville].name,
                            });
                        }
                    });
                    
                    Object.assign(Vil, {
                        [ville] : Communes,
                        "id" : displayZone[item].id,
                        "name": displayZone[item].name,
                    });
                }
            });

            Object.assign(Zon, {
                [item] : Vil
            });
        });

        return [Zon]; 
    }

    const afficheList = () => {
        const zone = displaySearch[0];
        let table = [];

        if(zone && Object.keys(zone)) {

            Object.keys(zone).map((item, i) => {

                let villes = [];

                Object.keys(zone[item]).map((ville, j) => {

                    let communes = [];

                    if(ville !== "name" && ville !== "id") {

                        Object.keys(zone[item][ville]).map((com, l) => {

                            let quartiers = [];

                            if(com !== "id" && com !== "name") {

                                Object.keys(zone[item][ville][com]).map((quart, m) => {
                
                                    if(quart !== "id" && quart !== "name") {

                                        quartiers.push(
                                            <ul key={`${item}_${ville}_${com}_q${m}`}>
                                                <li toggled="false">
                                                    <input
                                                        value={zone[item][ville][com][quart].name}
                                                        className="invisible-input"
                                                        onKeyDown={e => {
                                                            if(e.key === 'Enter' || e.keyCode === 13) {
                                                                handleEditZoneGeo(zone[item][ville][com][quart].id, e.target.value);
                                                                e.target.blur();
                                                            }
                                                        }}
                                                        onChange={e => {
                                                            let t = {...zone};
                                                            t[item][ville][com][quart].name = e.target.value;
                                                            setZoneGeo(t);
                                                        }}
                                                        onClick={e => e.stopPropagation()}
                                                        onFocus={() => setInitialInput(zone[item][ville][com][quart].name)}
                                                        onBlur={() => {
                                                            let t = {...zone};
                                                            t[item][ville][com][quart].name = initialInput;
                                                            setZoneGeo(t);
                                                        }}
                                                    />
                                                    &nbsp;<span className="small">QUARTIER</span>
                                                </li>
                                            </ul>
                                        )
                                    }
                                });

                                communes.push(
                                    <ul key={`${item}_${ville}_c${l}`}>
                                        <li
                                            onClick={(e) => quartiers.length > 0 ? handleListClick(e, `${item}_${ville}_${com}`) : {}}
                                            toggled="false"
                                            className={quartiers.length > 0 ? "" : "noQuart"}
                                        >
                                            <input
                                                value={zone[item][ville][com].name}
                                                className="invisible-input"
                                                onKeyDown={e => {
                                                    if(e.key === 'Enter' || e.keyCode === 13) {
                                                        handleEditZoneGeo(zone[item][ville][com].id, e.target.value);
                                                        e.target.blur();
                                                    }
                                                }}
                                                onChange={e => {
                                                    let t = {...zone};
                                                    t[item][ville][com].name = e.target.value;
                                                    setZoneGeo(t);
                                                }}
                                                onClick={e => e.stopPropagation()}
                                                onFocus={() => setInitialInput(zone[item][ville][com].name)}
                                                onBlur={() => {
                                                    let t = {...zone};
                                                    t[item][ville][com].name = initialInput;
                                                    setZoneGeo(t);
                                                }}
                                            />
                                            <span className="small">COMMUNE</span>
                                        </li>
                                        <li className={`quart ${item}_${ville}_${com}`} active="false">{quartiers}</li>
                                    </ul>
                                )
                            }
                        });

                        villes.push(
                            <ul key={`${item}_v${j}`}>
                                <li
                                    onClick={e => communes.length > 0 ? handleListClick(e, `${item}_${ville}`) : {}}
                                    toggled="false"
                                    className={communes.length > 0 ? "" : "noQuart"}
                                >
                                    <input
                                        value={zone[item][ville].name}
                                        className="invisible-input"
                                        onKeyDown={e => {
                                            if(e.key === 'Enter' || e.keyCode === 13) {
                                                handleEditZoneGeo(zone[item][ville].id, e.target.value);
                                                e.target.blur();
                                            }
                                        }}
                                        onChange={e => {
                                            let t = {...zone};
                                            t[item][ville].name = e.target.value;
                                            setZoneGeo(t);
                                        }}
                                        onClick={e => e.stopPropagation()}
                                        onFocus={() => setInitialInput(zone[item][ville].name)}
                                        onBlur={() => {
                                            let t = {...zone};
                                            t[item][ville].name = initialInput;
                                            setZoneGeo(t);
                                        }}
                                    />
                                    <span className="small">{t('SETTINGS.CITY')}</span>
                                </li>
                                <li className={`com ${item}_${ville}`} active="false">{communes}</li>
                            </ul>
                        )
                    }
                });

                table.push(
                    <ul key={i} className="zone">
                        <li
                            onClick={e => villes.length > 0 ? handleListClick(e, item) : {}}
                            toggled="false"
                            className={villes.length > 0 ? "" : "noQuart"}
                        >
                            <input
                                value={zone[item].name}
                                className="invisible-input"
                                onKeyDown={e => {
                                    if(e.key === 'Enter' || e.keyCode === 13) {
                                        handleEditZoneGeo(zone[item].id, e.target.value);
                                        e.target.blur();
                                    }
                                }}
                                onChange={e => {
                                    let t = {...zone};
                                    t[item].name = e.target.value;
                                    setZoneGeo(t);
                                }}
                                onClick={e => e.stopPropagation()}
                                onFocus={() => setInitialInput(zone[item].name)}
                                onBlur={() => {
                                    let t = {...zone};
                                    t[item].name = initialInput;
                                    setZoneGeo(t);
                                }}
                            />
                            <span className="small">{t('SETTINGS.ZONE')}</span>
                        </li>
                        <li className={`ville ${item}`} active="false">{villes}</li>
                    </ul>
                )
            });
        }

        return table;
    }

    const handleListClick = (e, item) => {
        const obj = document.getElementsByClassName(item)[0];
        const active = obj.getAttribute("active");
        obj.setAttribute("active", active === "true" ? "false" : "true");
        const toggled = e.target.getAttribute('toggled');
        e.target.setAttribute("toggled", toggled === "true" ? "false" : "true");
    }

    const searchHandle = display => {
        if(searchZone !== "") {

            if(display === undefined) setDisplaySearch(getAllData(zoneGeo));

            let data = {};
            let dataZ =  {};
            let dataV =  {};
            let dataC =  {};
            let dataQ = {};
            
            if(display) {

                if(Object.keys(display)) {

                    Object.keys(display).map(zone => {

                        if(display[zone]) {
                            if(
                                display[zone].name &&
                                display[zone].name.toLowerCase().indexOf(searchZone.toLowerCase()) !== -1
                            ) {   
                                let obj = { id: display[zone].id, name: display[zone].name };
                                Object.assign(dataZ, { [zone]: obj });
                            }

                            if(Object.keys(display[zone])) {      

                                Object.keys(display[zone]).map(ville => {  

                                    if(display[zone][ville]) {
                                        if(
                                            display[zone][ville].name &&
                                            display[zone][ville].name.toLowerCase().indexOf(searchZone.toLowerCase()) !== -1
                                        ) {

                                            let objVille = { id: display[zone][ville].id, name: display[zone][ville].name };
                                            Object.assign(dataV, {
                                                [zone]: {
                                                    id: display[zone].id,
                                                    name: display[zone].name,
                                                    [ville]: objVille
                                                }
                                            });
                                        }

                                        if(Object.keys(display[zone][ville])) {

                                            Object.keys(display[zone][ville]).map(commune => {

                                                if(
                                                    display[zone][ville][commune] &&
                                                    display[zone][ville][commune].name &&
                                                    display[zone][ville][commune].name.toLowerCase().indexOf(searchZone.toLowerCase()) !== -1
                                                ) {

                                                    let objCom = { id: display[zone][ville][commune].id, name: display[zone][ville][commune].name };
                                                    Object.assign(dataC, {
                                                        [zone]: {
                                                            id: display[zone].id,
                                                            name: display[zone].name,
                                                            [ville]: {
                                                                id: display[zone][ville].id,
                                                                name: display[zone][ville].name,
                                                                [commune]: objCom
                                                            }                                           
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                    }
                                });
                            }
                        }
                    });
                }    
            }
            if(!isEmpty(dataZ))
                data = dataZ;
            
            if(!isEmpty(dataV))
                data = Object.assign(dataZ, dataV);
            
            if(!isEmpty(dataC))
                data = Object.assign(dataZ, dataV, dataC);
            
            if(!isEmpty(dataQ))
                data = dataQ;
            
            setDisplaySearch([data]);
        }
        else
            setDisplaySearch(getAllData(zoneGeo));
    }

    const isEmpty = obj => {
        for(let key in obj) { if(obj.hasOwnProperty(key)) return false; }
        return true;
    }

    return(
        <section className="content">
        
            <section className="content-header">
                <h1>
                    {t('SETTINGS.GEOGRAPHICAL_AREA')}
                    <small>{t('SETTINGS.MANAGEMENT')}</small>
                </h1>
            </section>
        
            <section className="content zoneGeo">

                <div className="row">
                    <div className="col-md-12">
                        <div className="box tab-entete zoneGeo">
                            <CustomSelect
                                defaultText={t('SETTINGS.SELECT_COUNTRY')}
                                optionsList={countryList}
                                handleOptionClick={(id, nameCountry) => {
                                    handleGetZonesByCountry(nameCountry);
                                    setCountry(nameCountry);
                                }}
                            />

                            <p>{t('SETTINGS.SEARCH')}</p>
                            <input
                                value={searchZone}
                                onChange={(e) => setSearchZone(e.target.value)}
                                placeholder={t('SETTINGS.FILTER')}
                            />
                        </div>
                    </div>
                </div>

                <div className="listZoneGeo">
                    {afficheList()}
                </div>

            </section>

        </section>
    )
}