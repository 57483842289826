import { useState, useEffect, useContext } from 'react';
import "./JoursFeries.scss";
import { t } from '../i18nFunctions';
import { NotificationManager} from 'react-notifications';
import { StoreContext } from "../../store/store";

// components
import { CustomSelect } from '../../components/UI/CustomSelect/CustomSelect';
import { ButtonAdd } from '../../components/UI/Button/ButtonAdd/ButtonAdd';
import { PopupDelete } from '../../components/Popups/PopupDelete';
import { PopupJourFerie } from '../../components/Popups/JoursFeries/PopupJourFerie';

// images
import iconDelete from "../../assets/img/icons/icon-delete.png";

import {
    getHolidays,
    postHoliday,
    deleteHoliday,

    getAllCountries
} from '../../store/index.service';

export const JoursFeries = () => {

    const [countryList, setCountryList] = useState([]);
    const [jours, setJours] = useState([]);
    const [idDelete, setIdDelete] = useState(-1);
    const [idCountry, setIdCountry] = useState(-1);

    const { setLoadingStore } = useContext(StoreContext);

    // mounted
    useEffect(() => {
        setLoadingStore(true);

        getAllCountries().then(res => {
            const data = JSON.parse(res);
            if(data) setCountryList(data);

            setLoadingStore(false);
        });
    }, []);

    // functions

    const handleGetHolidays = (id) => {
        setLoadingStore(true);

        getHolidays(id).then(res => {
            const data = JSON.parse(res);

            if(data) setJours(data);
            else setJours([]);

            setLoadingStore(false);
        });
    }

    return(
        <section className="content">
        
            <section className="content-header">
                <h1>
                    {t('HOLIDAYS.TITLE')}
                    <small>{t('HOLIDAYS.MANAGEMENT')}</small>
                </h1>
            </section>
        
            <section className="content holidays">

                <div className="row">
                    <div className="col-md-12">
                        <div className="box info joursFeries">
                            <CustomSelect
                            defaultText={t('HOLIDAYS.SELECT_COUNTRY')}
                            optionsList={countryList}
                            handleOptionClick={(id) => {
                                handleGetHolidays(id);
                                setIdCountry(id);
                            }}
                            />

                            <ButtonAdd modal="#modalAddJourFerie"/>
                        </div>
                    </div>
                </div>

                {Object.keys(jours).length > 0 ? (
                    <table className="box tab-entete joursFeries">
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <p>{t('HOLIDAYS.COUNTRY')}</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>{t('HOLIDAYS.DATE')}</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>{t('HOLIDAYS.DESCRIPTION')}</p>
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                    <p>{t('HOLIDAYS.NO_HOLIDAYS')}</p>
                )}

                <table className="list joursFeries">
                    <tbody>
                    {jours.map(jour =>
                        <tr key={jour.id} className="list-row">
                            <td>
                                {jour.nameArea}
                            </td>
                            <td>
                                {new Date(jour.date).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}
                            </td>
                            <td>
                                {jour.name}
                            </td>
                            <td className="icons">
                                <div
                                className="icon-btn"
                                data-toggle="modal"
                                data-target="#modalDelete"
                                onClick={() => setIdDelete(jour.id)}>
                                    <img src={iconDelete} alt="x"/>
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>

            </section>

            <PopupJourFerie
                countryList={countryList}
                addJourFerie={(id, date, desc) => postHoliday(id, date, desc).then(() => {
                    NotificationManager.success('', "Jour férié ajouté");
                    handleGetHolidays(idCountry);
                }).catch(() => NotificationManager.error('', "Error"))}
            />

            <PopupDelete
                title="Supprimer un jour férié"
                handleDelete={(id) => deleteHoliday(id).then(() => {
                    NotificationManager.success('', "Jour férié supprimé");
                    handleGetHolidays(idCountry);
                }).catch(() => NotificationManager.error('', "Error"))}
                id={idDelete}
            />

        </section>
    )
}