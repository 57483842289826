import { useState, useContext } from 'react';
import { ActionsStoreContext } from '../../../../../store/store/storeActions';
import { ListStoreContext } from '../../../../../store/store/storeList';
import { PopUpStoreContext } from '../../../../../store/store/storePopUp';

import { t } from "../../../../../pages/i18nFunctions";

import Select from "react-select";
import { TabElements } from "../../../../TabElements/TabElements";
import { RestrictInput } from '../../../Input/RestrictInput/RestrictInput';
import { Checkbox } from "../../../Checkbox/Checkbox";
import { MissionTab } from './MissionTab';

import {
    getSectorByCountry
} from "../../../../../store/index.service";

export const AddMission = (props) => {
    const { stateActions, dispatchActions } = useContext(ActionsStoreContext);
    const { stateList, dispatchList } = useContext(ListStoreContext);
    const { statePopUp, dispatchPopUp } = useContext(PopUpStoreContext);
    
    const { dataActions } = stateActions;
    const { dataNetwork, dataPositions, dataSpeciality, dataProType } = stateList;

    const [item, setItem] = useState({
        name: "",
        country: null,
        ugvs: [],
        network: null,
        affectation: null,
        withSupervision: false
    });
    const [withSupervision, setWithSupervision] = useState(false);
    const [secteursList, setSecteursList] = useState([]);
    const [missionSpeList, setMissionSpeList] = useState([]);
    const [missionProTypeList, setMissionProTypeList] = useState([]);

    const cleanUGV = [];

    const handleChange = (name, value) => {
        let aDataActions = {...item, ...dataActions, [name]: value};
        let dDataPopUp = { ...statePopUp.dataPopUp, optionalData: aDataActions };

        if(name === "country")
            handleGetSectorByCountry(value.value);
        else if(name === "specialities")
            setMissionSpeList(value);
        else if(name === "protypes")
            setMissionProTypeList(value);
        else if(name === "withSupervision") {
            setWithSupervision(!withSupervision);
            aDataActions = { ...aDataActions, withSupervision: !withSupervision }
        }

        setItem(aDataActions);
        dispatchPopUp({type: 'setPopUp', dDataPopUp });
        dispatchActions({type: 'setDataActions', aDataActions})
    }

    const generateOptions = (data, type) => {
        if(typeof data !== 'undefined') {
            let options = [];

            if(type === "affectation")
                data.forEach(option => {
                    options.push({
                        value: option.id, 
                        label: `${option.name} (${option.userFirstName} ${option.userLastName})`,
                        delegue: `${option.userFirstName} ${option.userLastName}`,
                        position: option.name
                    });
                });
            else
                data.forEach((option, i) => {
                    options.push({
                        value: option.id, 
                        label: option.name,
                    });
                });

            return options;
        }
    }

    const handleGetSectorByCountry = (idCountry) => {
        getSectorByCountry(idCountry).then(res => {
            const data = JSON.parse(res);
            if(data) setSecteursList(
                data ? data.map(sect => ({
                    id: sect.idSector,
                    name: sect.nameSector
                }))
                : []
            );
        });
    }

    const handleTabChange = type => {
        let dDataOtherTabs = [];

        if(type === "specialite")
            dDataOtherTabs = missionSpeList;
        else if(type === "type_pds")
            dDataOtherTabs = missionProTypeList;

        return dispatchList({type: 'setDataOtherTabs', dDataOtherTabs }); 
    }

    return(
        <TabElements
            options={{
                general: t('MISSION.POP_EDIT.TAB_GENERAL'),
                specialite: t('MISSION.POP_EDIT.TAB_SPECIALITIES'),
                type_pds: t('MISSION.POP_EDIT.TAB_TYPE_PDS')
            }}
            values={{
                general: (
                    <form>

                        <label htmlFor="name">{t('MISSION.POP_EDIT.ENTITLED')}</label>
                        <input id="name" type="text" required value={item.name} onChange={(e) => handleChange('name', e.target.value)}/>

                        <div>
                            <label htmlFor="countries">{t('MISSION.POP_EDIT.COUNTRY')}</label>
                            <Select 
                                id="countries"
                                className='selectSearchBar' 
                                classNamePrefix='selectSearch' 
                                placeholder={t('MISSION.POP_EDIT.COUNTRY_PLACEHOLDER')}
                                isClearable={false}
                                options={generateOptions(JSON.parse(localStorage.getItem('userCountries')), 'country')}
                                onChange={(value, type) => handleChange('country', value, null) }
                            />
                        </div>

                        <label>{t('MISSION.POP_EDIT.SECTEURS')}</label>
                        <RestrictInput 
                            data={item}
                            data1={secteursList}
                            data2={cleanUGV}
                            label1={t('MISSION.POP_EDIT.AVAILABLE_UGV')}
                            label2={t('MISSION.POP_EDIT.SELECTED_UGV')}
                            role={item.country}
                            model='ugvs'
                        />

                        <div>
                            <label htmlFor="networks">{t('MISSION.POP_EDIT.NETWORK')}</label>
                            <Select 
                                id="networks"
                                className='selectSearchBar' 
                                classNamePrefix='selectSearch' 
                                placeholder={t('MISSION.POP_EDIT.NETWORK_PLACEHOLDER')}
                                isClearable={false}
                                options={generateOptions(dataNetwork, 'network')}
                                onChange={(value, type) => handleChange('network', value, null) }
                            />
                        </div>

                        <div>
                            <label htmlFor="affectation">{t('MISSION.POP_EDIT.AFFECTATION')}</label>
                            <Select 
                                id="affectation"
                                className='selectSearchBar' 
                                classNamePrefix='selectSearch' 
                                placeholder={t('MISSION.POP_EDIT.AFFECTATION_PLACEHOLDER')}
                                isClearable={false}
                                options={generateOptions(dataPositions, 'affectation')}
                                onChange={(value, type) => handleChange('affectation', value, null) }
                                formatOptionLabel={(option, { context }) => {
                                    /* context can be either `menu` or `value`
                                        menu - dropdown
                                        value - value displayed
                                    */
                                    return context === 'menu' ? (
                                        <div className="select-react-option">
                                            <p>{option.position}</p>
                                            <small>{option.delegue}</small>
                                        </div>
                                    ) : option.label;
                                }}
                            />
                        </div>

                        <Checkbox
                            isSwitch
                            label={t('MISSION.POP_EDIT.SUPERVISION_INCLUDED')}
                            value={withSupervision}
                            onChange={(value) => handleChange('withSupervision', !value, null)}
                        />

                    </form>
                ),
                specialite: (
                    <MissionTab
                        type="spe"
                        generateOptions={generateOptions}
                        dataList={dataSpeciality}
                        handleChange={handleChange}
                    />
                ),
                type_pds: (
                    <MissionTab
                        type="typePDS"
                        generateOptions={generateOptions}
                        dataList={dataProType}
                        handleChange={handleChange}
                    />
                )
            }}
            handleFocus={k => handleTabChange(k)}
        />
    )
}
