import React, { useRef, useEffect } from 'react';
import "./CalendarSearch.scss";
import { t } from '../../pages/i18nFunctions';
import ReactLoading from 'react-loading';
import { Draggable } from 'react-drag-and-drop';
import Select from 'react-select';

// components
import { DraggablePDS } from '../../components/DraggablePDS/DraggablePDS';
import { TabElements } from '../../components/TabElements/TabElements';

// helpers
import {
    generateEtabOptions,
    generateAllZGOptions
} from "../../store/helpers";


export const CalendarSearch = (props) => {

    // PROPS
    const {
        typeEtabList, handleGetAllEtabs, allEtabs,
        handleGetMissionPro, allPDS,
        handleGetAllSpecialite, allSpe,
        allZG,
        missionPros, pdsDragged, setPdsDragged, calendar,
        spe, zoneGeo, setState
    } = props;

    // REF
    const timer = useRef();


    // EFFECT

    useEffect(() => {
        timer.current = setTimeout(() => {}, 1000);

        // unmounted
        return () => {
            clearTimeout(timer.current);
        };
    }, []);


    // FUNCTIONS

    const handleFilter = (value, key) => {

        clearTimeout(timer.current);

        timer.current = setTimeout(() => {

            if(key === "name") {
                setState({ [key]: value });
                handleGetMissionPro(value);
            }
                
            else if(key === "searchLocation")
                handleGetAllEtabs({                           
                    page: 1,
                    maxPerPage: 100,
                    label: value
                });

        }, 1000);
    }

    const formatedZoneGeo = (data) => {
        let newState = { zoneGeo: data };

        if(data)
            newState = { ...newState, zgLoad: data.histo }

        setState(newState);
    }

    const generateTab = (list, tag, tagLabel) => (
        <div className="modal-body">
            {!missionPros.loading && list.map(pro => 
                <Draggable
                    type="pds"
                    data={JSON.stringify(pro)}
                    key={pro.id}
                >
                    <DraggablePDS
                        pro={pro}
                        pdsDragged={pdsDragged}
                        setPdsDragged={setPdsDragged}
                        calendar={calendar}
                        tag={tag}
                        tagLabel={tagLabel}
                    />
                </Draggable>
            )}
            {missionPros.loading && <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={30} width={30} className="spinner-loader pds" />}
        </div>
    );


    // VARIABLES

    const formatGroupLabel = data => <div><span>{data.label}</span></div>;


    // RENDER

    return (
        <div className="col-md-3">

            <div className="box info professionnels">

                <p>{t('PROS.LIST.RESEARCH.TITLE')}</p>

                <label>{t('PROS.LIST.RESEARCH.NAME')}</label>
                <input
                    type="text"
                    required
                    onChange={(e) => handleFilter(e.target.value, "name")}
                    placeholder={t('PROS.LIST.RESEARCH.LABEL_PLACEHOLDER')}
                />

                <label>{t('PROS.LIST.RESEARCH.CONSULTATION_PLACE')}</label>
                <Select
                    options={typeEtabList.length > 0 ? generateEtabOptions(allEtabs.list, typeEtabList) : []}
                    formatGroupLabel={formatGroupLabel}
                    onInputChange={(value) => handleFilter(value.replace(/\W/g, ''), "searchLocation")}
                    onChange={(newValue) => setState({ etab: newValue })}
                    placeholder={t('PROS.LIST.RESEARCH.CONSULTATION_PLACE_PLACEHOLDER')}
                    formatOptionLabel={(option, { context }) => {
                        return context === 'menu' ? (
                            <div className="select-react-option etab">
                                <div>
                                    <div className={`tag ${option.isPrivate === 1 ? "valid" : option.isPrivate === 2 ? "prive" : option.isPrivate === 3 ? "warning" : "inconnu"}`}>
                                        <p>
                                            {option.isPrivate === 1 ? t('PROS.LIST.RESEARCH.PUBLIC')
                                            : option.isPrivate === 2 ? t('PROS.LIST.RESEARCH.PRIVATE')
                                            : option.isPrivate === 3 ? t('PROS.LIST.RESEARCH.SEMI_PRIVATE')
                                            : t('COMMON.UNKNOWN')}
                                        </p>
                                    </div>
                                    <p>{option.ref} - {option.label}</p>
                                </div>
                                <small>{option.ad}</small>
                            </div>
                        ) : option.label;
                    }}
                    isClearable
                    className="react-select-container"
                    isLoading={allEtabs.loading}
                />

                <label>{t('PROS.LIST.RESEARCH.PDS_TYPE')}</label>
                <Select
                    options={allPDS.list.map((type) => ({
                        value: type.id,
                        label: type.name
                    }))}
                    onChange={(newValue) => {
                        handleGetAllSpecialite(newValue ? { protype: newValue.value } : {});
                        setState({ typePDS: newValue, spe: null })
                    }}
                    placeholder={t('PROS.LIST.RESEARCH.PDS_TYPE_PLACEHOLDER')}
                    isClearable
                    className="react-select-container"
                    isLoading={allPDS.loading}
                />

                <label>{t('PROS.LIST.RESEARCH.SPECIALITY')}</label>
                <Select
                    options={allSpe.list.map((spe) => ({
                        value: spe.id,
                        label: spe.name,
                        type: spe.medicalprotype.nameMedicalProType
                    }))}
                    value={spe}
                    onChange={(newValue) => setState({ spe: newValue })}
                    placeholder={t('PROS.LIST.RESEARCH.SPECIALITY_PLACEHOLDER')}
                    formatOptionLabel={(option, { context }) => {
                        return context === 'menu' ? (
                            <div className="select-react-option">
                                <p>{option.label}</p>
                                <small>{option.type}</small>
                            </div>
                        ) : option.label;
                    }}
                    isClearable
                    className="react-select-container"
                    isLoading={allSpe.loading}
                />

                <label>{t('PROS.LIST.RESEARCH.GEOGRAPHICAL_AREA')}</label>
                <div className="zoneGeo-select">
                    <Select
                        options={generateAllZGOptions(allZG.list)}
                        formatGroupLabel={formatGroupLabel}
                        value={zoneGeo}
                        onChange={(newValue) => formatedZoneGeo(newValue)}
                        onInputChange={(value) => handleFilter(value.replace(/\W/g, ''), "searchZoneGeo")}
                        placeholder={t('PROS.LIST.RESEARCH.GEOGRAPHICAL_AREA_PLACEHOLDER')}
                        formatOptionLabel={(option, { context }) => {
                            return context === 'menu' ? (
                                <div className="select-react-option">
                                    <p>{option.label}</p>
                                    <small>{option.ad}</small>
                                </div>
                            ) : option.label;
                        }}
                        isClearable
                        className="react-select-container"
                        isLoading={allZG.loading}
                    />
                    <div
                        className="searchZoneGeo"
                        data-toggle="modal"
                        data-target="#modalChooseZoneGeo"
                    >
                        <i
                            className="fa fa-search"
                            aria-hidden="true"
                        ></i>
                    </div>
                </div>
            </div>

            <div className="box info pds-found">

                <TabElements
                    options={{
                        resultats: (
                            <span>
                                {t('PLANNING.BOX_FOUND.TARGET')} &nbsp;<span className="nb-pastille valid">{missionPros.nbTGT}</span>
                            </span>
                        ),
                        reco: (
                            <span>
                                {t('PLANNING.BOX_FOUND.TARGET_OUT')} &nbsp;<span className="nb-pastille warning">{missionPros.nbNTGT}</span>
                            </span>
                        ),
                    }}
                    values={{
                        resultats: generateTab(missionPros.prosListTGT, "valid", t('PLANNING.BOX_FOUND.TARGET')),
                        reco: generateTab(missionPros.prosListNTGT, "warning", t('PLANNING.OOT'))
                    }}
                />

            </div>
        
        </div>
    )
}