import { ButtonAdd } from '../../Button/ButtonAdd/ButtonAdd';
import { Order } from './Filters/Order';
import { Search } from './Filters/Search';
import { DateInput } from './Filters/DateInput';
import { SelectFilt } from './Filters/SelectFilt';
import { CustomSelectFilt } from './Filters/CustomSelectFilt';

import './index.scss';

export const TableHeadManager = (props) => {
    const { dataHeader, isAdd, type } = props;

    const generateTabsHeads = () => {
		return dataHeader.map((head, i) => {
            let option = '';
            if(head.name === 'addresszoneGeo' || head.name === 'name')
                option = 'longTh';
            
			return (
				<th key={i} className={`containerTh ${option}`}>

					<div className={`containerHead ${
                        head.isSearchable || head.isDateFilter || head.isSelectable || head.isCustomSelectable
                        || type === "medicalProsInstitutions" || type === "specialityProPop" || type === "medicalProSoftPop" || type === "medicalProQualifiedProducts" ?
                            '' : 'retBottom'
                    }`}>
						<p>
                            {head.displayName}
                            {head.small ? <><br/>
                                <small>{head.small}</small>
                            </>
                            : null }
                        </p>

						{typeof head.isFilterable !== 'undefined' && head.isFilterable === true ? <Order head={head} type={type} /> : ''}						
					</div>

					{typeof head.isSearchable !== 'undefined' && head.isSearchable === true ? <Search head={head} type={type}/> : ''}
					{typeof head.isDateFilter !== 'undefined' && head.isDateFilter === true ? <DateInput head={head} type={type}/> : ''}
					{typeof head.isSelectable !== 'undefined' && head.isSelectable === true ? <SelectFilt head={head} type={type}/>  : ''}
					{typeof head.isCustomSelectable !== 'undefined' && head.isCustomSelectable === true ? <CustomSelectFilt head={head} type={type}/>  : ''}
				</th>
			);
		})
	}

    return(
        <thead>
            <tr className="tabHeader">
                {generateTabsHeads()}
                {/* <th className="emptyTh"></th> */}
                <th className='th-add'>
                {isAdd !== false &&
                    <ButtonAdd
                        type={type}
                    />
                }
                </th>
            </tr>
        </thead>
    )
}