import { useState, useEffect, useContext, useRef } from 'react';
import "./Dashboard.scss";
import { t } from "../../pages/i18nFunctions";
import { StoreContext } from "../../store/store";
import { ResponsiveContainer, BarChart, Bar, YAxis, XAxis, LabelList, Label, Tooltip, Legend, ReferenceLine, PieChart, Pie, Cell } from 'recharts';
import Select from "react-select";
import { TabContacts } from '../../components/TabContacts/TabContacts';
import { TablePosition } from "../../components/TablePosition/TablePosition";
import { Trans } from 'react-i18next';

// api
import {
    getContactData,
    getVisitsData,
    getProdLab
} from '../../store/index.service';
import { keyBy } from 'lodash';

const MONTHS = [
    t("MONTHS.ABREV.1"), t("MONTHS.ABREV.2"), t("MONTHS.ABREV.3"), t("MONTHS.ABREV.4"), t("MONTHS.ABREV.5"), t("MONTHS.ABREV.6"),
    t("MONTHS.ABREV.7"), t("MONTHS.ABREV.8"), t("MONTHS.ABREV.9"), t("MONTHS.ABREV.10"), t("MONTHS.ABREV.11"), t("MONTHS.ABREV.12")
]

export const Dashboard = () => {

    const [contactData, setContactData] = useState({});
    const [visitsData, setVisitsData] = useState();
    const [laboratories, setLaboratories] = useState({ list: [], loading: true });
    const [selectedLabs, setSelectedLabs] = useState(null);
    const [selectedCountries, setSelectedCountries] = useState(null);
    const [mounted, setMounted] = useState(false);

    const { setLoadingStore } = useContext(StoreContext);

    const role = localStorage.getItem('role');
    const userCountries = JSON.parse(localStorage.getItem("userCountries"));

    const timer = useRef();

    const keyByRole = () => {

        switch(role) {

            case "ROLE_DELEGUE":
                return "role_mission";

            case "ROLE_SUPERVISEUR":
            case "ROLE_DSM":
            case "ROLE_HP":
            case "ROLE_DP":
                return "role_labo";

            case "ROLE_COORDINATEUR":
                return "role_pays";

            default:
                return "";
        }
    }

    useEffect(() => {
        timer.current = setTimeout(() => {}, 1000);
        setMounted(true);

        if(keyByRole() !== "")
            getProdLab().then(res => {
                const data = JSON.parse(res);
                setLaboratories({ list: data ?? [], loading: false });

                handleGetData(data ?? []);
            });

        // unmounted
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    useEffect(() => {

        if(mounted)
            handleGetData(laboratories.list);

    }, [selectedLabs, selectedCountries]);

    const handleGetData = (labList = []) => {

        setLoadingStore(true);

        const products = generateFilterProdResults(selectedLabs, labList, "products");
        const countries = generateFilterResults(selectedCountries, userCountries, "name");

        Promise.all([

            getContactData(products, countries).then(res => {
                const data = JSON.parse(res);

                if(data)
                    setContactData(data[0] ?? {});
            }),

            getVisitsData(products, countries).then(res => {
                const data = JSON.parse(res);

                if(data && data['tableauMoyenneVisitesEtNombreJour'])
                    setVisitsData(data['tableauMoyenneVisitesEtNombreJour']);
            })

        ]).finally(() => {
            setLoadingStore(false);
        });
    }

    const generateFilterResults = (selection, origin, keyName) => {
        let results = [];

        if(selection === null)
            origin.forEach(el => results.push(el[keyName]));
        else
            origin.forEach(el => selection.find(item => item.value === el.id) && results.push(el[keyName]));

        return results;
    }

    const generateFilterProdResults = (selection, origin, keyName) => {
        let results = [];

        if(selection === null)
            origin.forEach(el => results.push(...el[keyName]));
        else
            origin.forEach(el => selection.find(item => item.value === el.id) && results.push(...el[keyName]));

        return results.map(el => el.id);
    }

    const handleFilters = (value, method) => {

        clearTimeout(timer.current);

        timer.current = setTimeout(() => {

            method(value);

        }, 1000);
    }

    const getColorFromDataValue = (value, asFractionFormat = false) => {

        if (asFractionFormat) {
            const splitted = value.split('/');
            value = parseFloat(splitted[0]) / parseFloat(splitted[1]);
        }

        if (parseFloat(value) < 0.9)
            return 'red';
        else if (parseFloat(value) >= 0.9 && parseFloat(value) < 1)
            return '#e6d307';
            
        return '#519529';
    }

    const getContactFromData = (data) => {
        return [
            {
                name: "Total",
                value: Math.round(parseFloat(data['NbContacts_Cibles']) * 100),
                fill: getColorFromDataValue(data['NbContacts_Cibles'])
            },
            {
                name: "A",
                value: Math.round(parseFloat(data['NbContacts_potentielA']) * 100),
                fill: getColorFromDataValue(data['NbContacts_potentielA'])
            },
            {
                name: "B",
                value: Math.round(parseFloat(data['NbContacts_potentielB']) * 100),
                fill: getColorFromDataValue(data['NbContacts_potentielB'])
            },
            {
                name: "C",
                value: Math.round(parseFloat(data['NbContacts_potentielC']) * 100),
                fill: getColorFromDataValue(data['NbContacts_potentielC'])
            },
            {
                name: "D",
                value: Math.round(parseFloat(data['NbContacts_potentielD']) * 100),
                fill: getColorFromDataValue(data['NbContacts_potentielD'])
            }
        ];
    }

    const getFrequencyFromData = (data) => {
        return [
            {
                v: 1,
                value: data['Frequences_Cibles'],
                fill: getColorFromDataValue(data['Frequences_Cibles'], true)
            },
            {
                v: 1,
                value: data['Frequences_potentielA'],
                fill: getColorFromDataValue(data['Frequences_potentielA'], true)
            },
            {
                v: 1,
                value: data['Frequences_potentielB'],
                fill: getColorFromDataValue(data['Frequences_potentielB'], true)
            },
            {
                v: 1,
                value: data['Frequences_potentielC'],
                fill: getColorFromDataValue(data['Frequences_potentielC'], true)
            },
            {
                v: 1,
                value: data['Frequences_potentielD'],
                fill: getColorFromDataValue(data['Frequences_potentielD'], true)
            }
        ];
    }

    const getVisitsFromData = (data) => {

        return MONTHS.map((m, i) => ({
            name: m,
            "Visites / j.": data[(i+1).toString().padStart(2, "0")] ? data[(i+1).toString().padStart(2, "0")]['1_MoyenneVisit'] : null
        }));
    }

    const getDaysWorkedFromData = (data) => {

        return MONTHS.map((m, i) => ({
            name: m,
            "Jours terrain": data[(i+1).toString().padStart(2, "0")] ? data[(i+1).toString().padStart(2, "0")]['2_NombreJourTravailles'] : null,
            "Jours ouvrés": data[(i+1).toString().padStart(2, "0")] ? data[(i+1).toString().padStart(2, "0")]['2_NombreJourOuvres'] : null,
            "NB jours duo": data[(i+1).toString().padStart(2, "0")] ? data[(i+1).toString().padStart(2, "0")]['2_NombreJourDuo'] : null
        }));
    }

    const getAverageRealVisits = () => {
        return visitsData ? parseFloat(visitsData['YTD']['1_MoyenneVisitReel']) : null;
    }

    const getAverageVisitsPerDayObjective = () => {
        return visitsData ? parseFloat(visitsData['YTD']['1_MoyenneVisitParJourObjectif']) : null;
    }

    const getAverageNumberOfDays = () => {
        return visitsData ? visitsData['YTD']['2_MoyenneNombreJour'] : null;
    }

    // PIE ASSETS
    const CustomPieTooltip = ({ active, payload, label }) => {
        if(active) return (
            <div className="custom-tooltip" style={{ backgroundColor: '#000', padding: '2px 5px', opacity: 0.8, borderRadius: 5 }}>
                <label style={{margin: 0, color: "#fff"}}>{`${payload[0].name} : ${payload[0].value}%`}</label>
            </div>
        );

        return null;
    };

    const isTop5 = (value, list) => {
        let first5 = list.sort((a, b) => a - b).reverse();
        return first5.slice(0, 5).find(el => el === value);
    }

    const COLORS = ['#FF6633', '#FFB399', '#FF33FF', '#00B3E6', 
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

    // CUSTOM LABEL FORMAT
    const renderCustomizedLabel = (props) => {
        const { x, y, width, height, value } = props;
      
        return value && (
            <g>
                <rect x={x} y={y - 40} width={width} height={30} fill="#fff" stroke="#4a89c1" />
                <text x={x + width / 2} y={y - 22.5} fill="#333" textAnchor="middle" dominantBaseline="middle">
                    {value}
                </text>
            </g>
        );
    };

    const renderCustomizedLineLabel = (props) => {
        const { viewBox, value, stroke, marginLeft = 0, marginTop = 0 } = props;
        const { x, y, width, height } = viewBox;
        const rect = { w: 40, h: 25 };
      
        return (
            <g>
                <rect x={width + rect.w + 25 + marginLeft} y={y - rect.h/2 + marginTop} width={rect.w} height={rect.h} fill="#fff" stroke={stroke} />
                <text x={width + rect.w + 25 + rect.w/2 + marginLeft} y={y + marginTop} fill="#333" textAnchor="middle" dominantBaseline="middle">
                    {value}
                </text>
            </g>
        );
    };

    const getFieldLegend = () => {
        let payload = [
            { id: 'Jours terrain', value: t("DB.LEGEND.field_day"), type: 'square', color: '#4273c5'},
            { id: 'Jours ouvrés', value: t("DB.LEGEND.working_day"), type: 'square', color: '#bbb'}
        ];

        if(role === "ROLE_SUPERVISEUR" || role === "ROLE_DSM")
            payload = [...payload,
                { id: 'NB jours duo', value: t("DB.LEGEND.duo"), type: 'square', color: '#FFC000'},
                { id: 'Moy. j. terrain', value: t("DB.LEGEND.moy_field_day"), type: 'line', color: '#4a89c1'}
            ];
        else
            payload = [...payload,
                { id: 'Moy. j. terrain', value: t("DB.LEGEND.moy_field_day"), type: 'line', color: '#4a89c1'}
            ];
        
        return payload;
    }

    const { positionProductMonth, positionProductYTD, repartitionVisit, listContactsMonth, listContactsYTD } = {...contactData[keyByRole()]};
    
    return (
        <section className="content dashboard">
        
            <section className="content-header">
                <h1>
                    {t('DB.TITLE')}
                    <small>{t('DB.SUBTITLE')}</small>
                </h1>
            </section>

            {keyByRole() !== "" &&
                <section className="content-dashboard">
                    
                    {role === "ROLE_DELEGUE" &&
                        <div className="nb-contact-del">
                            <div className="nb-contact-container">

                                <div className="nb-contact-box">

                                    <h2>{t("DB.nb_realises_plan")} - {t("DB.month")}</h2>

                                    {listContactsMonth &&
                                        <ResponsiveContainer width="100%" height={175}>
                                            <BarChart
                                                layout='vertical'
                                                data={getContactFromData(listContactsMonth)}
                                                margin={{
                                                    top: 0,
                                                    right: 41,
                                                    left: 0,
                                                    bottom: 20,
                                                }}
                                                >
                                                <YAxis dataKey="name" type="category" tickLine={false} axisLine={false} />
                                                <XAxis
                                                    type="number"
                                                    axisLine={false}
                                                    tickLine={false}
                                                    ticks={[0, 25, 50, 75, 100]}
                                                    domain={[0, dataMax => dataMax > 100 ? dataMax : 100]}
                                                    tickFormatter={(tick) => `${tick}%`}
                                                />
                                                <Bar
                                                    dataKey="value"
                                                    label={{ position: 'right', formatter: value => `${value}%`, fill: "#333" }}
                                                    isAnimationActive={false}
                                                    barSize={15}
                                                />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    }
                                </div>

                                {listContactsMonth &&
                                    <div className="nb-contact-box frequency">

                                        <span className="freq-title"><Trans i18nKey={"DB.freq"} /></span>

                                        <ResponsiveContainer width="100%" height={140}>
                                            <BarChart
                                            layout='vertical'
                                            width={500}
                                            height={300}
                                            data={getFrequencyFromData(listContactsMonth)}
                                            margin={{
                                                top: 0,
                                                right: 0,
                                                left: 0,
                                                bottom: 20,
                                            }}>
                                                <YAxis hide dataKey="name" type="category" tickLine={false} axisLine={{ stroke: '#000' }} />
                                                <XAxis hide type="number" />
                                                <Bar dataKey="v" isAnimationActive={false}>
                                                    <LabelList dataKey="value" style={{ fill: 'white' }} />
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                }
                            </div>
                            
                            <div className="nb-contact-container">

                                <div className="nb-contact-box">

                                    <h2>{t("DB.nb_realises_plan")} - YTD</h2>

                                    {listContactsYTD &&
                                        <ResponsiveContainer width="100%" height={175}>
                                            <BarChart
                                                layout='vertical'
                                                data={getContactFromData(listContactsYTD)}
                                                margin={{
                                                    top: 0,
                                                    right: 30,
                                                    left: 0,
                                                    bottom: 20,
                                                }}
                                            >
                                                <YAxis dataKey="name" type="category" tickLine={false} axisLine={false}  />
                                                <XAxis
                                                    type="number"
                                                    axisLine={false}
                                                    tickLine={false}
                                                    ticks={[0, 25, 50, 75, 100]}
                                                    domain={[0, dataMax => dataMax > 100 ? dataMax : 100]}
                                                    tickFormatter={(tick) => `${tick}%`}
                                                />
                                                <Bar
                                                    dataKey="value"
                                                    label={{ position: 'right', formatter: value => `${value}%`, fill: "#333" }}
                                                    isAnimationActive={false}
                                                    fillOpacity="0.5"
                                                    barSize={15}
                                                />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    }
                                </div>

                                {listContactsYTD &&
                                    <div className="nb-contact-box frequency">

                                        <span className="freq-title"><Trans i18nKey={"DB.freq"} /></span>

                                        <ResponsiveContainer width="100%" height={140}>
                                            <BarChart
                                                layout='vertical'
                                                data={listContactsYTD && getFrequencyFromData(listContactsYTD)}
                                                margin={{
                                                    top: 0,
                                                    right: 0,
                                                    left: 0,
                                                    bottom: 20,
                                                }}
                                            >
                                                <YAxis hide dataKey="name" type="category" tickLine={false} axisLine={{ stroke: '#000' }} />
                                                <XAxis hide type="number" />
                                                <Bar dataKey="v" isAnimationActive={false} fillOpacity="0.5" >
                                                    <LabelList dataKey="value" style={{ fill: 'white' }} />
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    {(keyByRole() === "role_labo" || keyByRole() === "role_pays") &&
                        <div className='contacts-notDel'>

                            <div className='filters row'>

                                <Select
                                    className="react-select col-md-4"
                                    placeholder={t("DB.all_lab")}
                                    isMulti
                                    isClearable
                                    options={laboratories.list.map(el => ({
                                        value: el.id,
                                        label: el.name
                                    }))} 
                                    onChange={(value) => handleFilters(value, setSelectedLabs)}
                                    isLoading={laboratories.loading}
                                    closeMenuOnSelect={false}
                                />
                                
                                {keyByRole() === "role_pays" &&
                                    <Select
                                        className="react-select col-md-4"
                                        placeholder={t("DB.all_countries")}
                                        isMulti
                                        isClearable
                                        options={userCountries.map(el => ({
                                            value: el.id,
                                            label: el.name
                                        }))} 
                                        onChange={(value) => handleFilters(value, setSelectedCountries)}
                                        closeMenuOnSelect={false}
                                    />
                                }
                                
                            </div>

                            <div className='tablesContacts'>
                                <div className='month'>

                                    <h2>{t("DB.nb_realises_plan")} - {t("DB.month")}</h2>

                                    {listContactsMonth &&
                                        <TabContacts
                                            period="month"
                                            data={listContactsMonth}
                                            labs={keyByRole() === "role_labo" ? laboratories.list : []}
                                            countries={keyByRole() === "role_pays" ? userCountries : []}
                                        />
                                    }

                                </div>

                                <div className='ytd'>

                                    <h2>{t("DB.nb_realises_plan")} - YTD</h2>

                                    {listContactsYTD &&
                                        <TabContacts
                                            period="ytd"
                                            data={listContactsYTD}
                                            labs={keyByRole() === "role_labo" ? laboratories.list : []}
                                            countries={keyByRole() === "role_pays" ? userCountries : []}
                                        />
                                    }
                                    
                                </div>
                            </div>

                        </div>
                    }

                    <div className="visits-graphs">
                        <div className="moy-visit">

                            <h2>{t("DB.moy_visit")}</h2>

                            {visitsData &&
                                <ResponsiveContainer width="100%" height={325}>
                                    <BarChart
                                        data={getVisitsFromData(visitsData)}
                                        margin={{
                                            // top: 40,
                                            top: 30,
                                            right: 45,
                                            left: 0,
                                            bottom: 70,
                                        }}
                                        barCategoryGap={"15%"}
                                    >
                                        <XAxis dataKey="name" angle={-45} dy={30} tickLine={false} />
                                        <YAxis
                                            type="number"
                                            axisLine={false}
                                            tickLine={false}
                                            domain={[0, dataMax => 2 * Math.ceil(Math.max(dataMax, getAverageRealVisits(), getAverageVisitsPerDayObjective()) / 2) ]}
                                        />
                                        <Tooltip />
                                        <Legend wrapperStyle={{position: 'relative', marginTop: '30px'}} payload={
                                            [
                                                { id: 'Visites / j.', value: t("DB.LEGEND.visit_j"), type: 'square', color: '#4273c5'},
                                                { id: 'Moy. visites / j.', value: t("DB.LEGEND.moy_visit_j"), type: 'line', color: '#4a89c1'},
                                                { id: 'Obj. visites / j.', value: t("DB.LEGEND.obj_visit_j"), type: 'line', color: '#bbb'},
                                            ]
                                        } />
                                        <Bar dataKey="Visites / j." isAnimationActive={false} fill="#4273c5" label={{position: "top"}}>
                                            {/* <LabelList dataKey="Visites / j." content={renderCustomizedLabel} /> */}
                                        </Bar>
                                        <ReferenceLine
                                            y={getAverageRealVisits()}
                                            stroke="#4a89c1"
                                            strokeWidth={4}
                                        >
                                            <Label value={getAverageRealVisits()} stroke="#4a89c1" content={renderCustomizedLineLabel}/>
                                        </ReferenceLine>
                                        <ReferenceLine
                                            y={getAverageVisitsPerDayObjective()}
                                            stroke="#bbb"
                                            strokeWidth={4}
                                        >
                                            <Label value={getAverageVisitsPerDayObjective()} stroke="#bbb" marginLeft={-40} marginTop={-20} content={renderCustomizedLineLabel}/>
                                        </ReferenceLine>
                                    </BarChart>
                                </ResponsiveContainer>
                            }

                        </div>

                        <div className="nb-jour-terrain">

                            <h2>{t("DB.nb_jour_terrain")}</h2>

                            {visitsData &&
                                <ResponsiveContainer width="100%" height={325}>
                                    <BarChart
                                        data={getDaysWorkedFromData(visitsData)}
                                        margin={{
                                            // top: 40,
                                            top: 30,
                                            right: 45,
                                            left: 0,
                                            bottom: 70,
                                        }}
                                    >
                                        <XAxis dataKey="name" angle={-45} dy={30} tickLine={false} />
                                        <YAxis
                                            type="number"
                                            axisLine={false}
                                            tickLine={false}
                                            domain={[0, dataMax => 2 * Math.ceil(Math.max(dataMax, getAverageNumberOfDays()) / 2) ]}
                                        />
                                        <Tooltip />
                                        <Legend wrapperStyle={{ position: 'relative', marginTop: '30px' }} payload={getFieldLegend()} />
                                        <Bar dataKey="Jours terrain" label={{ position: 'top' }} isAnimationActive={false} fill="#4273c5" />
                                        <Bar dataKey="Jours ouvrés" label={{ position: 'top' }} isAnimationActive={false} fill="#bbb" />
                                        {(role === "ROLE_SUPERVISEUR" || role === "ROLE_DSM") &&
                                            <Bar dataKey="NB jours duo" label={{ position: 'top' }} isAnimationActive={false} fill="#FFC000" />
                                        }
                                        <ReferenceLine
                                            y={getAverageNumberOfDays()}
                                            stroke="#4a89c1"
                                            strokeWidth={4}
                                        >
                                            <Label value={getAverageNumberOfDays()} stroke="#4a89c1" content={renderCustomizedLineLabel}/>
                                        </ReferenceLine>
                                    </BarChart>
                                </ResponsiveContainer>
                            }
                        </div>
                    </div>

                    <div className='position-prod'>

                        <div className='month'>

                            <h2>{t('DB.position')} - {t('DB.month')}</h2>

                            { positionProductMonth && Object.values(positionProductMonth).length > 0 ?
                                <TablePosition data={positionProductMonth} />
                                : <p>{t("DB.no_data")}</p>
                            }
                        </div>

                        <div className='ytd'>

                            <h2>{t('DB.position')} - YTD</h2>

                            { positionProductYTD && Object.values(positionProductYTD).length > 0 ?
                                <TablePosition data={positionProductYTD} />
                                : <p>{t("DB.no_data")}</p>
                            }
                        </div>

                    </div>

                    {repartitionVisit && repartitionVisit[0] &&
                        <div className='repart-visit'>

                            <h2 style={{ textAlign: "center" }}>{t("DB.repart_visit")}</h2>
                        
                            <ResponsiveContainer width="100%" height={window.innerWidth <= 1600 ? 300 : 400}>
                                <PieChart>
                                    <Pie
                                        data={Object.values(repartitionVisit[0])}
                                        color="#000000"
                                        dataKey="nbrVisit"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={window.innerWidth <= 1600 ? 100 : 120}
                                        fill="#8884d8"
                                        label={(entry) => isTop5(entry.value, Object.values(repartitionVisit[0]).map(el => el.nbrVisit)) &&
                                            `${entry.name} : ${entry.value}%`
                                        }
                                        labelLine={false}
                                        isAnimationActive={false}
                                    >
                                        {Object.values(repartitionVisit[0]).map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>

                                    <Tooltip content={<CustomPieTooltip />} />
                                </PieChart>
                            </ResponsiveContainer>

                        </div>
                    }

                </section>
            }

        </section>
    )
}