import { useState, useEffect, useRef } from 'react';
import { CustomSelect } from '../../UI/CustomSelect/CustomSelect';
import { NotificationManager} from 'react-notifications';

// components
import { ButtonAdd } from '../../UI/Button/ButtonAdd/ButtonAdd';

// images
import iconDelete from "../../../assets/img/icons/icon-delete.png";
import iconSave from "../../../assets/img/icons/icon-save.png";

import {
    getAllSpecialite,
    getAllProType,

    postAnswer,
    deleteAnswer
} from '../../../store/index.service';

export const PopupAddEditQuestions = (props) => {
    const { question, getAllQuestions, handleSubmit, editQuestion, addQuestion } = props;

    const [text, setText] = useState("");
    const [typeRep, setTypeRep] = useState(-1);
    const [listSpe, setListSpe] = useState([]);
    const [listePDS, setListePDS] = useState([]);
    const [minAnswer, setMinAnswer] = useState("");
    const [maxAnswer, setMaxAnswer] = useState("");
    const [codeRep, setCodeRep] = useState("");
    const [repPossible, setRepPossible] = useState("");
    const [repPosTab, setRepPosTab] = useState([]);
    const [speTab, setSpeTab] = useState([]);
    const [pdsTab, setPdsTab] = useState([]);
    const [selectedSpe, setSelectedSpe] = useState({ nameSpeciality: "Sélectionnez un spécialité" });
    const [selectedPDS, setSelectedPDS] = useState({ nameMedicalprotype: "Sélectionnez un type de profesionnel de santé" });

    const edit = Object.keys(question).length > 0;
    const btnRef = useRef(null);

    // mounted
    useEffect(() => {
        getAllSpecialite().then(res => {
            const data = JSON.parse(res);
            if(data.listSpeciality) setSpeTab(data.listSpeciality);
            else setSpeTab([]);
        });
        getAllProType().then(res => {
            const data = JSON.parse(res);
            if(data) setPdsTab(data);
            else setPdsTab([]);
        });
    }, []);

    // updates
    useEffect(() => {
        setText(edit ? question.name : "");
        setTypeRep(edit ? question.answertypeQuestion === "CTM" ? "1" : "0" : -1);
        setMinAnswer(edit ? question.minanswerQuestion : "");
        setMaxAnswer(edit ? question.maxanswerQuestion : "");
        setRepPosTab(edit ? question.answer : []);
        setListSpe(edit ? question.speciality : []);
        setListePDS(edit ? question.medicalprotype : []);
        setCodeRep("");
        setRepPossible("");
        setSelectedSpe({ nameSpeciality: "Sélectionnez une spécialité" });
        setSelectedPDS({ nameMedicalprotype: "Sélectionnez un type de professionnel de santé" });
    }, [question]);

    // functions

    const submitForm = (e) => {
        e.preventDefault();
        if(text !== "" && typeRep !== -1) {
            if(typeRep === "0") { //type liste
                if(repPosTab.length > 0) {
                    let spe = listSpe.map((s) => s.idSpeciality);
                    let pds = listePDS.map((p) => p.idMedicalprotype);
                    let answer = repPosTab.map((r) => r.idAnswer);

                    if(edit) editQuestion(text, 0, 0, "0", spe, pds, answer);
                    else addQuestion(text, 0, 0, "0", spe, pds, answer);

                    btnRef.current.click();
                }
            } else {    //type libre
                if(minAnswer !== "" && maxAnswer !== "") {
                    let spe = listSpe.map((s) => s.idSpeciality);
                    let pds = listePDS.map((p) => p.idMedicalprotype);

                    if(edit) editQuestion(text, minAnswer, maxAnswer, "CTM", spe, pds, []);
                    else addQuestion(text, minAnswer, maxAnswer, "CTM", spe, pds, []);

                    btnRef.current.click();
                }
            }
        }
    }

    const handleAddSpe = (e) => {
        e.preventDefault();
        if(selectedSpe.nameSpeciality !== "Sélectionnez une spécialité") {
            setListSpe([...listSpe, selectedSpe]);
            setSelectedSpe({ nameSpeciality: "Sélectionnez une spécialité" });
        }
    }

    const removeSpe = (id) => {
        let spe = [...listSpe];
        let selectS = spe.map((s) => s.idSpeciality).indexOf(id);
        if(selectS !== -1) spe.splice(selectS, 1);
        setListSpe(spe);
    }

    const handleAddPDS = (e) => {
        e.preventDefault();
        if(selectedPDS.nameMedicalprotype !== "Sélectionnez un type de professionnel de santé") {
            setListePDS([...listePDS, selectedPDS]);
            setSelectedPDS({ nameMedicalprotype: "Sélectionnez un type de professionnel de santé" });
        }
    }

    const removePDS = (id) => {
        let pds = [...listePDS];
        let selectP = pds.map((p) => p.idMedicalprotype).indexOf(id);
        if(selectP !== -1) pds.splice(selectP, 1);
        setListePDS(pds);
    }

    const handleAddAnswer = (e) => {
        e.preventDefault();
        if(codeRep !== "" && repPossible !== "") {
            postAnswer(question.id, codeRep, repPossible).then(res => {
                const idAnswer = JSON.parse(res);

                if(parseInt(idAnswer) !== -1) {
                    let answers = [...repPosTab];
                    answers.push({
                        idAnswer: idAnswer,
                        nameAnswer: repPossible,
                        codeAnswer: codeRep
                    });

                    getAllQuestions();

                    setRepPossible("");
                    setCodeRep("");
                    setRepPosTab(answers);

                    NotificationManager.success('', "Réponse ajoutée");
                }
            }).catch(() => NotificationManager.error('', "Erreur"));
        }
    }

    const removeAnswer = (id) => {
        getAllQuestions();

        let rep = [...repPosTab];
        let selectR = rep.map((r) => r.idAnswer).indexOf(id);
        if(selectR !== -1) rep.splice(selectR, 1);
        setRepPosTab(rep);

        NotificationManager.success('', "Réponse supprimée");
    }

    return(
        <div className="modal fade in" id="modalAddEditQuestion" tabIndex="-1" role="dialog" aria-labelledby="modalAddEditQuestionLabel" animate="animate">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title" id="myModalLabel">{edit ? "Modifier" : "Ajouter"} une question</h1>
                    </div>
                    <div className="modal-body">
                        <form id="formAddEditQuestion">

                            <div className="nav-tabs-custom">
                                <ul className="nav nav-tabs">
                                    <li><a href="#tab_1" data-toggle="tab" className="active">Général</a></li>
                                    <li><a href="#tab_2" data-toggle="tab">Spécialités</a></li>
                                    <li><a href="#tab_3" data-toggle="tab">Types de PDS</a></li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane active" id="tab_1">
                                        <label htmlFor="name">Question</label>
                                        <input type="text" required value={text} onChange={(e) => setText(e.target.value)}/>

                                        <label>Type de réponse</label>
                                        <div className="radio">
                                            <input
                                            type="radio"
                                            name="typeRep"
                                            checked={typeRep === "0"}
                                            value="0"
                                            onChange={(e) => setTypeRep(e.target.value)}
                                            id="repListe"
                                            />
                                            <label htmlFor="repListe">Liste</label>
                                        </div>
                                        <div className="radio">
                                            <input
                                            type="radio"
                                            name="typeRep"
                                            checked={typeRep === "1"}
                                            value="1"
                                            onChange={(e) => setTypeRep(e.target.value)}
                                            id="repLibre"
                                            />
                                            <label htmlFor="repLibre">Libre</label>
                                        </div>

                                        {typeRep === "1" ? (
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <label htmlFor="name">Borne inférieure</label>
                                                    <input type="text" required value={minAnswer} placeholder='Valeur minimum attendue' onChange={(e) => setMinAnswer(e.target.value)}/>
                                                </div>
                                                <div className="col-md-5">
                                                    <label htmlFor="name">Borne supérieure</label>
                                                    <input type="text" required value={maxAnswer} placeholder='Valeur maximum attendue' onChange={(e) => setMaxAnswer(e.target.value)}/>
                                                </div>
                                            </div>
                                        ) : typeRep === "0" &&
                                            <>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label htmlFor="name">Réponses possibles à ajouter</label>
                                                    </div>
                                                    <div className="ajoutRep">
                                                        <div className="col-md-2">
                                                            <input type="text" name="codeRep" required value={codeRep} placeholder="Code" onChange={(e) => setCodeRep(e.target.value)} />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <input type="text" name="repPossible" required value={repPossible} placeholder="Réponse possible" onChange={(e) => setRepPossible(e.target.value)}/>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <ButtonAdd handleAdd={handleAddAnswer} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <table className="list answers">
                                                    <tbody>
                                                    {repPosTab.map((rep, i) => {
                                                        return (
                                                            <tr key={i} className="list-row">
                                                                <td className="first">{rep.nameAnswer}</td>
                                                                <td className="icons last">
                                                                    <div className="icon-btn"
                                                                    onClick={() => deleteAnswer(rep.idAnswer).then(res => {
                                                                        removeAnswer(parseInt(JSON.parse(res)));
                                                                    }).catch(() => NotificationManager.error('', "Erreur"))}>
                                                                        <img src={iconDelete} alt="x"/>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                    </div>
                                    <div className="tab-pane" id="tab_2">
                                        <CustomSelect
                                        defaultText={selectedSpe.nameSpeciality}
                                        optionsList={speTab}
                                        handleOptionClick={(id, nameSpe) => setSelectedSpe({ idSpeciality: id, nameSpeciality: nameSpe })}
                                        />
                                        <ButtonAdd handleAdd={handleAddSpe} />

                                        <table className="list questSpe">
                                            <tbody>
                                            {listSpe.map((spe, i) => {
                                                return (
                                                    <tr key={i} className="list-row">
                                                        <td className="first">{spe.nameSpeciality}</td>
                                                        <td className="icons last">
                                                            <div className="icon-btn"
                                                            onClick={() => removeSpe(spe.idSpeciality)}>
                                                                <img src={iconDelete} alt="x"/>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="tab-pane" id="tab_3">
                                        <CustomSelect
                                        defaultText={selectedPDS.nameMedicalprotype}
                                        optionsList={pdsTab}
                                        handleOptionClick={(id, nameType) => setSelectedPDS({ idMedicalprotype: id, nameMedicalprotype: nameType })}
                                        />
                                        <ButtonAdd handleAdd={handleAddPDS} />
                                        
                                        <table className="list questPDS">
                                            <tbody>
                                            {listePDS.map((pds, i) => {
                                                return (
                                                    <tr key={i} className="list-row">
                                                        <td className="first">{pds.nameMedicalprotype}</td>
                                                        <td className="icons last">
                                                            <div className="icon-btn"
                                                            onClick={() => removePDS(pds.idMedicalprotype)}>
                                                                <img src={iconDelete} alt="x"/>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button
                        type="submit"
                        form="formAddEditQuestion"
                        className="btn-save"
                        data-toggle={ edit ? 'modal' : '' }
                        data-target={'#modalConfirmation'}
                        disabled={text === "" || typeRep === -1}
                        onClick={(e) => {
                            e.preventDefault();
                            if(edit) handleSubmit(submitForm);
                            else submitForm(e);
                        }}>
                            <img src={iconSave} alt=""/>
                            Sauvegarder
                        </button>
                        <button
                        type="button"
                        className="btn-cancel"
                        data-dismiss="modal"
                        ref={btnRef}
                        onClick={() => {
                            setText(edit ? question.name : "");
                            setTypeRep(edit ? question.answertypeQuestion === "CTM" ? "1" : "0" : -1);
                            setMinAnswer(edit ? question.minanswerQuestion : "");
                            setMaxAnswer(edit ? question.maxanswerQuestion : "");
                            setRepPosTab(edit ? question.answer : []);
                            setListSpe(edit ? question.speciality : []);
                            setListePDS(edit ? question.medicalprotype : []);
                            setCodeRep("");
                            setRepPossible("");
                            setSelectedSpe({ nameSpeciality: "Sélectionnez une spécialité" });
                            setSelectedPDS({ nameMedicalprotype: "Sélectionnez un type de professionnel de santé" });
                        }}
                        >Annuler</button>
                    </div>
                </div>
            </div>
        </div>
    )
}