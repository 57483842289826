import { useState, useEffect, useRef } from 'react';
import Select from 'react-select';

import {
    getAllLabo
} from '../../../store/index.service';

// components
import { CustomSelect } from '../../UI/CustomSelect/CustomSelect';

// images
import iconSave from "../../../assets/img/icons/icon-save.png";

export const PopupResProd = (props) => {

    const { resProd, handleSubmit, listProducts, searchProduct, postResProd, editResProd, isLoading, config } = props;

    const [prod, setProd] = useState(null);
    const [labList, setLabList] = useState([]);
    const [loadingLab, setLoadingLab] = useState(true);
    const [lab, setLab] = useState(null);
    const [pres, setPres] = useState("");
    const [mounted, setMounted] = useState(false);

    const btnRef = useRef(null);
    const timer = useRef(null);
    const edit = Object.keys(resProd).length > 0;

    // mounted
    useEffect(() => {
        setMounted(true);
        handleGetAllLabo();
        timer.current = setTimeout(() => {}, 1000);
        // unmounted
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    // updates
    useEffect(() => {

        setProd(null);
        setLab(null);
        setPres(edit ? resProd.presentation === 1 ? true : resProd.presentation === 0 ? false : "" : "");

    }, [resProd]);

    useEffect(() => {

        if(mounted)
            searchProduct("", lab);

    }, [lab]);

    // functions

    const handleGetAllLabo = (valName = "") => {
        setLoadingLab(true);
        getAllLabo({ laboName: valName, pagination: false }).then(res => {
            const data = JSON.parse(res);
            if(data)
                setLabList(data);
        }).finally(() => setLoadingLab(false))
    }

    const submitForm = () => {

        if((!edit && prod && pres !== "") || (edit && pres !== "")) {
            if(edit)
                editResProd(resProd.idNetworkProduct, pres);
            else
                postResProd(prod.map(el => el.value), pres);
            
            btnRef.current.click();
        }
    }

    const handleFilter = (val) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            if(lab === null)
                searchProduct(val);
        }, 1000);
    }

    return(
        <div className="modal fade in" id="modalAddEditResProd" tabIndex="-1" role="dialog" aria-labelledby="modalAddEditResProdLabel" animate="animate">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title" id="myModalLabel">{edit ? "Modifier" : "Ajouter"} un produit</h1>
                    </div>
                    <div className="modal-body">
                        <form id="formAjout">
                            {!edit && <>
                                {config === "default" &&
                                    <div>
                                        <label>Filtrer les produits par laboratoire <small>(non-requis)</small></label>
                                        <Select
                                            options={labList.map(p => ({
                                                value: p.id,
                                                label: p.name
                                            }))}
                                            value={lab}
                                            onChange={newValue => setLab(newValue)}
                                            placeholder="Sélectionnez un laboratoire"
                                            isClearable
                                            className="react-select-container"
                                            isLoading={loadingLab}
                                        />
                                    </div>
                                }
                                <div>
                                    <label>Produit</label>
                                    <Select
                                        options={listProducts.map(p => ({
                                            value: p.idProduct,
                                            label: p.nameProduct
                                        }))}
                                        value={prod}
                                        onInputChange={value => handleFilter(value)}
                                        onChange={newValue => setProd(newValue)}
                                        placeholder="Sélectionnez un produit"
                                        isClearable
                                        className="react-select-container"
                                        isLoading={isLoading}
                                        isMulti
                                    />
                                </div>
                            </>}

                            <label>Type de présentation par défaut</label>
                            <CustomSelect
                            defaultText={pres ? "Présentation" : pres === false ? "Rappel" : "Sélectionez un type de présentation"}
                            optionsList={[
                                { id: 0, name: "Rappel" },
                                { id: 1, name: "Présentation" }
                            ]}
                            handleOptionClick={id => setPres(id === "1")}
                            />
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button 
                            type="submit" 
                            form="formAjout" 
                            className="btn-save" 
                            disabled={(!edit && prod === null) || pres === ""}
                            data-toggle={ edit ? 'modal' : '' }
                            data-target={'#modalConfirmation'}
                            onClick={e => {
                                e.preventDefault();
                                if(edit) handleSubmit(submitForm);
                                else submitForm();
                            }}
                        >
                            <img src={iconSave} alt=""/>
                            Sauvegarder
                        </button>

                        <button
                            type="button"
                            className="btn-cancel"
                            data-dismiss="modal"
                            ref={btnRef}
                            onClick={() => {
                                setProd(null);
                                setPres(edit ? resProd.presentation === 1 ? true : resProd.presentation === 0 ? false : "" : "");
                            }}
                        >Annuler</button>
                    </div>
                </div>
            </div>
        </div>
    )
}