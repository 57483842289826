import { useContext } from 'react';
import { PopUpStoreContext } from '../../../../store/store/storePopUp';
import { ActionsStoreContext } from '../../../../store/store/storeActions';

import "./ButtonAdd.scss";

export const ButtonAdd = ({ disabled = false, handleAdd = () => {}, id, modal = "", type }, props) => {
    const { statePopUp, dispatchPopUp } = useContext(PopUpStoreContext);
    const { stateActions, dispatchActions } = useContext(ActionsStoreContext);
    const { dataPopUp, dataPopUpSup } = statePopUp;

    const handleClickAdd = (event) => {
        handleAdd(id ? id : event);

        if(type === 'medicalProsInstitutions') {
            let dDataPopUpSup = {
                type: type,
                action: 'add',
                isActive: !dataPopUpSup.isActive
            }
            dispatchPopUp( {type: 'setPopUpSup', dDataPopUpSup } );
        }else if(typeof type !== "undefined") {
            let dDataPopUp = {
                type: type,
                action: 'add',
                isActive: !dataPopUp.isActive
            }
            dispatchPopUp( {type: 'setPopUp', dDataPopUp } );
        }
        let aDataActions = stateActions.dataActions

        dispatchActions({type: 'setDataActions', aDataActions });

    }
    return(
        <>
        {dataPopUp.action !== 'visu' && 
            <button
                className="btn-add"
                disabled={disabled}
                data-toggle={disabled ? "" : "modal"}
                data-target={modal}
                onClick={(e) => handleClickAdd(e)}
            >
            +
            </button>
        }
        </>
    )
}