import { useRef, useEffect } from 'react';
import Select from 'react-select';
import { useContext } from 'react';
import { FilterStoreContext } from '../../../../store/store/storeFilters';
import { t } from '../../../../pages/i18nFunctions';

import {
    generateZGOptions,
    generateEtabOptions,
} from "../../../../store/helpers";

import './index.scss';

export const SearchSelect = (props) => {
    const { el, handleChange, type } = props;
    const { stateFilter, dispatchFilters } = useContext(FilterStoreContext);

    let lang = localStorage.getItem('currentLanguage') !== null ? localStorage.getItem('currentLanguage') : 'fr';

    const timer = useRef(null);

    // mounted
    useEffect(() => {
        timer.current = setTimeout(() => {}, 1000);
        // unmounted
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const generateOptions = (name, data, dataUtil = null) => {
        if(stateFilter.memoSearchCountry !== null && stateFilter.memoSearchCountry.country !== null)
            if(name === 'zoneGeo')
                return data = generateZGOptions(data, stateFilter.memoSearchCountry.country, stateFilter.memoSearchCountry.countryName)
            else if(name === 'location' && dataUtil !== null) {
                return data = generateEtabOptions(data, dataUtil);
            }
        let options = [];

        if(data !== null && data.length > 0) {
            switch (name) {
                case 'protype':
                case 'type':
                case 'country':
                    data.forEach((option, i) => {
                        options.push({
                            value: option.id, 
                            label: option.name 
                        });
                    });
                    return options;

                case 'mission':
                    data.forEach((option, i) => {
                        options.push({
                            value: option.id, 
                            label: option.name,
                            ad: option.affectation.namePosition
                        });
                    });
                    return options;

                case 'zoneGeo':
                case 'location':
                    return options = data;

                case 'speciality':
                    data.forEach((option, i) => {
                        options.push({
                            value: option.id, 
                            label: option.name,
                            ad: option.medicalprotype.nameMedicalProType
                        });
                    });
                    return options;

                default:
                    break;
            }
        }
    } 

    const generateFormatOptionLabel = (name, option, context) => {
        if(name === 'location')
            return context === 'menu' ? (
                <div className="select-react-option etab">
                    <div>
                        <div className={`tag ${option.isPrivate === 1 ? "valid" : option.isPrivate === 2 ? "prive" : option.isPrivate === 3 ? "warning" : "inconnu"}`}>
                            <p>
                                {option.isPrivate === 1 ? t('PROS.LIST.RESEARCH.PUBLIC')
                                : option.isPrivate === 2 ? t('PROS.LIST.RESEARCH.PRIVATE')
                                : option.isPrivate === 3 ? t('PROS.LIST.RESEARCH.SEMI_PRIVATE')
                                : t('COMMON.UNKNOWN')}
                            </p>
                        </div>
                        <p>{option.ref} - {option.label}</p>
                    </div>
                    <small>{option.ad}</small>
                </div>
            ) : option.label;

        else 
            return context === 'menu' ? (
                <div className="select-react-option">
                    <p>{option.label}</p>
                    <small>{option.ad}</small>
                </div>
            ) : option.label
    }

    const generateDefaultValue = (name) => {

        if(name === 'country')
            return stateFilter.memoSearchCountry.country ?
                {
                    value: stateFilter.memoSearchCountry.country,
                    label: stateFilter.memoSearchCountry.countryName
                }
            : null;
        
        else
            return false;

    }

    const handleFilter = (value, key) => {
        
        clearTimeout(timer.current);

        timer.current = setTimeout(() => {

            if(key === "location")
                el.getListFilter({ label: value });

        }, 1000);
    }

    return(
        <div className="containerSearchInput">
            {
                typeof el !== 'undefined' && stateFilter.memoSearchCountry !== null &&
                <>
                    <label>{el.displayName}</label>
                    <Select 
                        className='selectSearchBar' 
                        classNamePrefix='selectSearch' 
                        // isClearable={type === 'etab' && el.name === "country" ? false : true}
                        isClearable
                        placeholder={el.placeholder}
                        defaultValue={generateDefaultValue(el.name)} 
                        options={generateOptions(el.name, el.data, el.dataUtil)} 
                        onChange={(e, type) => handleChange(e, type)} 
                        onInputChange={(value) => handleFilter(value, el.name)}
                        isLoading={el.loading}

                        formatOptionLabel={ (option, {context}) => generateFormatOptionLabel(el.name, option, context)}
                        
                        formatGroupLabel={(data) => (<div><span>{data.label}</span></div>)}
                    />
                </>
            }
        </div>
    )
}
