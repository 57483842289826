export const Notif = (props) => {
    const { response, code } = props;

    const genType = () => {
        switch (code) {
            case 200:
            case '200':
                return 'success';
        
            default:
                return 'warning';
        }
    }
    
    return(
        <>
            <div className={`notif alert alert-${genType()}`}>
                {response}
            </div>
        </>
    )
}