import { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import "./Rapport1.scss";
import { t } from "../../i18nFunctions";
import { StoreContext } from '../../../store/store';
import { format } from 'date-fns';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';

import { MultiCheckbox } from '../../../components/UI/Checkbox/MultiCheckbox/MultiCheckbox';
import ReactLoading from 'react-loading';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { fr } from 'react-date-range/dist/locale';
import { enUS } from 'react-date-range/dist/locale';

// API
import {
    getProdLabByMission,
    getRapport1
} from "../../../store/index.service";

const Rapport1Table = (props) => {
    const { infos } = props;

    return (
        <div className='rapport1-tab first-tab'>
            <table className='rapport-1'>
                <thead>
                    <tr>
                        <td style={{ backgroundColor: 'transparent' }}></td>
                        <td>{t('SUIVI.RAPPORT.CIBLE')}</td>
                        <td>A</td>
                        <td>B</td>
                        <td>C</td>
                        <td>D</td>
                        <td>{t('SUIVI.HORS_CIBLE')}</td>
                        <td>{t('SUIVI.RAPPORT.TOTAL')}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='left-side-column'>{t('SUIVI.RAPPORT.CALLS')}</td>
                        <td>{ infos ? <>
                            {infos.NbContacts_Cibles.split("/")[0]}<b>/{infos.NbContacts_Cibles.split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? <>
                            {infos.NbContacts_potentielA.split("/")[0]}<b>/{infos.NbContacts_potentielA.split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? <>
                            {infos.NbContacts_potentielB.split("/")[0]}<b>/{infos.NbContacts_potentielB.split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? <>
                            {infos.NbContacts_potentielC.split("/")[0]}<b>/{infos.NbContacts_potentielC.split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? <>
                            {infos.NbContacts_potentielD.split("/")[0]}<b>/{infos.NbContacts_potentielD.split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? infos.NbContacts_HorsCibles : '...'}</td>
                        <td>{ infos ? infos.Calls_total : '...' }</td>
                    </tr>
                    <tr>
                        <td className='left-side-column'>{t('SUIVI.RAPPORT.MOY_CALLS')}</td>
                        <td>{ infos ? <>
                            {infos["Moy.Contacts/Jour_Cibles"].split("/")[0]}<b>/{infos["Moy.Contacts/Jour_Cibles"].split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? <>
                            {infos["Moy.Contacts/Jour_potentielA"].split("/")[0]}<b>/{infos["Moy.Contacts/Jour_potentielA"].split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? <>
                            {infos["Moy.Contacts/Jour_potentielB"].split("/")[0]}<b>/{infos["Moy.Contacts/Jour_potentielB"].split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? <>
                            {infos["Moy.Contacts/Jour_potentielC"].split("/")[0]}<b>/{infos["Moy.Contacts/Jour_potentielC"].split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? <>
                            {infos["Moy.Contacts/Jour_potentielD"].split("/")[0]}<b>/{infos["Moy.Contacts/Jour_potentielD"].split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? infos["Moy.Contacts/Jour_HorsCibles"] : '...'}</td>
                        <td>{ infos ? infos.Moyenne_total : '...' }</td>
                    </tr>
                    <tr>
                        <td className='left-side-column'>{t('SUIVI.RAPPORT.HCP_VISITS')}</td>
                        <td>{ infos ? <>
                            {infos.NbHcpUnique_Cibles.split("/")[0]}<b>/{infos.NbHcpUnique_Cibles.split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? <>
                            {infos.NbHcpUnique_potentielA.split("/")[0]}<b>/{infos.NbHcpUnique_potentielA.split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? <>
                            {infos.NbHcpUnique_potentielB.split("/")[0]}<b>/{infos.NbHcpUnique_potentielB.split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? <>
                            {infos.NbHcpUnique_potentielC.split("/")[0]}<b>/{infos.NbHcpUnique_potentielC.split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? <>
                            {infos.NbHcpUnique_potentielD.split("/")[0]}<b>/{infos.NbHcpUnique_potentielD.split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? infos.NbHcpUnique_HorsCibles : '...'}</td>
                        <td>{ infos ? infos.Pds_total : '...' }</td>
                    </tr>
                    <tr>
                        <td className='left-side-column'>{t('SUIVI.FREQ')}</td>
                        <td>{ infos ? <>
                            {infos.Frequences_Cibles.split("/")[0]}<b>/{infos.Frequences_Cibles.split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? <>
                            {infos.Frequences_potentielA.split("/")[0]}<b>/{infos.Frequences_potentielA.split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? <>
                            {infos.Frequences_potentielB.split("/")[0]}<b>/{infos.Frequences_potentielB.split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? <>
                            {infos.Frequences_potentielC.split("/")[0]}<b>/{infos.Frequences_potentielC.split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? <>
                            {infos.Frequences_potentielD.split("/")[0]}<b>/{infos.Frequences_potentielD.split("/")[1]}</b>
                            </> : '...'}
                        </td>
                        <td>{ infos ? infos.Frequences_HorsCibles : '...'}</td>
                        <td>{ infos ? infos.Frequence_totale : '...' }</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export const Rapport1 = () => {

    const { setLoadingStore } = useContext(StoreContext);

    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const timer = useRef(null);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate()+1)));
    const [key, setKey] = useState("selection");
    const [calendarIsHidden, setCalendarIsHidden] = useState(true);
    const [enteringDate, setEnteringDate] = useState(false);
    const [rapportReady, setRapportReady] = useState(false);

    const [prodList, setProdList] = useState([]);
    const [loadingProdLabMission, setLoadingProdLabMission] = useState(true);
    const [prodTab, setProdTab] = useState([]);
    const [rapportInfo, setRapportInfo] = useState();

    useEffect(() => {
        getProdLabByMission(id).then(res => {
            const data = JSON.parse(res);
            if(data) setProdList(data);
            setLoadingProdLabMission(false);
        });

        timer.current = setTimeout(() => {}, 1000);

        // unmounted
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    useEffect(() => {
        getMissionList(startDate, endDate);
    }, [prodTab]);

    const handleGetRapport1 = (start, end) => {
        setLoadingStore(true);
        setRapportReady(false);

        getRapport1(id, {
            startDate: start,
            endDate: end,
            products: prodTab.map(p => p.id)
        }).then(res => {

            const data = JSON.parse(res);
            setRapportInfo(data ? Object.values(data)[0] : null);

            setLoadingStore(false);
            setRapportReady(true);
        });
    }

    const getMissionList = (start, end) => {
        if(start && end) {
            const formatedStartDate = format(start, 'yyyy-MM-dd') + 'T00:00:00.000Z';
            const formatedEndDate = format(end, 'yyyy-MM-dd') + 'T00:00:00.000Z';

            handleGetRapport1(formatedStartDate, formatedEndDate);
        }
    }

    const handleProds = tab => {
        clearTimeout(timer.current);
    
        timer.current = setTimeout(() => {
            setProdTab(tab);
        }, 1000);
    }

    const changeDate = (item) => {
        if(enteringDate)
            getMissionList(item.selection.startDate, item.selection.endDate);
        
        setStartDate(item.selection.startDate);
        setEndDate(item.selection.endDate);
        setKey(item.selection.key);
        setCalendarIsHidden(item.selection.startDate !== item.selection.endDate);
        setEnteringDate(!enteringDate);
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if(active) return (
            <div className="custom-tooltip" style={{ backgroundColor: '#000', padding: '2px 5px', opacity: 0.8, borderRadius: 5 }}>
                <label style={{margin: 0, color: "#fff"}}>{`${payload[0].name} : ${payload[0].value}%`}</label>
            </div>
        );

        return null;
    };

    const isTop5 = (value, list) => {
        let first5 = list.sort((a, b) => a - b).reverse();
        return first5.slice(0, 5).find(el => el === value);
    }

    const COLORS = ['#FF6633', '#FFB399', '#FF33FF', '#00B3E6', 
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

    return (
        <section className='content'>
            <section className="content-header">
                {location.pathname.slice((location.pathname.length-8), location.pathname.length) !== "/delegue" &&
                    <span className="btn-back" onClick={() => navigate("/suivi-activite")}>&lt; {t('GEN.BUTTON.BACK')}</span>
                }

                <h1 style={{display: "inline-block", marginTop: 15}}>
                    {t('SUIVI.RAPPORT.ACTIVITY')} - {t('SUIVI.RAPPORT.TITLE')} 1 - {rapportInfo && rapportInfo.delegue}
                </h1>

                {/* {location.pathname.slice((location.pathname.length-8), location.pathname.length) !== "/delegue" && */}
                    <Link className="btn-rapport" to={`/rapport2/${id}`}>{t('SUIVI.SEE_REPORT')} 2</Link>
                {/* } */}
            </section>

            <section className='content rapport1'>
                <div className="row">
                    
                    <div className='col-md-3'>
                        <div className='box info ciblage'>
                            <p>
                                {t('SUIVI.PERIOD')}
                                { !rapportReady &&
                                    <small> &nbsp; ({t('COMMON.WAIT')}...)</small>
                                }
                            </p>

                            <div className="inner-addon right-addon">
                                <i className="glyphicon glyphicon-calendar"></i>
                                <input
                                    className="form-control"
                                    onClick={() => {
                                        setCalendarIsHidden(!calendarIsHidden);
                                        setEnteringDate(false);
                                    }}
                                    value={format(startDate, 'dd/MM/yyyy') + ' - ' + format(endDate, 'dd/MM/yyyy')}
                                    type="text"
                                    onChange={() => {}}
                                    disabled={!rapportReady}
                                />
                            </div>

                            { !calendarIsHidden &&
                            <DateRange
                                locale={localStorage.getItem('currentLanguage') === "en" ? enUS : fr}
                                editableDateInputs={true}
                                onChange={item => changeDate(item)}
                                showDateDisplay={false}
                                moveRangeOnFirstSelection={false}
                                ranges={[{
                                    startDate: startDate,
                                    endDate: endDate,
                                    key: key
                                }]}
                                dragSelectionEnabled={false}
                            />
                            }
                        </div>
                    </div>

                    <div className='col-md-3'>
                        <div className='box info ciblage'>
                            <p>{t('SUIVI.RAPPORT.PROD')}</p>
                            {loadingProdLabMission &&
                                <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader" />
                            }
                            
                            <MultiCheckbox
                                data={prodList}
                                type="laboratory"
                                handleCheck={(t) => handleProds(t)}
                            />
                        </div>
                    </div>

                </div>

                <div className='rapport-1-tables'>

                    <Rapport1Table infos={rapportInfo}/>

                    <div className='rapport1-tab'>
                        <table className='admin-and-work-days'>
                            <thead>
                                <tr>
                                    <td>{t('SUIVI.RAPPORT.NB_ADMIN_DAYS')}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{rapportInfo ?
                                    rapportInfo.NbJoursAdministratifs
                                    :
                                    '...'
                                    }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='rapport1-tab'>
                        <table className='admin-and-work-days'>
                            <thead>
                                <tr>
                                    <td>{t('SUIVI.NB_WORK_DAYS')}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{rapportInfo ?
                                    rapportInfo.NbJoursTravailles
                                    :
                                    '...'
                                    }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='dashboard-infos'>
                    <div className='graph'>
                        <div className="box info">
                            <p className="graph-title">{t('SUIVI.RAPPORT.VENTI_HCP_TYPE')}</p>
                            { rapportInfo && rapportInfo.diagramme1_type &&
                            rapportInfo.diagramme1_type.filter(el => el.nbrPds > 0).length > 0
                            &&
                            <ResponsiveContainer width="100%" height={window.innerWidth <= 1600 ? 300 : 500}>
                                <PieChart>
                                    <Pie
                                        data={rapportInfo.diagramme1_type.filter(el => el.nbrPds > 0)}
                                        color="#000000"
                                        dataKey="nbrPds"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={window.innerWidth <= 1600 ? 100 : 120}
                                        fill="#8884d8"
                                        label={(entry) => isTop5(entry.value, rapportInfo.diagramme1_type.map(el => el.nbrPds)) &&
                                            `${entry.name} : ${entry.value}%`
                                        }
                                        labelLine={false}
                                        isAnimationActive={false}
                                    >
                                        {rapportInfo.diagramme1_type.filter(el => el.nbrPds > 0).map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>

                                    <Tooltip content={<CustomTooltip />} />
                                </PieChart>
                            </ResponsiveContainer>
                            }

                            { rapportInfo && rapportInfo.diagramme1_type &&
                            rapportInfo.diagramme1_type.filter(el => el.nbrPds > 0).length === 0
                            &&
                            <p>{t('SUIVI.RAPPORT.NO_DATA')}</p>
                            }
                        </div>
                    </div>

                    <div className='graph'>
                        <div className="box info">
                            <p className="graph-title">{t('SUIVI.RAPPORT.VENTI_HCP_SPE')}</p>
                            { rapportInfo && rapportInfo.diagramme2_specialityMED &&
                            rapportInfo.diagramme2_specialityMED.filter(el => el.nbrPds > 0).length > 0
                            &&
                            <ResponsiveContainer width="100%" height={window.innerWidth <= 1600 ? 300 : 500}>
                                <PieChart>
                                    <Pie
                                        data={rapportInfo.diagramme2_specialityMED.filter(el => el.nbrPds > 0)}
                                        color="#000000"
                                        dataKey="nbrPds"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={window.innerWidth <= 1600 ? 100 : 120}
                                        fill="#8884d8"
                                        label={(entry) => isTop5(entry.value, rapportInfo.diagramme2_specialityMED.map(el => el.nbrPds)) &&
                                            `${entry.name} : ${entry.value}%`
                                        }
                                        labelLine={false}
                                        isAnimationActive={false}
                                    >
                                        {rapportInfo.diagramme2_specialityMED.filter(el => el.nbrPds > 0).map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>

                                    <Tooltip content={<CustomTooltip />} />
                                </PieChart>
                            </ResponsiveContainer>
                            }

                            { rapportInfo && rapportInfo.diagramme2_specialityMED &&
                            rapportInfo.diagramme2_specialityMED.filter(el => el.nbrPds > 0).length === 0
                            &&
                            <p>{t('SUIVI.RAPPORT.NO_DATA')}</p>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
}