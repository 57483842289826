import { useContext } from 'react';
import { PopUpStoreContext } from '../../../../store/store/storePopUp';
import { ActionsStoreContext } from '../../../../store/store/storeActions';
import { ListStoreContext } from '../../../../store/store/storeList';

import "./ButtonAddDirect.scss";

export const ButtonAddDirect = (props) => {
    const { type, value } = props;
    const { statePopUp, dispatchPopUp } = useContext(PopUpStoreContext);
    const { stateActions, dispatchActions } = useContext(ActionsStoreContext);
    const { dispatchList } = useContext(ListStoreContext);
    const { dataPopUp, dataPopUpSup } = statePopUp;

    const handleClickAdd = (event) => {
        let dDataOtherTabs;
        if(type === 'specialityProPop') {
            let idPro = stateActions.dataActions.id;
            let idSpe = value.idSpeciality;
            
            if(idSpe !== undefined)
            return stateActions.postSpePro(idPro, idSpe).then(res => {
                return stateActions.getSpePro(idPro).then((res) => {
                    const data = JSON.parse(res);
                    dDataOtherTabs = data;    
                    return dispatchList({type: 'setDataOtherTabs', dDataOtherTabs });
                });
            });
        }
        else if(type === 'medicalProSoftPop') {
            let idPro = stateActions.dataActions.id;
            let idLog = value.idSoftware;
            
            return stateActions.postLogPro(idPro, idLog).then(res => {
                return stateActions.getLogPro(idPro).then((res) => {
                    const data = JSON.parse(res);
                    dDataOtherTabs = data;    
                    return dispatchList({type: 'setDataOtherTabs', dDataOtherTabs });
                });
            });
        }

    }
    return(
        <button
            className="btn-add"
            onClick={(e) => handleClickAdd(e)}
        >
            +
        </button>
    )
}