import { useEffect, useState, useRef } from 'react';
import { CustomSelect } from '../../UI/CustomSelect/CustomSelect';

// images
import iconSave from "../../../assets/img/icons/icon-save.png";

import { getAllCountries } from '../../../store/index.service';

export const PopupProd = (props) => {

    const { product, editProduct, addProduct, handleSubmit } = props;

    const [name, setName] = useState("");
    const [pres, setPres] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [countryRestrictedList, setCountryRestrictedList] = useState([]);
    const [country, setCountry] = useState("");
    const [countryRestricted, setCountryRestricted] = useState("");

    const btnRef = useRef(null);
    const edit = Object.keys(product).length > 0;

    // mounted
    useEffect(() => {
        getAllCountries().then(res => {
            const data = JSON.parse(res);
            if(data) setCountryList(data);
        });
    }, []);

    // updates
    useEffect(() => {
        setName(product.nameProduct ? product.nameProduct : "");
        setPres(product.presentationProduct !== undefined && product.presentationProduct !== null ? product.presentationProduct : "");
        setCountryRestrictedList(product.restrictedArea ? product.restrictedArea : []);
        setCountry("");
        setCountryRestricted("");
    }, [product]);

    //functions

    const submitForm = () => {
        if(name !== "" && pres !== "") {
            let area = countryRestrictedList.map((c) => c.idArea);

            if(edit) editProduct(product.idProduct, name, pres, area);
            else addProduct(name, pres, area);
            
            btnRef.current.click();
        }
    }

    const handleCountryRestriction = () => {
        let cP = countryList.slice();
        let cR = countryRestrictedList.slice();

        if(country !== "") {
            let selectC = cP.map((c) => c.name).indexOf(country);

            cR.push({
                idArea: cP[selectC].id,
                nameArea: cP[selectC].name
            });

            setCountry("");
            setCountryList(cP);
            setCountryRestricted("");
            setCountryRestrictedList(cR);
        }
    }

    const handleCountryPresence = () => {
        let cP = countryList.slice();
        let cR = countryRestrictedList.slice();

        if(countryRestricted !== "") {
            let selectC = cR.map((c) => c.nameArea).indexOf(countryRestricted);
            if(selectC !==-1) cR.splice(selectC, 1);

            setCountry("");
            setCountryList(cP);
            setCountryRestricted("");
            setCountryRestrictedList(cR);
        }
    }

    const cP = countryList.slice();
    const cR = countryRestrictedList.slice();
    cR.forEach((cRes) => {
        let selectC = cP.map((c) => c.id).indexOf(cRes.idArea);
        if(selectC !==-1) cP.splice(selectC, 1);
    });

    return(
    <div className="modal fade in" id="modalAddEditProd" tabIndex="-1" role="dialog" aria-labelledby="modalAddEditProdLabel" animate="animate">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title" id="myModalLabel">{edit ? "Modifier" : "Ajouter"} un produit</h1>
                </div>
                <div className="modal-body">
                    <form id="formAjout">
                        <label htmlFor="name">Nom</label>
                        <input type="text" required value={name} onChange={(e) => setName(e.target.value)}/>

                        <label>Type de présentation par défaut</label>
                        <CustomSelect
                        defaultText={pres ? "Présentation" : pres === "" ? "Sélectionnez un type de présentation" : "Rappel"}
                        optionsList={[
                            {id: 0, name: "Rappel"},
                            {id: 1, name: "Présentation"}
                        ]}
                        handleOptionClick={id => setPres(id === "0" ? false : true)}
                        />

                        <div className="box info">
                            <p>
                                Vous pouvez restreindre la présence d'un produit à un certain nombre de pays. Le produit sera
                                automatiquement absent des listes (fiche de pré-visite, CR) dans ces pays.
                            </p>
                        </div>

                        <div className="row">
                            <div className="col-md-5">
                                <label>Pays où le produit est présent</label>
                                <select className="listSelect" size="5" value={country} onChange={(e) => setCountry(e.target.value)}>
                                    <option value=""></option>
                                    {cP.map((c, i) => (
                                        <option key={i} value={c.name}>{c.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-2">
                                <label>Actions</label>
                                <button type="button" onClick={handleCountryRestriction}>&rarr;</button>
                                <button type="button" onClick={handleCountryPresence}>&larr;</button>
                            </div>
                            <div className="col-md-5">
                                <label>Pays où le produit est absent</label>
                                <select className="listSelect" size="5" value={countryRestricted} onChange={(e) => setCountryRestricted(e.target.value)}>
                                    <option value=""></option>
                                    {countryRestrictedList.map((c, i) => (
                                        <option key={i} value={c.name}>{c.name || c.nameArea}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <button 
                    type="submit" 
                    form="formAjout"
                    className="btn-save" 
                    disabled={name === "" || pres === ""}
                    data-toggle={ edit ? 'modal' : '' }
                    data-target={'#modalConfirmation'}
                    onClick={(e) => {
                        e.preventDefault();
                        if(edit) handleSubmit(submitForm);
                        else submitForm();
                    }}>
                        <img src={iconSave} alt=""/>
                        Sauvegarder
                    </button>
                    <button
                    type="button"
                    className="btn-cancel"
                    data-dismiss="modal"
                    onClick={() => {
                        setName(product.nameProduct ? product.nameProduct : "");
                        setPres(product.presentationProduct !== undefined && product.presentationProduct !== null ? product.presentationProduct : "");
                        setCountryRestrictedList(product.restrictedArea ? product.restrictedArea : []);
                        setCountry("");
                        setCountryRestricted("");
                    }}
                    ref={btnRef}
                    >Annuler</button>
                </div>
            </div>
        </div>
    </div>
    )
}