import { useState, useEffect, useContext } from 'react';

import { PopUpStoreContext } from '../../../store/store/storePopUp';
import { ActionsStoreContext } from '../../../store/store/storeActions';

import { MedicalProsInstitsPopUpManager } from '../PopUp/MedicalPro/MedicalProsInstitsPopUpManager';
import { SpecialityProsPopUpManager } from '../PopUp/MedicalPro/SpecialityProsPopUpManager';
import { LogsProsPopUpManager } from '../PopUp/MedicalPro/LogsProsPopUpManager';

import { BtnSavePopUp } from '../../UI/Button/PopUp/BtnSavePopUp';
import { Notif } from './Notif/Notif';

import './index.scss'

export const PopUpSupManager = (props) => {
    const { type, displayTitle } = props;
    const { statePopUp, dispatchPopUp } = useContext(PopUpStoreContext);
    const { stateActions, dispatchActions } = useContext(ActionsStoreContext);
    const { dataPopUpSup, notif } = statePopUp;
    const { dataActions } = stateActions;

    const [memoPopUp, setMemoPopUp] = useState();

    useEffect(() => {
        setMemoPopUp(dataPopUpSup.action);
        if(dataPopUpSup.action !== '')
            if(dataPopUpSup.action !== memoPopUp && typeof memoPopUp !== 'undefined')
                dataPopUpSup.isActive = true;
            
    }, [dataPopUpSup.action]);

    const chooseTitle = () => {
        let optional = dataPopUpSup.optionalData;
        if(typeof displayTitle !== 'undefined' && typeof dataPopUpSup.action !== 'undefined')
            switch (dataPopUpSup.action) {
                case 'add':
                    return `Ajouter un ${displayTitle.toLowerCase()}`;

                case 'edit':
                    if(type === 'medicalProPage' && optional !== undefined)
                        return `Modifier un ${displayTitle.toLowerCase()} - ${optional.reference} - ${optional.medicalProType.nameMedicalProType} ${optional.lastName} ${optional.firstName}`;

                    return `Modifier un ${displayTitle.toLowerCase()}`;

                case 'delete':
                    return `Supprimer un ${displayTitle.toLowerCase()}`;
                
                case 'visu':
                    return `${dataActions.name}`;
            
                default:
                    return false;
            }
    }

    const chooseDisplayContent = () => {
        if(typeof type !== 'undefined' && dataPopUpSup.isActive === true)
            switch (type) {
                case 'specialityProPop':
                    return <SpecialityProsPopUpManager data={dataPopUpSup} dispatchPop={dispatchPopUp}/>;
                case 'medicalProsInstitutions':
                    return <MedicalProsInstitsPopUpManager data={dataPopUpSup} dispatchPop={dispatchPopUp}/>;
                case 'medicalProSoftPop':
                    return <LogsProsPopUpManager data={dataPopUpSup} dispatchPop={dispatchPopUp}/>;
            
                default:
                    return false;
            }
        else
            return false;
    }

    const handleClosePopUp = () => {
        let dDataPopUpSup = {
            ...dataPopUpSup,
            type: '',
            action: '',
            isActive: false
        };
        dispatchPopUp({type: 'setPopUpSup', dDataPopUpSup})
        setMemoPopUp('')
    }

    return (
        <>
            {dataPopUpSup.isActive === true &&
                <div className={`containerPopUpBase modal ${type}`}>
                    
                    <div className="modal-dialog">
                        <div className="modal-content">

                            {/* MODAL HEADER */}
                            <div className="modal-header">
                                <h1 className="modal-title">
                                    {chooseTitle()}
                                </h1>
                            </div>

                            {/* MODAL BODY */}
                            {chooseDisplayContent()}

                            {/* MODAL FOOTER */}
                            <div className="modal-footer">
                                <BtnSavePopUp 
                                    type={type}
                                    action={dataPopUpSup.action}
                                    handleClosePopUp={() => handleClosePopUp()}
                                />
                                <button className="btn-cancel" onClick={() => handleClosePopUp()}>
                                    {dataPopUpSup.action === 'visu' ? 'Ok' : 'Annuler'}
                                </button>
                            </div>

                        </div>
                    </div>

                </div>
            }
            {notif.response &&
                <Notif 
                    response={notif.response}
                    code={notif.code}
                />
            }
        </>
    )
}