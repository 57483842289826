import { useState, useContext, useEffect, useRef } from 'react';
import Select from 'react-select';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import fr from 'react-phone-input-2/lang/fr.json';

import { ActionsStoreContext } from '../../../../../store/store/storeActions';
import { ListStoreContext } from '../../../../../store/store/storeList';

import { t } from "../../../../../pages/i18nFunctions";

import { Pagination } from '../../../../../components/Pagination/Pagination';
import { TableManager } from '../../../Table/TableManager';

export const AddMedicalPro = (props) => {
    const { stateActions, dispatchActions } = useContext(ActionsStoreContext);
    const { stateList, dispatchList } = useContext(ListStoreContext);
    const { dataActions } = stateActions;

    let [countryState, setCountryState] = useState(false);
    let [medMainSpeState, setMedMainSpeState] = useState(false);
    let [loadingEtabs, setLoadingEtabs] = useState(false);

    let [gender, setGender] = useState(0)
    let [isComputerizedState, setIsComputerizedState] = useState(0)
    let [isThoughtLeaderState, setIsThoughtLeaderState] = useState(0)
    let [isVisitDistanceState, setVisitDistanceState] = useState(false)
    let [isRetiredState, setIsRetiredState] = useState(0)
    let [isDeathState, setIsDeathState] = useState(0)

    let [similarPro, setSimilarPro] = useState(0);
    let [maxPageVerif, setMaxPageVerif] = useState(0);
    let [pageVerif, setPageVerif] = useState(1);
	let [nbResultatsVerif, setNbResultatsVerif] = useState(0);

    const [item, setItem] = useState({
        ...dataActions,
        medicalProType :{
            idMedicalProType: null
        },
        medicalProMainSpeciality: {
            idProMainSpeciality: null
        },
        gender: 0,
        title: t('PROS.LIST.POPUP_VIEW.GENERAL.MR'),
        lastName: null,
        firstName: null,
        mail: null,
        mobile: null,
        landLine: null,
        faxNumber: null,
        idInstitution: null,
        visitDistance: false,
        isRetired: 0,
        isDeath: 0,
        isComputerized: 0,
        isThoughtLeader: 0
    });

    const timer = useRef(null);
    
    const generateOptions = (data, type = null) => {
        if(typeof data !== 'undefined') {
            let options = [];
            data.forEach((option, i) => {
                if(type === 'etabInPro')
                    options.push({
                        value: option.id,
                        label: option.name,
                        ref: option.reference,
                        ad: option.addresszoneGeo,
                        isPrivate: option.isPrivate
                    });
                
                else
                    options.push({
                        value: option.id, 
                        label: option.name,
                    });
            });
            return options;
        }
    }

    // mounted
    useEffect(() => {
        timer.current = setTimeout(() => {}, 1000);
        // unmounted
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    useEffect(() => {
        if(countryState === true) {
            setLoadingEtabs(true);
            let data = {
                country: dataActions.country.value
            }
            stateActions.getEtabs(data).then(res => {
                let dDataEtabs = JSON.parse(res);
                setLoadingEtabs(false);
                return dispatchList({type: 'setDataEtabs', dDataEtabs });
            });
        }
    }, [countryState, item.country]);

    useEffect(() => {
        if(item.lastName !== "" && item.firstName !== "" && item.lastName && item.firstName)
            return getVerif();
    }, [pageVerif])

    useEffect(() => {
        // setItem({
        //     ...item,
        //     medicalProMainSpeciality: null
        // });

        stateActions.getSpeByType(item.medicalProType.idMedicalProType).then((res) => {
            const data = JSON.parse(res);
            let dDataSpecialityByType = data ?? [];
            return dispatchList({type: 'setDataSpecialityByType', dDataSpecialityByType });
        });
    }, [item.medicalProType])

    const getVerif = (value = null, name = null) => {
        let data = {
            page: pageVerif,
            maxPerPage: 5,
            lastName: item.lastName,
            firstName: item.firstName,
            id: item.id,
            idCountry: item.country.value
        };
        if(value !== null && name !== null) {
            data = {
                ...data,
                [name]: value
            }
        }

        stateActions.verifPro(data).then(res => {
            let data = JSON.parse(res);
            let dDataVerifPro = data.similaireMedicalPro;
            
            setSimilarPro(data);
            setNbResultatsVerif(data.nbResultats)
            setMaxPageVerif(data.nbrPages);
            return dispatchList({type: 'setDataVerifPro', dDataVerifPro });
        });
    }
    
    const dataHeaderSimilar = [
        {
            name: 'reference', 
            displayName: t('PROS.LIST.RESEARCH.REFERENCE'), 
        },
        {
            name: 'lastName', 
            displayName: t('PROS.LIST.RESEARCH.NAME'), 
        },
        {
            name: 'firstName', 
            displayName: t('PROS.LIST.RESEARCH.FIRSTNAME'), 
        },
        {
            name: 'medicalProType', 
            displayName: t('PROS.LIST.RESEARCH.PDS_TYPE'), 
        },
        {
            name: 'medicalProMainSpeciality', 
            displayName: t('PROS.LIST.RESEARCH.SPECIALITY'), 
        },
    ];

    const handleChange = (value, name, type = null, setRadio = null) => {

        if(setRadio !== null) {
            if(typeof value === "boolean")
                setRadio(value);
            else {
                value = parseInt(value);
                setRadio(parseInt(value));
            }
        }
        let aDataActions = {
            ...stateActions.dataActions,
            ...item,
            [name]: value
        }
        if(type !== null && type.action === 'clear') {
            aDataActions = {
                ...stateActions.dataActions,
                ...item,
                [name]: null
            }
            setItem(aDataActions);
            dispatchActions({type: 'setDataActions', aDataActions });
            return;
        }
        if(name === 'type' || name === 'country') {
            value = value.value;

            if(name === 'country') {
                setCountryState(true);
            }
        }
        else if(name === 'medicalProType' || name === 'medicalProMainSpeciality') {
            if(name === 'medicalProType') {
                aDataActions = {
                    ...aDataActions,
                    [name]: {
                        idMedicalProType: value.value,
                        nameMedicalProType: value.label  
                    },
                    medicalProMainSpeciality: null
                }
                setMedMainSpeState(true);

            } else if(name === 'medicalProMainSpeciality'){
                aDataActions = {
                    ...aDataActions,
                    [name]: {
                        idMainSpeciality: value.value,
                        nameMainSpeciality: value.label,
                    }
                }
            }
        }
        else if(name === 'idInstitution') {
            aDataActions = {
                ...aDataActions,
                [name]: value.value
            }
        }
        else if(name === 'gender') {
            aDataActions = {
                ...aDataActions,
                title: value === "2" ? t('PROS.LIST.POPUP_VIEW.GENERAL.MS') : t('PROS.LIST.POPUP_VIEW.GENERAL.MR')
            }
        }
        console.log(aDataActions)

        setItem(aDataActions);
        dispatchActions({type: 'setDataActions', aDataActions });

        if(name === 'lastName' || name === 'firstName') {
            clearTimeout(timer.current);

            if(value.length >= 3)
                timer.current = setTimeout(() => {
                    getVerif(value, name);
                }, 1000);
            
            // else if(value.length < 3)
            //     return getVerif('', '');
            
            else if(value.length <= 2)
                return;
        }
    }

    const filterEtabs = (value) => {

        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            setLoadingEtabs(true);
            let data = {
                country: dataActions.country.value,
                label: value
            }
            stateActions.getEtabs(data).then(res => {
                let dDataEtabs = JSON.parse(res) ?? [];
                setLoadingEtabs(false);
                return dispatchList({ type: 'setDataEtabs', dDataEtabs });
            });
        }, 1000);
    }

    return(
        <>
            <form>
                <div>
                    <label htmlFor="country">{t('PROS.LIST.RESEARCH.COUNTRY')}</label>
                    <Select 
                        id="country"
                        className='selectSearchBar' 
                        classNamePrefix='selectSearch' 
                        placeholder={t('PROS.LIST.RESEARCH.COUNTRY_PLACEHOLDER')}
                        isClearable={false}
                        options={generateOptions(JSON.parse(localStorage.getItem("userCountries")))} 
                        onChange={(value, type) => {handleChange(value, 'country', null, null) } } 
                    />
                </div>

                {countryState === true && 
                    <div className='flex-row two-cols'>
                        <div>
                            <label htmlFor="medicalProType">{t('PROS.LIST.POPUP_VIEW.GENERAL.PDS_TYPE')}</label>
                            <Select 
                                id="medicalProType"
                                className='selectSearchBar' 
                                classNamePrefix='selectSearch' 
                                placeholder={t('PROS.LIST.RESEARCH.PDS_TYPE_PLACEHOLDER')}
                                isClearable={false}
                                options={generateOptions(stateList.dataProType)} 
                                onChange={(value, type) => {handleChange(value, 'medicalProType', null, null)} }
                            />
                        </div>

                        {(medMainSpeState === true && stateList.dataSpecialityByType.length > 0) &&
                            <div>
                                <label htmlFor="medicalProMainSpeciality">{t('PROS.LIST.POPUP_VIEW.GENERAL.MAIN_SPECIALITY')}</label>
                                <Select 
                                    id="medicalProMainSpeciality"
                                    className='selectSearchBar' 
                                    classNamePrefix='selectSearch' 
                                    placeholder={t('PROS.LIST.RESEARCH.SPECIALITY_PLACEHOLDER')}
                                    isClearable={false}
                                    options={generateOptions(stateList.dataSpecialityByType)} 
                                    onChange={(value, type) => {handleChange(value, 'medicalProMainSpeciality', null, null)} }
                                    value={item.medicalProMainSpeciality ? {
                                        value: item.medicalProMainSpeciality.idMainSpeciality,
                                        label: item.medicalProMainSpeciality.nameMainSpeciality
                                    } : null}
                                />
                            </div>
                        }
                    </div>
                }
                
                {countryState === true && 
                    <>
                        <div className='flex-row two-cols'>
                            <div>
                                <label htmlFor="lastName">{t('PROS.LIST.POPUP_VIEW.GENERAL.NAME')}</label>
                                <input id="lastName" type="text" required
                                    value={item.lastName ?? ''} 
                                    onChange={ (e) => {handleChange(e.target.value, 'lastName', null, null)} }
                                />
                            </div>
                            <div>
                                <label htmlFor="firstName">{t('PROS.LIST.POPUP_VIEW.GENERAL.FIRSTNAME')}</label>
                                <input id="firstName" type="text" required
                                    value={item.firstName ?? ''} 
                                    onChange={ (e) => {handleChange(e.target.value, 'firstName', null, null)} }
                                />
                            </div>
                        </div>
                        {
                            (typeof similarPro.similaireMedicalPro !== 'undefined' && similarPro.similaireMedicalPro !== null) &&
                            <div className="containerRedBorder">
                                <div>{t('PROS.LIST.TABLE_HEADERS.SIMILAR_PDS')}</div>
                                <TableManager 
                                    dataHeader={dataHeaderSimilar} 
                                    actionsList={[]}
                                    type="verifPro"
                                    isAdd={false}
                                />
                                <br/>
                                <Pagination
                                    maxPage={maxPageVerif}
                                    onChangePage={(data) => setPageVerif(data)}
                                    nbCount={nbResultatsVerif}
                                ></Pagination>
                            </div>
                        }
                        <div className="containerInline">
                            <div className="containerLabelRadioInline">
                                <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.GENDER')}</label>
                            </div>
                            <div>
                                <div className="containerInline">
                                    <div className="containerInline containerRadioInput">
                                        <input type="radio" name="gender" id="gender"
                                            onChange={(e) => {handleChange(e.target.value, 'gender', setGender(0), null)} }
                                            checked={gender === 0}
                                            value={0}
                                        />
                                        <label htmlFor="gender">{t('COMMON.UNKNOWN')}</label>
                                    </div>
                                    <div className="containerInline containerRadioInput">
                                        <input type="radio" name="gender" id="gender1"
                                            onChange={(e) => {handleChange(e.target.value, 'gender', setGender(1), null)} }
                                            checked={gender === 1}
                                            value={1}
                                        />
                                        <label htmlFor="gender1">{t('PROS.LIST.POPUP_VIEW.GENERAL.MAN')}</label>
                                    </div>
                                    <div className="containerInline containerRadioInput">
                                        <input type="radio" name="gender" id="gender2"
                                            onChange={(e) => {handleChange(e.target.value, 'gender', setGender(2), null)} }
                                            checked={gender === 2}
                                            value={2}
                                        />
                                        <label htmlFor="gender2">{t('PROS.LIST.POPUP_VIEW.GENERAL.WOMAN')}</label>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div>
                            <label htmlFor="mail">{t('PROS.LIST.POPUP_VIEW.GENERAL.EMAIL')}</label>
                            <input id="mail" type="text" required
                                value={item.mail ?? ''} 
                                onChange={ (e) => {handleChange(e.target.value, 'mail', null, null)} }
                            />
                        </div>

                        <div className='flex-row two-cols'>
                            <div className="phoneInputContainer">
                                <label htmlFor="phoneMobile">{t('PROS.LIST.POPUP_VIEW.GENERAL.PHONE_NUMBER_MOBILE')}</label>
                                <PhoneInput
                                    id="phoneMobile"
                                    // country={'bj'}
                                    value={item.mobile ?? ''}
                                    onChange={ (value) => {handleChange(value, 'mobile', null, null)} }
                                    localization={fr}
                                />
                            </div>
                            <div className="phoneInputContainer">
                                <label htmlFor="phone">{t('PROS.LIST.POPUP_VIEW.GENERAL.PHONE_NUMBER')}</label>
                                <PhoneInput
                                    id="phone"
                                    // country={'bj'}
                                    value={item.landLine ?? ''}
                                    onChange={ (value) => {handleChange(value, 'landLine', null, null)} }
                                    localization={fr}
                                />
                            </div>
                        </div>


                        <div className="containerInline">
                            <div className="containerLabelRadioInline">
                                <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.Computerized')}</label>
                                <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.OPINION_LEADER')}</label>
                                <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.REMOTE_VISIT')}</label>
                                <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.REGISTRED_ON_ETHICA')}</label>
                                <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.RETIREMENT')}</label>
                                <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.DECEASED')}</label>
                            </div>
                            <div>

                                <div>
                                    <div className="containerInline">
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="isComputerized" id="isComputerized"
                                                onChange={(e) => {handleChange(parseInt(e.target.value), 'isComputerized', null, setIsComputerizedState, null)} }
                                                checked={isComputerizedState === 0}
                                                value={0}
                                            />
                                            <label htmlFor="isComputerized">{t('COMMON.UNKNOWN')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="isComputerized" id="isComputerized1"
                                                onChange={(e) => {handleChange(e.target.value, 'isComputerized', null, setIsComputerizedState, null)} }
                                                checked={isComputerizedState === 1}
                                                value={1}
                                            />
                                            <label htmlFor="isComputerized1">{t('COMMON.YES')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="isComputerized" id="isComputerized2"
                                                onChange={(e) => {handleChange(e.target.value, 'isComputerized', null, setIsComputerizedState, null)} }
                                                checked={isComputerizedState === 2}
                                                value={2}
                                            />
                                            <label htmlFor="isComputerized2">{t('COMMON.NO')}</label>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="containerInline">
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="isThoughtLeader" id="isThoughtLeader"
                                                onChange={(e) => {handleChange(e.target.value, 'isThoughtLeader', null, setIsThoughtLeaderState, null)} }
                                                checked={isThoughtLeaderState === 0}
                                                value={0}
                                            />
                                            <label htmlFor="isThoughtLeader">{t('COMMON.UNKNOWN')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="isThoughtLeader" id="isThoughtLeader1"
                                                onChange={(e) => {handleChange(e.target.value, 'isThoughtLeader', null, setIsThoughtLeaderState, null)} }
                                                checked={isThoughtLeaderState === 1}
                                                value={1}
                                            />
                                            <label htmlFor="isThoughtLeader1">{t('COMMON.YES')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="isThoughtLeader" id="isThoughtLeader2"
                                                onChange={(e) => {handleChange(e.target.value, 'isThoughtLeader', null, setIsThoughtLeaderState, null)} }
                                                checked={isThoughtLeaderState === 2}
                                                value={2}
                                            />
                                            <label htmlFor="isThoughtLeaderState2">{t('COMMON.NO')}</label>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="containerInline">

                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="visitDistance" id="visitDistance1"
                                                onChange={(e) => {handleChange(true, 'visitDistance', null, setVisitDistanceState, null)} }
                                                checked={isVisitDistanceState}
                                                value={true}
                                            />
                                            <label htmlFor="visitDistance1">{t('COMMON.YES')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="visitDistance" id="visitDistance2"
                                                onChange={(e) => {handleChange(false, 'visitDistance', null, setVisitDistanceState, null)} }
                                                checked={!isVisitDistanceState}
                                                value={false}
                                            />
                                            <label htmlFor="visitDistance2">{t('COMMON.NO')}</label>
                                        </div>

                                    </div>
                                </div>

                                <div>
                                    <div className="containerInline">
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="isSavedOn" id="isSavedOn1" />
                                            <label htmlFor="isSavedOn1">{t('COMMON.YES')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="isSavedOn" id="isSavedOn2" checked />
                                            <label htmlFor="isSavedOn2">{t('COMMON.NO')}</label>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="containerInline">
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="isRetired" id="isRetired"
                                                onChange={(e) => {handleChange(e.target.value, 'isRetired', null, setIsRetiredState, null)} }
                                                checked={isRetiredState === 0}
                                                value={0}
                                            />
                                            <label htmlFor="isRetiredState">{t('COMMON.UNKNOWN')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="isRetired" id="isRetired1"
                                                onChange={(e) => {handleChange(e.target.value, 'isRetired', null, setIsRetiredState, null)} }
                                                checked={isRetiredState === 1}
                                                value={1}
                                            />
                                            <label htmlFor="isRetired1">{t('COMMON.YES')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="isRetired" id="isRetired2"
                                                onChange={(e) => {handleChange(e.target.value, 'isRetired', null, setIsRetiredState, null)} }
                                                checked={isRetiredState === 2}
                                                value={2}
                                            />
                                            <label htmlFor="isRetired2">{t('COMMON.NO')}</label>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="containerInline">
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="isDeath" id="isDeath"
                                                onChange={(e) => {handleChange(e.target.value, 'isDeath', null, setIsDeathState, null)} }
                                                checked={isDeathState === 0}
                                                value={0}
                                            />
                                            <label htmlFor="isDeath">{t('COMMON.UNKNOWN')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="isDeath" id="isDeath1"
                                                onChange={(e) => {handleChange(e.target.value, 'isDeath', null, setIsDeathState, null)} }
                                                checked={isDeathState === 1}
                                                value={1}
                                            />
                                            <label htmlFor="isDeath1">{t('COMMON.YES')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="isDeath" id="isDeath2"
                                                onChange={(e) => {handleChange(e.target.value, 'isDeath', null, setIsDeathState, null)} }
                                                checked={isDeathState === 2}
                                                value={2}
                                            />
                                            <label htmlFor="isDeath2">{t('COMMON.NO')}</label>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                        {(countryState === true) &&
                            <div>
                                <label htmlFor="consultPlace">{t('PROS.LIST.RESEARCH.CONSULTATION_PLACE')}</label>
                                <Select 
                                    id="consultPlace"
                                    className='selectSearchBar' 
                                    classNamePrefix='selectSearch' 
                                    placeholder={t('PROS.LIST.RESEARCH.CONSULTATION_PLACE_PLACEHOLDER')}
                                    isClearable={false}
                                    options={generateOptions(stateList.dataEtabs, 'etabInPro')} 
                                    onChange={(value, type) => {handleChange(value, 'idInstitution', null, null)} } 
                                    onInputChange={(value) => filterEtabs(value)}
                                    isLoading={loadingEtabs}
                                    formatOptionLabel={(option, { context }) => {
                                        /* context can be either `menu` or `value`
                                            menu - dropdown
                                            value - value displayed
                                        */
                                        return context === 'menu' ? (
                                            <div className="select-react-option etab">
                                                <div>
                                                    <div className={`tag ${option.isPrivate === 1 ? "valid" : option.isPrivate === 2 ? "prive" : option.isPrivate === 3 ? "warning" : "inconnu"}`}>
                                                        <p>
                                                            {option.isPrivate === 1 ? t('PROS.LIST.RESEARCH.PUBLIC')
                                                            : option.isPrivate === 2 ? t('PROS.LIST.RESEARCH.PRIVATE')
                                                            : option.isPrivate === 3 ? t('PROS.LIST.RESEARCH.SEMI_PRIVATE')
                                                            : t('COMMON.UNKNOWN')}
                                                        </p>
                                                    </div>
                                                    <p>{option.ref} - {option.label}</p>
                                                </div>
                                                <small>{option.ad}</small>
                                            </div>
                                        ) : option.label;
                                    }}
                                />
                            </div>
                        }

                    </>
                }
            </form>
        </>
    )
}
