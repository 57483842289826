import { useEffect, useState, useContext } from 'react';
import "./NavigationMenu.scss";
import { NavLink } from "react-router-dom";
import { t } from "../../pages/i18nFunctions";
import { ListStoreContext } from '../../store/store/storeList';
import { ActionsStoreContext } from '../../store/store/storeActions';

import { getRemainingCR } from '../../store/index.service';

// images
import statsIcon from "../../assets/img/icons/stats.png";
import dashboardIcon from "../../assets/img/icons/icon-dashboard.png";
import annuaireIcon from "../../assets/img/icons/icon-annuaire.png";
import calendarIcon from "../../assets/img/icons/icon-calendrier.png";
import userIcon from "../../assets/img/icons/icon-user.png";
import historyIcon from "../../assets/img/icons/icon-historique.png";
import docIcon from "../../assets/img/icons/icon-doc.png";
import paramIcon from "../../assets/img/icons/icon-param.png";

export const NavigationMenu = ({ idMission }) => {

    const [showParam, setShowParam] = useState(false);
    const [showAnnuaire, setShowAnnuaire] = useState(false);
    const [showRes, setShowRes] = useState(false);
    const [showStats, setShowStats] = useState(false);

    const { stateList, dispatchList } = useContext(ListStoreContext);
    const { paginationRemainingCR } = stateList;

    const roleUser = localStorage.getItem('role');

    useEffect(() => {

        if(idMission && roleUser === "ROLE_DELEGUE")
            getRemainingCR(idMission).then(res => {
                const data = JSON.parse(res) ? JSON.parse(res)[0] : [];

                dispatchList({ type: 'setDataRemainingCR', dDataRemainingCR: data.listVisits ?? [] });

                const pPaginationRemainingCR = {
                    maxPerPage: data.maxPerPage,
                    nbResultats: data.nbResultats,
                    nbrPages: data.nbrPages,
                    page: 1
                }
                return dispatchList({ type: 'setPaginationRemainingCR', pPaginationRemainingCR });
            });

    }, [idMission]);

    return(
    <aside className="main-sidebar">
        {/* sidebar: style can be found in sidebar.less */}
        <section className="sidebar" >
            {/* sidebar menu: : style can be found in sidebar.less */}
            <ul className="sidebar-menu tree" data-widget="tree">
                
                {["ROLE_DELEGUE", "ROLE_SUPERVISEUR", "ROLE_DSM", "ROLE_HP", "ROLE_DP", "ROLE_COORDINATEUR"].includes(roleUser) &&
                    <li>
                        <NavLink to="/">
                            <div className="icon">
                                <img src={dashboardIcon} alt=""/>
                            </div>
                            <span>{t('DB.TITLE')}</span>
                        </NavLink>
                    </li>
                }
                {roleUser !== "ROLE_DELEGUE" &&
                    <li>
                        <NavLink to="/suivi-activite">
                            <div className="icon">
                                <img src={statsIcon} alt=""/>
                            </div>
                            <span>{t('MENU.SUIVI_ACTIVITE')}</span>
                        </NavLink>
                    </li>
                }
                <li className="treeview-tree">
                    <a onClick={() => {
                        setShowAnnuaire(!showAnnuaire);
                        setShowParam(false);
                        setShowRes(false);
                        setShowStats(false);
                    }}>
                        <div className="icon">
                            <img src={annuaireIcon} alt=""/>
                        </div>
                        <span>{t('MENU.DIRECTORY')}</span>
                        <span className="pull-right-container">
                            <i className={`fa fa-angle-${showAnnuaire ? "down" : "left"} pull-right`}></i>
                        </span>
                    </a>
                    <ul className="treeview--menu" style={{display: 'block', height: showAnnuaire ? "70px" : "0px"}}>
                        <li><NavLink to="/professionnels"><i className="fa fa-circle-o"></i> {t('MENU.CALENDAR.PROFESSIONALS')}</NavLink></li>
                        <li><NavLink to="/etablissements"><i className="fa fa-circle-o"></i> {t('MENU.CALENDAR.ESTABLISHMENTS')}</NavLink></li>
                    </ul>
                </li>
                {(roleUser === "ROLE_DELEGUE"
                || roleUser === "ROLE_HP"
                || roleUser === "ROLE_DP"
                || roleUser === "ROLE_DSM"
                || roleUser === "ROLE_SUPERVISEUR") &&
                    <li>
                        <NavLink to="/calendar/current">
                            <div className="icon">
                                <img src={calendarIcon} alt=""/>
                            </div>
                            <span>{t('MENU.MY_PLANNING')}</span>
                        </NavLink>
                    </li>
                }
                {roleUser === "ROLE_DELEGUE" &&
                <li className="treeview-tree">
                    <a onClick={() => {
                        setShowAnnuaire(false);
                        setShowParam(false);
                        setShowRes(false);
                        setShowStats(!showStats);
                    }}>
                        <div className="icon">
                            <img src={statsIcon} alt=""/>
                        </div>
                        <span>{t('MENU.SUIVI_ACTIVITE')}</span>
                        <span className="pull-right-container">
                            <i className={`fa fa-angle-${showStats ? "down" : "left"} pull-right`}></i>
                        </span>
                    </a>
                    <ul className="treeview--menu" style={{display: 'block', height: showStats ? "70px" : "0px"}}>
                        <li>
                            {idMission ?
                            <NavLink to={`/rapport1/${idMission}/delegue`}><i className="fa fa-circle-o"></i> {t('MENU.RAPPORT')} 1</NavLink>
                            : `${t('MENU.LOADING')}...` }
                        </li>
                        <li>
                            {idMission ?
                            <NavLink to={`/rapport2/${idMission}/delegue`}><i className="fa fa-circle-o"></i> {t('MENU.RAPPORT')} 2</NavLink>
                            : `${t('MENU.LOADING')}...` }
                        </li>
                    </ul>
                </li>
                }
                {roleUser !== "ROLE_DELEGUE" && 
                <li>
                    <NavLink to="/missions">
                        <div className="icon">
                            <img src={userIcon} alt=""/>
                        </div>
                        <span>{t('MENU.MISSIONS')}</span>
                    </NavLink>
                </li>
                }
                {roleUser === "ROLE_ADMIN" && 
                <li>
                    <NavLink to="/jours-feries">
                        <div className="icon">
                            <img src={calendarIcon} alt=""/>
                        </div>
                        <span>{t('MENU.HOLIDAYS')}</span>
                    </NavLink>
                </li>
                }
                {roleUser === "ROLE_ADMIN" && 
                <li>
                    <NavLink to="/historique">
                        <div className="icon">
                            <img src={historyIcon} alt=""/>
                        </div>
                        <span>{t('MENU.HISTORY')}</span>
                    </NavLink>
                </li>
                }
                <li>
                    <NavLink to="/documentation">
                        <div className="icon">
                            <img src={docIcon} alt=""/>
                        </div>
                        <span>{t('MENU.DOCUMENTATION')}</span>
                    </NavLink>
                </li>
                {roleUser === "ROLE_ADMIN" && 
                <li className="treeview-tree">
                    <a onClick={() => {
                        setShowAnnuaire(false);
                        setShowParam(!showParam);
                        setShowRes(false);
                    }}>
                        <div className="icon">
                            <img src={paramIcon} alt=""/>
                        </div>
                        <span>{t('MENU.SETTINGS')}</span>
                        <span className="pull-right-container">
                            <i className={`fa fa-angle-${showParam ? "down" : "left"} pull-right`}></i>
                        </span>
                    </a>
                    <ul className="treeview--menu" style={{display: 'block', height: showParam ? "355px" : "0px"}}>
                        <li><NavLink to="/laboratoires"><i className="fa fa-circle-o"></i> {t('MENU.SETTINGS_OPTIONS.LABORATORIES')}</NavLink></li>
                        <li className="treeview-tree">
                            <a onClick={() => setShowRes(!showRes)}>
                                <i className="fa fa-circle-o"></i>
                                <span> {t('MENU.SETTINGS_OPTIONS.NETWORKS')}</span>
                                <span className="pull-right-container">
                                    <i className={`fa fa-angle-${showRes? "down" : "left"} pull-right`}></i>
                                </span>
                            </a>
                            <ul className="treeview--menu" style={{display: 'block', height: showRes ? "65px" : "0px"}}>
                                <li><NavLink to="/reseaux"><i className="fa fa-circle-thin"></i> {t('MENU.SETTINGS_OPTIONS.NETWORKS')}</NavLink></li>
                                <li><NavLink to="/frequences-visites"><i className="fa fa-circle-thin"></i> {t('MENU.SETTINGS_OPTIONS.FREQUENCY')}</NavLink></li>
                            </ul>
                        </li>
                        <li><NavLink to="/organigramme"><i className="fa fa-circle-o"></i> {t('MENU.SETTINGS_OPTIONS.ORGANIZATIONAL_CHART')}</NavLink></li>
                        <li><NavLink to="/zones-geographiques"><i className="fa fa-circle-o"></i> {t('MENU.SETTINGS_OPTIONS.GEOGRAPHICAL_AREA')}</NavLink></li>
                        <li><NavLink to="/secteurs"><i className="fa fa-circle-o"></i> {t('MENU.SETTINGS_OPTIONS.SECTORS')}</NavLink></li>
                        <li><NavLink to="/specialites"><i className="fa fa-circle-o"></i> {t('MENU.SETTINGS_OPTIONS.SPECIALTIES')}</NavLink></li>
                        <li><NavLink to="/types-pds"><i className="fa fa-circle-o"></i> {t('MENU.SETTINGS_OPTIONS.PDS_TYPE')}</NavLink></li>
                        <li><NavLink to="/utilisateurs"><i className="fa fa-circle-o"></i> {t('MENU.SETTINGS_OPTIONS.USERS')}</NavLink></li>
                    </ul>
                </li>
                }
            </ul>
        </section>
        {/* /.sidebar */}

        {roleUser === "ROLE_DELEGUE" &&
            <div className={`small-box ${paginationRemainingCR.nbResultats === 0 ? "bg-green" : "bg-red"}`} >
                <div className="inner">
                    <h3>{paginationRemainingCR.nbResultats}</h3>
                    <p>{t('DB.remaining_cr')}</p>
                </div>
                <div className="icon">
                    <i className="ion ion-ios-list-outline"></i>
                </div>
                <span
                    className="small-box-footer"
                    data-toggle="modal"
                    data-target="#modalRemainingCR"
                    onClick={() => dispatchList({ type: 'setPaginationRemainingCR', pPaginationRemainingCR: { ...paginationRemainingCR, page: 1} })}
                >
                    {t('DB.see_details')} <i className="fa fa-arrow-circle-right"></i>
                </span>
            </div>
        }
    </aside>
    )
}