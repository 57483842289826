import { t } from "../pages/i18nFunctions";

export const generateZGOptions = (zgList, idCountry, country) => {
    const list = { ...zgList };

    let table = [
        {
            label: "Pays",
            options: [{ value: idCountry, label: country, ad: country }]
        },
        {
            label: "Zone",
            options: []
        },
        {
            label: "Ville",
            options: []
        },
        {
            label: "Commune",
            options: []
        },
        {
            label: "Quartier",
            options: []
        },
    ];

    for(const [zgKey, zoneG] of Object.entries(list)) {
        
        if(zgKey !== "name" && zgKey !== "id") {
            for(const [cityKey, city] of Object.entries(zoneG)) {
        
                if(cityKey !== "name" && cityKey !== "id") {
                    for(const [comKey, com] of Object.entries(city)) {
                
                        if(comKey !== "name" && comKey !== "id") {
                            for(const [quartKey, quart] of Object.entries(com)) {
                        
                                if(quartKey !== "name" && quartKey !== "id")

                                    table.find(el => el.label === "Quartier").options.push({
                                        value: quart.id,
                                        label: quart.name,
                                        ad: `${country}, ${zoneG.name}, ${city.name}, ${com.name}, ${quart.name}`
                                    });
                            }

                            table.find(el => el.label === "Commune").options.push({
                                value: com.id,
                                label: com.name,
                                ad: `${country}, ${zoneG.name}, ${city.name}, ${com.name}`
                            });
                        }
                    }

                    table.find(el => el.label === "Ville").options.push({
                        value: city.id,
                        label: city.name,
                        ad: `${country}, ${zoneG.name}, ${city.name}`
                    });
                }
            }

            table.find(el => el.label === "Zone").options.push({
                value: zoneG.id,
                label: zoneG.name,
                ad: `${country}, ${zoneG.name}`
            });
        }
    }

    // zone
    // Object.keys(zone).map(item => {

    //     if(item !== "name" && item !== "id") {

    //         // ville
    //         Object.keys(zone[item]).map(ville => {

    //             if(ville !== "name" && ville !== "id") {

    //                 // commune
    //                 Object.keys(zone[item][ville]).map(com => {

    //                     if(com !== "name" && com !== "id") {

    //                         // quartier
    //                         Object.keys(zone[item][ville][com]).map(quart => {

    //                             if(quart !== "name" && quart !== "id") {
    //                                 table[table.map(g => g.label).indexOf("Quartier")].options.push(
    //                                     {
    //                                         value: zone[item][ville][com][quart].id,
    //                                         label: zone[item][ville][com][quart].name,
    //                                         ad: `${country}, ${zone[item].name}, ${zone[item][ville].name}, ${zone[item][ville][com].name}, ${zone[item][ville][com][quart].name}`
    //                                     }
    //                                 )
    //                             }
    //                         }); // -----

    //                         table[table.map(g => g.label).indexOf("Commune")].options.push(
    //                             {
    //                                 value: zone[item][ville][com].id,
    //                                 label: zone[item][ville][com].name,
    //                                 ad: `${country}, ${zone[item].name}, ${zone[item][ville].name}, ${zone[item][ville][com].name}`
    //                             }
    //                         )
    //                     }
    //                 }); // -------
                
    //                 table[table.map(g => g.label).indexOf("Ville")].options.push(
    //                     {
    //                         value: zone[item][ville].id,
    //                         label: zone[item][ville].name,
    //                         ad: `${country}, ${zone[item].name}, ${zone[item][ville].name}`
    //                     }
    //                 )
    //             }
    //         }); // ------

    //         table[table.map(g => g.label).indexOf("Zone")].options.push(
    //             {
    //                 value: zone[item].id,
    //                 label: zone[item].name,
    //                 ad: `${country}, ${zone[item].name}`
    //             }
    //         )
    //     }
    // });

    return table;
}

export const addressIntoOption = (zgTab) => {
    let tab = [];

    zgTab.map(z => {
        let label = z.addressZonegeo.split(", ");

        tab.push({
            value: z.idZonegeo,
            label: label[label.length-1],
            ad: z.addressZonegeo
        });
    });
    
    return tab;
}

export const generateEtabOptions = (etabList, typeEtabList) => {

    const etabs = etabList;
    const types = typeEtabList;
    let table = [];

    types.map(t =>
        table.push({
            label: t.name,
            options: []
        })
    )
    
    if((types !== null && types.length > 0) && (etabs !== null && etabs.length > 0))
        etabs.map((e) => {
            let el = table.find(g => g.label === e.nameInstitutionType);

            if(el)
                el.options.push({
                    value: e.id,
                    label: e.name,
                    ref: e.reference,
                    ad: e.addresszoneGeo,
                    isPrivate: e.isPrivate
                });
        });

    return table;
}

export const handleZoneSearch = (country, province, ville, commune, zoneID) => {
    return {
        zoneGeo: {
            value: zoneID,
            label: commune ? commune : ville ? ville : province ? province : country,
            ad: `${country}${province ? `, ${province}` : ""}${ville ? `, ${ville}` : ""}${commune ? `, ${commune}` : ""}`
        }
    }
}

export const generateAllZGOptions = (zgList) => {

    const zone = zgList;
    
    let table = [
        {
            label: t('INSTITUTION.POP_EDIT.COUNTRY'),
            options: [],
            code: "Pays"
        },
        {
            label: t('INSTITUTION.POP_EDIT.ZONE'),
            options: [],
            code: "Zone"
        },
        {
            label: t('INSTITUTION.POP_EDIT.CITY'),
            options: [],
            code: "Ville"
        },
        {
            label: t('INSTITUTION.POP_EDIT.COMMUNE'),
            options: [],
            code: "Commune"
        },
        {
            label: t('INSTITUTION.POP_EDIT.DISTRICT'),
            options: [],
            code: "Quartier"
        }
    ];

    for(const [countryKey, country] of Object.entries(zone)) {

        if(countryKey !== "name" && countryKey !== "id") {
            for(const [zgKey, zoneG] of Object.entries(country)) {
                
                if(zgKey !== "name" && zgKey !== "id") {
                    for(const [cityKey, city] of Object.entries(zoneG)) {
                
                        if(cityKey !== "name" && cityKey !== "id") {
                            for(const [comKey, com] of Object.entries(city)) {
                        
                                if(comKey !== "name" && comKey !== "id") {
                                    for(const [quartKey, quart] of Object.entries(com)) {
                                
                                        if(quartKey !== "name" && quartKey !== "id")

                                            table.find(el => el.code === "Quartier").options.push({
                                                value: quart.id,
                                                label: quart.name,
                                                ad: `${country.name}, ${zoneG.name}, ${city.name}, ${com.name}, ${quart.name}`,
                                                histo: {
                                                    country: country.name, countryID: country.id,
                                                    zoneG: zoneG.name, zoneGID: zoneG.id, zoneA: zgKey,
                                                    ville: city.name, villeID: city.id, villeA: cityKey,
                                                    com: com.name, comID: com.id, comA: comKey,
                                                    quart: quart.name, quartID: quart.id, quartA: quartKey
                                                }
                                            });
                                    }

                                    table.find(el => el.code === "Commune").options.push({
                                        value: com.id,
                                        label: com.name,
                                        ad: `${country.name}, ${zoneG.name}, ${city.name}, ${com.name}`,
                                        histo: {
                                            country: country.name, countryID: country.id,
                                            zoneG: zoneG.name, zoneGID: zoneG.id, zoneA: zgKey,
                                            ville: city.name, villeID: city.id, villeA: cityKey,
                                            com: com.name, comID: com.id, comA: comKey
                                        }
                                    });
                                }
                            }

                            table.find(el => el.code === "Ville").options.push({
                                value: city.id,
                                label: city.name,
                                ad: `${country.name}, ${zoneG.name}, ${city.name}`,
                                histo: {
                                    country: country.name, countryID: country.id,
                                    zoneG: zoneG.name, zoneGID: zoneG.id, zoneA: zgKey,
                                    ville: city.name, villeID: city.id, villeA: cityKey
                                }
                            });
                        }
                    }

                    table.find(el => el.code === "Zone").options.push({
                        value: zoneG.id,
                        label: zoneG.name,
                        ad: `${country.name}, ${zoneG.name}`,
                        histo: {
                            country: country.name, countryID: country.id,
                            zoneG: zoneG.name, zoneGID: zoneG.id, zoneA: zgKey
                        }
                    });
                }
            }

            table.find(el => el.code === "Pays").options.push({
                value: country.id,
                label: country.name,
                ad: `${country.name}`,
                histo: {
                    country: country.name, countryID: country.id
                }
            });
        }
    }
    
    return table;
}