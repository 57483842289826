import { useContext } from 'react';
import { PopUpStoreContext } from '../../../../../../store/store/storePopUp';
import { ActionsStoreContext } from '../../../../../../store/store/storeActions';

import iconEdit from "../../../../../../assets/img/icons/icon-edit.png";

export const Edit = (props) => {
    const { type, line, action } = props;
    const { statePopUp, dispatchPopUp } = useContext(PopUpStoreContext);
    const { stateActions, dispatchActions } = useContext(ActionsStoreContext);
    const { dataPopUp, dataPopUpSup } = statePopUp;
    const { dataActions } = stateActions;
    
    const handleClickEdit = (event) => {
        let aDataActions = line;
        if(type === 'medicalProsInstitutions') {
            let dDataPopUpSup = {
                type: type,
                action: action,
                isActive: !dataPopUpSup.isActive,
                optionalData: line
            }
            aDataActions = { ...line, ...dataActions, idInstitution: line.id };
            dispatchPopUp({type: 'setPopUpSup', dDataPopUpSup });
            dispatchActions({type: 'setDataActions', aDataActions });
        } else {
            let dDataPopUp = {
                type: type,
                action: action,
                isActive: !dataPopUp.isActive,
                optionalData: line
            }
            dispatchPopUp({type: 'setPopUp', dDataPopUp });
            dispatchActions({type: 'setDataActions', aDataActions });
        }
    }

    const isDisabled = () => {

        if(type === "medicalProPage") {
            return line.status === 0 && !line.isEdit && localStorage.getItem("role") !== "ROLE_ADMIN";
        }

        return false;
    }

    return(
        <>
            {dataPopUp.action !== 'visu' &&
                <div className="icon-btn" onClick={(e) => !isDisabled() && handleClickEdit(e)} disabled={isDisabled()}>
                    <img src={iconEdit} alt="edit"/>
                </div>
            }
        </>
    )
}