import { useContext } from 'react';
import { PopUpStoreContext } from '../../../../../../store/store/storePopUp';
import { ActionsStoreContext } from '../../../../../../store/store/storeActions';

import { PopUpSupManager } from '../../../../PopUp/PopUpSupManager';

import iconDelete from "../../../../../../assets/img/icons/icon-delete.png";

export const Delete = (props) => {
    const { type, line, action } = props;
    const { statePopUp, dispatchPopUp } = useContext(PopUpStoreContext);
    const { stateActions, dispatchActions } = useContext(ActionsStoreContext);
    const { dataPopUp } = statePopUp;
    
    const handleClickDelete = (event) => {
        let aDataActions;
        let dDataPopUp = {
            type: type,
            action: action,
            isActive: !dataPopUp.isActive
        }

        if(type === 'specialityProPop' || type === 'medicalProSoftPop') {
            aDataActions = {
                ...stateActions.dataActions,
                idMedicalProSpeciality: line.idMedicalProSpeciality,
                idSoftware: line.idSoftware
            };
            let dDataPopUpSup = dDataPopUp;
            dispatchPopUp({type: 'setPopUpSup', dDataPopUpSup });
        }else {
            aDataActions = line;
            dispatchPopUp({type: 'setPopUp', dDataPopUp });
        }
        return dispatchActions({type: 'setDataActions', aDataActions });
    }
    
    return(
        <>
            <div className="icon-btn" data-toggle="modal" data-target="#modalDelete" onClick={(e) => handleClickDelete(e)}>
                <img src={iconDelete} alt="delete"/>
            </div>
            <PopUpSupManager 
                type={type}
                displayTitle={type === 'specialityProPop' ? 'spécialité' : 'logiciel'} 
                isSup={true}
            />
        </>
    )
}