import "./ButtonExport.scss";
import { t } from "../../../../pages/i18nFunctions";
import iconExport from "../../../../assets/img/icons/svg/icon-export.svg";

export const ButtonExport = (props) => {
    const {
        disabled = false,
        handleExport = () => {},
        id
    } = props;

    return(
        <button
        className="btn-export"
        disabled={disabled}
        onClick={() => handleExport(id)}
        >
            <img className="btn-export-img" src={iconExport} alt="" />
            <span className="btn-export-text">{t('MISSION.LIST.EXPORT')}</span>
        </button>
    )
}