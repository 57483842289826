import { useState, useEffect } from 'react';
import { t } from "../../../../../pages/i18nFunctions";
import { CustomSelect } from '../../../CustomSelect/CustomSelect';
import { RestrictInput } from '../../../Input/RestrictInput/RestrictInput';

export const RoleInput = (props) => {
    const { labos, countries, item, handleChange, role } = props;

    const [display, setDisplay] = useState(t('SETTINGS.COUNTRY'));
    const [textSelect, setTextSelect] = useState(null);

    useEffect(() => {
        handleDefaultCountry();
    }, [])
    
    const handleDefaultCountry = () => {
        if(typeof item.restrictedArea !== 'undefined') {
            return countries.map((e) => {
                if(item.restrictedArea.length > 0 && item.restrictedArea[0].name === e.name) 
                    return setTextSelect(item.restrictedArea[0].name);

            });
        }
    }

    const handleSelectDisplay = () => {
        let roleCopy = role;
        if(typeof roleCopy !== 'string') {
            roleCopy = roleCopy[0];
        }

        switch (roleCopy) {
            case 'ROLE_ADMIN':
            case 'ROLE_QADMIN':
            case 'ROLE_MONITEUR':
                return false;
                
            case 'ROLE_DELEGUE':
            case 'ROLE_DSM':
            case 'ROLE_SUPERVISEUR':
                return <>
                    <label>{display}</label>
                    <CustomSelect
                        defaultText={textSelect ?? 'Sélectionner un pays'}
                        optionsList={countries}
                        handleOptionClick={(id, name, value) => handleChange('restrictedArea', [{id: id, name: value}])}
                    />
                </>;
                                        
            case 'ROLE_SUPPORT':                                        
            case 'ROLE_DDV':
            case 'ROLE_HP':
            case 'ROLE_DP':
                return <>
                    <label>{display}</label>
                    <RestrictInput 
                        data={item}
                        data1={countries}
                        data2={item.restrictedArea}
                        label1={"Pays disponibles"}
                        label2={"Pays dont l'accès est global"}
                        role={role}
                        model='restrictedArea'
                    />   
                </>;
                        
            case 'ROLE_COORDINATEUR':
                return <>
                    <label>{'Labo'}</label>
                    <RestrictInput 
                        data={item}
                        data1={labos}
                        data2={item.labos}
                        label1={"Laboratoires disponibles"}
                        label2={"Laboratoires rattachés"}
                        role={role}
                        model='labos'
                    />        
                </>;
            
            default:
                break;
        }
    }
    
    return(
        <>
            <div>           
                {handleSelectDisplay()}
            </div>
        </>
    )
}