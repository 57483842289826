
import { Link } from 'react-router-dom';
import iconCalendar from "../../../../../../assets/img/icons/icon-calendrier.png";

export const LinkPlanning = (props) => {
    const { line } = props;

    return (
        <Link
            className="icon-btn"
            to={`/missions/${line.id}/calendar`}
            state={{
                name: line.name,
                unlockedcal: line.unlockedCal,
                delegue: line.delegue ? line.delegue.fullNameDelegue : ""
            }}
        >
            <img src={iconCalendar} alt="calendar"/>
        </Link>
    )
}