import { AddMedicalPro } from './Type/AddMedicalPro';
import { EditMedicalPro } from './Type/EditMedicalPro';

export const MedicalProPopUpManager = (props) => {
    const { data, dispatchPop } = props;
    const { action } = data;

    const generatePopUps = () => {
        switch (action) {
            case 'add':
                return <AddMedicalPro data={data} dispatchPop={dispatchPop} />;

            case 'visu':
            case 'edit':
                return <EditMedicalPro data={data} dispatchPop={dispatchPop} />;
                
            default:
                return false;
        }
    }

    return (
        <>
            {
                <div className="modal-body">
                    {generatePopUps()}
                </div>
            }
        </>
    )
}