import { useState, useRef, useEffect } from 'react';
import { t } from "../../../pages/i18nFunctions";

// images
import iconSave from "../../../assets/img/icons/icon-save.png";

export const PopupLabo = (props) => {
    const { nameId, addLabo, editLabo, handleSubmit } = props;

    const [name, setName] = useState("");

    const btnRef = useRef(null);
    const edit = Object.keys(nameId).length > 0;

    // updates
    useEffect(() => {
        setName(nameId.name ? nameId.name : "");
    }, [nameId]);

    // functions
    const submitForm = () => {
        if(name !== "") {
            if(edit) editLabo(name, nameId.id);
            else addLabo(name);

            btnRef.current.click();
        }
    }

    return(
        <div className="modal fade in" id="modalLabo" tabIndex="-1" role="dialog" aria-labelledby="modalLaboLabel" animate="animate">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title" id="myModalLabel">{edit ? "Modifier" : "Ajouter"} un laboratoire</h1>
                    </div>
                    <div className="modal-body">
                        <form id="formAjout">
                            <label htmlFor="name">{t('SETTINGS.NAME')}</label>
                            <input type="text" required value={name} onChange={(e) => setName(e.target.value)}/>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button
                        type="submit"
                        form="formAjout"
                        className="btn-save"
                        data-toggle={ edit ? 'modal' : '' }
                        data-target={'#modalConfirmation'}
                        onClick={(e) => {
                            e.preventDefault();
                            if(edit) handleSubmit(submitForm);
                            else submitForm();
                        }}
                        disabled={name === "" || name === nameId.name}
                        >
                            <img src={iconSave} alt=""/>
                            {t('COMMON.SAVE')}
                        </button>
                        <button
                        type="button"
                        className="btn-cancel"
                        data-dismiss="modal"
                        onClick={() => setName(nameId.name ? nameId.name : "")}
                        ref={btnRef}
                        >
                            {t('COMMON.CANCEL')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}