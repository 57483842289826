export const config = {
    // baseUrl: "https://api-amivmv2.uxia-agency.com/",
    baseUrl: "https://apiv2.amivm.com/",
    groupeList: [
        {
            id: "admin",
            name: "Administrateur",
            val: ["ROLE_ADMIN"]
        },
        {
            id: "homme_pays",
            name: "Homme Pays",
            val: ["ROLE_HP"]
        },
        {
            id: "qadmin",
            name: "QueryAdmins",
            val: ["ROLE_QADMIN"]
        },
        {
            id: "delegue",
            name: "Délégués",
            val: ["ROLE_DELEGUE"]
        },
        {
            id: "superviseur",
            name: "Superviseurs",
            val: ["ROLE_SUPERVISEUR"]
        },
        {
            id: "directeur_pays",
            name: "Directeur Pays",
            val: ["ROLE_DP"]
        },
        {
            id: "district_sale_manager",
            name: "District Sale Manager",
            val: ["ROLE_DSM"]
        },
        {
            id: "directeur_ventes",
            name: "Directeur des Ventes",
            val: ["ROLE_DDV"]
        },
        {
            id: "moniteur",
            name: "Moniteurs",
            val: ["ROLE_MONITEUR"]
        },
        {
            id: "support",
            name: "Supports",
            val: ["ROLE_SUPPORT"]
        },
        {
            id: "coordinateur",
            name: "Coordinateurs Labo",
            val: ["ROLE_COORDINATEUR"]
        }
    ]
};