import { useState, useRef } from 'react';
import { t } from "../../../pages/i18nFunctions";

// components
import { CustomSelect } from '../../UI/CustomSelect/CustomSelect';

// images
import iconSave from "../../../assets/img/icons/icon-save.png";

export const PopupCancelAppointment = ({ listCancel, cancelAppointment }) => {

    const [motif, setMotif] = useState(t('PLANNING.SELECT_MOTIVE'));
    const [idMotif, setIdMotif] = useState(-1);

    const btnRef = useRef(null);
    
    const handleSubmit = (e) => {
        e.preventDefault();

        if(idMotif !== -1) {
            cancelAppointment(idMotif);
            btnRef.current.click()
        }
    }

    return(
        <div className="modal fade in" id="modalCancelAppointment" tabIndex="-1" role="dialog" aria-labelledby="modalCancelAppointmentLabel" animate="animate">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title" id="myModalLabel">{t('PLANNING.CANCEL_APPOINTMENT')}</h1>
                    </div>
                    <div className="modal-body">
                        <form id="formAjout">

                            <label>{t('PLANNING.CANCEL_MOTIVE')}</label>
                            <CustomSelect
                                defaultText={motif}
                                optionsList={listCancel}
                                handleOptionClick={(id, name) => {
                                    setIdMotif(id);
                                    setMotif(name);
                                }}
                            />

                        </form>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            form="formAjout"
                            className="btn-save"
                            disabled={idMotif === -1}
                            onClick={handleSubmit}
                        >
                            <img src={iconSave} alt=""/>
                            {t('COMMON.SAVE')}
                        </button>
                        <button
                            type="button"
                            className="btn-cancel"
                            data-dismiss="modal"
                            ref={btnRef}
                            onClick={() => {
                                setIdMotif(-1);
                                setMotif(t('PLANNING.SELECT_MOTIVE'));
                            }}
                        >{t('COMMON.CANCEL')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}