import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const MultiCheckbox = (props) => {

    const { type, handleCheck, data } = props;

    const { state: locationState } = useLocation();

    const [selected, setSelected] = useState([]);
    const [selectedProd, setSelectedProd] = useState([]);
    const [display, setDisplay] = useState({});

    useEffect(() => {
        if(locationState && locationState.labData && type === "laboratory")
            handleCheckGroup(locationState.labData.id, locationState.labData.name, locationState.labData.products);
    }, [locationState]);

    const handleCheckGroup = (id, name, prods) => {
        let tab = [...selected];
        let tabProd = [...selectedProd];
        let exist = false;

        tab.forEach(el => {
            if(el.id === id) {
                tab.splice(tab.map(ln => ln.id).indexOf(id), 1);
                exist = true;
            }
        });

        prods.forEach(p => {
            const idProd = type === "network" ? p.product.idProduct : p.id;
            tabProd.forEach(pid => {
                if(idProd === pid.id)
                    tabProd.splice(tabProd.map(tpp => tpp.id).indexOf(pid.id), 1);
            });
        });
            
        if(!exist) {
            tab.push({
                id: id,
                name: name,
                prods: prods
            });

            prods.forEach(p => {
                tabProd.push({
                    id: type === "network" ? p.product.idProduct : p.id,
                    name: type === "network" ? p.product.nameProduct : p.name,
                    parent: id
                });
            });
        }

        handleCheck(tabProd);

        setSelected(tab);
        setSelectedProd(tabProd);
    }

    const handleCheckProd = (id, name, idType) => {
        let tabProd = [...selectedProd];
        let exist = false;

        tabProd.forEach(el => {
            if(el.id === id) {
                tabProd.splice(tabProd.map(tp => tp.id).indexOf(id), 1);
                exist = true;
            }
        })

        if(!exist) tabProd.push({
            id: id,
            name: name,
            parent: idType
        });

        handleCheck(tabProd);

        setSelectedProd(tabProd);
    }

    const displayProds = () => {
        let tab = [];

        selectedProd.forEach(prod => {
            let labnet = false;
            
            selected.forEach(item => {
                if(prod.parent === item.id) labnet = true;
            });

            if(!labnet) tab.push(
                <span className="multi-label prod" key={`labnet-prod-${prod.id}`}>
                    {prod.name}
                    <span
                        className="close-label"
                        onClick={() => handleCheckProd(prod.id, prod.name, prod.idType)}
                    >×</span>
                </span>
            );
        });

        return tab;
    }

    return (
    <>
        <div className="multi-check-container">
            {data.map((el) => {
                const item = type === "network" ? el[type][0] : el;
                return (
                <div key={`check-${type}-${item.id}`}>

                    <div className="checkbox-line">

                        <input 
                            type="checkbox"
                            checked={selected.map(ln => ln.id).indexOf(item.id) !== -1}
                            onChange={() => handleCheckGroup(item.id, item.name, el.products)}
                            id={`check-${type}-${item.id}`}
                        />

                        <label
                            style={{cursor: 'pointer'}}
                            onClick={() => setDisplay({
                                ...display,
                                [`display${item.id}`]: display[`display${item.id}`] ? false : true
                            })}
                        >
                            {item.name} &nbsp;
                            <i className={`fa fa-angle-${display[`display${item.id}`] ? "left" : "down"}`}></i>
                        </label>

                    </div>

                    {display[`display${item.id}`] && el.products.map((prod) => {
                        const idProd = type === "network" ? prod.product.idProduct : prod.id;
                        const nameProd = type === "network" ? prod.product.nameProduct : prod.name;
                        return (
                            <div className="checkbox-line prod" key={`check-${type}-${item.id}-prod-${idProd}`}>
                                <input 
                                    type="checkbox"
                                    checked={selectedProd.map(p => p.id).indexOf(idProd) !== -1}
                                    onChange={() => handleCheckProd(idProd, nameProd, item.id)}
                                    id={`check-${type}-${item.id}-prod-${idProd}`}
                                    disabled={selected.map(ln => ln.id).indexOf(item.id) !== -1}
                                />
                                <label htmlFor={`check-${type}-${item.id}-prod-${idProd}`}>{nameProd}</label>
                            </div>
                        )
                    })}
                </div>
                )
            })}
        </div>

        <div className="multi-check-labels">
            {selected.map(item =>
                <span className="multi-label" key={`labnet-${item.id}`}>
                    {item.name}
                    <span
                        className="close-label"
                        onClick={() => handleCheckGroup(item.id, item.name, item.prods)}
                    >×</span>
                </span>
            )}
            {displayProds()}
        </div>
    </>
    )
}