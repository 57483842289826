import { useState, useContext, useRef, useEffect } from 'react';
import { FilterStoreContext } from '../../../../../store/store/storeFilters';

export const Search = (props) => {
    const { head } = props;
    
    const { stateFilter, dispatchFilters } = useContext(FilterStoreContext);
    const { searchFilters } = stateFilter;
    
    let [searchParamState, setSearchParamState] = useState({});

    const timer = useRef(null);

    // mounted
    useEffect(() => {
        timer.current = setTimeout(() => {}, 1000);
        // unmounted
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const handleSearchInput = (value, name) => {
        clearTimeout(timer.current);

        setSearchParamState({ name, value });

        timer.current = setTimeout(() => {
            let sSearchFilters = { ...searchFilters,
                [name]: value
            };

            dispatchFilters({type: 'setSearchFilters', sSearchFilters });
        }, 1000);
    }
    
    return(
        <>
           <input 
                type="text" 
                placeholder="..." 
                value={head.name === searchParamState.name ? searchParamState.value : ''} 
                onChange={(e) => handleSearchInput(e.target.value, head.name)} 
            />
        </>
    )
}