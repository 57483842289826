import { useState, useEffect, useContext } from 'react';
import './Missions.scss';
import { t } from "../i18nFunctions";

import { FilterStoreContext } from '../../store/store/storeFilters';
import { ListStoreContext } from '../../store/store/storeList';
import { ActionsStoreContext } from '../../store/store/storeActions';
import { StoreContext } from '../../store/store';

import { Pagination } from '../../components/Pagination/Pagination';

import { TableManager } from '../../components/UI/Table/TableManager';
import { PopUpManager } from '../../components/UI/PopUp/PopUpManager';

import {
    getAllMissions,
    disableMission,
    deleteMission,
	postMission,
    editMission,

    getAllProType,
    getAllSpecialite,
    getAllReseaux,
	getPositions
} from '../../store/index.service';

export const Missions = () => {
	const { stateFilter, dispatchFilters } = useContext(FilterStoreContext);
	const { dispatchList } = useContext(ListStoreContext);
	const { dispatchActions } = useContext(ActionsStoreContext);
	const { setLoadingStore } = useContext(StoreContext);

    const { searchFilters } = stateFilter;

	let [maxPage, setMaxPage] = useState(0);
    let [page, setPage] = useState(1);
	let [nbResultats, setNbResultats] = useState(0);
	let [maxPerPage, setMaxPerPage] = useState(50);
	let [mounted, setMounted] = useState(false);
	const [listMissions, setListMissions] = useState([]);

	const userCountries = JSON.parse(localStorage.getItem("userCountries"));

	const handleDeleteTab = (id, keyId, dataActions, dataOtherTabs) => {
        let tab = dataOtherTabs;

        let selectS = tab.map((s) => {
			return s[keyId]
		}).indexOf(id);
        if(selectS !== -1) tab.splice(selectS, 1);

        const key = keyId === "idSpeciality" ? "specialities" : "protypes";
		let aDataActions = {...dataActions, [key]: tab};
        dispatchActions({type: 'setDataActions', aDataActions});
        
        return dispatchList({type: 'setDataOtherTabs', dDataOtherTabs: tab });
    }

	// mounted
	useEffect(() => {
		let sSearchFilters = { filter: false };
        dispatchFilters({ type: 'setSearchFilters', sSearchFilters });
		dispatchActions({ type: 'initAllActions', allActions });

		getAllProType().then(res => {
			const data = JSON.parse(res);
			let dDataProType = data;
			dispatchList({ type: 'setDataProType', dDataProType });
		});

        getAllSpecialite().then(res => {
			const data = JSON.parse(res);
			let dDataSpeciality = data.listSpeciality ?? [];
			dispatchList({ type: 'setDataSpeciality', dDataSpeciality });
		});

        getAllReseaux().then(res => {
			const data = JSON.parse(res);
			let dDataNetwork = data.listNetwork ?? [];
			dispatchList({ type: 'setDataNetwork', dDataNetwork });
		});

		getPositions().then(res => {
			const data = JSON.parse(res);
			let dDataPositions = data ?? [];
			dispatchList({ type: 'setDataPositions', dDataPositions });
		});

		setMounted(true);
	}, []);
	
	// updates
	useEffect(() => {
		let params = {
			page: page,
			maxPerPage: maxPerPage
		}
		let dDataPagination = params;
		let sOrderByKey = {};

		dispatchList({type: 'setDataPagination', dDataPagination });
        dispatchFilters({type: 'setOrderByKey', sOrderByKey });

	}, [page]);

	useEffect(() => {
		if(mounted) {
			setPage(1);
			handleGetAllMissions({
				page: 1,
				maxPerPage: maxPerPage
			});
		}
	}, [searchFilters]);

	const handleGetAllMissions = (params) => {

		setLoadingStore(true);
		getAllMissions({
			...params,
			...searchFilters,
			filter: false,
			countryName: searchFilters.country,
			countries: userCountries.map(el => el.name)
		}).then(res => {
			const data = JSON.parse(res);
			let dDataList = data.listMission ?? [];

			setNbResultats(data.nbResultats);
			setMaxPerPage(data.maxPerPage);
			setMaxPage(data.nbrPages);
			setListMissions(dDataList);

			dispatchList({ type: 'setList', dDataList });

			setLoadingStore(false);
		});
	}
	
	const dataHeader = [
		{
			name: 'country',
			displayName: t('MISSION.LIST.HEADERS.COUNTRY'),
			isFilterable: true,
			isSearchable: true,
		},
		{
			name: 'name',
			displayName: t('MISSION.LIST.HEADERS.NAME'),
			isFilterable: true,
			isSearchable: true,
		},
		{
			name: 'network',
			displayName: t('MISSION.LIST.HEADERS.NETWORK'),
			isFilterable: true,
			isSearchable: true,
		},
		{
			name: 'delegue',
			displayName: t('MISSION.LIST.HEADERS.AFFECTED_TO'),
			isFilterable: true,
			isSearchable: true,
		},
		{
			name: 'withSupervision',
			displayName: t('MISSION.LIST.HEADERS.SUP'),
			isSelectable: true,
            placeholder: t('COMMON.ALL')
		},
		{
			name: 'numCRlastweek',
			displayName: t('MISSION.LIST.HEADERS.CR'),
            small: t('MISSION.LIST.HEADERS.CR_LAST_WEEK'),
			isSelectable: true,
            placeholder: t('COMMON.ALL')
		},
		{
			name: 'numCRcurrentweek',
			displayName: t('MISSION.LIST.HEADERS.CR'),
            small: t('MISSION.LIST.HEADERS.CR_THIS_WEEK'),
			isSelectable: true,
            placeholder: t('COMMON.ALL')
		},
		{
			name: 'targetCount',
			displayName: t('MISSION.LIST.HEADERS.TARGETS')
		}
	];

	let actionsList = [
        'planning',
        'ciblage'
	];

	const handleFiltersTable = (list) => {
		let filteredList = [...list];

		if(stateFilter.searchSupervision) {
			const fil = stateFilter.searchSupervision.value ? true : false;
			filteredList = filteredList.filter(el => el.withSupervision === fil);
		}
		if(stateFilter.searchCRCurrentWeek) {
			const fil = stateFilter.searchCRCurrentWeek.value ? true : false;

			filteredList = filteredList.filter(el => {
				const crCurrent = el.numCRcurrentweek.split('_');
				const done = crCurrent[0] === "0" ? crCurrent[1] === "0" ? true : false : crCurrent[1]/crCurrent[0] <= 2 ? true : false;
				return done === fil;
			});
		}
		if(stateFilter.searchCRLastWeek) {
			const fil = stateFilter.searchCRLastWeek.value ? true : false;

			filteredList = filteredList.filter(el => {
				const crPrevious = el.numCRlastweek.split('_');
				const done = crPrevious[0] === "0" ? crPrevious[1] === "0" ? true : false : crPrevious[1]/crPrevious[0] <= 2 ? true : false;
				return done === fil;
			});
		}

		return filteredList;
	}

	if(localStorage.getItem("role") === "ROLE_ADMIN")
		actionsList = [
			...actionsList,
			'edit',
			'lock',
			'delete',
		];

	let allActions = {
		editAction: editMission,
		addAction: postMission,
		deleteAction: deleteMission,
		lockAction: disableMission,
		getAll: handleGetAllMissions,
		deleteDirect: handleDeleteTab
	}

	return (
		<section className="content" id="list-missions">
			<section className="content-header">
				<h1>
					{t('MISSION.LIST.TITLE')}
					<small>{t('MISSION.LIST.MANAGEMENT')}</small>
				</h1>
			</section>
		
			<section className="content sectionTable">
				<TableManager 
					dataHeader={dataHeader} 
					actionsList={actionsList}
					type="mission"
					isAdd={localStorage.getItem("role") === "ROLE_ADMIN"}
					handleFiltersTable={handleFiltersTable}
				/>
				<br/>
				{ maxPage > 1 &&
					<Pagination
						maxPage={maxPage}
						onChangePage={(data) => {
							setPage(data);
							handleGetAllMissions({
								page: data,
								maxPerPage: maxPerPage
							});
						}}
						nbCount={nbResultats}
						page={page}
					></Pagination>
				}
			</section>

			<PopUpManager 
				type="mission"
				displayTitle="Mission"
			/>

		</section>
	)
}
