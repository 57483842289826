import { useContext, useState,useEffect } from 'react';
import { FilterStoreContext } from '../../../../store/store/storeFilters';

import { TableRowManager } from '../../Table/Row/TableRowManager';
import { ListStoreContext } from '../../../../store/store/storeList';

import './index.scss';

export const TableBodyManager = (props) => {

	const { dataHeader, actionsList, type, isAdd, handleFiltersTable = (l) => { return l; } } = props;

	const { stateList } = useContext(ListStoreContext);
	const { stateFilter } = useContext(FilterStoreContext);
    const { orderByKey } = stateFilter;

	const { dataList, dataMedicalPro, dataOtherTabs, dataVerifEtab, dataVerifPro, dataRemainingCR } = stateList;

	let [dataSource, setDataSource] = useState();

	useEffect(() => {
		let data = dataList;

		if(type === 'medicalProPop')
			data = dataMedicalPro;

		else if(type === 'verifEtab')
			data = dataVerifEtab;
		else if(type === 'verifPro')
			data = dataVerifPro;

		else if(type === 'medicalProsInstitutions')
			data = dataOtherTabs;
		
		else if(type === 'specialityProPop')
			data = dataOtherTabs;
		
		else if(type === 'medicalProSoftPop')
			data = dataOtherTabs;
		
		else if(type === 'medicalProHisto')
			data = dataOtherTabs;

		else if(type === 'medicalProQualifiedProducts')
			data = dataOtherTabs;
			
		else if(type === 'missionTab')
			data = dataOtherTabs;
			
		else if(type === 'remainingCR')
			data = dataRemainingCR;
		
			
		const filteredData = handleFiltersTable(data);
		const sortedData = handleOrderByKey(filteredData, orderByKey);
		return setDataSource(sortedData), generateLines(sortedData);
		
	}, [dataList, dataOtherTabs, dataVerifEtab, dataVerifPro, dataMedicalPro, stateFilter, dataRemainingCR]) 

	const handleOrderByKey = (liste = [], params) => {
		
		if(liste.length > 0) {
			let dataCopy = liste.slice();

			if(params[params.key] === true) {
				if(params.key === "lastpwddatechange" || params.key === "lastactivity")
					dataCopy.sort((a, b) => checkParams(a[params.key], params.key) - checkParams(b[params.key], params.key));
				else if(params.key === "status" && type === "medicalProPage")
					dataCopy.sort(a => checkParams(a[params.key], params.key) ? 1 : -1) //check for boolean
				else if(params.key === "medicalProMainSpeciality"){
					dataCopy.sort((a, b) => {
						if(typeof a[params.key].nameMainSpeciality !== 'undefined' && typeof b[params.key].nameMainSpeciality !== 'undefined')
							return checkParams(a[params.key], params.key).localeCompare(checkParams(b[params.key], params.key));
						else 
							return -1;
					})
				}
					
				else
					dataCopy.sort((a, b) => checkParams(a[params.key], params.key).localeCompare(checkParams(b[params.key], params.key)));
				
			}
			else {
				if(params.key === "lastpwddatechange" || params.key === "lastactivity")
					dataCopy.sort((a, b) => checkParams(b[params.key], params.key) - checkParams(a[params.key], params.key));
				else if(params.key === "status" && type === "medicalProPage")
					dataCopy.sort(a => checkParams(a[params.key], params.key) ? -1 : 1) //check for boolean
				else if(params.key === "medicalProMainSpeciality") {
					dataCopy.sort((a, b) => {
						if(typeof a[params.key].nameMainSpeciality !== 'undefined' && typeof b[params.key].nameMainSpeciality !== 'undefined')
							return checkParams(b[params.key], params.key).localeCompare(checkParams(a[params.key], params.key));
						else 
							return 1;
					});
				}

				else
					dataCopy.sort((a, b) => checkParams(b[params.key], params.key).localeCompare(checkParams(a[params.key], params.key)));
				
			}

			return dataCopy;
		}

		return liste;
	}

    const checkParams = (el, key) => {
		
		if(typeof el !== 'undefined' && el.length !== 0) {
			if(key === "lastpwddatechange" || key === "lastactivity")
				return new Date(el);
			else if(key === "roles" )
				return el[0];
			else if(key === "specialityMedicalPro")
				return el[0].nameSpecialityMedicalPro;
			else if(key === "medicalProType")
				return el.nameMedicalProType;
			else if(key === "medicalProSpeciality")
				return el[0].nameMedicalProSpeciality;
			else if(key === "medicalProMainSpeciality")
				return el.nameMainSpeciality;
			else if(key === "status")
				return el;
			else if(key === "country" && type === "mission")
				return el.nameCountry;
			else if(key === "network")
				return el.nameNetwork;
			else if(key === "delegue")
				return el.fullNameDelegue;
			else 
				return el.trim();
		}
		else
			return "";
			// return false;
	}

	const generateLines = (data) => {
		if(typeof data !== 'undefined' && data !== null)

			return data.map((line, i) => {
				return (
					<tr className="list-row" key={i}>
						<TableRowManager 
							type={type}
							dataHeader={dataHeader}
							line={line}
							actionsList={actionsList}
							isAdd={isAdd}
						/>
					</tr>
				)
			});
		else
			return (
				<tr className="list-row">
					<td>Aucun résultat</td>
				</tr>
			)
	}

    return(
		<tbody className="tabBody">
			{
				typeof dataSource !== 'undefined' &&
				generateLines(dataSource)
			}
		</tbody>
    )
}