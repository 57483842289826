import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getMedicalProsQualifProd = (idPro) => {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get(`api/professionnel/${idPro}/qualifiedproducts`)
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getMedicalPros = (data) => {

    return new Promise((resolve, reject) => {
        try { 
            let params = {};
            if( data !== undefined ){
                params = {
                    "page" : data.page,
                    "maxPerPage": data.maxPerPage,
                    "country" : data.country,
                    "mission" : data.mission,
                    "label" : data.label,
                    "location" : data.location,
                    "protype" : data.protype,
                    "speciality" : data.speciality,
                    "zoneGeo" : data.zoneGeo,
                }
            }
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/professionnel/list', params)
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const postMedicalPro = (pro) => {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).post('api/professionnel', {
                    "idArea": pro.country.value,
                    "idMedicalProType": pro.medicalProType.idMedicalProType,
                    "gender": pro.gender,
                    "title": pro.title,
                    "lastName": pro.lastName,
                    "firstName": pro.firstName,
                    "mail": pro.mail,
                    "mobile": pro.mobile,
                    "landLine": pro.landLine,
                    "faxNumber": pro.faxNumber,
                    "isComputerized": pro.isComputerized,
                    "isThoughtLeader": pro.isThoughtLeader,
                    "isEthicaFriend": 0,
                    "phoneNumberToRetrieve": "",
                    "emailToRetrieve": "",
                    "comment": "",
                    "orgnCode": "",
                    "idInstitution": pro.idInstitution,
                    "savedOnEthica": pro.savedOnEthica,
                    "visitDistance": pro.visitDistance,
                    "idMainSpeciality": pro.medicalProMainSpeciality ? pro.medicalProMainSpeciality.idMainSpeciality : undefined,
                    "isRetired": pro.isRetired,
                    "isDeath": pro.isDeath
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const editMedicalPro = (pro) => {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).put('api/professionnel/'+pro.id, {
                    "idArea": pro.idArea,
                    "idMedicalProType": pro.medicalProType.idMedicalProType,
                    "gender": pro.gender,
                    "title": pro.title ? pro.title.label : "",
                    "lastName": pro.lastName,
                    "firstName": pro.firstName,
                    "mail": pro.mail,
                    "mobile": pro.mobile,
                    "landLine": pro.landLine,
                    "faxNumber": pro.faxNumber,
                    "isComputerized": pro.isComputerized,
                    "isThoughtLeader": pro.isThoughtLeader,
                    "isEthicaFriend": pro.isEthicaFriend,
                    "phoneNumberToRetrieve": "",
                    "emailToRetrieve": "",
                    "comment": "",
                    "orgnCode": "",
                    "savedOnEthica": pro.savedOnEthica,
                    "visitDistance": pro.visitDistance,
                    "isRetired": pro.isRetired,
                    "isDeath": pro.isDeath,
                    "idMainSpeciality": pro.medicalProMainSpeciality ? pro.medicalProMainSpeciality.idMainSpeciality : undefined,
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const verifPro = (data) => {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/verif-name/professionnel', {
                    "lastName": data.lastName,
                    "firstName": data.firstName,
                    "page": data.page,
                    "maxPerPage": data.maxPerPage,
                    "id": data.id ? data.id : null,
                    "area_id": data.idCountry
                })
                .then(function (response) {
                    try {
                        resolve(JSON.stringify(response.data));
                    } catch (error) {
                        reject(error);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getSavedOn = (data) => {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/professionnel/saved-ethica', data)
                .then(function (response) {
                    try {
                        resolve(JSON.stringify(response.data));
                    } catch (error) {
                        reject(error);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

// ETAB

export const postEtabPro = (etab) => {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).post('api/professionnel/'+etab.idPro+'/location', {
                    "idInstitution": etab.idInstitution,
                    "nbPatientsDay": etab.nbPatientsDay === "" ? null : etab.nbPatientsDay,
                    "withInternetConn": etab.withInternetConn,
                    "isHeadDept": etab.isHeadDept,
                    "hasSecretary": etab.hasSecretary,
                    "onAppointment": etab.onAppointment,
                    "prospectingScore": "",
                    "schedule": etab.schedule
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const editEtabPro = (etab) => {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).put('api/professionnel/'+etab.idPro+'/location', {
                    "idInstitution": etab.idInstitution,
                    "nbPatientsDay": etab.nbPatientsDay === "" ? null : etab.nbPatientsDay,
                    "withInternetConn": etab.withInternetConn,
                    "isHeadDept": etab.isHeadDept,
                    "hasSecretary": etab.hasSecretary,
                    "onAppointment": etab.onAppointment,
                    "prospectingScore": "",
                    "schedule": etab.schedule
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const disableEtabPro = (params) => {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).put('api/professionnel/'+params.idPro+'/location/disable', {
                    idInstitution: params.idEtab,
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

// ONE

export const getOnePDS = (id) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/professionnel/'+id+'/one', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.MedicalPro[0]));
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getEtabPro = (idPro) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/professionnel/'+idPro+'/location', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listMedicalProInstitution));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

//SPECIALITES

export const getSpePro = (idPro) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/professionnel/'+idPro+'/speciality', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listMedicalProSpeciality));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getSpeByType = (idType) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/medicalprotype/'+idType+'/speciality', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listSpecialityByMedicalProType));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const postSpePro = (idPro, idSpe) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).post('api/professionnel/'+idPro+'/speciality', {
                    idSpeciality: idSpe
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const disableSpePro = (params) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).put('api/professionnel/'+params.idPro+'/speciality/disable', {
                    idSpeciality: params.idSpe
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const deleteSpePro = (params) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).delete('api/professionnel/'+params.idPro+'/speciality/delete', { data: {
                    idSpeciality: params.idSpe
                }})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

//LOGICIELS

export const getLogiciels = () => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).get('api/software', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listSoftware));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getLogPro = (idPro) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).get('api/professionnel/'+idPro+'/software', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listMedicalProSoftware));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const postLogPro = (idPro, idLog) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).post('api/professionnel/'+idPro+'/software', {
                    idSoftware: idLog
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const disableLogPro = (params) => {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).delete('api/professionnel/'+params.idPro+'/software/disable', { data: {
                    idSoftware: params.idLog
                }})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const deleteLogPro = (params) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).delete('api/professionnel/'+params.idPro+'/software/delete', { data: {
                    idSoftware: params.idLog
                }})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}




export const medicalProService = {
    getMedicalPros,
    postMedicalPro,
    editMedicalPro,
    getEtabPro,
    postEtabPro,
    editEtabPro,
    disableEtabPro,
    getOnePDS,
    getSpePro,
    postSpePro,
    disableSpePro,
    deleteSpePro,
    getLogiciels,
    getLogPro,
    postLogPro,
    disableLogPro,
    verifPro,
    deleteLogPro,
    getSpeByType,
    getMedicalProsQualifProd,
    getSavedOn
};