import { useContext, useState, useEffect } from 'react';
import { FilterStoreContext } from '../../../../../store/store/storeFilters';
import { ListStoreContext } from '../../../../../store/store/storeList';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
// import { format } from 'date-fns';
import * as calendarLocales from 'react-date-range/dist/locale';

export const DateInput = (props) => {
    const { head, type } = props;
	
    const { stateFilter, dispatchFilters } = useContext(FilterStoreContext);
	const { stateList, dispatchList } = useContext(ListStoreContext);

	let [startDate, setStartDate] = useState(
		head.startDate ? new Date(`${head.startDate.split("-")[1]}/${head.startDate.split("-")[0]}/${head.startDate.split("-")[2]}`)
		: new Date(new Date().setDate(new Date().getDate()-7))
	);
	let [endDate, setEndDate] = useState(
		head.endDate ? new Date(`${head.endDate.split("-")[1]}/${head.endDate.split("-")[0]}/${head.endDate.split("-")[2]}`)
		: new Date()
	);
	let [key, setKey] = useState('selection');
	let [value, setValue] = useState('');
	let [enteringDate, setEnteringDate] = useState(false);

	let [calendarIsHidden, setCalendarIsHidden] = useState(false);

	useEffect(() => {
		setValue(`${formatDate(startDate)} - ${formatDate(endDate)}`);
	}, []);

	const handleSelect = (item) => {
		if(enteringDate) {
			let sSearchDate = item.selection;
			dispatchFilters({type: 'setSearchDate', sSearchDate});
		}

		setStartDate(item.selection.startDate)
		setEndDate(item.selection.endDate)
		setKey(item.selection.key)
		setValue(`${formatDate(item.selection.startDate)} - ${formatDate(item.selection.endDate)}`);
		setEnteringDate(!enteringDate)

		if(item.selection.startDate !== item.selection.endDate)
			setCalendarIsHidden(!calendarIsHidden);
	}

	const formatDate = (date) => {
		const padTo2Digits = (num) => {
			return num.toString().padStart(2, '0');
		}
		  
		return [
			padTo2Digits(date.getDate()),
			padTo2Digits(date.getMonth() + 1),
			date.getFullYear(),
		].join('/');
	}

	return(
        <>
			<div className="inner-addon right-addon">
				<i className="glyphicon glyphicon-calendar"></i>
				<input
					className="form-control"
					onClick={() => {
						setCalendarIsHidden(!calendarIsHidden);
						setEnteringDate(false);
					}}
					type="text"
					value={value}
					onChange={() => {}}
				/>
			</div>
			{calendarIsHidden === true && 
				<DateRange
					locale={localStorage.getItem('currentLanguage') === "en" ? calendarLocales.enUS : calendarLocales.fr}
					onChange={(item) => handleSelect(item)}
					showDateDisplay={false}
					moveRangeOnFirstSelection={false}
					ranges={[
						{
							startDate: startDate,
							endDate: endDate,
							key: key
						}
					]}
					dragSelectionEnabled={false}
					{...( enteringDate ? { maxDate: new Date() } : {})}
				/>
			}
        </>
    )
}