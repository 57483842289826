import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getVisitsData = (products = [], countries = []) => {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.request({
                    method: 'POST',
                    baseURL: config.baseUrl,
                    url: 'api/dashboard/visits-jour',
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    },
                    data: { products, countries }
                })
                .then(function (response) {
                    try {
                        resolve(JSON.stringify(response.data));
                    } catch (error) {
                        reject(error);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getContactData = (products = [], countries = []) => {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.request({
                    method: 'POST',
                    baseURL: config.baseUrl,
                    url: 'api/dashboard/contacts',
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    },
                    data: { products, countries }
                })
                .then(function (response) {
                    try {
                        resolve(JSON.stringify(response.data));
                    } catch (error) {
                        reject(error);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getRemainingCR = (idMission, page) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: { 
                        'Authorization': 'Bearer ' + res.token, 
                        'Content-Type': 'application/json'
                    }
                }).post(`api/dashboard/compte-rendu/${idMission}`, {
                    page: page ?? 1
                })
                .then(function (response) {
                    try {
                        resolve(JSON.stringify(response.data));
                    } catch (error) {
                        reject(error);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getSummaryMissionById = (idMission) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: { 
                        'Authorization': 'Bearer ' + res.token, 
                        'Content-Type': 'application/json'
                    }
                }).get('api/summary/mission/'+idMission, {})
                .then(function (response) {
                    try {
                        resolve(JSON.stringify(response.data));
                    } catch (error) {
                        reject(error);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}