import { useContext } from "react";
import { StoreContext } from "../../../../../../store/store";
import { PopUpStoreContext } from "../../../../../../store/store/storePopUp";
import moment from 'moment';
import { NotificationManager } from "react-notifications";
import { t } from "../../../../../../pages/i18nFunctions";

export const ShowReport = (props) => {

    const { action, stateActions, type, line, refresh } = props;
    const { missionUser } = useContext(StoreContext);
    const { dispatchPopUp } = useContext(PopUpStoreContext);

    const handleClick = () => {
        if(isBlockedByLock)
            NotificationManager.error('', t("DB.locked_cr_msg"));
        else {
            if(type === "remainingCR")
                stateActions.getUnfilledCRData(line.idAppointment);
            else
                stateActions.getCRData(line.idAppointment);

            const dDataPopUp = {
                type: '',
                action: '',
                isActive: false,
                optionalData: {
                    halfday: line.halfday,
                    idInstitution: line.idInstitution,
                    idMedicalPro: line.idMedicalPro
                }
            };
            dispatchPopUp({ type: 'setPopUp', dDataPopUp })
        }
    }

    const isBlockedByLock = (
        type === "remainingCR" &&
        missionUser.unlockedCal === false &&
        moment().diff(line.date, "weeks") > 1
    )

    return (
        <div
            className="icon-btn"
            onClick={() => handleClick()}
            data-toggle={"modal"}
            data-target={type === "remainingCR" ? !isBlockedByLock ? "#modalRemplirCRMenu" : "" : "#modalRemplirCR"}
        >
            <i  
                className="fa fa-file-text-o"
                aria-hidden="true"
            ></i>
        </div>
    )
}