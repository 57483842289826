import { useState, useEffect, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import "./SuiviActivite.scss";
import { format } from 'date-fns';
import { t } from "../i18nFunctions";
import { StoreContext } from '../../store/store';
import { ActionsStoreContext } from '../../store/store/storeActions';
import Select from 'react-select';
import { loginAsUser } from '../../store/index.service';

import { Pagination } from '../../components/Pagination/Pagination';
import { TabElements } from '../../components/TabElements/TabElements';
import { MultiCheckbox } from '../../components/UI/Checkbox/MultiCheckbox/MultiCheckbox';
import { ListRowSuiviActivite } from './ListRowSuiviActivite';
import ReactLoading from 'react-loading';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { fr } from 'react-date-range/dist/locale';
import { enUS } from 'react-date-range/dist/locale';

// API
import {
    getSuiviActivite,
    getProdNet,
    getProdLab
} from "../../store/index.service";

export const SuiviActivite = () => {

    const { setLoadingStore } = useContext(StoreContext);

    const { state: locationState } = useLocation();

    const { dispatchActions } = useContext(ActionsStoreContext);

    const [suiviActivite, setSuiviActivite] = useState([]);
    const [displayList, setDisplayList] = useState(suiviActivite);
    const [suiviReady, setSuiviReady] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        maxPage: 1,
        maxPerPage: 30,
        nbResultats: 0
    });
    const [selectedProdRes, setSelectedProdRes] = useState([]);
    const [selectedProdLab, setSelectedProdLab] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState(
        locationState && locationState.countriesData ?
            {
                value: locationState.countriesData.id,
                label: locationState.countriesData.name
            }
        :
            null
    );

    const [startDate, setStartDate] = useState(
        locationState && locationState.navStartDate ?
            new Date(locationState.navStartDate)
        :
            new Date()
    );
    const [endDate, setEndDate] = useState(
        locationState && locationState.navEndDate ?
            new Date(locationState.navEndDate)
        :
            new Date(new Date().setDate(new Date().getDate()+1))
    );
    const [key, setKey] = useState("selection");
    const [calendarIsHidden, setCalendarIsHidden] = useState(true);
    const [enteringDate, setEnteringDate] = useState(false);

    const [resList, setResList] = useState([]);
    const [loadingProdNet, setLoadingProdNet] = useState(true);
    const [labList, setLabList] = useState([]);
    const [loadingProdLab, setLoadingProdLab] = useState(true);
    const [labnet, setLabnet] = useState("labo");

    const [filters, setFilters] = useState({
        delegue: ""
    });
    const [sorting, setSorting] = useState({
        NbJoursTravailles: null,
        Calls_total: null,
        'Moy.Contacts/Jour_Cibles': null,
        NbHcpUnique_Cibles: null,
        Frequences_Cibles: null,
        NbContacts_potentielA: null,
        Frequences_potentielA: null,
        NbContacts_potentielB: null,
        Frequences_potentielB: null,
        NbContacts_potentielC: null,
        Frequences_potentielC: null,
        NbContacts_potentielD: null,
        Frequences_potentielD: null,
        NbContacts_HorsCibles: null
    });

    const role = localStorage.getItem('role');
    // const originalRole = localStorage.getItem("originalRole");
    const timer = useRef(null);

    let allActions = {
		loginAsUser: loginAsUser
	}

    useEffect(() => {
        getProdLab().then(res => {
            const data = JSON.parse(res);
            if(data) setLabList(data);
            setLoadingProdLab(false);
        });

        if(role !== "ROLE_COORDINATEUR")
            getProdNet().then(res => {
                const data = JSON.parse(res);
                if(data) setResList(data);
                setLoadingProdNet(false);
            });


		dispatchActions({type: 'initAllActions', allActions});

        timer.current = setTimeout(() => {}, 1000);

        // unmounted
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    useEffect(() => {
        filterList(1);
    }, [suiviActivite, filters, sorting, selectedCountry]);

    // updates
    const handleGetSuiviActivite = (start, end, prodTab) => {
        setLoadingStore(true);

        if(suiviReady)
            setSuiviReady(false);

        getSuiviActivite({
            startDate: start,
            endDate: end,
            products: prodTab
        })
        .then(res => {
            const data = JSON.parse(res);
            const synthese = data.listMissionDelegue ? Object.values(data.listMissionDelegue) : [];

            setSuiviActivite(synthese);
            setSuiviReady(true);
        })
        .finally(() => setLoadingStore(false))
    }

    useEffect(() => {
        getMissionList(startDate, endDate);
    }, [selectedProdLab, selectedProdRes, labnet]);

    const getMissionList = (start, end) => {
        if(start && end) {
            const formatedStartDate = format(start, 'yyyy-MM-dd') + 'T00:00:00.000Z';
            const formatedEndDate = format(end, 'yyyy-MM-dd') + 'T00:00:00.000Z';
            const prodTab = labnet === "labo" ? [...selectedProdLab] : [...selectedProdRes];

            handleGetSuiviActivite(formatedStartDate, formatedEndDate, prodTab.map(p => p.id));
        }
    }

    const handleProds = (tab, method) => {
        clearTimeout(timer.current);
    
        timer.current = setTimeout(() => {
            method(tab);
        }, 1000);
    }
    
    const handleSort = (col) => {
        const value = sorting[col];
        let resetted = { ...sorting };

        // reset all to null
        Object.keys(resetted).forEach(key => resetted[key] = null);

        setSorting({
            ...resetted,
            [col]: value === null ? 0 : value === 0 ? 1 : null
        });
    }

    const filterList = (page = pagination.page) => {

        let list = [...suiviActivite]
            .filter(el =>
                filters.delegue ? el.delegue.toLowerCase().includes(filters.delegue) : true
                &&
                selectedCountry ? el.pays === selectedCountry.label : true
            );

        for (const [key, value] of Object.entries(sorting)) {

            if(value !== null)
                list.sort((a, b) => {
                    let compareA = a[key], compareB = b[key];

                    if(typeof a[key] === "string") {
                        compareA = a[key].split("/")[0];
                        compareB = b[key].split("/")[0];
                    }

                    return parseFloat(compareA) - parseFloat(compareB);
                });

            if(value === 1)
                list = list.reverse();
        }

        const startIndex = pagination.maxPerPage * (page - 1);

        setPagination({
            ...pagination,
            page: page,
            maxPage: Math.ceil(list.length / pagination.maxPerPage),
            nbResultats: list.length,
        });
        
        list = list.slice(startIndex, startIndex + pagination.maxPerPage);

        setDisplayList(list);
    }
    
    let tabLabel = { labo: t('SUIVI.LABO') };

    if(role !== "ROLE_COORDINATEUR")
        tabLabel = {
            ...tabLabel,
            reseaux: t('SUIVI.NET')
        }

    let tabs = {
        labo: (
        <>
            {loadingProdLab && <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader" />}
            <MultiCheckbox
                data={labList}
                type="laboratory"
                handleCheck={(t) => handleProds(t, setSelectedProdLab)}
            />
        </>
        )
    }
    if(role !== "ROLE_COORDINATEUR")
        tabs = {
            ...tabs,
            reseaux: (
            <>
                {loadingProdNet && <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader" />}
                <MultiCheckbox
                    data={resList}
                    type="network"
                    handleCheck={(t) => handleProds(t, setSelectedProdRes)}
                />
            </>
            )
        }

    return(
        <section className="content">
        
            <section className="content-header">
                <h1>{t('SUIVI.TITLE')}</h1>
            </section>
        
            <section className="content activite">
                <div className="row">

                    <div className='col-md-3'>
                        <div className='box info ciblage'>
                            <p>
                                {t('SUIVI.PERIOD')}
                                { !suiviReady &&
                                    <small> &nbsp; ({t('COMMON.WAIT')}...)</small>
                                }
                            </p>

                            <div className="inner-addon right-addon">
                                <i className="glyphicon glyphicon-calendar"></i>
                                <input
                                    className="form-control"
                                    onClick={() => {
                                        setCalendarIsHidden(!calendarIsHidden);
                                        setEnteringDate(false);
                                    }}
                                    value={format(startDate, 'dd/MM/yyyy') + ' - ' + format(endDate, 'dd/MM/yyyy')}
                                    type="text"
                                    onChange={() => {}}
                                    disabled={!suiviReady}
                                />
                            </div>


                            { !calendarIsHidden &&
                            <DateRange
                                locale={localStorage.getItem('currentLanguage') === "en" ? enUS : fr}
                                editableDateInputs={true}
                                onChange={item => {
                                    if(enteringDate)
                                        getMissionList(item.selection.startDate, item.selection.endDate);

                                    setStartDate(item.selection.startDate);
                                    setEndDate(item.selection.endDate);
                                    setKey(item.selection.key);
                                    setCalendarIsHidden(item.selection.startDate !== item.selection.endDate);
                                    setEnteringDate(!enteringDate);
                                }}
                                showDateDisplay={false}
                                moveRangeOnFirstSelection={false}
                                ranges={[{
                                    startDate: startDate,
                                    endDate: endDate,
                                    key: key
                                }]}
                                dragSelectionEnabled={false}
                            />
                            }
                        </div>
                    </div>

                    <div className='col-md-3'>
                        <div className='box info ciblage prods'>
                            <TabElements
                                handleFocus={(k) => setLabnet(k)}
                                options={tabLabel}
                                values={tabs}
                            />
                        </div>
                    </div>

                    <div className='col-md-3'>
                        <div className='box info'>
                            <p>{t("SETTINGS.COUNTRY")}</p>

                            <Select
                                options={JSON.parse(localStorage.getItem("userCountries")).map(c => ({
                                    value: c.id,
                                    label: c.name
                                }))}
                                value={selectedCountry}
                                onChange={newValue => setSelectedCountry(newValue)}
                                placeholder={t("SETTINGS.SELECT_COUNTRY")}
                                isClearable
                                className="react-select-container"
                            />
                        </div>
                    </div>

                </div>

                {/* { role !== "ROLE_ADMIN" &&
                    <div className='resumeCountryContainer'>

                        <table className='resumeCountry'>
                            <thead>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td colSpan="2" className="border-left border-right">A</td>
                                    <td colSpan="2" className="border-left border-right">B</td>
                                    <td colSpan="2" className="border-left border-right">C</td>
                                    <td colSpan="2" className="border-left">D</td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>{t('SETTINGS.COUNTRY')}</p>
                                    </td>
                                    <td>
                                        <p>{t('SUIVI.FREQ')}</p>
                                    </td>
                                    <td>
                                        <p>{t('SUIVI.TAUX_COUV_PDS')}</p>
                                    </td>
                                    <td className="border-left">
                                        <p>{t('SUIVI.TAUX_COUV')}</p>
                                    </td>
                                    <td className="border-right">
                                        <p>{t('SUIVI.FREQ')}</p>
                                    </td>
                                    <td className="border-left">
                                        <p>{t('SUIVI.TAUX_COUV')}</p>
                                    </td>
                                    <td className="border-right">
                                        <p>{t('SUIVI.FREQ')}</p>
                                    </td>
                                    <td className="border-left">
                                        <p>{t('SUIVI.TAUX_COUV')}</p>
                                    </td>
                                    <td className="border-right">
                                        <p>{t('SUIVI.FREQ')}</p>
                                    </td>
                                    <td className="border-left">
                                        <p>{t('SUIVI.TAUX_COUV')}</p>
                                    </td>
                                    <td>
                                        <p>{t('SUIVI.FREQ')}</p>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>BENIN</td>
                                    <td className='valid'>5.2/7.4</td>
                                    <td className=''>55%</td>
                                    <td className="border-left valid">87%</td>
                                    <td className="border-right valid">6.0/6.9</td>
                                    <td className="border-left alert">23%</td>
                                    <td className="border-right">0.9/4.1</td>
                                    <td className="border-left">67%</td>
                                    <td className="border-right">2.8/4.1</td>
                                    <td className="border-left alert">38%</td>
                                    <td className='alert'>1.5/4.1</td>
                                </tr>
                                <tr>
                                    <td>GABON</td>
                                    <td className='alert'>0.2/7.4</td>
                                    <td className='alert'>55%</td>
                                    <td className="border-left alert">23%</td>
                                    <td className="border-right alert">0.9/4.1</td>
                                    <td className="border-left valid">87%</td>
                                    <td className="border-right valid">6.0/6.9</td>
                                    <td className="border-left">67%</td>
                                    <td className="border-right">2.8/4.1</td>
                                    <td className="border-left ">58%</td>
                                    <td className=''>2.5/4.1</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                } */}

                <div className="suiviContainer">

                    <table className='suivi-Activite'>
                        <thead>
                            <tr>
                                <td className='fixedTD'></td>
                                <td className='fixedTD col2'></td>
                                {/* {!originalRole && role === "ROLE_COORDINATEUR" &&
                                    <td className='fixedTD col3'></td>
                                } */}
                                <td className='fixedTD col4'></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td colSpan='2' className="border-left border-right">
                                    <div>
                                        <p>A</p>
                                    </div>
                                </td>
                                <td colSpan='2' className="border-left border-right">
                                    <div>
                                        <p>B</p>
                                    </div>
                                </td>
                                <td colSpan='2' className="border-left border-right">
                                    <div>
                                        <p>C</p>
                                    </div>
                                </td>
                                <td colSpan='2' className="border-left border-right">
                                    <div>
                                        <p>D</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>{t('SUIVI.HORS_CIBLE')}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='fixedTD'></td>
                                <td className='fixedTD col2'></td>
                                {/* {!originalRole && role === "ROLE_COORDINATEUR" &&
                                    <td className='fixedTD col3'></td>
                                } */}
                                <td className='fixedTD col4'>
                                    <div>
                                        <p>{t('SUIVI.NAME')}</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>{t('SETTINGS.COUNTRY')}</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>{t('SUIVI.NB_WORK_DAYS')}</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>{t('SUIVI.NB_CONTACTS')}</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>{t('SUIVI.MOY_CONTACTS')}</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>{t('SUIVI.NB_HCP')}</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>{t('SUIVI.FREQ')}</p>
                                    </div>
                                </td>
                                <td className="border-left">
                                    <div>
                                        <p>{t('SUIVI.NB_CONTACTS')}</p>
                                    </div>
                                </td>
                                <td className="border-right">
                                    <div>
                                        <p>{t('SUIVI.FREQ')}</p>
                                    </div>
                                </td>
                                <td className="border-left">
                                    <div>
                                        <p>{t('SUIVI.NB_CONTACTS')}</p>
                                    </div>
                                </td>
                                <td className="border-right">
                                    <div>
                                        <p>{t('SUIVI.FREQ')}</p>
                                    </div>
                                </td>
                                <td className="border-left">
                                    <div>
                                        <p>{t('SUIVI.NB_CONTACTS')}</p>
                                    </div>
                                </td>
                                <td className="border-right">
                                    <div>
                                        <p>{t('SUIVI.FREQ')}</p>
                                    </div>
                                </td>
                                <td className="border-left">
                                    <div>
                                        <p>{t('SUIVI.NB_CONTACTS')}</p>
                                    </div>
                                </td>
                                <td className="border-right">
                                    <div>
                                        <p>{t('SUIVI.FREQ')}</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>{t('SUIVI.NB_CONTACTS')}</p>
                                    </div>
                                </td>
                            </tr>
                            <tr className='lastTr'>
                                <td className='fixedTD'></td>
                                <td className='fixedTD col2'></td>
                                {/* {!originalRole && role === "ROLE_COORDINATEUR" &&
                                    <td className='fixedTD col3'></td>
                                } */}
                                <td className='fixedTD col4'>
                                    <input 
                                        type="text" 
                                        placeholder="..." 
                                        value={filters.delegue} 
                                        onChange={(e) => setFilters({ ...filters, delegue: e.target.value })}
                                        disabled={!suiviReady}
                                    />
                                </td>
                                <td></td>
                                <td>
                                    <span className='sort-icon' onClick={() => handleSort("NbJoursTravailles")}>⇅</span>
                                </td>
                                <td>
                                    <span className='sort-icon' onClick={() => handleSort("Calls_total")}>⇅</span>
                                </td>
                                <td>
                                    <span className='sort-icon' onClick={() => handleSort("Moy.Contacts/Jour_Cibles")}>⇅</span>
                                </td>
                                <td>
                                    <span className='sort-icon' onClick={() => handleSort("NbHcpUnique_Cibles")}>⇅</span>
                                </td>
                                <td>
                                    <span className='sort-icon' onClick={() => handleSort("Frequences_Cibles")}>⇅</span>
                                </td>
                                <td className="border-left">
                                    <span className='sort-icon' onClick={() => handleSort("NbContacts_potentielA")}>⇅</span>
                                </td>
                                <td className="border-right">
                                    <span className='sort-icon' onClick={() => handleSort("Frequences_potentielA")}>⇅</span>
                                </td>
                                <td className="border-left">
                                    <span className='sort-icon' onClick={() => handleSort("NbContacts_potentielB")}>⇅</span>
                                </td>
                                <td className="border-right">
                                    <span className='sort-icon' onClick={() => handleSort("Frequences_potentielB")}>⇅</span>
                                </td>
                                <td className="border-left">
                                    <span className='sort-icon' onClick={() => handleSort("NbContacts_potentielC")}>⇅</span>
                                </td>
                                <td className="border-right">
                                    <span className='sort-icon' onClick={() => handleSort("Frequences_potentielC")}>⇅</span>
                                </td>
                                <td className="border-left">
                                    <span className='sort-icon' onClick={() => handleSort("NbContacts_potentielD")}>⇅</span>
                                </td>
                                <td className="border-right">
                                    <span className='sort-icon' onClick={() => handleSort("Frequences_potentielD")}>⇅</span>
                                </td>
                                <td>
                                    <span className='sort-icon' onClick={() => handleSort("NbContacts_HorsCibles")}>⇅</span>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            { displayList.length > 0 ?
                                displayList.map(item =>
                                    <ListRowSuiviActivite
                                        key={item.missionId}
                                        item={item}
                                    />
                                )
                            :
                                <tr><td>{t('SUIVI.NO_FOUND')}</td></tr>
                            }
                        </tbody>
                    </table>

                </div>
            </section>

            {pagination.maxPage > 1 && suiviReady &&
                <Pagination
                    maxPage={pagination.maxPage}
                    page={pagination.page}
                    nbCount={pagination.nbResultats}
                    onChangePage={data => filterList(data)}
                ></Pagination>
            }
        </section>
    )
}