import './index.scss';
import { TdNumber } from './Td/TdNumber';
import { TdString } from './Td/TdString';
import { TdObject } from './Td/TdObject';
import { TdBool } from './Td/TdBool';

export const FetchRowManager = (props) => {
    const {el, line, type} = props;
    let defaultModel = <td>--</td>;
    
    const genFetchRow = () => {
        if(typeof line[el.name] !== 'undefined')
            switch (typeof line[el.name]) {
                case 'boolean':
                    return <TdBool line={line} el={el} type={type} />;
                    
                case 'number':
                    return <TdNumber line={line} el={el} type={type} />;
                
                case 'string':
                    return <TdString line={line} el={el} type={type}/>;
    
                case 'object':
                    return <TdObject line={line} el={el} type={type}/>;
                
                case 'undefined':
                case null:
                    return defaultModel;
    
                default:
                    break;
            }

        else if(el.name === 'rapport' && type === 'medicalProHisto')
            return <></>;

        else
            return defaultModel;
    }
        
    return(
		<>
			{genFetchRow()}
		</>
    )
}