import { FetchRowManager } from './Fetch/FetchRowManager';
import { ActionsRowManager } from './Actions/ActionsRowManager';

import './index.scss';

export const TableRowManager = (props) => {
	const { dataHeader, line, actionsList, type, isAdd } = props;

    const generateRow = (line) => {
        return dataHeader.map((el, i) => {
            return <FetchRowManager el={el} key={i} line={line} type={type}/>
        });
    }

    return(
		<>
			{line && generateRow(line)}
            <ActionsRowManager actionsList={actionsList} line={line} type={type} isAdd={isAdd} />
		</>
    )
}