import { useState, useEffect, useContext } from 'react';
import { ActionsStoreContext } from '../../../../store/store/storeActions';

export const RestrictInput = (props) => {
    const { data2, data1, role, data, label1, label2, model } = props;
    const { dispatchActions } = useContext(ActionsStoreContext);

    let [dataRestrictedList, setDataRestrictedList] = useState(data2);
    let [dataList, setDataList] = useState(data1);
    let allData = data1;

    let [dataState1, setDataState1] = useState({});
    let [dataState2, setDataState2] = useState({});
    let [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        cleanList();
    }, [data1]);

    useEffect(() => {
        if(mounted)
            setDataRestrictedList([]);
    }, [model]);

    useEffect(() => {
        if(mounted && model === "ugvs") {
            setDataRestrictedList([]);
        }
    }, [role]);

    const cleanList = () => {
        let cleanList = [];
        data1.forEach((el) => {
            dataRestrictedList.forEach((elem) => {
                if(el.name === elem.name)
                    cleanList.push(el)
            });
        });
        let difference = data1.filter(x => !cleanList.includes(x));
        return setData(dataRestrictedList, difference, '', '');
    }

    const setData = (restrict, cleanList, param1, param2) => {
        let aDataActions = {...data, [model]: restrict};//care
        setDataRestrictedList(restrict);
        setDataList(cleanList);
        setDataState1(param1);
        setDataState2(param2);
        return dispatchActions({type: 'setDataActions', aDataActions})
    }
    
    const handleDataRestriction = () => {
        let copyList = dataList.slice();
        let copyRestrictList = dataRestrictedList.slice();

        if(dataState1 !== "") {
            let selectCL = copyList.map((c) => c.name).indexOf(dataState1);

            copyRestrictList.push({
                id: copyList[selectCL].id,
                name: copyList[selectCL].name
            });
            copyList.splice(selectCL, 1);
            return setData(copyRestrictList, copyList, '', '');
        }
    }

    const handleDataPresence = () => {
        let copyList = dataList.slice();
        let copyRestrictList = dataRestrictedList.slice();
        let res;

        if(dataState2 !== "") {
            let selectCR = copyRestrictList.map((c) => c.name).indexOf(dataState2);

            allData.map((el, i) => {
                if(el.name === dataState2)
                    return res = el;
                
            });
            copyList.push(res);
            copyRestrictList.splice(selectCR, 1);
            return setData(copyRestrictList, copyList, '', '');
        }
    }

    return(
        <>
            <div className="row">

                <div className="col-md-5">
                    <label>{label1}</label>
                    <select className="listSelect" size="5" value={dataState1} 
                        onChange={(e) => setDataState1(e.target.value)}
                    >
                        <option value=""></option>
                        {dataList.sort((a, b) => a.name > b.name ? 1:-1).map((el, i) => (
                            <option key={i} value={el.name}>{el.name}</option>
                        ))}
                    </select>
                </div>
                
                <div className="col-md-2 actionArrow">
                    <label>Actions</label>
                    <button type="button" onClick={() => handleDataRestriction()}>&rarr;</button>
                    <button type="button" onClick={() => handleDataPresence()}>&larr;</button>
                </div>

                <div className="col-md-5">
                    <label>{label2}</label>
                    <select className="listSelect" size="5" value={dataState2} 
                        onChange={(e) => setDataState2(e.target.value)}
                    >
                        <option value=""></option>
                        {dataRestrictedList.map((el, i) => (
                            <option key={i} value={el.name}>{el.name}</option>
                        ))}
                    </select>
                </div>

            </div>   
        </>
    )
}
