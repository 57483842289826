export const authHeader = () => {
  return new Promise((resolve, reject) => {
    
    const user = localStorage.getItem("loggedInAs") ?? localStorage.getItem("user");
    
    if(user) 
      resolve(JSON.parse(user));
    else 
      reject();
  });
};