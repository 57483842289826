import { t } from "../../i18nFunctions";
import iconEdit from "../../../assets/img/icons/icon-edit.png";
import iconProd from "../../../assets/img/icons/icon-produit.png";
import iconDelete from "../../../assets/img/icons/icon-delete.png";

export const ListRowLabo = ({ item, showProducts, handleNameIdEdit, handleIdDelete }) => {

    return(
        <tr className="list-row">
            <td className="uppercase">{item.name}</td>
            <td>
                <div className="icon-btn" onClick={() => showProducts(item.id, item.name)}>
                    <img src={iconProd} alt="" className="inline-block"/>
                    <p>{t('SETTINGS.PRODUCTS')}</p>
                </div>
                <div className="icon-btn" data-toggle="modal" data-target="#modalLabo" onClick={() => handleNameIdEdit(item.name, item.id)}>
                    <img src={iconEdit} alt="edit"/>
                </div>
                <div className="icon-btn" data-toggle="modal" data-target="#modalDelete" onClick={() => handleIdDelete(item.id, "laboratoire")}>
                    <img src={iconDelete} alt="delete"/>
                </div>
            </td>
        </tr>
    )
}