import "./ButtonEdit.scss";

// images
import iconWrite from "../../../../assets/img/icons/svg/ecrire.svg";
import iconWriteDark from "../../../../assets/img/icons/svg/ecrire-dark.svg";

export const ButtonEdit = ({ dark, code, label, handleAdd, disabled = false }) => {

    const colorButton = (code) => {
        let background = '#8c8dbc';
        let color = "#fff";

        switch(code) {    
            case "VIST":
                background = "#00a65a";
                break;
            case "FORM":
                background = '#550a18';
                break;
            case "ADM":
                background = '#3c8dbc';
                break;
            case "CGM":          
                background = "#af0048";
                break;
            case "MALD":
                background = "#ba1f00";
                break;
            case "VACS":
                background = "#d2d6de";
                color = "#444";
                break;
            case "CGR":          
                background = "#5ed9d2";
                break;
            case "TSP":
                background = "#555299";
                break;
            case "SEMR":
                background = "#de2a14";
                break;
            case "DUO":        
                background = "#edea21";
                color = "#444";
                break;
            case "FER":
                background = "#aba4a3";
                break;
            case "GRST":
                background = "#1b77e0";
                break;
            case "RP":
                background = "#0b511f";
                break;
            case "OFF":
                background = "#aba4a3";
                break;
            case "Tournée":
                background = "#c53a49";
                break;
            default:
                background = "#8c8dbc";
                break;
        }

        return { background, color };
    }

    return(
        <button
            style={{ backgroundColor: colorButton(code).background }}
            className={`btn-export ${dark ? "dark" : ""}`}
            disabled={disabled}
            onClick={handleAdd}
        >
            <img alt="" className="btn-export-img" src={dark ? iconWriteDark : iconWrite} />
            <span
                style={{ color : colorButton(code).color }}
                className="btn-export-text"
            >
                {label}
            </span>
        </button>
    )
}