import { useState, useEffect, useRef } from 'react';
import { t } from "../../../pages/i18nFunctions";
import Select from 'react-select';

// images
import iconSave from "../../../assets/img/icons/icon-save.png";

// components
import { CustomSelect } from '../../UI/CustomSelect/CustomSelect';

// api
import {
    getZonesByCountry
} from "../../../store/index.service";

// helpers
import {
    generateZGOptions,
    addressIntoOption
} from "../../../store/helpers";

export const PopupSecteur = (props) => {
    const { secteur, countryLock, handleSubmit, addSecteur, editSecteur, countryList, laboList } = props;

    const [name, setName] = useState("");
    const [idCountry, setIdCountry] = useState(-1);
    const [country, setCountry] = useState(t('SETTINGS.SELECT_COUNTRY'));
    const [propharmed, setPropharmed] = useState(false);
    const [zoneGeo, setZoneGeo] = useState([]);
    const [idLabo, setIdLabo] = useState(-1);
    const [labo, setLabo] = useState(t('SETTINGS.SELECT_LABORATORY'));
    const [zgList, setZgList] = useState([]);
    const [zgLoading, setZgLoading] = useState(false);

    const btnRef = useRef(null);
    const edit = Object.keys(secteur).length > 0;

    // updates
    useEffect(() => {
        setName(edit ? secteur.nameSector : "");
        setIdCountry(edit ? countryLock.id : -1);
        setCountry(edit ? countryLock.name : t('SETTINGS.SELECT_COUNTRY'));
        setPropharmed(edit ? secteur.lab === undefined : false);
        setZoneGeo(edit ? addressIntoOption(secteur.zonesGeo) : []);
        setIdLabo(edit && secteur.lab ? secteur.lab.idLabSector : -1);
        setLabo(edit && secteur.lab ? secteur.lab.nameLabSector : t('SETTINGS.SELECT_LABORATORY'));
    }, [secteur]);

    useEffect(() => {
        if(countryLock.name)
            handleGetZonesByCountry(countryLock.name);
    }, [countryLock]);

    const handleGetZonesByCountry = (nameC) => {
        setZgLoading(true);

        getZonesByCountry(nameC).then(res => {
            const data = JSON.parse(res);

            if(data) setZgList(data);
            else setZgList([]);

            setZgLoading(false);
        });
    }

    // functions

    const submitForm = () => {

        if(name !== "" && idCountry !== -1 && (propharmed || idLabo !== -1)) {

            if(edit)
                editSecteur(
                    secteur.idSector,
                    name,
                    idCountry,
                    propharmed ? null : idLabo,
                    zoneGeo.map((z) => z.value)
                );
            else
                addSecteur(
                    name,
                    idCountry,
                    propharmed ? null : idLabo,
                    zoneGeo.map((z) => z.value)
                );
            
            btnRef.current.click();
        }
    }

    return(
        <div className="modal fade in" id="modalAddEditSecteur" tabIndex="-1" role="dialog" aria-labelledby="modalAddEditSecteurLabel" animate="animate">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title" id="myModalLabel">{edit ? t('SETTINGS.EDIT_SECTOR') : t('SETTINGS.ADD_SECTOR')}</h1>
                    </div>
                    <div className="modal-body">
                        <form id="formAjout">
                            <label htmlFor="name">{t('SETTINGS.ENTITLED')}</label>
                            <input type="text" required value={name} onChange={(e) => setName(e.target.value)}/>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="check">
                                        <input
                                        type="checkbox"
                                        id="defaultSectorPopup"
                                        checked={propharmed}
                                        onChange={() => setPropharmed(!propharmed)}
                                        />
                                        <label htmlFor="defaultSectorPopup">{t('SETTINGS.DEFAULT_SECTOR')}</label>
                                    </div>
                                </div>
                                {!propharmed &&
                                    <div className="col-md-6">
                                        <label>{t('SETTINGS.SPECIFIC_SECTOR')}</label>
                                        <CustomSelect
                                        defaultText={labo}
                                        optionsList={laboList}
                                        handleOptionClick={(id, name) => {
                                            setIdLabo(id);
                                            setLabo(name);
                                        }}
                                        />
                                    </div>
                                }
                            </div>

                            <label>{t('SETTINGS.COUNTRY')}</label>
                            <CustomSelect
                            defaultText={country}
                            optionsList={countryList}
                            handleOptionClick={(id, name) => {
                                if(idCountry !== id) {
                                    setIdCountry(id);
                                    setCountry(name);
                                    setZoneGeo([]);

                                    handleGetZonesByCountry(name);
                                }
                            }}
                            />

                            {idCountry !== -1 &&
                                <div className="zoneGeoSecteur">
                                    <label>{t('SETTINGS.GEOGRAPHICAL_AREA')}</label>
                                    <Select
                                        options={generateZGOptions(zgList, idCountry, country)}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        formatGroupLabel={data => <div><span>{data.label}</span></div>}
                                        value={zoneGeo}
                                        onChange={newValue => setZoneGeo(newValue)}
                                        placeholder={t('PROS.LIST.RESEARCH.GEOGRAPHICAL_AREA_PLACEHOLDER')}
                                        formatOptionLabel={(option, { context }) => {
                                            /* context can be either `menu` or `value`
                                                menu - dropdown
                                                value - value displayed
                                            */
                                            return context === 'menu' ? (
                                                <div className="select-react-option">
                                                    <p>{option.label}</p>
                                                    <small>{option.ad}</small>
                                                </div>
                                            ) : option.ad;
                                            }}
                                        isLoading={zgLoading}
                                    />
                                </div>
                            }
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button
                        type="submit"
                        form="formAjout"
                        className="btn-save"
                        disabled={!(name !== "" && idCountry !== -1 && (propharmed || idLabo !== -1))}
                        data-toggle={edit ? 'modal' : ''}
                        data-target={'#modalConfirmation'}
                        onClick={e => {
                            e.preventDefault();
                            if(edit) handleSubmit(submitForm);
                            else submitForm();
                        }}>
                            <img src={iconSave} alt=""/>
                            {t('COMMON.SAVE')}
                        </button>

                        <button
                        type="button"
                        className="btn-cancel"
                        data-dismiss="modal"
                        ref={btnRef}
                        onClick={() => {
                            setName(edit ? secteur.nameSector : "");
                            setIdCountry(edit ? countryLock.id : -1);
                            setCountry(edit ? countryLock.name : t('SETTINGS.SELECT_COUNTRY'));
                            setPropharmed(edit ? secteur.lab === null : false);
                            setZoneGeo(edit ? addressIntoOption(secteur.zonesGeo) : []);
                            setIdLabo(edit && secteur.lab ? secteur.lab.idLabSector : -1);
                            setLabo(edit && secteur.lab ? secteur.lab.nameLabSector : t('SETTINGS.SELECT_LABORATORY'));
                        }}
                        >{t('COMMON.CANCEL')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}