import { useState } from "react";
import { t } from "../i18nFunctions";
import { NotificationManager } from 'react-notifications';
import ReactLoading from 'react-loading';

import {
    editUser
} from '../../store/index.service';

export const Account = () => {

    const [pwd, setPwd] = useState("");
    const [confirmPwd, setConfirmPwd] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        const userId = JSON.parse(localStorage.getItem("userData")).id;

        if(isValid()) {
            setLoading(true);
            
            editUser({
                id: userId,
                passwordOnly: true,
                pwd: pwd
            }).then(() => {
                NotificationManager.success("", t('ACCOUNT.EDIT_PWD_SUCCESS'));
                setLoading(false);
            }).catch(err => {
                console.error(err);
                NotificationManager.error("", "Error");
                setLoading(false);
            })
        }
    }

    const isValid = () => {
        return pwd.length >= 5 && pwd === confirmPwd;
    }

    return(
        <section className="content">
            <section className="content-header">
                <h1>
                    {t('ACCOUNT.TITLE')}
                    <small>{t('ACCOUNT.EDIT_INFO')}</small>
                </h1>
                <ol className="breadcrumb">
                    <li><a><i className="fa fa-envelope"></i> {t('ACCOUNT.TITLE')}</a></li>
                    <li className="active" translate="">{t('ACCOUNT.MODIFY')}</li>
                </ol>
            </section>
            <section className="content">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box-body account">
                                <div className="form-group">
                                    <label htmlFor="password" translate="">{t('ACCOUNT.PASSWORD')}</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        placeholder={t('ACCOUNT.PASSWORD_PLACEHOLDER')}
                                        value={pwd}
                                        onChange={(e) => {setPwd(e.target.value)}}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="passwordConfirm" translate="">{t('ACCOUNT.CONFIRM_PWD')}</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="passwordConfirm"
                                        name="passwordConfirm"
                                        placeholder={t('ACCOUNT.CONFIRM_PLACEHOLDER')}
                                        value={confirmPwd}
                                        onChange={(e) => setConfirmPwd(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-body">
                        <div className="col-xs-12">
                            <button
                                type="submit"
                                className="btn btn-primary pull-right"
                                disabled={!isValid()}
                            >
                                {loading ?
                                    <ReactLoading type={"spinningBubbles"} color={"#fff"} height={20} width={20} className="spinner-loader questions" />
                                :
                                    <><i className="fa fa-save"></i> {t('COMMON.SAVE')}</>
                                }
                            </button>
                        </div>
                    </div>
                </form>
            </section>
        </section>
    )
}