import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';
// import * as CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

export const login = (username, password) => {

    return new Promise((resolve, reject) => {
        try {
            axios.create({
                    baseURL: config.baseUrl,
                    headers: { 
                        'Content-Type': 'application/json'
                    }
                }).post('/login_check', {
                    "username": username,
                    "password": password
                }).then(function (response) {
                    try {
                        Cookies.set('loggedIn', true, { expires: 0.416667 });
                        localStorage.setItem('user', JSON.stringify(response.data));
                        // let encryptUsername = CryptoJS.AES.encrypt(username, config.cryptedKey);
                        // let encryptPassword = CryptoJS.AES.encrypt(password, config.cryptedKey);
                        // localStorage.setItem('userInfo', [encryptUsername.toString(), encryptPassword.toString()]);
                        resolve(response.data);
                    } catch (error) {
                        reject(error);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const loginHistory = () => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: { 
                        'Authorization': 'Bearer ' + res.token, 
                        'Content-Type': 'application/json'
                    }
                }).post('/api/user/login', {})
                .then(function (response) {
                    try {
                        resolve(response.data);
                    } catch (error) {
                        reject(error);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getUserConnected = () => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: { 
                        'Authorization': 'Bearer ' + res.token, 
                        'Content-Type': 'application/json'
                    }
                }).get('/api/user', {}).then(function (response) {
                    try {
                        localStorage.setItem('role', response.data.UserConnected[0].roles[0]);
                        localStorage.setItem("userData", JSON.stringify(response.data.UserConnected[0]));
                        localStorage.setItem("userCountries", JSON.stringify(response.data.UserCountries));
                        resolve(response.data.UserConnected[0]);
                    } catch (error) {
                        reject(error);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const logout = () => {
    Cookies.remove('loggedIn');
    localStorage.clear();
}

export const forgotPassword = (email) => {
    
    return new Promise((resolve, reject) => {
        try {
            (async () => {
                await axios.create({
                    baseURL: config.baseUrl,
                    headers: { 
                        'Content-Type': 'application/json'
                    }
                }).post('/request-password', {
                    "email": email
                }).then(function (response) {
                    if(response.data.code === "900" || response.data.code === 900){
                        reject(response.data);
                    }else{
                        resolve(JSON.stringify(response.data)); 
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
            })();
        }
        catch (error) {
            reject(error);
        }
    });
}

export const loginAsUser = (username) => {
    
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: { 
                        'Authorization': 'Bearer ' + res.token, 
                        'Content-Type': 'application/json'
                    }
                }).get(`/api/user/become/${username}`, {})
                .then(function (response) {

                    if(response.data.code === "900" || response.data.code === 900)
                        reject(response.data);
                    else {
                        localStorage.setItem('loggedInAs', JSON.stringify(response.data));
                        localStorage.setItem("originalUserData", localStorage.getItem("userData"));
                        localStorage.setItem("originalRole", localStorage.getItem("role"));
                        resolve(JSON.stringify(response.data)); 
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}
