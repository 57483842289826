import { useState, useContext, useEffect } from 'react';
import { ActionsStoreContext } from '../../../../../store/store/storeActions';
import { ListStoreContext } from '../../../../../store/store/storeList';

import { config } from "../../../../../store/config";

import { t } from "../../../../../pages/i18nFunctions";

import { CustomSelect } from '../../../../UI/CustomSelect/CustomSelect';
import { RoleInput } from './RoleInput';

export const EditUser = (props) => {
    const { stateActions, dispatchActions } = useContext(ActionsStoreContext);
    const { stateList } = useContext(ListStoreContext);
    
    const { dataActions } = stateActions;
    const { dataCountries, dataLab} = stateList;

    const [item, setItem] = useState({
        ...dataActions,
        emailAsUsername: dataActions.username === dataActions.email,
        modifyPwd: false,
        pwd: '',
        pwdConfirm: '',
        restrictedArea: dataActions.restrictedArea ?? [],
        labos: dataActions.labos ?? []
    });
    const [role, setRole] = useState(item.roles[0]);
    const [roleValue, setRoleValue] = useState('');

    useEffect(() => {
        const handleDisplayRole = () => {
            return optionList.map((e) => {
                if(e.val[0] === role) {
                    return setRole(e.val), setRoleValue(e.name);
                }
            });
        }
    
        handleDisplayRole();
    }, [item.roles])
    

    const optionList = config.groupeList;

    const handleChange = (name, value) => { 
        if(name === 'roles')
            setRole(value)

        let aDataActions = {...item, ...dataActions, [name]: value};
        setItem(aDataActions)
        dispatchActions({type: 'setDataActions', aDataActions})
    }

    return(
        <>
            <form>
                <div>
                    <label>{t('SETTINGS.GROUP')}</label>
                    <CustomSelect
                        defaultText={roleValue}
                        optionsList={optionList}
                        handleOptionClick={(id, name, value) => handleChange('roles', value)}
                    />   
                </div>

                <div className='flex-row two-cols'>
                    <div>
                        <label htmlFor="lastName">{t('SETTINGS.NAME')}</label>
                        <input id="lastName" type="text" required value={item.lastName ?? ''} onChange={(e) => handleChange('lastName', e.target.value)}/>
                    </div>

                    <div>
                        <label htmlFor="firstName">{t('SETTINGS.FIRSTNAME')}</label>
                        <input id="firstName" type="text" required value={item.firstName ?? ''} onChange={(e) => handleChange('firstName', e.target.value)}/>
                    </div>
                </div>

                <div className="check">
                    <input id="emailAsUsername" type="checkbox" checked={item.emailAsUsername ?? false} onChange={(e) => handleChange('emailAsUsername', e.target.checked)}/>
                    <label htmlFor="emailAsUsername">{t('SETTINGS.USE_EMAIL_PWD')}</label>
                </div>
                {item.emailAsUsername === false &&
                    <div>
                        <label htmlFor="userName">{t('SETTINGS.IDENTIFIER')}</label>
                        <input id="userName" type="text" required value={item.username ?? ''} onChange={(e) => handleChange('username', e.target.value)}/>
                    </div>
                }

                <div>
                    <label htmlFor="email">{t('SETTINGS.EMAIL')}</label>
                    <input id="email" type="text" required value={item.email ?? ''} onChange={(e) => handleChange('email', e.target.value)}/>
                </div>

                <div className="check">
                    <input id="modifyPwd" type="checkbox" checked={item.modifyPwd ?? false} onChange={(e) => handleChange('modifyPwd', e.target.checked)}/>
                    <label htmlFor="modifyPwd">{t('SETTINGS.EDIT_PWD')}</label>
                </div>
                {item.modifyPwd === true &&
                    <div className='flex-row two-cols'>
                        <div>
                            <label htmlFor="pwd">{t('SETTINGS.MDP')}</label>
                            <input id="pwd" type="password" required value={item.pwd ?? ''} onChange={(e) => handleChange('pwd', e.target.value)}/>
                        </div>
                        <div>
                            <label htmlFor="pwdConf">{t('SETTINGS.CONFIRM_PWD')}</label>
                            <input id="pwdConf" type="password" required value={item.pwdConfirm ?? ''} onChange={(e) => handleChange('pwdConfirm', e.target.value)}/>
                        </div>
                    </div>
                }
                <RoleInput
                    role={role}
                    item={item}
                    countries={dataCountries}
                    labos={dataLab}
                    handleChange={() => handleChange()}
                />
            </form>
        </>
    )
}
