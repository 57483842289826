import { useState, useRef } from "react";

export const OrgaItem = (props) => {

    const {
        item,
        editOrga,
        onInputChange,
        resetInput,
        initialInput,
        setSelectedPoste,
        fullName,
        poste1,
        moveOrga,
        moveDisabled,
        setIdDelete,
        setPoste1,
        children,
        noPlus,
        postOrga = () => {}
    } = props;

    const [open, setOpen] = useState(true);

    const ref = useRef(null);

    return (
        <ul>
            <li
                ref={ref}
                onClick={(e) => {
                    if(children.length > 0 && e.target === ref.current)
                        setOpen(!open);
                }}
                toggled={`${open}`}
                className={children.length > 0 ? "" : "noQuart"}
            >
                {/* TITLE */}
                <input
                    value={item.name}
                    className="invisible-input"
                    onKeyDown={e => {
                        if(e.key === 'Enter' || e.keyCode === 13) {
                            editOrga(item.id, e.target.value, "modifié");
                            e.target.blur();
                        }
                    }}
                    onChange={e => onInputChange(e.target.value)}
                    onFocus={() => resetInput(item.name)}
                    onBlur={() => onInputChange(initialInput)}
                />
                
                {/* POSTE */}
                &nbsp;- <span
                    className="small poste"
                    data-toggle={"modal"}
                    data-target={"#modalAddPoste"}
                    onClick={() => setSelectedPoste(item)}
                >

                    {item.delegue.idDelegue === null ?
                    <span><i>Vacant</i></span>
                    :
                    <span>Occupé par <b>{fullName}</b></span>}
                </span>

                <div className="last">

                    {/* ADD */}
                    {!(noPlus === true) &&
                        <div 
                            className="bouton plus"
                            onClick={() => postOrga(item.id, item.name, "ajouté")}
                        >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                        </div>
                    }

                    {/* MOVE */}
                    <button
                        type="button"
                        className="bouton moveOrga"
                        onClick={(e) => {
                            if(poste1 === null)
                                setPoste1(item.id);
                            else if(poste1 === item.id)
                                setPoste1(null);
                            else {
                                moveOrga(poste1, item.id, "déplacé");
                                e.target.blur();
                            }
                        }}
                        disabled={moveDisabled}
                    >
                        <i
                            className={`fa ${poste1 === null || moveDisabled || poste1 === item.id ? "fa-exchange" : "fa-check"}`}
                            aria-hidden="true"
                            style={poste1 === item.id ? { color: "#424343" } : {}}
                        ></i>
                    </button>
                    
                    {/* DELETE */}
                    <div 
                        className="bouton deleteOrga"
                        disabled={children.length > 0}
                        data-toggle={children.length > 0 ? "" : "modal"}
                        data-target={children.length > 0 ? "" : "#modalDelete"}
                        onClick={() => {
                            if(children.length <= 0)
                                setIdDelete(item.id)
                        }}
                    >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                    </div>

                </div>
            </li>

            {/* CHILDREN */}
            {children.length > 0 &&
                <li className={`list-children`} active={`${open}`}>
                    {children}
                </li>
            }
        </ul>
    )
};