import { useContext } from "react";
import { PopUpStoreContext } from '../../../../../../store/store/storePopUp';
import { ActionsStoreContext } from '../../../../../../store/store/storeActions';
import { t } from "../../../../../../pages/i18nFunctions";
import { config } from "../../../../../../store/config";

export const TdObject = (props) => {

    const { line, el, type } = props;
    const { statePopUp, dispatchPopUp } = useContext(PopUpStoreContext);
    const { dispatchActions } = useContext(ActionsStoreContext);
    const { dataPopUp } = statePopUp;

    const handleDisplayPopup = () => {
        let dDataPopUp = {
            type: type,
            action: 'visu',
            isActive: !dataPopUp.isActive
        }
        let aDataActions = line;

        dispatchPopUp({type: 'setPopUp', dDataPopUp });
        dispatchActions({type: 'setDataActions', aDataActions });
    };

    const genObject = () => {
        if(el.name === 'roles')
            return <td>{config.groupeList.find(r => r.val[0] === line[el.name][0]).name}</td>

        else if(el.name === 'typeInstitution')
            return <td>{line[el.name].nameType}</td> 

        else if(el.name === 'medicalProMainSpeciality')
            return <td>{line[el.name].nameMainSpeciality}</td> 

        else if(el.name === 'medicalProType' && type === "medicalProPage")
            return <td className="clickableTab" onClick={() => handleDisplayPopup()}>{line[el.name].nameMedicalProType}</td>;

        else if(el.name === 'typeMedicalPro')
            return <td>{line[el.name].nameTypeMedicalPro}</td>;

        else if(el.name === 'medicalProSpeciality' && type === "medicalProPage")
            return (
                <td className="clickableTab" onClick={() => handleDisplayPopup()}>
                    {line[el.name].map((spe, i) => {
                        let endStr = (i+1) !== line[el.name].length ? ", " : "";
                        return spe.nameMedicalProSpeciality + endStr;
                    })}
                </td>
            );

        else if(el.name === 'specialityMedicalPro')
            return (
                <td>
                    {line[el.name].map((spe, i) => {
                        let endStr = (i+1) !== line[el.name].length ? ", " : "";
                        return spe.nameSpecialityMedicalPro + endStr;
                    })}
                </td>
            );

        else if(el.name === 'country')
            return <td>{line[el.name].nameCountry}</td>;

        else if(el.name === 'network')
            return <td>{line[el.name].nameNetwork}</td>;

        else if(el.name === 'delegue')
            return <td>{line[el.name].fullNameDelegue}</td>;

        else if(el.name === 'target' && type === "missionTab")
            return <td>{t('MISSION.POP_EDIT.ALL')}</td>;
        
        else if(line[el.name] === null)
            return <td>--</td>
    }

    return(
        <>
           {genObject()}
        </>
    )
}