import React, { useReducer, createContext } from 'react';

export const initialPopUpState = {
    dataPopUp: {
        type: '',
        action: '',
        isActive: false,
        optionalData: {}
    },
    dataPopUpSup: {
        type: '',
        action: '',
        isActive: false
    },
    notif: {}
}

export const PopUpStoreContext = createContext(initialPopUpState);

export function reducer(state, action) {
    switch (action.type) {
        case 'setPopUp':
            return {...state, dataPopUp: action.dDataPopUp};
        case 'setPopUpSup':
            return {...state, dataPopUpSup: action.dDataPopUpSup};
        case 'setNotif':
            return {...state, notif: action.nNotif};
        
        default:
            throw new Error();
    } 
}

export const PopUpStoreContainer = ({children}) => {
    const [statePopUp, dispatchPopUp] = useReducer(reducer, initialPopUpState);
    
    return (
        <PopUpStoreContext.Provider value={{statePopUp, dispatchPopUp}}>
            {children}
        </PopUpStoreContext.Provider>
    )
}
