import PDFViewer from 'pdf-viewer-reactjs';

export const Documentation = () => {

    return(
        <section className="content">
            <PDFViewer
                document={{
                    url: require("../../assets/documents/AMIVM-manuel-utilisateur-delegue.pdf"),
                }}
                scale= {1}
                navbarOnTop={true}
            />            
        </section>
    )
}