import { useState, useContext, useEffect } from 'react';

import { ActionsStoreContext } from '../../../../../store/store/storeActions';
import { ListStoreContext } from '../../../../../store/store/storeList';
import { PopUpStoreContext } from '../../../../../store/store/storePopUp';

import { t } from "../../../../../pages/i18nFunctions";

export const EditMedicalProInstits = (props) => {
    const { data, dispatchPop } = props;
    const { stateActions, dispatchActions } = useContext(ActionsStoreContext);
   
    const { dataActions } = stateActions;

    const [item, setItem] = useState({
        ...dataActions,
        idInstitution: dataActions.idInstitution,
    });

    let [withInternetConnState, setWithInternetConnState] = useState(dataActions.withInternetConn)
    let [isThoughtLeaderState, setIsThoughtLeaderState] = useState(dataActions.isHeadDept)
    let [hasSecretaryState, setHasSecretaryState] = useState(dataActions.hasSecretary)
    let [onAppointmentState, setOnAppointmentState] = useState(dataActions.onAppointment)
    
    
    let [isTargetState, setIsTargetState] = useState(dataActions.isTarget)
    let [isEthicaFriendState, setIsEthicaFriendState] = useState(dataActions.isEthicaFriend)
    
    const handleChange = (value, name, setRadio = null) => {
        let aDataActions = { ...dataActions, ...item, [name]: value};
        if(setRadio !== null) {
            setRadio(parseInt(value));
            parseInt(value)
        }

        setItem(aDataActions);
        dispatchActions({type: 'setDataActions', aDataActions})
    }

    const generateMorning = () => {
        if(item.schedule.length > 0 && item.schedule !== '') {
            return Array.from(item.schedule).map((el, i) => {
                if(i % 2 === 0) {
                    return <td key={i}>
                        <span onClick={() => {handleActiveMoment(el, i)}} className={`tag ${el === '0' ? 'warning' : 'valid'}`}>
                            {el === "0" ? t('PROS.LIST.EDIT_PRO.ABSENT') : t('PROS.LIST.EDIT_PRO.PRESENT')}
                        </span>
                    </td>
                }
            })
        }
        else
            return false;
    }

    const generateAfternoon = () => {
        if(item.schedule.length > 0 && item.schedule !== '') {
            return Array.from(item.schedule).map((el, i) => {
                if(i % 2 !== 0) {
                    return <td key={i}>
                        <span onClick={() => {handleActiveMoment(el, i)}} className={`tag ${el === '0' ? 'warning' : 'valid'}`}>
                            {el === "0" ? t('PROS.LIST.EDIT_PRO.ABSENT') : t('PROS.LIST.EDIT_PRO.PRESENT')}
                        </span>
                    </td>
                }
            })
        }
        else
            return false;
    }

    const generateSchedule = () => {
        return <>
                <tr>
                    <td>{t('PROS.LIST.EDIT_PRO.MORNING')}</td>
                    {generateMorning()}
                </tr>
                <tr>
                    <td>{t('PROS.LIST.EDIT_PRO.AFTERNOON')}</td>
                    {generateAfternoon()}
                </tr>
            </>  
    }

    const handleActiveMoment = (element, index) => {
        let itemCopy = item;
        let copySchedule = Array.from(item.schedule);
        if(element === "0") {
            copySchedule[index] = "1";
        } else if(element === "1") {
            copySchedule[index] = "0";
        }
        let backString = copySchedule.toString().replaceAll(',', '');
        itemCopy.schedule = backString;
        
        let aDataActions = { ...dataActions, ...itemCopy};
        setItem(aDataActions)
        dispatchActions({type: 'setDataActions', aDataActions });

    }
     
    return(
        <form>
            <h2><b>{t('PROS.LIST.EDIT_PRO.AT_THIS_PLACE')}</b></h2>

            <div>
                <label htmlFor="nbPatientsDay">{t('PROS.LIST.EDIT_PRO.NB_OF_PATIENTS_PER_WEEK')}</label>
                <input id="nbPatientsDay" type="text" required readOnly={data.action === 'visu' ? true : false}
                    value={item.nbPatientsDay ?? ''} 
                    onChange={ (e) => {handleChange(e.target.value, 'nbPatientsDay')} }
                />
            </div>

            <div className="containerInline">
                <div className="containerLabelRadioInline">
                    <label>{t('PROS.LIST.EDIT_PRO.INTERNET')}</label>
                    <label>{t('PROS.LIST.EDIT_PRO.MANAGER')}</label>
                    <label>{t('PROS.LIST.EDIT_PRO.SECRETARY')}</label>
                    <label>{t('PROS.LIST.EDIT_PRO.ONLY_BY_APPOINTMENT')}</label>
                </div>
                <div>
                    <div>
                        <div className="containerInline">
                            <div className="containerInline containerRadioInput">
                                <input type="radio" name="withInternetConn" id="withInternetConn" disabled={data.action === 'visu' ? true : false}
                                    onChange={(e) => {handleChange(e.target.value, 'withInternetConn', setWithInternetConnState)} }
                                    checked={withInternetConnState === 0}
                                    value={0}
                                />
                                <label htmlFor="withInternetConn">{t('COMMON.UNKNOWN')}</label>
                            </div>
                            <div className="containerInline containerRadioInput">
                                <input type="radio" name="withInternetConn" id="withInternetConn1" disabled={data.action === 'visu' ? true : false}
                                    onChange={(e) => {handleChange(e.target.value, 'withInternetConn', setWithInternetConnState)} }
                                    checked={withInternetConnState === 1}
                                    value={1}
                                />
                                <label htmlFor="withInternetConn1">{t('COMMON.YES')}</label>
                            </div>
                            <div className="containerInline containerRadioInput">
                                <input type="radio" name="withInternetConn" id="withInternetConn2" disabled={data.action === 'visu' ? true : false}
                                    onChange={(e) => {handleChange(e.target.value, 'withInternetConn', setWithInternetConnState)} }
                                    checked={withInternetConnState === 2}
                                    value={2}
                                />
                                <label htmlFor="withInternetConn2">{t('COMMON.NO')}</label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="containerInline">
                            <div className="containerInline containerRadioInput">
                                <input type="radio" name="isThoughtLeader" id="isThoughtLeader" disabled={data.action === 'visu' ? true : false}
                                    onChange={(e) => {handleChange(e.target.value, 'isHeadDept', setIsThoughtLeaderState)} }
                                    checked={isThoughtLeaderState === 0}
                                    value={0}
                                />
                                <label htmlFor="isThoughtLeader">{t('COMMON.UNKNOWN')}</label>
                            </div>
                            <div className="containerInline containerRadioInput">
                                <input type="radio" name="isThoughtLeader" id="isThoughtLeader1" disabled={data.action === 'visu' ? true : false}
                                    onChange={(e) => {handleChange(e.target.value, 'isHeadDept', setIsThoughtLeaderState)} }
                                    checked={isThoughtLeaderState === 1}
                                    value={1}
                                />
                                <label htmlFor="isThoughtLeader1">{t('COMMON.YES')}</label>
                            </div>
                            <div className="containerInline containerRadioInput">
                                <input type="radio" name="isThoughtLeader" id="isThoughtLeader2" disabled={data.action === 'visu' ? true : false}
                                    onChange={(e) => {handleChange(e.target.value, 'isHeadDept', setIsThoughtLeaderState)} }
                                    checked={isThoughtLeaderState === 2}
                                    value={2}
                                />
                                <label htmlFor="isThoughtLeader2">{t('COMMON.NO')}</label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="containerInline">

                            <div className="containerInline containerRadioInput">
                                <input type="radio" name="hasSecretary" id="hasSecretary" disabled={data.action === 'visu' ? true : false}
                                    onChange={(e) => {handleChange(e.target.value, 'hasSecretary', setHasSecretaryState)} }
                                    checked={hasSecretaryState === 0}
                                    value={0}
                                />
                                <label htmlFor="hasSecretary">{t('COMMON.UNKNOWN')}</label>
                            </div>
                            <div className="containerInline containerRadioInput">
                                <input type="radio" name="hasSecretary" id="hasSecretary1" disabled={data.action === 'visu' ? true : false}
                                    onChange={(e) => {handleChange(e.target.value, 'hasSecretary', setHasSecretaryState)} }
                                    checked={hasSecretaryState === 1}
                                    value={1}
                                />
                                <label htmlFor="hasSecretary1">{t('COMMON.YES')}</label>
                            </div>
                            <div className="containerInline containerRadioInput">
                                <input type="radio" name="hasSecretary" id="hasSecretary2" disabled={data.action === 'visu' ? true : false}
                                    onChange={(e) => {handleChange(e.target.value, 'hasSecretary', setHasSecretaryState)} }
                                    checked={hasSecretaryState === 2}
                                    value={2}
                                />
                                <label htmlFor="hasSecretary2">{t('COMMON.NO')}</label>
                            </div>

                        </div>
                    </div>
                    <div>
                        <div className="containerInline">

                            <div className="containerInline containerRadioInput">
                                <input type="radio" name="onAppointment" id="onAppointment" disabled={data.action === 'visu' ? true : false}
                                    onChange={(e) => {handleChange(e.target.value, 'onAppointment', setOnAppointmentState)} }
                                    checked={onAppointmentState === 0}
                                    value={0}
                                />
                                <label htmlFor="onAppointment">{t('COMMON.UNKNOWN')}</label>
                            </div>
                            <div className="containerInline containerRadioInput">
                                <input type="radio" name="onAppointment" id="onAppointment1" disabled={data.action === 'visu' ? true : false}
                                    onChange={(e) => {handleChange(e.target.value, 'onAppointment', setOnAppointmentState)} }
                                    checked={onAppointmentState === 1}
                                    value={1}
                                />
                                <label htmlFor="onAppointment1">{t('COMMON.YES')}</label>
                            </div>
                            <div className="containerInline containerRadioInput">
                                <input type="radio" name="onAppointment" id="onAppointment2" disabled={data.action === 'visu' ? true : false}
                                    onChange={(e) => {handleChange(e.target.value, 'onAppointment', setOnAppointmentState)} }
                                    checked={onAppointmentState === 2}
                                    value={2}
                                />
                                <label htmlFor="onAppointment2">{t('COMMON.NO')}</label>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <table className="planning">
                <tbody>
                    <tr>
                        <td></td>
                        <td>{t('DAYS.MONDAY')}</td>
                        <td>{t('DAYS.TUESDAY')}</td>
                        <td>{t('DAYS.WEDNESDAY')}</td>
                        <td>{t('DAYS.THURSDAY')}</td>
                        <td>{t('DAYS.FRIDAY')}</td>
                        <td>{t('DAYS.SATURDAY')}</td>
                        <td>{t('DAYS.SUNDAY')}</td>
                    </tr>
                    {generateSchedule()}
                </tbody>
            </table>
        </form>
    )
}