import { useState, useRef } from 'react';
import { t } from "../../../pages/i18nFunctions";

// components
import { CustomSelect } from '../../UI/CustomSelect/CustomSelect';

// images
import iconSave from "../../../assets/img/icons/icon-save.png";

export const PopupJourFerie = ({ countryList, addJourFerie }) => {

    const [idCountry, setIdCountry] = useState(-1);
    const [nameCountry, setNameCountry] = useState(t('HOLIDAYS.SELECT_COUNTRY'));
    const [date, setDate] = useState("");
    const [desc, setDesc] = useState("");

    const btnRef = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();

        if(desc !== "" && idCountry !== -1 && date !== "") {
            addJourFerie(idCountry, new Date(date).toJSON(), desc);
            btnRef.current.click();
        }
    }

    return(
        <div className="modal fade in" id="modalAddJourFerie" tabIndex="-1" role="dialog" aria-labelledby="modalAddJourFerieLabel" animate="animate">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title" id="myModalLabel">{t('HOLIDAYS.ADD_HOLIDAY')}</h1>
                    </div>
                    <div className="modal-body">
                        <form id="formAjout">
                            <label>{t('HOLIDAYS.COUNTRY')}</label>
                            <CustomSelect
                            defaultText={nameCountry}
                            optionsList={countryList}
                            handleOptionClick={(id, name) => {
                                setIdCountry(id);
                                setNameCountry(name);
                            }}
                            />

                            <label>{t('HOLIDAYS.DATE')}</label>
                            <input
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            required
                            />

                            <label>{t('HOLIDAYS.DESCRIPTION')}</label>
                            <input
                            type="text"
                            required
                            value={desc}
                            onChange={(e) => setDesc(e.target.value)}
                            />
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button
                        type="submit"
                        form="formAjout"
                        className="btn-save"
                        disabled={desc === "" || idCountry === -1 || date === ""}
                        onClick={handleSubmit}
                        >
                            <img src={iconSave} alt=""/>
                            {t('COMMON.SAVE')}
                        </button>
                        <button
                        type="button"
                        className="btn-cancel"
                        data-dismiss="modal"
                        ref={btnRef}
                        onClick={() => {
                            setIdCountry(-1);
                            setNameCountry(t('HOLIDAYS.SELECT_COUNTRY'));
                            setDate("");
                            setDesc("");
                        }}
                        >
                            {t('COMMON.CANCEL')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}