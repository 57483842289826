import { useState, useEffect, useContext } from 'react';
import { t } from "../../../../../pages/i18nFunctions";
import { ListStoreContext } from '../../../../../store/store/storeList';

import Select from 'react-select';
import { ButtonAdd } from '../../../Button/ButtonAdd/ButtonAdd';
import { TableManager } from '../../../Table/TableManager';

export const MissionTab = (props) => {
    const { type, generateOptions, dataList, handleChange } = props;

    const { stateList, dispatchList } = useContext(ListStoreContext);
    const { dataOtherTabs } = stateList;

    const placeholderSelected = type === "spe" ? t('MISSION.POP_EDIT.SPE_PLACEHOLDER') : t('MISSION.POP_EDIT.TYPE_PLACEHOLDER');

    const prospectingList = [
        {
            value: "T",
            label: t('MISSION.POP_EDIT.ALL')
        },
        {
            value: "A",
            label: "A"
        },
        {
            value: "B",
            label: "B"
        },
    ];

    const key = type === "spe" ? "idSpeciality" : "idProType";

    const [selected, setSelected] = useState(null);
    const [prospecting, setProspecting] = useState(prospectingList[0]);

    const handleAdd = () => {
        let t = [...dataOtherTabs];

        t.push({
            [key]: selected.value,
            name: selected.label,
            target: prospecting.value === "T" ? null : prospecting.value
        });

        handleChange(type === "spe" ? "specialities" : "protypes", t);
        setSelected(null);
        
        return dispatchList({type: 'setDataOtherTabs', dDataOtherTabs: t }); 
    }

    const handleCleanList = () => {
        let clean = [];
        dataList.forEach((el) => {
            dataOtherTabs.forEach((elem) => {
                if(el.name === elem.name)
                    clean.push(el);
            });
        });
        let difference = dataList.filter(x => !clean.includes(x));
        return difference;
    }

    const dataHeaderMissionSpe = [
        {
			name: "name",
			displayName: t('MISSION.POP_EDIT.SPECIALITIES')
		},
		{
			name: 'target',
			displayName: t('MISSION.POP_EDIT.MINIMUM_TARGET'),
		},
    ];

    const dataHeaderMissionPDS = [
        {
			name: "name",
			displayName: t('MISSION.POP_EDIT.PDS_TYPE')
		},
		{
			name: 'target',
			displayName: t('MISSION.POP_EDIT.MINIMUM_TARGET'),
		},
    ];

    return (
    <>
        <div className="row">

            <div className="help-block">
                {type === "typePDS" &&
                <>
                    <strong>{t('MISSION.POP_EDIT.WARNING')} : </strong> 
                    {t('MISSION.POP_EDIT.FILTER_TIP')}
                </>}
            </div>

            <div className='flex-row'>
                <div className="col-md-6">
                    <div>
                        <label htmlFor={`mission-${type}`}>
                            {type === "spe" ? t('MISSION.POP_EDIT.SPECIALITIES')
                            : t('MISSION.POP_EDIT.PDS_TYPE')}
                        </label>
                        <Select 
                            id={`mission-${type}`}
                            className='selectSearchBar' 
                            classNamePrefix='selectSearch' 
                            placeholder={placeholderSelected}
                            isClearable
                            options={generateOptions(handleCleanList(), type)}
                            onChange={(value, type) => setSelected(value) }
                            value={selected}
                        />
                    </div>
                </div>

                <div className="col-md-4">
                    <div>
                        <label htmlFor={`prospecting-${type}`}>{t('MISSION.POP_EDIT.MINIMUM_TARGET')}</label>
                        <Select 
                            id={`prospecting-${type}`}
                            className='selectSearchBar' 
                            classNamePrefix='selectSearch' 
                            placeholder={t('MISSION.POP_EDIT.ALL')}
                            isClearable={false}
                            options={prospectingList}
                            onChange={(value, type) => setProspecting(value) }
                            value={prospecting}
                        />
                    </div>
                </div>

                <div className="col-md-2">
                    <ButtonAdd
                        disabled={selected === null}
                        handleAdd={handleAdd}
                    />
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-12">
                <TableManager
                    dataHeader={type === "spe" ? dataHeaderMissionSpe : dataHeaderMissionPDS} 
					actionsList={["delete"]}
					type="missionTab"
					isAdd={false}
                />
            </div>
        </div>
    </>
    )
}