import "./Checkbox.scss";

export const Checkbox = ({ value, label, onChange, isSwitch = false }) => {

    return(
        <span className={`checkbox-${isSwitch ? "switcher" : "crosser"}`} onClick={value => onChange(value)}>
            <span className={`checkbox-${isSwitch ? "switch" : "cross"} ${value ? "checked" : ""}`}>
            </span>
            <label className={`checkbox-${isSwitch ? "switch" : "cross"}-label`}>{label}</label>
        </span>
    )
}