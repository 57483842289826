import { useContext } from 'react';
import moment from 'moment';
import { PopUpStoreContext } from '../../../store/store/storePopUp';
import { ActionsStoreContext } from '../../../store/store/storeActions';

import {
    getOnePDS
} from "../../../store/index.service";

export const RowRapport2 = (props) => {

    const { pro, onlyProds } = props;

    const { statePopUp, dispatchPopUp } = useContext(PopUpStoreContext);
    const { dispatchActions } = useContext(ActionsStoreContext);
    const { dataPopUp } = statePopUp;

    const showPDS = () => {
        getOnePDS(pro.id).then(res => {
            const data = JSON.parse(res);
    
            let dDataPopUp = {
                ...dataPopUp,
                action: 'visu',
                isActive: !dataPopUp.isActive
            }
            let aDataActions = data ? {
                ...data,
                name: `${data.reference} - ${data.title} ${data.firstName} ${data.lastName}`
            } : {};
    
            dispatchPopUp({ type: 'setPopUp', dDataPopUp });
            dispatchActions({ type: 'setDataActions', aDataActions });
        });
    }

    const affichePotentiel = (prod) => {
        const tab = pro.medicalProQualifiedProducts;
        const index = tab.map(p => p.idProduct).indexOf(prod.id);
        if(index !== -1)
            return tab[index].nameQualificationProdcut;
    }

    return (
        <tr className="list-row">
            <td className="visit fixedTD">
                {pro.lastVisitDate !== "" && moment(pro.lastVisitDate).locale('fr').format('L')}
            </td>
            <td className="visit fixedTD col2">
                {pro.nextVisitDate !== "" && moment(pro.nextVisitDate).locale('fr').format('L')}
            </td>
            <td
                className="ref fixedTD col3"
                onClick={showPDS}
            >
                {pro.reference}
            </td>
            <td
                className="lname fixedTD col4"
                onClick={showPDS}
            >
                {pro.lastName}
            </td>
            <td
                className="fname fixedTD col5"
                onClick={showPDS}
            >
                {pro.firstName}
            </td>
            <td className='fixedTD col6'>
                {pro.medicalProType.nameMedicalProType}
            </td>
            <td>
                {pro.medicalProSpeciality.map((spe, i) => {
                    let endStr = (i+1) !== pro.medicalProSpeciality.length ? ", " : "";
                    return spe.nameMedicalProSpeciality + endStr;
                })}
            </td>
            <td className="ville">
                {pro.city && pro.city.ville.name}
            </td>
            <td className="etab">
                {pro.medicalProsInstitutions.map((etab, i) => {
                    let endStr = (i+1) !== pro.medicalProsInstitutions.length ? ", " : "";
                    return etab.nameInstitution + endStr;
                })}
            </td>
            {onlyProds.map((prod, i) => {
                return(
                    <td className="prod" key={`prodQualif-${i}`}>
                        {affichePotentiel(prod)}
                    </td>
                )
            })}
        </tr>
    )
}