import { useState, useEffect, useContext } from 'react';
import "./HeaderBar.scss";
import { NotificationManager } from 'react-notifications';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import ReactCountryFlag from "react-country-flag";
import { t, switchlanguage } from "../../pages/i18nFunctions";
import { StoreContext } from '../../store/store';
import { config } from "../../store/config";

import logoutIcon from "../../assets/img/icons/logout.svg";

import {
    logout,
    getAllCountries,
    getUserConnected
} from '../../store/index.service';

export const HeaderBar = ({ userLogOut }) => {

    const [showUserMenu, setShowUserMenu] = useState(false);
    const [logStatus, setLogStatus] = useState(true);

    const loggedInAsUser = localStorage.getItem("loggedInAs");
    const role = loggedInAsUser ? localStorage.getItem("originalRole") : localStorage.getItem("role");
    const userData = JSON.parse(localStorage.getItem("userData"));
    const originalUserData = loggedInAsUser ? JSON.parse(localStorage.getItem("originalUserData")) : userData;
    const groupeList = config.groupeList;

    const { setCountryList, lang, setLang, setLoadingStore } = useContext(StoreContext);

    // mounted
    useEffect(() => {
        let intervalId = setInterval(() => {
            let log = Cookies.get('loggedIn');
            if(log !== "true") setLogStatus(false);
        }, 1000);

        getAllCountries().then(res => {
            const data = JSON.parse(res);
            if(data) setCountryList(data);
        });

        if(JSON.parse(localStorage.getItem('userData')).status !== 0) {
            handleLogOut();
        }

        // unmounted
        return(() => {
            clearInterval(intervalId);
        })
    }, []);

    // updates
    useEffect(() => {
        if(!logStatus) handleLogOut();
    }, [logStatus]);


    const pushMenu = () => {
        var body = document.body;

        if(body.clientWidth > 768){
            if(body.className.indexOf('sidebar-collapse') === -1){
                body.className += ' sidebar-collapse';
            } else {
                body.className = body.className.replace(' sidebar-collapse', '');
            }
        } else {
            if (body.className.indexOf('sidebar-open') === -1) {
                body.className += ' sidebar-open';
            } else {
                body.className = body.className.replace(' sidebar-open','');
            }
        }
    }

    const handleLogOut = () => {
        userLogOut();
        logout();
    }

    const handleLang = (l) => {
        localStorage.setItem('currentLanguage', l);
        switchlanguage(l)
        setLang(l);
    }

    const backToOriginalUser = () => {
        setLoadingStore(true);

        localStorage.removeItem('loggedInAs');
        localStorage.removeItem("originalUserData");
        localStorage.removeItem("originalRole");

        getUserConnected().then(res => {
            window.location.replace("/");
        })
        .catch(err => console.error(err))
        .finally(() => setLoadingStore(false))
    }

    return (
        <header className={`main-header ${loggedInAsUser ? "loggedInAsUser" : ""}`}>
            {/* Logo */}
            <Link to="/" className="logo">
                {/* mini logo for sidebar mini 50x50 pixels */}
                <span className="logo-mini"><b>AVM</b></span>
                {/* logo for regular state and mobile devices */}
                <span className="logo-lg"><b>AmiVM</b></span>
            </Link>

            {/* Header Navbar: style can be found in header.less */}
            <nav className="navbar navbar-static-top" role="navigation">
                {/* Sidebar toggle button*/}
                <a className="sidebar-toggle" data-toggle="offcanvas" role="button" onClick={pushMenu}>
                    <span className="sr-only">Basculer la navigation</span>
                </a>
                <div className="navbar-custom-menu">
                    <ul className="nav navbar-nav">

                        {loggedInAsUser && 
                        <li className='loginAs-menu'>
                            <span><b>Connecté en tant que :</b> {userData.firstName} {userData.lastName}</span>
                            <img
                                className='icon'
                                src={logoutIcon}
                                alt="déco."
                                onClick={() => backToOriginalUser()}
                                title="Déconnexion"
                            />
                        </li>
                        }

                        {/* User Account: style can be found in dropdown.less */}
                        <li className="user user-menu">

                            {lang === "fr" ?
                                <div
                                    className="headerLang"
                                    onClick={() => handleLang("en")}
                                >
                                    <ReactCountryFlag
                                        svg
                                        className="langFlag"
                                        countryCode="FR"
                                        aria-label={t('fr')}
                                        title={t('fr')}
                                        style={{ width: "1.7em", height: "1.7em" }}
                                    />
                                </div>
                                :
                                <div
                                    className="headerLang"
                                    onClick={() => handleLang("fr")}
                                >
                                    <ReactCountryFlag
                                        svg
                                        className="langFlag"
                                        countryCode="GB"
                                        aria-label={t('en')}
                                        title={t('en')}
                                        style={{ width: "1.7em", height: "1.7em" }}
                                    />
                                </div>
                            }
                        </li>

                        <li className="user user-menu">
                            <a className="user_a_dropdown" onClick={() => setShowUserMenu(!showUserMenu)}>
                                <i className="fa fa-user"></i>
                                <span className="hidden-xs">{originalUserData.firstName} {originalUserData.lastName}
                                <i className="caret"></i></span>
                            </a>
                            <ul className="dropdown-menu" style={showUserMenu ? { display:"block" } : { display:"none" }}>
                                <li className="user-header bg-light-blue">
                                    <p>
                                        {originalUserData.firstName} {originalUserData.lastName}
                                        <small>{role && t('ROLES.' + groupeList[groupeList.map((r) => r.val[0]).indexOf(role)].val)}</small>
                                    </p>
                                </li>
                                <li className="user-footer">
                                    <div className="pull-left">
                                        <Link
                                            to="/profile/edit"
                                            onClick={() => setShowUserMenu(!showUserMenu)}
                                            className="btn btn-default btn-flat"
                                            disabled={loggedInAsUser}
                                        >
                                            {t('THEME_PROFIL_BOX_BUTTON_PROFILE')}
                                        </Link>
                                    </div>
                                    <div className="pull-right" >
                                        <a onClick={handleLogOut} className="btn btn-default btn-flat">{t('THEME_PROFIL_BOX_BUTTON_LOG_OUT')}</a>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    )
}