import { useContext } from 'react';
import { FilterStoreContext } from '../../../../../store/store/storeFilters';
import { ListStoreContext } from '../../../../../store/store/storeList';

import aToZ from "../../../../../assets/img/icons/icon-a-z.png";
import zToA from "../../../../../assets/img/icons/icon-z-a.png";

export const Order = (props) => {
    const { head, type } = props;
	
    const { stateFilter, dispatchFilters } = useContext(FilterStoreContext);
	const { stateList, dispatchList } = useContext(ListStoreContext);

	const { dataList, dataMedicalPro } = stateList;
    const { orderByKey } = stateFilter;

    const handlePressFilterbyKey = (key) => {
        let sOrderByKey = { [key]: !orderByKey[key], key};
        dispatchFilters({type: 'setOrderByKey', sOrderByKey })
	
		if(type === 'medicalProPop')
			return handleOrderByKey(dataMedicalPro, {[key]: !orderByKey[key], key})
		else
			return handleOrderByKey(dataList, {[key]: !orderByKey[key], key})
		
    }

    const handleOrderByKey = (liste, params) => {
		
		if(liste.length > 0) {
			let dataCopy = liste.slice();

			if(params[params.key] === true) {
				if(params.key === "lastpwddatechange" || params.key === "lastactivity")
					dataCopy.sort((a, b) => checkParams(a[params.key], params.key) - checkParams(b[params.key], params.key));
				else if(params.key === "status" && type === "medicalProPage")
					dataCopy.sort(a => checkParams(a[params.key], params.key) ? 1 : -1) //check for boolean
				else if(params.key === "medicalProMainSpeciality"){
					dataCopy.sort((a, b) => {
						if(typeof a[params.key].nameMainSpeciality !== 'undefined' && typeof b[params.key].nameMainSpeciality !== 'undefined')
							return checkParams(a[params.key], params.key).localeCompare(checkParams(b[params.key], params.key));
						else 
							return -1;
					})
				}
					
				else
					dataCopy.sort((a, b) => checkParams(a[params.key], params.key).localeCompare(checkParams(b[params.key], params.key)));
				
			}
			else {
				if(params.key === "lastpwddatechange" || params.key === "lastactivity")
					dataCopy.sort((a, b) => checkParams(b[params.key], params.key) - checkParams(a[params.key], params.key));
				else if(params.key === "status" && type === "medicalProPage")
					dataCopy.sort(a => checkParams(a[params.key], params.key) ? -1 : 1) //check for boolean
				else if(params.key === "medicalProMainSpeciality") {
					dataCopy.sort((a, b) => {
						if(typeof a[params.key].nameMainSpeciality !== 'undefined' && typeof b[params.key].nameMainSpeciality !== 'undefined')
							return checkParams(b[params.key], params.key).localeCompare(checkParams(a[params.key], params.key));
						else 
							return 1;
					});
				}

				else
					dataCopy.sort((a, b) => checkParams(b[params.key], params.key).localeCompare(checkParams(a[params.key], params.key)));
				
			}

			if(type === 'medicalProPop') {
				let dDataMedicalPro = dataCopy;
				return dispatchList({type: 'setDataMedicalPro', dDataMedicalPro });
			}
			else {
				let dDataList = dataCopy;
				return dispatchList({type: 'setList', dDataList });
			}
		}
	}

    const checkParams = (el, key) => {
		
		if(typeof el !== 'undefined' && el.length !== 0) {
			if(key === "lastpwddatechange" || key === "lastactivity")
				return new Date(el);
			else if(key === "roles" )
				return el[0];
			else if(key === "specialityMedicalPro")
				return el[0].nameSpecialityMedicalPro;
			else if(key === "medicalProType")
				return el.nameMedicalProType;
			else if(key === "medicalProSpeciality")
				return el[0].nameMedicalProSpeciality;
			else if(key === "medicalProMainSpeciality")
				return el.nameMainSpeciality;
			else if(key === "status")
				return el;
			else if(key === "country" && type === "mission")
				return el.nameCountry;
			else if(key === "network")
				return el.nameNetwork;
			else if(key === "delegue")
				return el.fullNameDelegue;
			else 
				return el.trim();
		}
		else
			return "";
			// return false;
	}

    return(
        <>
            <img
                style={{ height: "20px" }}
                src={!orderByKey[head.name] ? aToZ : zToA}
                alt={!orderByKey[head.name] ? "a-z" : "z-a"}
                onClick={() => handlePressFilterbyKey(head.name)}
            />
        </>
    )
}