import { useState, useContext, useEffect, useRef } from 'react';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import fr from 'react-phone-input-2/lang/fr.json';

import { ActionsStoreContext } from '../../../../../store/store/storeActions';
import { ListStoreContext } from '../../../../../store/store/storeList';

import { t } from "../../../../../pages/i18nFunctions";

import { ZoneGeoInput } from '../../../Input/ZoneGeoInput/ZoneGeoInput';
import { TableManager } from '../../../Table/TableManager';

import { Pagination } from '../../../../Pagination/Pagination';

export const AddEtab = (props) => {
    const { stateActions, dispatchActions } = useContext(ActionsStoreContext);
    const { stateList, dispatchList } = useContext(ListStoreContext);

    let [initParam, setInitParam] = useState(false);

    const [item, setItem] = useState({
        zoneGeo: '',
        phone: '',
        name: '',
        mail: '',
        type: '',
        faxNumber: '',
    });

    let [similarInstit, setSimilarInstit] = useState(0);

    let [maxPage, setMaxPage] = useState(0);
    let [page, setPage] = useState(1);
	let [nbResultats, setNbResultats] = useState(0);

    const timer = useRef(null);

    // mounted
    useEffect(() => {
        timer.current = setTimeout(() => {}, 1000);
        // unmounted
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    useEffect(() => {
        if(item.name !== "" && item.name)
            return getVerif();
    }, [page]) 

    const getVerif = (value = null) => {
        let data = {
            page: page,
            maxPerPage: 5,
            name: item.name,
            id: item.id,
            idCountry: item.country
        };
        if(value !== null) {
            data = {
                ...data,
                name: value
            }
        }

        stateActions.verifEtab(data).then(res => {
            let data = JSON.parse(res);
            let dDataVerifEtab = data.similaireInstitution;
            setSimilarInstit(data);
            setNbResultats(data.nbResultats)
            setMaxPage(data.nbrPages);
            return dispatchList({type: 'setDataVerifEtab', dDataVerifEtab });
        });
    }


    const generateOptions = (data) => {
        if(typeof data !== 'undefined') {
            let options = [];
            data.forEach((option, i) => {
                options.push({
                    value: option.id, 
                    label: option.name,
                });
            });
            return options;
        }
    }

    const handleChange = (value, name, type = null) => {
        let itemCopy;
        if(type !== null && type.action === 'clear') {
            itemCopy = {
                ...item,
                ...stateActions.dataActions,
                [name]: null
            }
            let aDataActions = itemCopy;
            setItem(itemCopy);
            dispatchActions({type: 'setDataActions', aDataActions });
            return;
        }
        if(name === 'type') {
            value = value.value
        }
        itemCopy = {
            ...item,
            ...stateActions.dataActions,
            [name]: value
        }
        let aDataActions = itemCopy;
        setItem(itemCopy);
        dispatchActions({type: 'setDataActions', aDataActions });
                
        if(name === 'name' ) {
            clearTimeout(timer.current);

            if(value.length >= 3)
                timer.current = setTimeout(() => {
                    getVerif(value);
                }, 1000);
            
            // else if(value.length < 3)
            //     return getVerif('');
            
            else if(value.length <= 2)
                return;
        }
    }

    const dataHeaderSimilar = [
        {
            name: 'reference', 
            displayName: t('INSTITUTION.LIST.REFERENCE'), 
        },
        {
            name: 'name', 
            displayName: t('INSTITUTION.LIST.NAME'), 
        },
        {
            name: 'addresszoneGeo', 
            displayName: t('INSTITUTION.LIST.ADDRESS'), 
        },
    ];

    return(
        <form>
            <h2><b>{t('INSTITUTION.POP_EDIT.TAB_ADDRESS')}</b></h2>

            <ZoneGeoInput
                item={item}
                countries={stateList.dataCountries}
                actions={stateActions}
                setInitParam={(e) => {setInitParam(e)}}
                edit={false}
            />
            {initParam === true && 
                <>
                    <h2><b>{t('INSTITUTION.POP_ADD.GENERAL_INFORMATIONS')}</b></h2>

                    <div>
                        <label htmlFor="name">{t('INSTITUTION.POP_EDIT.NAME')}</label>
                        <input id="name" type="text" required value={item.name} onChange={ (e) => {handleChange(e.target.value, 'name')} }/>
                    </div>

                    <div>
                        <label htmlFor="type">{t('INSTITUTION.POP_EDIT.TYPE')}</label>
                        <Select 
                            id="type"
                            className='selectSearchBar' 
                            classNamePrefix='selectSearch' 
                            placeholder={t('INSTITUTION.POP_EDIT.TYPE_PLACEHOLDER')}
                            isClearable={true}
                            options={generateOptions(stateList.dataTypeEtabs)} 
                            onChange={(value, type) => {handleChange(value, 'type', type)} } 
                            defaultValue={''} 
                        />
                    </div>

                    <div>
                        <label htmlFor="mail">{t('INSTITUTION.POP_EDIT.EMAIL')}</label>
                        <input id="mail" type="text" required value={item.mail} onChange={ (e) => {handleChange(e.target.value, 'mail')} }/>
                    </div>

                    <div className="containerInline">
                        <div className="phoneInputContainer">
                            <label htmlFor="phone">{t('INSTITUTION.POP_EDIT.PHONE_NUMBER')}</label>
                            <PhoneInput
                                // country={'bj'}
                                value={''}
                                onChange={ (value) => {handleChange(value, 'landLine')} }
                                localization={fr}
                            />
                        </div>

                        <div>
                            <label htmlFor="faxNumber">{t('INSTITUTION.POP_EDIT.FAX')}</label>
                            <input id="faxNumber" type="text" required value={item.faxNumber} onChange={ (e) => {handleChange(e.target.value, 'faxNumber')} }/>
                        </div>
                    </div>

                    {(typeof similarInstit.similaireInstitution !== 'undefined' && similarInstit.similaireInstitution !== null) &&
                        <div className="containerRedBorder">
                            <TableManager 
                                dataHeader={dataHeaderSimilar} 
                                actionsList={[]}
                                type="verifEtab"
                                isAdd={false}
                            />
                            <br/>
                            <Pagination
                                maxPage={maxPage}
                                onChangePage={(data) => setPage(data)}
                                nbCount={nbResultats}
                            ></Pagination>
                        </div>
                    }
                </>
            }
        </form>
    )
}
