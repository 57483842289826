import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getAllMissions = (data) => {
    return new Promise((resolve, reject) => {
        try {
            let params = {                    
                "page": (data.page !== undefined) ? data.page : 1,
                "maxPerPage": (data.maxPerPage !== undefined) ? data.maxPerPage : 50,
                "country": (data.countryName !== undefined) ? data.countryName : data.country,
                "affectation": (data.delegue !== undefined) ? data.delegue : null,
                "protype": (data.protype !== undefined) ? data.protype : null,
                "label": (data.label !== undefined) ? data.label : null,
                "speciality": (data.speciality !== undefined) ? data.speciality : null,
                "location": (data.location !== undefined) ? data.location : null,
                "mission": (data.name !== undefined) ? data.name : null,
                "network": (data.network !== undefined) ? data.network : null,
                "filter": (data.filter !== undefined) ? data.filter : true,
                "zoneGeo": (data.zoneGeo !== undefined) ? data.zoneGeo : null,
                "countries": (data.countries !== undefined) ? data.countries : null,
            }
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/mission/list', params)
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const postMission = (mission) => {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                let params = {
                    name: mission.name,
                    ugvSectors: mission.ugvs.map(ugv => ugv.id),
                    idNetwork: mission.network.value,
                    withSupervision: !!mission.withSupervision,
                    idPosition: mission.affectation.value,
                    specialities: mission.specialities ?? [],
                    protypes: mission.protypes ?? [],
                    country: mission.country.label
                };
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).post('api/mission', params)
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const editMission = (mission) => {
    return new Promise((resolve, reject) => {
        
        try {
            authHeader()
            .then(res => {
                let params = {
                    name: mission.name,
                    ugvSectors: mission.ugvs.map(ugv => ugv.id),
                    idNetwork: mission.network.idNetwork ?? mission.network.value,
                    withSupervision: mission.withSupervision,
                    idPosition: mission.affectation.idPosition ?? mission.affectation.value,
                    specialities: mission.specialities,
                    protypes: mission.protypes,
                    country: mission.country.label
                };
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).put(`api/mission/${mission.id}`, params)
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const deleteMission = (idMission) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).delete('api/mission/'+idMission+'/delete', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const disableMission = (idMission) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).put('api/mission/'+idMission+'/lock', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}


//SPE

export const getMissionSpe = (idMission) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/mission/'+idMission+'/speciality', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listMissionSpeciality));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const postMissionSpe = (idMission, idSpe, prospecting) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).post('api/mission/'+idMission+'/speciality', {
                    "idSpeciality": idSpe,
                    "prospecting_score": prospecting
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const deleteMissionSpe = (idMissionSpe) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).delete('api/mission/speciality/'+idMissionSpe, {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}


//TYPE PDS

export const getMissionTypePDS = (idMission) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/mission/'+idMission+'/protype', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listMissionProType));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const postMissionTypePDS = (idMission, idPDS, prospecting) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).post('api/mission/'+idMission+'/protype', {
                    "idMedicalProType": idPDS,
                    "prospecting_score": prospecting
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const deleteMissionTypePDS = (idMissionPDS) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).delete('api/mission/protype/'+idMissionPDS, {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}


// PDS & ETAB

export const getProsMission = (idMission, data) => {

    return new Promise((resolve, reject) => {
        try {
            let params = {};
            if( data !== undefined ){
                params = {
                    "page" : data.page,
                    "maxPerPage": data.maxPerPage,
                    "location" : data.location,
                    "label" : data.label,
                    "protype" : data.protype,
                    "speciality" : data.speciality,
                    "zonegeo" : data.zonegeo,
                }
            }
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/mission/'+idMission+'/professionnel', params)
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getEtabsMission = (idMission, data) => {

    return new Promise((resolve, reject) => {
        try {
            
            let params = {};
            if( data !== undefined ){
                params = {
                    "page" : data.page,
                    "maxPerPage": data.maxPerPage,
                    "country" : data.country,
                    "mission" : data.mission,
                    "label" : data.label,
                    "type" : data.type,
                    "zonegeo" : data.zonegeo
                }
            }
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/mission/'+idMission+'/institution', params)
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}


// CIBLAGE

export const getCiblage = (id, data) => {

    return new Promise((resolve, reject) => {
        try {
            let params = {};
            if(data !== undefined) params = {
                "page": (data.page !== undefined) ? data.page : 1,
                "maxPerPage": (data.page !== undefined) ? data.maxPerPage : 50,
                "pdsFirstName": (data.pdsFirstName !== undefined && data.pdsFirstName !== "") ? data.pdsFirstName : null,
                "pdsLastName": (data.pdsLastName !== undefined && data.pdsLastName !== "") ? data.pdsLastName : null,
                "pdsInstitution": (data.pdsInstitution !== undefined) ? data.pdsInstitution : null,
                "pdsArea": (data.pdsArea !== undefined) ? data.pdsArea : null,
                "pdsType": (data.pdsType !== undefined) ? data.pdsType : null,
                "pdsSpeciality": (data.pdsSpeciality !== undefined) ? data.pdsSpeciality : null,
                "targetingStatus": (data.targetingStatus !== undefined) ? data.targetingStatus : null,
                "isQualified": (data.isQualified !== undefined) ? data.isQualified : null,
                "qualifiedProducts": (data.qualifiedProducts !== undefined) ? data.qualifiedProducts : {},
                "pdsReference": (data.pdsReference !== undefined && data.pdsReference !== "") ? data.pdsReference : null
            };
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/targeting/mission/'+id+'/professionnel', params)
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const editCiblage = (idMission, isTarget, idPro) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).put('api/targeting/mission/'+idMission+'/edit-targeting', {
                    "newTargetingStatus": isTarget ? 1 : 0,
                    "medicalPros": [idPro]
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const untargetAll = (idMission) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).post(`api/mission/${idMission}/planning/professionnels/disable-target`, {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}