import { useContext } from 'react';
import { ListStoreContext } from '../../../../../../store/store/storeList';
import { FilterStoreContext } from '../../../../../../store/store/storeFilters';
import { StoreContext } from '../../../../../../store/store';

import iconLock from "../../../../../../assets/img/icons/icon-lock.png";
import iconLockColor from "../../../../../../assets/img/icons/icon-lock-color.png";

export const Lock = (props) => {
    const { action, stateActions, type, line, refresh } = props;
    const { stateList, dispatchList } = useContext(ListStoreContext);
    const { stateFilter } = useContext(FilterStoreContext);
    const { setLoadingStore } = useContext(StoreContext);

    const handleClick = () => {
        stateActions.lockAction(line.id).then((response) => {
            let res = JSON.parse(response);
            if(res.code === '200' || res.code === 200) {
                setLoadingStore(true);

                stateActions.getAll({
                    ...stateList.dataPagination,
                    ...stateFilter.searchFilters
                }).then((res) => {
                    const data = JSON.parse(res);
                    let dDataList = [];

                    if(type === 'user')
                        dDataList = data.listUser;
                    else if(type === "mission")
                        dDataList = data.listMission;

                    setLoadingStore(false);
                    return dispatchList({type: 'setList', dDataList });
                });
            }
            else 
                return false;
        });
    }

    const isUnlocked = () => {
        if(type !== "mission") {
            if(type === "user") {
                return !line.status;
            }
            return line.status;
        }
        return line.unlockedCal;
    }

    return (
        <>
            <div className="icon-btn" onClick={() => handleClick()}>
                <img src={isUnlocked() ? iconLock : iconLockColor} alt="" className="inline-block"/>
            </div>
        </>
    )
}