import iconEdit from "../../../assets/img/icons/icon-edit.png";
import iconQuestion from "../../../assets/img/icons/icon-question.png";
import iconDelete from "../../../assets/img/icons/icon-delete.png";

export const ListRowLaboProd = ({ item, handleEditProd, seeQuestions, handleIdDelete }) => {

    const afficheAbsence = () => {
        let abs = "";
        item.restrictedArea.map((area, i) => {
            abs += area.nameArea;
            if(i !== item.restrictedArea.length - 1) abs += ", ";
        });
        return abs;
    }

    return(
        <tr className="list-row">
            <td className="uppercase">{item.nameProduct}</td>
            <td className='pres'>{item.presentationProduct ? "Présentation" : "Rappel"}</td>
            <td className="text-center questions">{item.nbquestionsProduct}</td>
            <td className="text-center countries">{item.restrictedArea && afficheAbsence()}</td>
            <td className="icons">
                <div className="icon-btn"
                data-toggle="modal"
                data-target="#modalAddEditProd"
                onClick={() => handleEditProd(item)}
                >
                    <img src={iconEdit} alt="edit"/>
                </div>
                <div
                className="icon-btn"
                data-toggle="modal"
                data-target="#modalQuestions"
                onClick={() => seeQuestions(item)}
                >
                    <img src={iconQuestion} alt="?"/>
                </div>
                <div
                className="icon-btn"
                data-toggle="modal"
                data-target="#modalDelete"
                onClick={() => handleIdDelete(item.idProduct, "produit")}>
                    <img src={iconDelete} alt="delete"/>
                </div>
            </td>
        </tr>
    )
}