// images
import iconStop from "../../../../assets/img/icons/icon-stop-big.png";

export const ProdResume = (props) => {

    const { resume, type, condition, entete, ordreLabel } = props;

    return(
    <>
        <div className="col-md-2 reseauxProd">
            <table className="box tab-entete reseauxProd">
                <tbody>
                    <tr>
                        <td>Ordre</td>
                        <td>Produit</td>
                    </tr>
                </tbody>
            </table>

            <table className="list reseauxProd1">
                <tbody>
                    {Object.keys(resume).map((item, i) => {
                        return(
                            <tr className="list-row" key={`resumeC${i}`}>
                                <td>
                                    {resume[item].ordreDef}
                                </td>
                                <td>
                                    {resume[item].name}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>

        <div className="col-md-10 reseauxProd">
            <div>
                <table className="box tab-entete reseauxProd">
                    <tbody>
                        <tr>
                            {Object.keys(condition).length > 0 ?
                            entete.map((c, i) =>
                                <td key={`tabC${i}`}>
                                    {c.name}
                                </td>
                            ) :
                                <td className="vide">
                                    Il n'y a pas de configuration spécifique à des {type}
                                </td>
                            }
                        </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <table className="list reseauxProd2">
                    <tbody>
                        {Object.keys(resume).map((item, i) =>

                            <tr className="list-row" key={`resumeCTab-tr-${i}`}>

                                {Object.keys(resume[item]).map((c, j) => {

                                    if((c !== "name") && (c !== "ordreDef")) {
                                        return(
                                            <td key={`resumeCTab-td-${j}`}>
                                                {resume[item][c].isrestricted ? (
                                                    <img src={iconStop} alt="x"/>
                                                )
                                                : resume[item][c][ordreLabel] !== 0 ?
                                                    resume[item][c][ordreLabel]
                                                :
                                                    null
                                                }
                                            </td>
                                        )
                                    }
                                })}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    </>
    )
}