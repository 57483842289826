import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getHistory = (data) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).get('api/log?page='+data.page+'&maxPerPage='+data.maxPerPage, {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getHistoryPDS = (data) => {
    return new Promise((resolve, reject) => {
        try {
            let params = {
                filters: "",
                page: data.page ? data.page : 1,
                maxPerPage: 50
            };
            if(data.name !== "" && data.name) 
                params = { ...params, filters: `${params.filters}/who`, name: data.name };
            if(data.productId !== "" && data.productId) 
                params = { ...params, filters: `${params.filters}/products`, productId: data.productId };
            if(data.typeContact !== "" && data.typeContact) 
                params = { ...params, filters: `${params.filters}/type`, typeContact: data.typeContact };
            // if(data.dateMin !== "" && data.dateMax !== "" && data.dateMax && data.dateMin) {
            //     params = {
            //         ...params,
            //         filters: `${params.filters}/date`,
            //         dateMin: data.dateMin,
            //         dateMax: data.dateMax
            //     };
            // }
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/history/'+data.idPDS, params)
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}