import React, { useContext, useEffect, useState, useRef } from 'react';
import "./Planning.scss";
import { useMatch, useLocation } from 'react-router-dom';
import { t } from "../i18nFunctions";
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import { StoreContext } from "../../store/store";
import { ActionsStoreContext } from '../../store/store/storeActions';
import { ListStoreContext } from '../../store/store/storeList';
import { PopUpManager } from '../../components/UI/PopUp/PopUpManager';

// components
import { Checkbox } from '../../components/UI/Checkbox/Checkbox';
import { CalendarElement } from "../../components/CalendarElement/CalendarElement";
import { CalendarSearch } from '../../components/CalendarSearch/CalendarSearch';

import { PopupDelete } from '../../components/Popups/PopupDelete';
// import PopupChooseZoneGeo from '../components/shared/PopupChooseZoneGeo';
import { PopupCancelAppointment } from '../../components/Popups/Planning/PopupCancelAppointment';
import { PopupSelectEtab } from '../../components/Popups/Planning/PopupSelectEtab';
import { PopupDuo } from "../../components/Popups/Planning/PopupDuo";
import { PopupRemplirCR } from "../../components/Popups/Planning/PopupRemplirCR";

// API
import {
    getAllSpecialite,
    getAllProType,
    getAllZonesGeo,
    getAllMissions,
    getAllEtabs,
    getTypeEtab,
    getCancelReasonList,
    getTypeContact,
    getObjectiveList,
    getRPTypes,

    getActivityList,
    postActivity,
    editActivity,

    getMissionPro,
    getMissionUser,

    getPlanningWeek,
    postRDV,
    deleteRDV,
    cancelRDV,

    getCR,
    remplirCR,
    defineDuo,

    getHistoryPDS,
    getSpeByType,
    getSpePro,
    getEtabPro,
    getLogPro,
    getLogiciels,
    verifPro,
    getMedicalProsQualifProd
} from '../../store/index.service';


export const Planning = () => {

    const match = useMatch("/missions/:id/calendar");
    const path = match ? match.pattern.path : "/calendar/current";
    const location = useLocation();
    const calendarRef = useRef();

    // STATE

    const [allPDS, setAllPDS] = useState({ list: [], loading: true });
    const [allZG, setAllZG] = useState({ list: [], loading: true });
    const [allSpe, setAllSpe] = useState({ list: [], loading: true });
    const [allEtabs, setAllEtabs] = useState({ list: [], loading: true });

    const [typeEtabList, setTypeEtabList] = useState([]);
    const [missionsList, setMissionsList] = useState([]);
    const [cancelReasonsList, setCancelReasonsList] = useState([]);
    const [histoTypeList, setHistoTypeList] = useState([]);
    const [objectiveList, setObjectiveList] = useState([]);
    const [rpList, setRPList] = useState([]);

    const [state, setState] = useState({
        date: moment(),
        name: "",
        etab: null,
        typePDS: null,
        spe: null,
        zoneGeo: null,
        planningRDV: {},
        pdsDragged: {},
        zgLoad: {
            country: null,
            countryID: null,
            zoneG: null,
            zoneA: null,
            zoneGID: null,
            ville: null,
            villeID: null,
            villeA: null,
            comID: null,
            comA: null,
            com: null
        },
        idPDS: 0,
        availableEtabs: [],
        halfday: "",
        dateRDV: "",
        nextApp: {},
        weekend: false,
        details: true,
        cancelID: 0,
        deleteID: 0,
        duo: {}
    });

    const [missionPros, setMissionPros] = useState({
        prosListTGT: [],
        prosListNTGT: [],
        nbTGT: 0,
        nbNTGT: 0,
        loading: true
    });

    const [mission, setMission] = useState({ loading: true });

    const [cr, setCR] = useState({
        report: {},
        readOnly: false,
        loading: true
    });

    const [mounted, setMounted] = useState(false);


    // CONTEXT

    const { dispatchActions } = useContext(ActionsStoreContext);
    const { dispatchList } = useContext(ListStoreContext);

    const { setLoadingStore } = useContext(StoreContext);


    // EFFECT

    useEffect(() => {

        dispatchActions({ type: 'initAllActions', allActions });

        setLoadingStore(true);

        Promise.allSettled([
            handleGetAllSpecialite(),

            getAllProType()
                .then(res => {
                    const data = JSON.parse(res);
                    setAllPDS({ list: data ?? [], loading: false });
                })
                .catch(err => console.error(err))
            ,

            getAllZonesGeo()
                .then(res => {
                    const data = JSON.parse(res) ? JSON.parse(res).listZoneGeo : null;
                    setAllZG({ list: data ?? [], loading: false });
                })
                .catch(err => console.error(err))
            ,

            getTypeEtab()
                .then(res => {
                    const data = JSON.parse(res);
                    if(data)
                        setTypeEtabList(data);
                })
                .catch(err => console.error(err))
            ,

            getActivityList()
                .then(res => {
                    const data = JSON.parse(res);
                    let dDataActivity = data ?? [];
                    dispatchList({type: 'setDataActivity', dDataActivity });
                })
            ,

            handleGetAllEtabs({                           
                page: 1,
                maxPerPage: 100,
                location: state.etab ? state.etab.value : null
            }),

            getAllMissions({ page: 1, maxPerPage: 400 })
                .then(res => {
                    const data = JSON.parse(res);
                    if(data && data.listMission)
                        setMissionsList(data.listMission);
                })
                .catch(err => console.error(err))
            ,

            getCancelReasonList()
                .then(res => {
                    const data = JSON.parse(res);
                    if(data)
                        setCancelReasonsList(data);
                })
                .catch(err => console.error(err))
            ,

            getTypeContact().then(res => {
                const data = JSON.parse(res);
                if(data)
                    setHistoTypeList(data);
            }),

            getObjectiveList().then(res => {
                const data = JSON.parse(res);
                if(data)
                    setObjectiveList(data);
            }),

            getRPTypes().then(res => {
                const data = JSON.parse(res);
                if(data)
                    setRPList(data);
            })

        ]).then(() => {

            if(path === "/missions/:id/calendar")
                handlePlanningAndMissionPro(
                    match.params.id,
                    {
                        id: match.params.id,
                        name: location.state.name
                    },
                    location.state.unlockedcal
                );
            else
                getMissionUser().then(res => {
                    const data = JSON.parse(res)[0];
                    handlePlanningAndMissionPro(data.id, data, data.unlockedCal);
                });
        });

        setMounted(true);

    }, []);

    useEffect(() => {

        if(mounted)
            handleGetMissionPro(mission.id);

    }, [state.etab, state.zoneGeo, state.typePDS, state.spe]);


    // UPDATES

    const handleGetAllSpecialite = (params) => {

        setAllSpe({ ...allSpe, loading: true });

        getAllSpecialite(params)
            .then(res => {

                const data = JSON.parse(res);
                setAllSpe({
                    list: data && data.listSpeciality ? data.listSpeciality : [],
                    loading: false
                });
            })
            .catch(err => console.error(err))
    }

    const handleGetAllEtabs = (params) => {

        setAllEtabs({ ...allEtabs, loading: true });

        getAllEtabs(params)
            .then(res => {

                const data = JSON.parse(res);
                setAllEtabs({ list: data ?? [], loading: false });
            })
            .catch(err => console.error(err))
    }

    const handleGetPlanningWeek = (week, id, year, loading = true, resetPDSDragged = false) => {

        if(loading)
            setLoadingStore(true);

        getPlanningWeek(week, id, year).then(res => {

            const data = JSON.parse(res);
            setState({
                ...state,
                planningRDV: data ?? {},
                pdsDragged: resetPDSDragged ? {} : state.pdsDragged
            });

            if(loading)
                setLoadingStore(false);
        });
    }

    const handleGetMissionPro = (id, name) => {

        if(!missionPros.loading)
            setMissionPros({ ...missionPros, loading: true })

        let nameParam = name ?? state.name;

        getMissionPro(id, {
            page: 1,
            maxPerPage: 25,
            etab: state.etab && state.etab .value? state.etab.value : null,
            label: nameParam !== '' ? nameParam : null,
            type: state.typePDS && state.typePDS.value ? state.typePDS.value : null,
            spe: state.spe && state.spe.value ? state.spe.value : null,
            zonegeo: state.zoneGeo && state.zoneGeo.value ? state.zoneGeo.value : null
        })
            .then(res => {
                const data = JSON.parse(res);

                let listTGT = [];
                let listNTGT = [];
                let nbT = 0;
                let nbN = 0;

                if(data.listTargetMissionMedicalPro) {
                    listTGT = data.listTargetMissionMedicalPro;
                    nbT = data.nbResultatsTGT;
                }
                if(data.listNotTargetMissionMedicalPro) {
                    listNTGT = data.listNotTargetMissionMedicalPro;
                    nbN = data.nbResultatsNTGT;
                }

                setMissionPros({
                    prosListTGT: listTGT,
                    prosListNTGT: listNTGT,
                    nbTGT: nbT,
                    nbNTGT: nbN,
                    loading: false
                });
            });
    }

    const handlePostRDV = (rdv) => {
        postRDV(mission.id, state.date.isoWeek(), rdv).then(() => {

            NotificationManager.success('', t('COMMON.NOTIFICATIONS.ADD_APPOINTMENT'));

            handleGetPlanningWeek(state.date.isoWeek(), mission.id, state.date.year(), true, true);

            // setState({ ...state, pdsDragged: {} });
        })
        .catch(() => NotificationManager.error('', "Error"));
    }

    const handleActivity = (setter, params, method) => {
        setter(...params).then(() => {

            // method = "ADD" or "EDIT"
            NotificationManager.success('', t(`COMMON.NOTIFICATIONS.${method}_ACTIVITY`));

            handleGetPlanningWeek(state.date.isoWeek(), mission.id, state.date.year());
        })
        .catch(() => NotificationManager.error('', "Error"));
    }

    const handleGetCR = (id) => {

        getCR(id).then(res => {

            const data = JSON.parse(res);
            setCR({
                ...cr,
                report: data ?? {},
                loading: false
            });
        });
    }


    // FUNCTIONS

    let allActions = {
        getHistoryPDS: getHistoryPDS,
        getSpeByType: getSpeByType,
        getSpePro: getSpePro,
        getEtabPro: getEtabPro,
        getLogPro: getLogPro,
        getLogiciels: getLogiciels,
        verifPro: verifPro,
        getMedicalProsQualifProd: getMedicalProsQualifProd,
        getData: handleGetCR,
        getCRData: handleGetCR
	};

    const handlePlanningAndMissionPro = (id, missionData, unlockedcal) => {
        Promise.all([
            handleGetPlanningWeek(state.date.isoWeek(), id, state.date.year(), false),

            handleGetMissionPro(id)
        ])
        .then(() => setLoadingStore(false));

        setMission({
            ...missionData,
            locked: !unlockedcal,
            loading: false
        });
    }

    // const handleZoneSearch = (country, province, ville, commune, zoneID) => setState({ ...state,
    //     zoneGeo: {
    //         value: zoneID,
    //         label: commune ? commune : ville ? ville : province ? province : country,
    //         ad: `${country}${province ? `, ${province}` : ""}${ville ? `, ${ville}` : ""}${commune ? `, ${commune}` : ""}`
    //     }
    // });

    const submitAfterEtab = (idEtab) => {

        if(state.idPDS && state.halfday && state.dateRDV) {
            handlePostRDV({
                date: state.dateRDV,
                halfday: state.halfday,
                idInstitution: idEtab,
                idMedicalPro: state.idPDS
            });
        }
    }

    const handleNextApp = (halfday, idInstitution, idMedicalPro) => setState({ ...state,
        nextApp: {
            idMission: mission.id,
            halfday: halfday,
            idInstitution: idInstitution,
            idMedicalPro: idMedicalPro
        }
    });


    // VARIABLES

    const daysTrad = [
        t('DAYS.ABREV.MON'),
        t('DAYS.ABREV.TUE'),
        t('DAYS.ABREV.WED'),
        t('DAYS.ABREV.THU'),
        t('DAYS.ABREV.FRI'),
        t('DAYS.ABREV.SAT'),
        t('DAYS.ABREV.SUN')
    ];

    const roleUser = localStorage.getItem('role');

    const lang = localStorage.getItem('currentLanguage');
    const dateTrad = moment(state.date).locale(lang);

    const style = { width: `calc(100% / ${state.weekend ? "7" : "5"})` };

    const currentWeek = moment().isoWeek();

    const CalendarTemplate = props => {
        return (
            <CalendarElement
                pdsDragged={state.pdsDragged}
                pdsIsDragged={Object.keys(state.pdsDragged).length > 0}
                style={style}
                details={state.details}
                path={path}
                updateSelect={(
                    moment().diff(state.date, "weeks") > 1
                    && mission.locked
                    && (
                        roleUser === "ROLE_SUPERVISEUR" 
                        || roleUser === "ROLE_DELEGUE" 
                        || roleUser === "ROLE_ADMIN" 
                        || roleUser === "ROLE_DP" 
                        || roleUser === "ROLE_HP"
                        || roleUser === "ROLE_DSM"
                    )
                )}

                cancelApp={(cancelID) => setState({ ...state, cancelID })}
                deleteRDV={(deleteID) => setState({ ...state, deleteID })}
                handleDuo={(duo) => setState({ ...state, duo })}
                readOnlyCR={(readOnly) => setCR({ ...cr, readOnly })}
                handleNextApp={(halfday, idInstitution, idMedicalPro) => handleNextApp(halfday, idInstitution, idMedicalPro)}
                prepareEtab={(idM, etabs, date) => setState({ ...state,
                    availableEtabs: etabs,
                    idPDS: idM,
                    halfday: props.bottomText,
                    dateRDV: date
                })}

                postActivite={(date, halfday, code) => handleActivity(
                    postActivity,
                    [mission.id, date, halfday, code],
                    "ADD"
                )}
                editActivite={(idSchedule, code) => handleActivity(
                    editActivity,
                    [idSchedule, code, state.date.isoWeek()],
                    "EDIT"
                )}
                postRDV={(rdv) => handlePostRDV(rdv)}
                getCR={(id) => handleGetCR(id)}

                {...props}
            />
        );
    };


    // RENDER

    return (
        <section className="content">

            <section className="content-header">
                <h1>
                    {path === "/missions/:id/calendar" ? location.state.delegue : t('PLANNING.TITLE')} - {mission.name}
                    <small>
                        {t('CAL.FROM')} {dateTrad.startOf('isoWeek').format("dddd DD MMMM YYYY")} {t('CAL.TO')} {dateTrad.endOf('isoWeek').format("dddd DD MMMM YYYY")}
                    </small>
                </h1>
            </section>

            <section className='content'>
                <div className='row calendar'>

                    {/* NO COL FOR OTHERS' CALENDARS -- NO COL IF SELECTED WEEK BEFORE LAST AND PLANNING IS LOCKED */}
                    { !( (moment().diff(state.date, "weeks") > 1 && mission.locked) || path === "/missions/:id/calendar" )
                    &&
                        <CalendarSearch
                            typeEtabList={typeEtabList}
                            handleGetAllEtabs={handleGetAllEtabs}
                            allEtabs={allEtabs}
                            handleGetMissionPro={value => handleGetMissionPro(mission.id, value)}
                            allPDS={allPDS}
                            handleGetAllSpecialite={handleGetAllSpecialite}
                            allSpe={allSpe}
                            allZG={allZG}
                            missionPros={missionPros}
                            pdsDragged={state.pdsDragged}
                            setPdsDragged={p => setState({ ...state, pdsDragged: p })}
                            calendar={calendarRef.current}
                            setState={values => setState({ ...state, ...values })}
                            spe={state.spe}
                            zoneGeo={state.zoneGeo}
                        />
                    }

                    {/* IF NO LEFT COL, CALENDAR TAKES WHOLE WIDTH */}
                    <div
                        className={
                            ( (moment().diff(state.date, "weeks") > 1 && mission.locked) || path === "/missions/:id/calendar" ) ?
                            "col-md-12" : "col-md-9"
                        }
                        ref={calendarRef}
                    >
                        <section>
                            <div className="calendar-element">

                                <div className="calendar-header">

                                    <div className="calendar-title">
                                        {state.date.year()} - {t('PLANNING.WEEK')} {state.date.isoWeek()}
                                        {currentWeek === state.date.isoWeek() && " (" + t('PLANNING.CURRENT') + ")"}
                                    </div>

                                    <div className="calendar-week">
                                        <Checkbox
                                            label="Weekend"
                                            value={state.weekend}
                                            onChange={() => setState({ ...state, weekend: !state.weekend })}
                                        />
                                    </div>

                                    <div className="calendar-details">
                                        <Checkbox
                                            label="Details"
                                            value={state.details}
                                            onChange={() => setState({ ...state, details: !state.details })}
                                            isSwitch
                                        />
                                    </div>
                                    
                                    <div>
                                        <div
                                            className="calendar-navigation btn-week" 
                                            onClick={() => {
                                                if(mission.id) {
                                                    let d = state.date;
                                                    d.add(1, 'weeks');
                                                    handleGetPlanningWeek(d.isoWeek(), mission.id, d.year());
                                                    setState({ ...state, date: d });
                                                }
                                            }}
                                        >
                                            {">"}
                                        </div>

                                        <div
                                            className="calendar-navigation btn-week"
                                            onClick={() => {
                                                if(mission.id) {
                                                    let d = state.date;
                                                    d.subtract(1, 'weeks');
                                                    handleGetPlanningWeek(d.isoWeek(), mission.id, d.year());
                                                    setState({ ...state, date: d });
                                                }
                                            }}
                                        >
                                            {"<"}
                                        </div>
                                    </div>
                                    <div className="content-clear"></div>
                                </div>

                                <div className="calendar-content-head no-mobile">
                                    {daysTrad.map((d, i) => {
                                        const tradDate = moment(state.date).locale(lang);

                                        if(i < 5 || state.weekend) return(
                                            <div className="calendar-head" style={style} key={i}>
                                                <strong>
                                                    {d}&nbsp;
                                                </strong>
                                                {tradDate.startOf('isoWeek').add(i, 'days').format("DD MMMM")}
                                            </div>
                                        )
                                    })}
                                </div>
                                
                                <div className="calendar-content-container">

                                    {daysTrad.map((d, i) => {

                                        const tradDate = moment(state.date).locale(lang).startOf('isoWeek').add(i, 'days');
                                            
                                        const fullPlanning = Object.keys(state.planningRDV).length > 0;

                                        if(i < 5 || state.weekend) return(
                                            <React.Fragment key={i}>

                                                <div className="calendar-content-head mobile-only">
                                                    <div className="calendar-head">
                                                        <strong>
                                                            {d}&nbsp;
                                                        </strong>
                                                        {tradDate.format("DD MMMM")}
                                                    </div>
                                                </div>

                                                <CalendarTemplate
                                                    bottomText={"AM"}
                                                    date={tradDate.format()}
                                                    off={i >= 5}
                                                    dropped={ fullPlanning ? state.planningRDV[Object.keys(state.planningRDV)[i]].appointments : [] }
                                                    idSchedule={ fullPlanning ? state.planningRDV[Object.keys(state.planningRDV)[i]].idSchedule : undefined }
                                                    buttonText={ fullPlanning ? state.planningRDV[Object.keys(state.planningRDV)[i]].activityCode : "" }
                                                    withDelegue={ fullPlanning ? state.planningRDV[Object.keys(state.planningRDV)[i]].with : { mission: {} } }
                                                />

                                                <div className="mobile-only">
                                                    <CalendarTemplate
                                                        up={true}
                                                        bottomText={"PM"}
                                                        date={tradDate.format()}
                                                        off={i >= 5}
                                                        dropped={ fullPlanning ? state.planningRDV[Object.keys(state.planningRDV)[7+i]].appointments : [] }
                                                        idSchedule={ fullPlanning ? state.planningRDV[Object.keys(state.planningRDV)[7+i]].idSchedule : undefined }
                                                        buttonText={ fullPlanning ? state.planningRDV[Object.keys(state.planningRDV)[7+i]].activityCode : "" }
                                                        withDelegue={ fullPlanning ? state.planningRDV[Object.keys(state.planningRDV)[7+i]].with : { mission: {} } }
                                                    />
                                                </div>
                                            </React.Fragment>
                                        )
                                    })} 

                                </div>

                                <div className="calendar-content-container no-mobile">

                                    {daysTrad.map((d, i) => {
                                        const tradDate = moment(state.date).locale(lang).startOf('isoWeek').add(i, 'days');

                                        const fullPlanning = Object.keys(state.planningRDV).length > 0;

                                        if(i < 5 || state.weekend) return (
                                            <CalendarTemplate
                                                key={i}
                                                up={true}
                                                bottomText={"PM"}
                                                date={tradDate.format()}
                                                off={i >= 5}
                                                dropped={ fullPlanning ? state.planningRDV[Object.keys(state.planningRDV)[7+i]].appointments : [] }
                                                idSchedule={ fullPlanning ? state.planningRDV[Object.keys(state.planningRDV)[7+i]].idSchedule : undefined }
                                                buttonText={ fullPlanning ? state.planningRDV[Object.keys(state.planningRDV)[7+i]].activityCode : "" }
                                                withDelegue={ fullPlanning ? state.planningRDV[Object.keys(state.planningRDV)[7+i]].with : { mission: {} } }
                                            />
                                        )
                                    })}

                                </div>
                            </div>
                        </section>
                    </div>

                </div>
            </section>

            <PopUpManager 
                type="medicalProPage"
                displayTitle="Professionnel"
            />

            <PopupRemplirCR
                crReady={!cr.loading}
                item={!cr.loading && cr.report}
                readOnly={cr.readOnly}
                listTypeHisto={histoTypeList}
                listObjectifs={objectiveList}
                listRP={rpList}
                remplirCR={(idRDV, cr) => remplirCR(idRDV, cr, state.nextApp)
                    .then(() => {
                        NotificationManager.success('', t('COMMON.NOTIFICATIONS.COMPLETE_APPOINTMENT'));
                        handleGetPlanningWeek(state.date.isoWeek(), mission.id, state.date.year());
                    })
                    .catch(() => NotificationManager.error('', "Error"))
                }
            />

            <PopupCancelAppointment
                listCancel={cancelReasonsList}
                cancelAppointment={(idMotif) => cancelRDV(state.cancelID, idMotif)
                    .then(() => {
                        NotificationManager.success('', t('COMMON.NOTIFICATIONS.CANCELED_APPOINTMENT'));
                        handleGetPlanningWeek(state.date.isoWeek(), mission.id, state.date.year());
                    })
                    .catch(() => NotificationManager.error('', "Error"))
                }
            />

            <PopupDelete
                title={t('DELETE.APPOINTMENT')}
                id={state.deleteID}
                handleDelete={id => deleteRDV(id)
                    .then(() => {
                        NotificationManager.success('', t('COMMON.NOTIFICATIONS.DELETE_APPOINTMENT'));
                        handleGetPlanningWeek(state.date.isoWeek(), mission.id, state.date.year());
                    }).catch(() => NotificationManager.error('', "Error"))
                }
            />

            <PopupSelectEtab
                etabs={state.availableEtabs}
                choseEtab={(idEtab) => submitAfterEtab(idEtab)}
            />

            {/* <PopupChooseZoneGeo
                zoneGeo={state.zoneGeo}
                zoneGeoLoad={state.zgLoad}
                submitZone={(country, province, ville, commune, zoneID) => handleZoneSearch(country, province, ville, commune, zoneID)}
            /> */}

            <PopupDuo
                missionList={missionsList}
                duo={state.duo}
                defineDuo={(idMission) => defineDuo(idMission, state.duo.idSchedule)
                    .then(res => {
                        const data = JSON.parse(res);

                        if(data.code === "900")
                            NotificationManager.error('', t('COMMON.NOTIFICATIONS.DUO_ERROR'));
                        else {
                            NotificationManager.success('', t('COMMON.NOTIFICATIONS.DUO_SUCCESS'));
                            handleGetPlanningWeek(state.date.isoWeek(), mission.id, state.date.year());
                        }

                    }).catch(() => NotificationManager.error('', "Error"))
                }
            />

        </section>
    )

}