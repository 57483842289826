export const isDiff = (a, b) => { 
    if(a !== b)
        return true;

    return true;
}

export const isSupLength = (a, length) => { 
    if(a.length >= length)
        return true;

    return false;
}