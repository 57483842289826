import { useState, useEffect, useContext } from 'react';
import "./Frequences.scss";
import { t } from "../../i18nFunctions";
import { NotificationManager } from 'react-notifications';
import ReactLoading from 'react-loading';
import Select from 'react-select';
import { StoreContext } from "../../../store/store";

// components
import { PopupConfirmation } from '../../../components/Popups/PopupConfirmation';

// api
import {
    getAllReseaux,
    getProdsByConfig,
    
    getFrequences,
    editFrequences,

    getCycles,
    postCycle
} from '../../../store/index.service';

export const Frequences = () => {

    const [resList, setResList] = useState([]);
    const [name, setName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [listProd, setListProd] = useState([]);
    const [loadingResProd, setLoadingResProd] = useState(false);
    const [cyclesList, setCyclesList] = useState([]);
    const [cycleId, setCycleId] = useState(-1);
    const [action, setAction] = useState("");
    const [tabFrequency, setTabFrequency] = useState([]);
    const [reseau, setReseau] = useState({ label: "Sélectionnez un réseau" });

    const { setLoadingStore } = useContext(StoreContext);

    // mounted
    useEffect(() => {
        setLoadingStore(true);

        getAllReseaux().then(res => {
            const data = JSON.parse(res);

            if(data.listNetwork) setResList(data.listNetwork);
            else setResList([]);

            setLoadingStore(false);
        });
    }, []);

    // updates
    const handleGetCycles = (val) => {
        setLoadingStore(true);

        getCycles(val).then(res => {
            const data = JSON.parse(res);

            if(data) setCyclesList(data);
            else setCyclesList([]);

            setLoadingStore(false);
        });
    }

    // functions

    const handleOptionClick = res => {
        handleGetCycles(res.value);

        setLoadingResProd(true);

        getProdsByConfig(res.value, "default").then(res => {
            const data = JSON.parse(res);

            if(data) setListProd(data);
            else setListProd([]);

            setLoadingResProd(false);
        });

        setReseau(res);
        setAction("");
        setCycleId(-1);
    }

    const handleSubmit = () => {
        const data = {
            name: name,
            startDate: new Date(startDate).toJSON(),
            endDate: new Date(endDate).toJSON()
        };

        if(action === "edit")
            editFrequences(cycleId, {
                ...data,
                tabFrequency: tabFrequency.map(freq => ({
                    id: freq.id,
                    potentiel_A: freq.potentielA,
                    potentiel_B: freq.potentielB,
                    potentiel_C: freq.potentielC,
                    potentiel_D: freq.potentielD
                }))
            }).then(res => {
                const data = JSON.parse(res);

                if(data.code === "900")
                    NotificationManager.error('', "Un cycle existe déjà pour les dates données");
                else {
                    handleGetCycles(reseau.value);
                    NotificationManager.success('', "Cycle modifié");
                }
            });
        else if(action === "post")
            postCycle(reseau.value, data).then(res => {
                const data = JSON.parse(res);

                if(data.code === "900")
                    NotificationManager.error('', "Un cycle existe déjà pour les dates données");
                else {
                    handleGetCycles(reseau.value);
                    NotificationManager.success('', "Cycle ajouté");
                }
            });
    }

    const handleChange = (value, freq, cle) => {
        let tab = [...tabFrequency];
        const index = tab.map(f => f.id).indexOf(freq.id);

        if(index !== -1) tab[index][cle] = value;

        setTabFrequency(tab);
    }

    return(
        <section className="content">
        
            <section className="content-header">
                <h1>
                    {t('SETTINGS.VISIT_FREQUENCY')}
                    <small>{t('SETTINGS.MANAGEMENT')}</small>
                </h1>
            </section>
        
            <section className="content frequences">

                <div className="row">
                    <div className="col-md-12">
                        <div className="box info frequences">
                            <div className="reseau-col">

                                <label>{t('SETTINGS.NETWORK')}</label>
                                <Select
                                    options={resList.map(res => ({
                                        value: res.id,
                                        label: res.name
                                    }))}
                                    value={reseau}
                                    onChange={newValue => handleOptionClick(newValue)}
                                    placeholder={t('SETTINGS.SELECT_NETWORK')}
                                    formatOptionLabel={(option, { context }) => {
                                        /* context can be either `menu` or `value`
                                            menu - dropdown
                                            value - value displayed
                                        */
                                        return context === 'menu' ? (
                                            <div className="select-react-option">
                                                <p>{option.label}</p>
                                            </div>
                                        ) : option.label;
                                    }}
                                    className="react-select-container"
                                />
                            </div>

                            {reseau.value &&
                                <button
                                    type="submit"
                                    className="btn-save"
                                    onClick={() => {
                                        setAction("post");
                                        setName("");
                                        setStartDate("");
                                        setEndDate("");
                                        setCycleId(-1);
                                    }}
                                >
                                    {t('COMMON.ADD')}
                                </button>
                            }
                        </div>
                    </div>
                </div>

                <div className="row cycles">
                    <div className="col-md-6">
                        {cyclesList.map((cycle, i) =>
                            <div className="cycleRow" key={`cycle-${i}`}>

                                <span><span className="dot">•</span>{cycle.name}</span>

                                <i className="fa fa-edit" onClick={() => {
                                    setLoadingStore(true);

                                    getFrequences(cycle.id).then(res => {
                                        const data = JSON.parse(res);
                            
                                        if(data) setTabFrequency(data);
                                        else setTabFrequency([]);
                            
                                        setLoadingStore(false);
                                    });

                                    setAction("edit");
                                    setName(cycle.name);
                                    setStartDate(cycle.startDate.slice(0, 10));
                                    setEndDate(cycle.endDate.slice(0, 10));
                                    setCycleId(cycle.id);
                                }}></i>
                            </div>
                        )}
                    </div>
                </div>

                {action !== "" &&
                <div className="row">
                    <div className="col-md-12">
                        <div className="box info cycle">
                            <div className="cycle-col">
                                <label>{t('SETTINGS.NAME')}</label>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>

                            <div className="cycle-col">
                                <label>{t('SETTINGS.CYCLE_START')}</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={e => setStartDate(e.target.value)}
                                />
                            </div>

                            <div className="cycle-col">
                                <label>{t('SETTINGS.CYCLE_END')}</label>
                                <input
                                    type="date"
                                    value={endDate}
                                    onChange={e => setEndDate(e.target.value)}
                                />
                            </div>

                            <button
                                type="submit"
                                className="btn-save"
                                data-toggle={action === "edit" ? 'modal' : ''}
                                data-target={"#modalConfirmation"}
                                onClick={() => {
                                    if(action !== 'edit')
                                        handleSubmit();
                                }}
                            >
                                {t('COMMON.SAVE')}
                            </button>
                        </div>
                    </div>
                </div>}

                { action !== "" && cycleId !== -1 &&
                <div className="row frequencesTab">
                    <div className="leWrapper">
                        <div className="col-md-2">
                            <table className="box tab-entete">
                                <tbody>
                                    <tr>
                                        <td>
                                            <p>{t('SETTINGS.PRODUCTS')}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="list products">
                                <tbody>
                                    {listProd.map((prod, i) => {
                                        return(
                                            <tr className="list-row" key={`prod-${i}`}>
                                                <td>
                                                    <p>{prod.product.nameProduct}</p>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {loadingResProd &&
                                    <tr>
                                        <td>
                                            <ReactLoading
                                                type={"spinningBubbles"}
                                                color={"#0073bd"}
                                                height={20}
                                                width={20}
                                                className="spinner-loader products"
                                            />
                                        </td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="col">
                        <div>
                            <table className="box tab-entete potentiels">
                                <tbody>
                                    <tr>
                                        <td>A</td>
                                        <td>B</td>
                                        <td>C</td>
                                        <td>D</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <table className="list freqNum">
                                <tbody>
                                    {tabFrequency.map((freq, i) =>
                                    <tr className="list-row" key={`freq-prod-${i}`}>
                                        <td>
                                            <input
                                                type="number"
                                                value={freq.potentielA}
                                                onChange={e => handleChange(e.target.value, freq, "potentielA")}
                                                onBlur={e => e.target.value === "" && handleChange("0", freq, "potentielA")}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                value={freq.potentielB}
                                                onChange={e => handleChange(e.target.value, freq, "potentielB")}
                                                onBlur={e => e.target.value === "" && handleChange("0", freq, "potentielB")}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                value={freq.potentielC}
                                                onChange={e => handleChange(e.target.value, freq, "potentielC")}
                                                onBlur={e => e.target.value === "" && handleChange("0", freq, "potentielC")}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                value={freq.potentielD}
                                                onChange={e => handleChange(e.target.value, freq, "potentielD")}
                                                onBlur={e => e.target.value === "" && handleChange("0", freq, "potentielD")}
                                            />
                                        </td>
                                    </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>

                    <PopupConfirmation message={t('COMMON.CONFIRM_MSG')} handleSubmit={handleSubmit}/>
                    
                </div>
                }

            </section>

        </section>
    )
}