import { useState, useEffect, useContext } from 'react';

import { PopUpStoreContext } from '../../../store/store/storePopUp';
import { ActionsStoreContext } from '../../../store/store/storeActions';

import { UserPopUpManager } from '../PopUp/User/UserPopUpManager';
import { EtabPopUpManager } from '../PopUp/Etab/EtabPopUpManager';
import { MedicalProPopUpManager } from '../PopUp/MedicalPro/MedicalProPopUpManager';
import { MissionPopUpManager } from '../PopUp/Mission/MissionPopUpManager';

import { BtnSavePopUp } from '../../UI/Button/PopUp/BtnSavePopUp';
import { Notif } from './Notif/Notif';

import './index.scss'

export const PopUpManager = (props) => {
    const { type, displayTitle } = props;
    const { statePopUp, dispatchPopUp } = useContext(PopUpStoreContext);
    const { stateActions, dispatchActions } = useContext(ActionsStoreContext);
    const { dataPopUp, notif } = statePopUp;
    const { dataActions } = stateActions;

    const [memoPopUp, setMemoPopUp] = useState();

    useEffect(() => {
        setMemoPopUp(dataPopUp.action);
        if(dataPopUp.action !== '')
            if(dataPopUp.action !== memoPopUp && typeof memoPopUp !== 'undefined')
                dataPopUp.isActive = true;
            
    }, [dataPopUp.action]);

    const chooseTitle = () => {
        let optional = dataPopUp.optionalData;
        if(typeof displayTitle !== 'undefined' && typeof dataPopUp.action !== 'undefined')
            switch (dataPopUp.action) {
                case 'add':
                    return `Ajouter un${displayTitle.toLowerCase() === "mission" ? "e" : ""} ${displayTitle.toLowerCase()}`;

                case 'edit':
                    if(type === 'medicalProPage' && optional !== undefined)
                        return `Modification -  ${optional.reference} - ${optional.title} ${optional.firstName} ${optional.lastName}`;
                    
                    if(type === 'etab')
                        return `Modification -  ${dataActions.reference} - ${dataActions.name}`;

                    return `Modifier un ${displayTitle.toLowerCase()}`;

                case 'delete':
                    return `Supprimer un ${displayTitle.toLowerCase()}`;
                
                case 'visu':
                    if(type === 'medicalProPage' && optional === undefined)
                        return `${dataActions.reference} - ${dataActions.title} ${dataActions.firstName} ${dataActions.lastName}`;
                    
                    if(type === 'etab')
                        return `${dataActions.reference} - ${dataActions.name}`;
                    
                    return `${dataActions.name}`;
            
                default:
                    return false;
            }
    }

    const chooseDisplayContent = () => {
        if(typeof type !== 'undefined' && dataPopUp.isActive === true)
            switch (type) {
                case 'user':
                    return <UserPopUpManager data={dataPopUp} dispatchPop={dispatchPopUp}/>;
                case 'etab':
                    return <EtabPopUpManager data={dataPopUp} dispatchPop={dispatchPopUp}/>;
                case 'medicalProPage':
                    return <MedicalProPopUpManager data={dataPopUp} dispatchPop={dispatchPopUp}/>;
                case 'mission':
                    return <MissionPopUpManager data={dataPopUp} dispatchPop={dispatchPopUp}/>;
            
                default:
                    return false;
            }
        else
            return false;
    }

    const handleClosePopUp = () => {

        let dDataPopUp = {
            action: '',
            isActive: false,
            type: ''
        };
        dispatchPopUp({type: 'setPopUp', dDataPopUp})
        setMemoPopUp('')
    }
    
    return (
        <>
            {dataPopUp.isActive === true &&
                <div className={`containerPopUpBase modal ${type}`}>
                    
                    <div className="modal-dialog">
                        <div className="modal-content">

                            {/* MODAL HEADER */}
                            <div className="modal-header">
                                <h1 className="modal-title">
                                    {chooseTitle()}
                                </h1>
                            </div>

                            {/* MODAL BODY */}
                            {chooseDisplayContent()}

                            {/* MODAL FOOTER */}
                            <div className="modal-footer">
                                <BtnSavePopUp 
                                    type={type}
                                    action={dataPopUp.action}
                                    handleClosePopUp={() => handleClosePopUp()}
                                />
                                <button className="btn-cancel" onClick={() => handleClosePopUp()}>
                                    {dataPopUp.action === 'visu' ? 'Ok' : 'Annuler'}
                                </button>
                            </div>

                        </div>
                    </div>

                </div>
            }
            {notif.response &&
                <Notif 
                    response={notif.response}
                    code={notif.code}
                />
            }
        </>
    )
}