import { useState, useContext } from "react";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { PopUpStoreContext } from '../../store/store/storePopUp';
import { ActionsStoreContext } from '../../store/store/storeActions';

// API
import {
    getOnePDS
} from "../../store/index.service";

export const CallsLine = (props) => {

  const { data, spe } = props;

  const { statePopUp, dispatchPopUp } = useContext(PopUpStoreContext);
  const { dispatchActions } = useContext(ActionsStoreContext);
  const { dataPopUp } = statePopUp;

  const [details, setDetails] = useState(false);

  const showDetails = () => {
      const list = spe ? data.listPdsBySpe : data.listPdsByType;

      return (
        <div className="calls-details">
            {list.length > 0 ? list.filter(el => el.datapds !== null).map((pds) => {
                if(!(pds.callsRealized === 0 && pds.callsToBeRealized <= 0 && pds.nbrHorsCible === 0 && pds.horsCibleProduit === 0)) return (
                    <div
                        className="details-line"
                        key={`calls-${pds.datapds.id}`}
                        data-toggle="modal"
                        data-target="#modalAddEditPro"
                        onClick={() => showPDS(pds.datapds.id)}
                    >
                        <span className="calls-label">
                            {pds.datapds.firstName} {pds.datapds.lastName}<br/>
                            <span className="small">{pds.datapds.reference}</span>
                        </span>
                        <div className="calls-progress">
                            <ProgressBar
                                bgColor="#e6d307"
                                completed={pds.callsRealized}
                                remaining={pds.callsToBeRealized < 0 ? 0 : pds.callsToBeRealized}
                            />
                            {pds.nbrHorsCible > 0 && <span className="hc">{pds.nbrHorsCible}</span>}
                            {pds.horsCibleProduit > 0 && <span className="hcProd">{pds.horsCibleProduit}</span>}
                        </div>
                    </div>
                )
            })
            :
            <div className="details-line noPDS">
                <p>Aucun professionnel à afficher</p>
            </div>
            }
        </div>
      )
  }

  const showPDS = (id) => {
    getOnePDS(id).then(res => {
        const data = JSON.parse(res);

        let dDataPopUp = {
            ...dataPopUp,
            action: 'visu',
            isActive: !dataPopUp.isActive
        }
        let aDataActions = data ? {
            ...data,
            name: `${data.reference} - ${data.title} ${data.firstName} ${data.lastName}`
        } : {};

        dispatchPopUp({ type: 'setPopUp', dDataPopUp });
        dispatchActions({ type: 'setDataActions', aDataActions });
    });
  }

  return (
    <div className={`calls-line ${!details ? "align" : ""}`} onDoubleClick={() => setDetails(!details)}>
        <span className="calls-label">{data.name}</span>

        <div className="calls-content">
            <div className="calls-progress">
                <ProgressBar
                    bgColor={spe ? "#6dbf3c" : "#0073bd"}
                    completed={data.callsRealized}
                    remaining={data.callsToBeRealized < 0 ? 0 : data.callsToBeRealized}
                />
                {data.horsCibleProduit > 0 && <span className="hcProd">{data.horsCibleProduit}</span>}
                {data.nbrHorsCible > 0 && <span className="hc">{data.nbrHorsCible}</span>}
            </div>

            {details && showDetails()}
        </div>
    </div>
  );
};