import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import "./ReseauxProduits.scss";
import { t } from "../../../i18nFunctions";
import { NotificationManager } from 'react-notifications';
import { StoreContext } from "../../../../store/store";

// components
import { ButtonAdd } from '../../../../components/UI/Button/ButtonAdd/ButtonAdd';
import { PopupDelete } from '../../../../components/Popups/PopupDelete';
import { PopupConfirmation } from '../../../../components/Popups/PopupConfirmation';
import { CustomSelect } from '../../../../components/UI/CustomSelect/CustomSelect';
import { PopupResProd } from '../../../../components/Popups/Reseaux/PopupResProd';
import { PopupConfig } from '../../../../components/Popups/Reseaux/PopupConfig';
import { ProdResume } from './ProdResume';
import { ProdConfig } from './ProdConfig';

// api
import {
    getAllCountries,
    getAllSpecialite,
    getAllProType,
    getAllProducts,
    getLaboProduct,

    getNetworkResumes,
    getProdsByConfig,
    postResProd,

    getConfigCountry,
    postConfigCountry,

    getConfigProtype,
    postConfigProtype,

    getConfigSpe,
    postConfigSpe,

    editResProd,
    deleteResProd,
    changeOrdreResProd
} from '../../../../store/index.service';

export const ReseauxProduits = () => {

    const { idRes, nameRes } = useParams();

    const { setLoadingStore } = useContext(StoreContext);

    const [listProducts, setListProducts] = useState([]);
    const [selectedProd, setSelectedProd] = useState({});
    const [idDelete, setIdDelete] = useState(-1);
    const [prod1, setProd1] = useState(null);
    const [config, setConfig] = useState("resume");
    const [loadingAllProd, setLoadingAllProd] = useState(false);
    const [confirmSubmit, setConfirmSubmit] = useState(() => () => {});

    const [resumeCountry, setResumeCountry] = useState({});
    const [resumeProtype, setResumeProtype] = useState({});
    const [resumeSpe, setResumeSpe] = useState({});

    const [prodListDefault, setProdListDefault] = useState([]);
    const [prodListCountry, setProdListCountry] = useState([]);
    const [prodListProtype, setProdListProtype] = useState([]);
    const [prodListSpe, setProdListSpe] = useState([]);

    const [configPaysList, setConfigPaysList] = useState([]);
    const [configTypeList, setConfigTypeList] = useState([]);
    const [configSpeList, setConfigSpeList] = useState([]);

    const [countryList, setCountryList] = useState([]);
    const [idCountry, setIdCountry] = useState(-1);
    const [nameCountry, setNameCountry] = useState("Par pays");

    const [pdsTab, setPdsTab] = useState([]);
    const [idPDS, setIdPDS] = useState(-1);
    const [namePDS, setNamePDS] = useState("Par type");

    const [speTab, setSpeTab] = useState([]);
    const [idSpe, setIdSpe] = useState(-1);
    const [nameSpe, setNameSpe] = useState("Par spécialité");

    // mounted
    useEffect(() => {
        setLoadingStore(true);

        Promise.all([
            getAllCountries().then(res => {
                const data = JSON.parse(res);
                if(data) setCountryList(data);
            }),
            getAllSpecialite().then(res => {
                const data = JSON.parse(res);
                if(data.listSpeciality) setSpeTab(data.listSpeciality);
            }),
            getAllProType().then(res => {
                const data = JSON.parse(res);
                if(data) setPdsTab(data);
            }),

            handleGetNetworkResumes(),
            handleGetAllProducts(),
            handleGetProdsByConfig("default", undefined, false),

            handleGetConfigCountry(),
            handleGetConfigProtype(),
            handleGetConfigSpe()

        ]).then(() => setLoadingStore(false));
    }, []);

    // updates
    useEffect(() => {
        if(config === "default") handleGetProdsByConfig(config);
    }, [config]);

    const handleGetNetworkResumes = () => {
        getNetworkResumes(idRes).then(res => {
            const data = JSON.parse(res);

            if(data.countryResume) setResumeCountry(data.countryResume);
            else setResumeCountry({});

            if(data.protypeResume) setResumeProtype(data.protypeResume);
            else setResumeProtype({});

            if(data.specialityResume) setResumeSpe(data.specialityResume);
            else setResumeSpe({});
        });
    }

    const handleGetProdsByConfig = (configType, configId, loading = true) => {
        if(loading) setLoadingStore(true);

        getProdsByConfig(idRes, configType, configId).then(res => {
            const data = JSON.parse(res);

            switch(configType) {
                case "default":
                    if(data) setProdListDefault(data);
                    else setProdListDefault([]);
                    break;
                case "country":
                    if(data) setProdListCountry(data);
                    else setProdListCountry([]);
                    break;
                case "protype":
                    if(data) setProdListProtype(data);
                    else setProdListProtype([]);
                    break;
                case "speciality":
                    if(data) setProdListSpe(data);
                    else setProdListSpe([]);
                    break;
                default:
                    break;
            }

            if(loading) setLoadingStore(false);
        });
    }

    const handleGetConfigCountry = () => {
        getConfigCountry(idRes).then(res => {
            const data = JSON.parse(res);

            if(data) setConfigPaysList(data);
            else setConfigPaysList([]);
        });
    }

    const handleGetConfigProtype = () => {
        getConfigProtype(idRes).then(res => {
            const data = JSON.parse(res);

            if(data) setConfigTypeList(data);
            else setConfigTypeList([]);
        });
    }

    const handleGetConfigSpe = () => {
        getConfigSpe(idRes).then(res => {
            const data = JSON.parse(res);

            if(data) setConfigSpeList(data);
            else setConfigSpeList([]);
        });
    }

    const handleGetAllProducts = (value, lab) => {
        setLoadingAllProd(true);

        if(lab)
            getLaboProduct(lab.value, { pagination: false })
                .then(res => {
                    const data = JSON.parse(res);

                    if(data && data.listProduct) setListProducts(data.listProduct);
                    else setListProducts([]);
                })
                .finally(() => setLoadingAllProd(false));
        else
            getAllProducts({
                page: 1,
                maxPerPage: 50,
                name: value
            })
                .then(res => {
                    const data = JSON.parse(res);

                    if(data.listProduct) setListProducts(data.listProduct);
                    else setListProducts([]);
                })
                .finally(() => setLoadingAllProd(false));
    }

    const handleChangeOrdreResProd = (p, id, conf) => {
        changeOrdreResProd(p, id, conf).then(() => {
            NotificationManager.success('', "Ordre modifié");

            const configId =
                config === "country" ? idCountry
                : config === "protype" ? idPDS
                : config === "speciality" ? idSpe
                : undefined;

            handleGetProdsByConfig(conf, configId);
            handleGetNetworkResumes();

            setProd1(null);

        }).catch(() => NotificationManager.error('', "Error"));
    }

    return(
        <section className="content">
        
            <section className="content-header">
                <h1>
                    Réseau {decodeURIComponent(nameRes)}
                    <small>Configuration des produits</small>
                </h1>
            </section>
        
            <section className="content resProd">

                <div className="box info reseauxProd">
                    <p>
                        {config === "resume" ?
                        "Résumé par pays"
                        : config === "default" ?
                        "Liste des produits"
                        : config === "country" ?
                        "Liste des produits par pays"
                        : config === "protype" ?
                        "Liste des produits par type"
                        : config === "speciality" ?
                        "Liste des produits par spécialité"
                        :
                        "Liste des produits"
                        }
                    </p>
                    <form>
                        <div className="radio">
                            <input
                            type="radio"
                            name="filtre"
                            checked={config === "resume"}
                            value="resume"
                            onChange={e => {
                                setConfig(e.target.value);
                                setProd1(null);
                            }}
                            id="filtreResume"
                            />
                            <label htmlFor="filtreResume">Résumé</label>
                        </div>

                        <div className="radio">
                            <input
                            type="radio"
                            name="filtre"
                            checked={config === "default"}
                            value="default"
                            onChange={e => {
                                setConfig(e.target.value);
                                setProd1(null);
                            }}
                            id="filtreDefaut"
                            />
                            <label htmlFor="filtreDefaut">Par défaut</label>
                        </div>

                        <div className="radio">
                            <input
                            type="radio"
                            name="filtre"
                            checked={config === "country"}
                            value="country"
                            onChange={e => {
                                setConfig(e.target.value);
                                setProd1(null);
                            }}
                            id="filtrePays"
                            />
                            {config === "country" ? (
                                <CustomSelect
                                defaultText={nameCountry}
                                optionsList={configPaysList}
                                handleOptionClick={(id, name) => {
                                    handleGetProdsByConfig(config, id);
                                    handleGetProdsByConfig("default", id, false);
                                    setIdCountry(id);
                                    setNameCountry(name);
                                }}
                                />
                            ) : (
                                <label htmlFor="filtrePays">Par pays</label>
                            )}
                        </div>

                        <div className="radio">
                            <input
                            type="radio"
                            name="filtre"
                            checked={config === "protype"}
                            value="protype"
                            onChange={e => {
                                setConfig(e.target.value);
                                setProd1(null);
                            }}
                            id="filtreType"
                            />
                            {config === "protype" ? (
                                <CustomSelect
                                defaultText={namePDS}
                                optionsList={configTypeList}
                                handleOptionClick={(id, name) => {
                                    handleGetProdsByConfig(config, id);
                                    setIdPDS(id);
                                    setNamePDS(name);
                                }}
                                />
                            ) : (
                                <label htmlFor="filtreType">Par type</label>
                            )}
                        </div>

                        <div className="radio">
                            <input
                            type="radio"
                            name="filtre"
                            checked={config === "speciality"}
                            value="speciality"
                            onChange={e => {
                                setConfig(e.target.value);
                                setProd1(null);
                            }}
                            id="filtreSpe"
                            />
                            {config === "speciality" ? (
                                <CustomSelect
                                defaultText={nameSpe}
                                optionsList={configSpeList}
                                handleOptionClick={(id, name) => {
                                    handleGetProdsByConfig(config, id);
                                    setIdSpe(id);
                                    setNameSpe(name);
                                }}
                                />
                            ) : (
                                <label htmlFor="filtreSpe">Par spécialité</label>
                            )}
                        </div>
                    </form>

                    {(config === "country" || config === "protype" || config === "speciality") &&
                        <ButtonAdd modal="#modalAddResConfigPays"/>
                    }
                </div>

                { config === "resume" ?
                    <div className="row reseauxProd">

                        <ProdResume
                            type="pays"
                            resume={resumeCountry}
                            condition={resumeCountry}
                            entete={countryList}
                            ordreLabel={"ordreCountry"}
                        />
                    
                        <div className="col-md-12">
                            <div className="box info reseauxProd">
                                <p>Résumé par spécialité</p>
                            </div>
                        </div>

                        <ProdResume
                            type="spécialités"
                            resume={resumeSpe}
                            condition={configSpeList}
                            entete={configSpeList}
                            ordreLabel={"ordreSpeciality"}
                        />
                    
                        <div className="col-md-12">
                            <div className="box info reseauxProd">
                                <p>Résumé par type de PDS</p>
                            </div>
                        </div>

                        <ProdResume
                            type="types de PDS"
                            resume={resumeProtype}
                            condition={configTypeList}
                            entete={configTypeList}
                            ordreLabel={"ordreMedicalProType"}
                        />
                    
                    </div>
                : config === "default" ?
                    <ProdConfig
                        prodList={prodListDefault}
                        prod1={prod1}
                        updateSelectedProd={(prod) => setSelectedProd(prod)}
                        handleMove={(e, idNetProd) => {
                            if(prod1 === null) setProd1(idNetProd);
                            else if(prod1 === idNetProd) setProd1(null);
                            else {
                                e.target.blur();
                                handleChangeOrdreResProd(prod1, idNetProd, config);
                            }
                        }}
                        handleDelete={(id) => setIdDelete(id)}
                    />
                : config === "country" ?
                    <ProdConfig
                        prodList={prodListCountry}
                        prod1={prod1}
                        updateSelectedProd={(prod) => setSelectedProd(prod)}
                        handleMove={(e, idNetProd) => {
                            if(prod1 === null) setProd1(idNetProd);
                            else if(prod1 === idNetProd) setProd1(null);
                            else {
                                e.target.blur();
                                handleChangeOrdreResProd(prod1, idNetProd, config);
                            }
                        }}
                        handleDelete={(id) => setIdDelete(id)}
                    />
                : config === "protype" ?
                    <ProdConfig
                        prodList={prodListProtype}
                        prod1={prod1}
                        updateSelectedProd={(prod) => setSelectedProd(prod)}
                        handleMove={(e, idNetProd) => {
                            if(prod1 === null) setProd1(idNetProd);
                            else if(prod1 === idNetProd) setProd1(null);
                            else {
                                e.target.blur();
                                handleChangeOrdreResProd(prod1, idNetProd, config);
                            }
                        }}
                        handleDelete={(id) => setIdDelete(id)}
                    />
                : config === "speciality" &&
                    <ProdConfig
                        prodList={prodListSpe}
                        prod1={prod1}
                        updateSelectedProd={(prod) => setSelectedProd(prod)}
                        handleMove={(e, idNetProd) => {
                            if(prod1 === null) setProd1(idNetProd);
                            else if(prod1 === idNetProd) setProd1(null);
                            else {
                                e.target.blur();
                                handleChangeOrdreResProd(prod1, idNetProd, config);
                            }
                        }}
                        handleDelete={(id) => setIdDelete(id)}
                    />
                }

            </section>

            <PopupResProd
                resProd={selectedProd}
                isLoading={loadingAllProd}
                config={config}

                listProducts={config !== "default" ? prodListDefault.map(prod => ({
                    idProduct: prod.product.idProduct,
                    nameProduct: prod.product.nameProduct
                })) : listProducts}

                handleSubmit={submit => setConfirmSubmit(() => () => submit())}
                searchProduct={(value, lab) => config === "default" && handleGetAllProducts(value, lab)}

                postResProd={(id, pres) => {
                    const configId =
                        config === "country" ? idCountry
                        : config === "protype" ? idPDS
                        : config === "speciality" ? idSpe
                        : undefined;

                    postResProd(idRes, {
                        id: id,
                        pres: pres,
                        configType: config,
                        configId: configId
                    }).then(() => {
                        NotificationManager.success('', "Produit ajouté");
    
                        handleGetProdsByConfig(config, configId);

                        if(config !== "default")
                            handleGetNetworkResumes();
    
                    }).catch(() => NotificationManager.error('', "Error"))
                }}

                editResProd={(id, pres) => editResProd(id, pres, config).then(() => {
                    NotificationManager.success('', "Produit modifié");

                    const configId =
                        config === "country" ? idCountry
                        : config === "protype" ? idPDS
                        : config === "speciality" ? idSpe
                        : undefined;

                    handleGetProdsByConfig(config, configId);

                }).catch(() => NotificationManager.error('', "Error"))}
            />

            <PopupConfig
                list={
                    config === "country" ? countryList
                    : config === "protype" ? pdsTab
                    : config === "speciality" ? speTab
                    : []
                }
                config={config}
                postConfig={id => {
                    const setter =
                        config === "country" ? postConfigCountry
                        : config === "protype" ? postConfigProtype
                        : config === "speciality" ? postConfigSpe
                        : () => {};
                    
                    setter(idRes, id).then(() => {
                        NotificationManager.success('', `Configuration par ${
                            config === "country" ? "pays"
                            : config === "protype" ? "type de PDS"
                            : config === "speciality" ? "spécialité"
                            : ""
                        } ajoutée`);

                        switch(config) {
                            case "country":
                                handleGetConfigCountry();
                                break;
                            case "protype":
                                handleGetConfigProtype();
                                break;
                            case "speciality":
                                handleGetConfigSpe();
                                break;
                            default:
                                break;
                        }
    
                        handleGetNetworkResumes();
    
                    }).catch(() => NotificationManager.error('', "Error"))
                }}
            />

            <PopupDelete
                title="Supprimer un produit"
                id={idDelete}
                handleDelete={id => deleteResProd(id, config).then(() => {
                    NotificationManager.success('', "Produit supprimé");
                    
                    const configId =
                        config === "country" ? idCountry
                        : config === "protype" ? idPDS
                        : config === "speciality" ? idSpe
                        : undefined;

                    handleGetProdsByConfig(config, configId, config !== "default");
                    handleGetNetworkResumes();

                    if(config === "default") {
                        setLoadingStore(true);

                        Promise.all([
                            handleGetProdsByConfig("country", idCountry, false),
                            handleGetProdsByConfig("protype", idPDS, false),
                            handleGetProdsByConfig("speciality", idSpe, false)
                        ]).then(() =>
                            setLoadingStore(false)
                        );
                    }

                }).catch(() => NotificationManager.error('', "Error"))}
            />

            <PopupConfirmation message={t('COMMON.CONFIRM_MSG')} handleSubmit={confirmSubmit}/>

        </section>
    )
}