export const ProgressBar = (props) => {
  const { bgColor, completed, remaining } = props;

  const percent = (100 * completed)/(completed + remaining);

  return (
    <div className="progress-bar-custom">
      <div
        className="progress-filler"
        style={{
          width: `${(remaining <= 0 && completed === 0) ? "0" : remaining <= 0 ? "100" : percent}%`,
          backgroundColor: bgColor
        }}
      >
        <span>{completed}</span>
      </div>
      <div
        className="progress-remaining"
        style={{ width: `${remaining === 0 && completed === 0 ? "100" : 100-percent}%` }}
      >
          <span>{(remaining > 0 || (remaining === 0 && completed === 0)) && remaining}</span>
      </div>
    </div>
  );
};