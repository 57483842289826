import { useState, useEffect, useContext } from 'react';
import './Etablissements.scss';
import { t } from '../i18nFunctions';

import { FilterStoreContext } from '../../store/store/storeFilters';
import { ListStoreContext } from '../../store/store/storeList';
import { ActionsStoreContext } from '../../store/store/storeActions';
import { StoreContext } from '../../store/store';

import { Pagination } from '../../components/Pagination/Pagination';

import { TableManager } from '../../components/UI/Table/TableManager';
import { PopUpManager } from '../../components/UI/PopUp/PopUpManager';
import { SearchFilter } from '../../components/UI/SearchFilter/SearchFilter';

import {
    getEtablissements,
	postEtablissement,
    editEtablissement,
    getOneInstitutionEmployee,
    verifEtablissement,

    getAllCountries,
    getTypeEtab,
    getAllMissions,
    getAllZonesGeo
} from '../../store/index.service';

export const Etablissements = () => {
	const { stateFilter, dispatchFilters } = useContext(FilterStoreContext);
	const { stateList, dispatchList } = useContext(ListStoreContext);
	const { dispatchActions } = useContext(ActionsStoreContext);
	const { setLoadingStore } = useContext(StoreContext);

	let [maxPage, setMaxPage] = useState(0);
    let [page, setPage] = useState(1);
	let [nbResultats, setNbResultats] = useState(0);
	let [maxPerPage, setMaxPerPage] = useState(20);
	let [userCountries, setUserCountries] = useState(JSON.parse(localStorage.getItem('userCountries')));
	let [mounted, setMounted] = useState(false);

    let [countrySearchState, setCountrySearchState] = useState({
        id: userCountries[0].id,
        name: userCountries[0].name
    });

    const handleGetAllEtabs = (params, isStart = false) => {
        setLoadingStore(true);

        getEtablissements(params).then(res => {
            const data = JSON.parse(res);
            let dDataList = data.listInstitution;
            let mSearchList = data.listInstitution;

            setNbResultats(data.nbResultats);
            setMaxPerPage(data.maxPerPage);
            setMaxPage(data.nbrPages);

            dispatchFilters({type: 'setMemoSearchList', mSearchList });
            dispatchList({type: 'setList', dDataList });

            setLoadingStore(false);
        });

        if(params.countryName !== null) {
            if(isStart !== false)
                params = {
                    country: params.country,
                    countryName: params.countryName
                };
            
            getAllZonesGeo(params).then(res => {
                const data = JSON.parse(res);
                let dDataZoneGeos = Object.values(data.listZoneGeo)[0];
                dispatchList({type: 'setDataZoneGeos', dDataZoneGeos });
            });
            getAllMissions(params).then(res => {
                const data = JSON.parse(res);
                let dDataMissions = data.listMission;
                dispatchList({type: 'setDataMissions', dDataMissions });
            });
        }
        if(isStart !== false) {
            getAllCountries().then(res => {
                const data = JSON.parse(res);
                let dDataCountries = data;
                dispatchList({type: 'setDataCountries', dDataCountries });
            });
            getTypeEtab().then(res => {
                const data = JSON.parse(res);
                let dDataTypeEtabs = data;
                dispatchList({type: 'setDataTypeEtabs', dDataTypeEtabs });
            });
        }
    }
    
	let allActions = {
		editAction: editEtablissement,
		addAction: postEtablissement,
		getAll: handleGetAllEtabs,
        getAllZG: getAllZonesGeo,
        getOneInstitEmp: getOneInstitutionEmployee,
        verifEtab: verifEtablissement,
	}

    useEffect(() => {
        dispatchActions({type: 'initAllActions', allActions});
        dispatchList({type: 'setList', dDataList: [] });
        setMounted(true);
    }, []);

    useEffect(() => {

        if(stateFilter.memoSearchCountry !== null && stateFilter.memoSearchCountry.country !== null)
            return setCountrySearchState({
                id: stateFilter.memoSearchCountry.value,
                name: stateFilter.memoSearchCountry.label
            });
        
    }, [stateFilter.memoSearchCountry])
	
	useEffect(() => {
		let params = {
			page: page,
			maxPerPage: maxPerPage
		}
        let mSearchCountry = { 
            country: countrySearchState.id,
            countryName: countrySearchState.name
        };
        if(!stateFilter.memoSearchCountry){
            dispatchFilters({type: 'setMemoSearchCountry', mSearchCountry})
        }
        else {
            setCountrySearchState({
                id: stateFilter.memoSearchCountry.country,
                name: stateFilter.memoSearchCountry.countryName
            });
        }
		
        let dDataPagination = params;
		let sOrderByKey = {};

		dispatchList({type: 'setDataPagination', dDataPagination });
        dispatchFilters({type: 'setOrderByKey', sOrderByKey });	

        if(stateList.dataParamSearch) {
            let dataCountryState = {
                country: countrySearchState.id,
                countryName: countrySearchState.name
            };
            
            if(mounted)
                params = {
                    ...params,
                    ...stateList.dataParamSearch
                };
            
            params = {
                ...params,
                ...(stateFilter.memoSearchCountry && stateFilter.memoSearchCountry.country ? stateFilter.memoSearchCountry : dataCountryState),
            }

            console.log(stateFilter.memoSearchCountry, dataCountryState, params)
        }

        
        
        return handleGetAllEtabs(params, true);
	}, [page]);
	
	const dataHeader = [
		{
			name: 'reference', 
			displayName: t('INSTITUTION.LIST.HEADER.REF'), 
			isFilterable: true, 
		},
		{
			name: 'name', 
			displayName: t('INSTITUTION.LIST.HEADER.NAME'), 
			isFilterable: true, 
		},
		{
			name: 'nameInstitutionType', 
			displayName: t('INSTITUTION.LIST.HEADER.TYPE'),
		},
		{
			name: 'addresszoneGeo', 
			displayName: t('INSTITUTION.LIST.HEADER.LOCALISATION'),
		},
		{
			name: 'isPrivate', 
			displayName: t('INSTITUTION.LIST.HEADER.PRIVATE_PUBLIC'),
		},
		{
			name: 'status', 
			displayName: t('INSTITUTION.LIST.HEADER.STATUS'),
		},
	];

    const searchFilterParams = [
        {
            name: 'country',
            displayName: t('INSTITUTION.LIST.SEARCH_BOX.FORM.COUNTRY'),
            placeholder: t('INSTITUTION.LIST.SEARCH_BOX.FORM.COUNTRY'),
            restrict: [
                'ROLE_DELEGUE',
                "ROLE_SUPERVISEUR",
                "ROLE_DSM",
                "ROLE_MONITEUR"
            ],
            type: 'select',
            data: JSON.parse(localStorage.getItem('userCountries'))
        },
        {
            name: 'mission',
            displayName: t('INSTITUTION.LIST.SEARCH_BOX.FORM.MISSION'),
            placeholder: t('INSTITUTION.LIST.SEARCH_BOX.FORM.MISSION_PH'),
            restrict: false,
            type: 'select',
            data: stateList.dataMissions
        },
        {
            name: 'label',
            displayName: t('INSTITUTION.LIST.SEARCH_BOX.FORM.NAME'),
            placeholder: t('INSTITUTION.LIST.SEARCH_BOX.FORM.NAME_PLACEHOLDER'),
            restrict: false,
            type: 'text',
        },
        {
            name: 'type',
            displayName: t('INSTITUTION.LIST.SEARCH_BOX.FORM.TYPE'),
            placeholder: t('INSTITUTION.LIST.SEARCH_BOX.FORM.TYPE_PLACEHOLDER'),
            restrict: false,
            type: 'select',
            data: stateList.dataTypeEtabs
        },
        {
            name: 'zoneGeo',
            displayName: t('INSTITUTION.LIST.SEARCH_BOX.FORM.GEOGRAPHICAL_AREA'),
            placeholder: t('INSTITUTION.LIST.SEARCH_BOX.FORM.GEOGRAPHICAL_AREA_PLACEHOLDER'),
            restrict: false,
            type: 'select',
            data: stateList.dataZoneGeos
        }
    ];

    const role = localStorage.getItem('role');

    return (
        <>
            <section className="content-header">
                <h1>
                    {t('INSTITUTION.LIST.TITLE')}
                    <small>{t('INSTITUTION.LIST.SUBTITLE')}</small>
                </h1>
            </section>
            <section className="etabContainer">
                <SearchFilter type="etab" dataFilter={searchFilterParams} title={t('INSTITUTION.LIST.SEARCH_BOX.TITLE')}/>

                <section className="content" id="listEtablissements">            
                    <section className="sectionTable etab">
                        <TableManager 
                            dataHeader={dataHeader} 
                            actionsList={role !== "ROLE_COORDINATEUR" ? ['edit'] : []}
                            type="etab"
                            isAdd={role !== "ROLE_COORDINATEUR"}
                        />
                        <br/>
                        <Pagination
                            maxPage={maxPage}
                            onChangePage={(data) => setPage(data)}
                            nbCount={nbResultats}
                        ></Pagination>
                    </section>

                    <PopUpManager 
                        type="etab"
                        displayTitle="établissement"
                    />

                </section>
            </section>
        </>
	)
}
