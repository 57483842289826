import { useContext } from "react";
import { t } from "../../pages/i18nFunctions";
import { PopUpStoreContext } from '../../store/store/storePopUp';
import { ActionsStoreContext } from '../../store/store/storeActions';

// API
import {
    getOnePDS
} from "../../store/index.service";

export const DraggablePDS = ({ pro, pdsDragged, setPdsDragged, calendar, tag, tagLabel }) => {

    const { statePopUp, dispatchPopUp } = useContext(PopUpStoreContext);
    const { dispatchActions } = useContext(ActionsStoreContext);
    const { dataPopUp } = statePopUp;

    const voirPDS = () => {
        getOnePDS(pro.id).then(res => {
            const data = JSON.parse(res);

            let dDataPopUp = {
                ...dataPopUp,
                action: 'visu',
                isActive: !dataPopUp.isActive
            }
            let aDataActions = data ? {
                ...data,
                name: `${data.reference} - ${data.title} ${data.firstName} ${data.lastName}`
            } : {};
    
            dispatchPopUp({ type: 'setPopUp', dDataPopUp });
            dispatchActions({ type: 'setDataActions', aDataActions });
        });
    }

    return(
        <div
            className="draggable-pds col-md-12"
            onClick={voirPDS}
        >
            <div
                className="row"
                data-toggle="modal"
                data-target="#modalAddEditPro"
            >
                <div className="col-md-12">
                    <span className="info-pastille pull-right">?</span>
                </div>
            </div>

            <div
                className="row"
                data-toggle="modal"
                data-target="#modalAddEditPro"
            >
                <div className="col-md-3">
                    <span className={`tag ${tag}`}>{tagLabel}</span>
                </div>

                <div className="col-md-9">
                    <h3>{pro.title === "Docteur" || pro.title === "Doctor" ? "Dr" : pro.title} {pro.firstName} {pro.lastName}</h3>
                    <small>
                        {pro.medicalProType.nameMedicalProType}<br/>
                        {pro.medicalProsInstitutions.length > 0 ? pro.medicalProsInstitutions[0].nameInstitution : null}
                    </small>
                </div>
            </div>

            <div className="row" onClick={(e) => e.stopPropagation()}>
                <div className="col-md-12">
                    <span 
                        className="choisir-pds"
                        onClick={() => {
                            if(pro.id === pdsDragged.id)
                                setPdsDragged({});
                            else {
                                calendar.scrollIntoView({ behavior: "smooth" });
                                setPdsDragged(pro);
                            }
                        }}
                        active={`${pro.id === pdsDragged.id}`}
                    >
                        + {t('GEN.BUTTON.CHOOSE')}
                    </span>
                </div>
            </div>
        </div>
    )
}