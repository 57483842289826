import React, { useReducer, createContext } from 'react';

export const initialActionsState = {
    editAction: {},
    addAction: {},
    deleteAction: {},
    lockAction: {},
    dataActions: {}
}

export const ActionsStoreContext = createContext(initialActionsState);

export function reducer(state, action) {
    switch (action.type) {
        case 'setDataActions':
            return {...state, dataActions: action.aDataActions };
        case 'initAllActions':
            return {...state, ...action.allActions };
            
        case 'setEditAction':
            return {...state, editAction: action.edtAction};
        case 'setAddAction':
            return {...state, addAction: action.adAction};
        case 'setDeleteAction':
            return {...state, deleteAction: action.dltAction};
        case 'setLockAction':
            return {...state, lockAction: action.lckAction};
        default:
            throw new Error();
    } 
}

export const ActionsStoreContainer = ({children}) => {
    const [stateActions, dispatchActions] = useReducer(reducer, initialActionsState);

    return (
        <ActionsStoreContext.Provider value={{stateActions, dispatchActions}}>
            {children}
        </ActionsStoreContext.Provider>
    )
}
