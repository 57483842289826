import { useState, useEffect, useRef } from 'react';
import { ButtonAdd } from '../../UI/Button/ButtonAdd/ButtonAdd';
import { ListRowQuestion } from '../../../pages/Parametres/Laboratoires/ListRowQuestion';
import { PopupAddEditQuestions } from './PopupAddEditQuestions';
import { NotificationManager} from 'react-notifications';
import ReactLoading from 'react-loading';

import {
    getAllQuestions,
    postQuestion,
    editQuestion,
    disableQuestion
} from '../../../store/index.service';

export const PopupQuestions = (props) => {
    const { selectedProd, handleSubmit, getLaboProduct } = props;

    const [listQ, setListQ] = useState([]);
    const [editedQuestion, setEditedQuestion] = useState({});
    const [loadingQ, setLoadingQ] = useState(false);

    const edit = Object.keys(selectedProd).length > 0;
    const btnRef = useRef(null);

    // updates
    useEffect(() => {
        if(edit) handleGetAllQuestions();
    }, [selectedProd]);

    const handleGetAllQuestions = () => {
        setLoadingQ(true);
        getAllQuestions(selectedProd.idProduct).then(res => {
            const data = JSON.parse(res);
            if(data) setListQ(data);
            else setListQ([]);
            setLoadingQ(false);
        });
    }

    return(
    <>
        <div className="modal fade in" id="modalQuestions" tabIndex="-1" role="dialog" aria-labelledby="modalQuestionsLabel" animate="animate">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title" id="myModalLabel">{edit ? selectedProd.nameProduct : ""} - Liste des questions</h1>
                    </div>
                    <div className="modal-body">
                        <table className="box tab-entete questions">
                            <tbody>
                                <tr>
                                    <td className="quest">
                                        <div>
                                            <p>Question</p>
                                        </div>
                                    </td>
                                    <td className="center stat">
                                        <div>
                                            <p>Statut</p>
                                        </div>
                                    </td>
                                    <td className="last">
                                        <ButtonAdd
                                        modal="#modalAddEditQuestion"
                                        handleAdd={() => setEditedQuestion({})}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="list questions">
                            <tbody>
                                { loadingQ ?
                                <tr><td style={{display: "inline-flex", justifyContent: "center", width: "100%"}}>
                                    <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader questions" />
                                </td></tr>
                                : listQ.map(q =>
                                    <ListRowQuestion
                                    key={q.id}
                                    item={q}
                                    handleQuestionEdit={(quest) => setEditedQuestion(quest)}
                                    disableQuestion={(id) => disableQuestion(id).then(() => {
                                        handleGetAllQuestions();
                                        NotificationManager.success('', "Question activée/désactivée");
                                    }).catch(() => NotificationManager.error('', "Erreur"))}
                                    />    
                                )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button
                        type="button"
                        className="btn-cancel"
                        data-dismiss="modal"
                        ref={btnRef}
                        >Fermer</button>
                    </div>
                </div>
            </div>
        </div>

        <PopupAddEditQuestions
            handleSubmit={handleSubmit}
            addQuestion={(text, min, max, type, spe, proType, answer) => postQuestion({
                idProd: selectedProd.idProduct,
                text: text,
                min: min,
                max: max,
                type: type,
                spe: spe,
                proType: proType,
                answer: answer
            }).then(() => {
                handleGetAllQuestions();
                getLaboProduct();
                NotificationManager.success('', "Question ajoutée");
            }).catch(() => NotificationManager.error('', "Erreur"))}
            question={editedQuestion}
            editQuestion={(text, min, max, type, spe, proType, answer) => editQuestion({
                idQ: editedQuestion.id,
                text: text,
                min: min,
                max: max,
                type: type,
                spe: spe,
                proType: proType,
                answer: answer
            }).then(() => {
                handleGetAllQuestions();
                NotificationManager.success('', "Question modifiée");
            }).catch(() => NotificationManager.error('', "Erreur"))}
            getAllQuestions={handleGetAllQuestions}
        />

    </>
    )
}