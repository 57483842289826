import { TableHeadManager } from '../Table/Head/TableHeadManager';
import { TableBodyManager } from '../Table/Body/TableBodyManager';

import './index.scss'

export const TableManager = (props) => {
    const { dataHeader, actionsList, type, isAdd, action = null, handleFiltersTable } = props;

    return (
        <table>
            <TableHeadManager 
                dataHeader={dataHeader}
                type={type}
                isAdd={isAdd}
            />
            <TableBodyManager 
                dataHeader={dataHeader}
                actionsList={actionsList}
                type={type}
                isAdd={isAdd}
                handleFiltersTable={handleFiltersTable}
            />
        </table>
    )
}