import { useState, useEffect, useContext } from 'react';
import "./Organigramme.scss";
import { t } from "../../i18nFunctions";
import { NotificationManager } from 'react-notifications';
import { StoreContext } from "../../../store/store";

// components
import { PopupDelete } from '../../../components/Popups/PopupDelete';
import { PopupOrga } from '../../../components/Popups/Organigramme/PopupOrga';
import { PopupConfirmation } from '../../../components/Popups/PopupConfirmation';
import { ButtonAdd } from '../../../components/UI/Button/ButtonAdd/ButtonAdd';
import { ButtonExport } from '../../../components/UI/Button/ButtonExport/ButtonExport';
import { OrgaItem } from '../../../components/OrgaItem/OrgaItem';

// api
import {
    getOrga,
    postOrga,
    editOrga,
    deleteOrga,
    moveOrga,
    changeUser,
    removeUser,

    getSimpleUsers
} from '../../../store/index.service';

export const Organigramme = () => {

    const [usersList, setUsersList] = useState([]);
    const [orga, setOrga] = useState([]);
    const [idDelete, setIdDelete] = useState(-1);
    const [selectedPoste, setSelectedPoste] = useState({});
    const [initialInput, setInitialInput] = useState("");
    const [search, setSearch] = useState("");
    const [poste1, setPoste1] = useState(null);
    const [confirmSubmit, setConfirmSubmit] = useState(() => () => {});

    const { setLoadingStore } = useContext(StoreContext);

    // mounted
    useEffect(() => {
        handleGetOrga();

        getSimpleUsers().then(res => {
            const data = JSON.parse(res);
            if(data.listUser)
                setUsersList(data.listUser);
        });
    }, []);

    const handleGetOrga = () => {
        setLoadingStore(true);

        getOrga().then(res => {
            const data = JSON.parse(res);

            if(data)
                setOrga(data);
            else
                setOrga([]);

            setLoadingStore(false);
        });
    }

    const updateOrga = (apiRoute, id, value, msg) => {
        apiRoute(id, value).then(res => {
            const data = JSON.parse(res);
            
            if(data.code === "900" || data.code === 900)
                NotificationManager.error('', data.response);
            else {
                NotificationManager.success('', `Poste ${msg}`);
                handleGetOrga();
                if(msg === "déplacé") setPoste1(null);
            }
        }).catch(() => NotificationManager.error('', "Error"));
    }

    const afficheList = () => {
        const zone = [...orga];
        let table = [];

        if(zone.length > 0){
            zone.map((item, i) => {

                let verifVille = false;
                let villes = [];

                Object.keys(zone[i]).map((ville, j) => {

                    let verifCommunes = false;
                    let communes = [];
                    const elementVille = zone[i][ville];

                    if(typeof elementVille === "object" && ville !== "delegue") {

                        Object.keys(elementVille).map((com, l) => {
                            
                            let verifQuartiers = false;
                            let quartiers = [];
                            const elementCom = elementVille[com];

                            if(typeof elementCom === "object" && com !== "delegue") {

                                Object.keys(elementCom).map((quart, m) => {
                                    
                                    let verifLasts = false;
                                    let lasts = [];
                                    const elementQuart = elementCom[quart];
                                    
                                    if(typeof elementQuart === "object" && quart !== "delegue") {

                                        Object.keys(elementQuart).map((last, n) => {

                                            const elementLast = elementQuart[last];

                                            if(typeof elementLast === "object" && last !== "delegue") {

                                                const fullName = elementLast.delegue.fullname;

                                                if(
                                                    ( elementLast.delegue.idDelegue !== null ? 
                                                        (
                                                            fullName.toLowerCase().indexOf(search.toLowerCase()) !== -1 
                                                            ||
                                                            elementLast.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                                                        )
                                                    :
                                                        elementLast.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                                                    )
                                                ) {
                                                    verifLasts = true;

                                                    lasts.push(
                                                        <OrgaItem
                                                            key={`${i}_${ville}_${com}_${quart}_l${n}`}
                                                            item={elementLast}
                                                            initialInput={initialInput}
                                                            fullName={fullName}
                                                            poste1={poste1}
                                                            moveDisabled={poste1 === elementQuart.id || poste1 === zone[i].id}
                                                            children={[]}
                                                            noPlus={true}

                                                            onInputChange={(value) => {
                                                                let t = [...zone];
                                                                t[i][ville][com][quart][last].name = value;
                                                                setOrga(t);
                                                            }}
                                                            editOrga={(id, value, msg) => updateOrga(editOrga, id, value, msg)}
                                                            resetInput={() => setInitialInput(elementLast.name)}
                                                            setSelectedPoste={setSelectedPoste}
                                                            moveOrga={(id, value, msg) => updateOrga(moveOrga, id, value, msg)}
                                                            setIdDelete={setIdDelete}
                                                            setPoste1={setPoste1}
                                                        />
                                                    )
                                                }
                                            }
                                        });
                                        
                                        const fullName = elementQuart.delegue.fullname;

                                        if( verifLasts
                                            ||
                                            ( elementQuart.delegue.idDelegue !== null
                                            ? 
                                                (
                                                    fullName.toLowerCase().indexOf(search.toLowerCase()) !== -1
                                                    ||
                                                    elementQuart.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                                                )
                                            :
                                                elementQuart.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                                            )
                                        ) {
                                            verifQuartiers = true;

                                            quartiers.push(
                                                <OrgaItem
                                                    key={`${i}_${ville}_${com}_q${m}`}
                                                    item={elementQuart}
                                                    initialInput={initialInput}
                                                    fullName={fullName}
                                                    poste1={poste1}
                                                    moveDisabled={poste1 === elementCom.id || poste1 === zone[i].id}
                                                    children={lasts}
    
                                                    onInputChange={(value) => {
                                                        let t = [...zone];
                                                        t[i][ville][com][quart].name = value;
                                                        setOrga(t);
                                                    }}
                                                    editOrga={(id, value, msg) => updateOrga(editOrga, id, value, msg)}
                                                    resetInput={() => setInitialInput(elementQuart.name)}
                                                    setSelectedPoste={setSelectedPoste}
                                                    moveOrga={(id, value, msg) => updateOrga(moveOrga, id, value, msg)}
                                                    setIdDelete={setIdDelete}
                                                    setPoste1={setPoste1}
                                                    postOrga={(id, value, msg) => updateOrga(postOrga, id, value, msg)}
                                                />
                                            )
                                        }   
                                    }
                                });

                                const fullName = elementCom.delegue.fullname;
                                
                                if( verifQuartiers
                                    ||
                                    ( elementCom.delegue.idDelegue !== null ? 
                                    (
                                        fullName.toLowerCase().indexOf(search.toLowerCase()) !== -1
                                        ||
                                        elementCom.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                                    )
                                    :
                                        elementCom.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                                    )
                                ) {
                                    verifCommunes = true;
                                    
                                    communes.push(
                                        <OrgaItem
                                            key={`${i}_${ville}_c${l}`}
                                            item={elementCom}
                                            initialInput={initialInput}
                                            fullName={fullName}
                                            poste1={poste1}
                                            moveDisabled={poste1 === elementVille.id || poste1 === zone[i].id}
                                            children={quartiers}
    
                                            onInputChange={(value) => {
                                                let t = [...zone];
                                                t[i][ville][com].name = value;
                                                setOrga(t);
                                            }}
                                            editOrga={(id, value, msg) => updateOrga(editOrga, id, value, msg)}
                                            resetInput={() => setInitialInput(elementCom.name)}
                                            setSelectedPoste={setSelectedPoste}
                                            moveOrga={(id, value, msg) => updateOrga(moveOrga, id, value, msg)}
                                            setIdDelete={setIdDelete}
                                            setPoste1={setPoste1}
                                            postOrga={(id, value, msg) => updateOrga(postOrga, id, value, msg)}
                                        />
                                    )
                                }
                            }
                        });

                        const fullName = elementVille.delegue.fullname;

                        if( verifCommunes
                            ||
                            ( elementVille.delegue.idDelegue !== null ? 
                                (
                                    fullName.toLowerCase().indexOf(search.toLowerCase()) !== -1
                                    ||
                                    elementVille.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                                )
                                :
                                    elementVille.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                            )
                        ) {
                            verifVille = true;
                            
                            villes.push(
                                <OrgaItem
                                    key={`${i}_v${j}`}
                                    item={elementVille}
                                    initialInput={initialInput}
                                    fullName={fullName}
                                    poste1={poste1}
                                    moveDisabled={poste1 === zone[i].id}
                                    children={communes}
    
                                    onInputChange={(value) => {
                                        let t = [...zone];
                                        t[i][ville].name = value;
                                        setOrga(t);
                                    }}
                                    editOrga={(id, value, msg) => updateOrga(editOrga, id, value, msg)}
                                    resetInput={() => setInitialInput(elementVille.name)}
                                    setSelectedPoste={setSelectedPoste}
                                    moveOrga={(id, value, msg) => updateOrga(moveOrga, id, value, msg)}
                                    setIdDelete={setIdDelete}
                                    setPoste1={setPoste1}
                                    postOrga={(id, value, msg) => updateOrga(postOrga, id, value, msg)}
                                />
                            )
                        }
                    }
                });

                const fullName = zone[i].delegue.fullname;

                if( verifVille
                    ||
                    ( zone[i].delegue.idDelegue !== null ? 
                        (
                            fullName.toLowerCase().indexOf(search.toLowerCase()) !== -1
                            ||
                            zone[i].name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                        )
                    :
                        zone[i].name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                    )
                ) {
                    
                    table.push(
                        <OrgaItem
                            key={i}
                            item={zone[i]}
                            initialInput={initialInput}
                            fullName={fullName}
                            poste1={poste1}
                            moveDisabled={false}
                            children={villes}
    
                            onInputChange={(value) => {
                                let t = [...zone];
                                t[i].name = value;
                                setOrga(t);
                            }}
                            editOrga={(id, value, msg) => updateOrga(editOrga, id, value, msg)}
                            resetInput={() => setInitialInput(zone[i].name)}
                            setSelectedPoste={setSelectedPoste}
                            moveOrga={(id, value, msg) => updateOrga(moveOrga, id, value, msg)}
                            setIdDelete={setIdDelete}
                            setPoste1={setPoste1}
                            postOrga={(id, value, msg) => updateOrga(postOrga, id, value, msg)}
                        />
                    )
                }
            });
        
        }
        
        return table;
    }

    return(
        <section className="content">
        
            <section className="content-header">
                <h1>
                    {t('SETTINGS.ORGANISATION')}
                    <small>{t('SETTINGS.VIEW_ORGANISATION')}</small>
                </h1>

                <table className="box tab-entete organ">
                    <tbody>
                        <tr>
                            <td>
                                <div>
                                    <p>{t('SETTINGS.SEARCH')}</p>
                                    <input
                                        type="text"
                                        required
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        placeholder={t('SETTINGS.SEARCH_PLACEHOLDER')}
                                    />
                                </div>
                            </td>
                            <td className="last">
                                <ButtonExport/>

                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>

                                <ButtonAdd
                                    handleAdd={() => updateOrga(postOrga, null, "Nouveau poste", "ajouté")}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
        
            <section className="content organ">

                <div className="listOrga">
                    { orga.length === 0 ?
                        "Aucun poste défini"
                    :
                        afficheList()
                    }
                </div>

            </section>

            <PopupDelete
                title="Supprimer un poste"
                id={idDelete}
                handleDelete={id => updateOrga(deleteOrga, id, undefined, "supprimé")}
            />

            <PopupOrga
                poste={selectedPoste}
                listUsers={usersList}
                getAllUsers={getSimpleUsers}
                handleSubmit={(submit) => setConfirmSubmit(() => () => submit())}

                changeUser={(id, idUser) => changeUser(id, idUser).then(() => {
                    NotificationManager.success('', "Poste attribué");
                    handleGetOrga();
                }).catch(() => NotificationManager.error('', "Error"))}
                removeUser={id => removeUser(id).then(() => {
                    NotificationManager.success('', "Poste désattribué");
                    handleGetOrga();
                }).catch(() => NotificationManager.error('', "Error"))}
            />
            
            <PopupConfirmation message={t('COMMON.CONFIRM_MSG')} handleSubmit={confirmSubmit}/>

        </section>
    )
}