import { useContext, useState } from 'react';
import { FilterStoreContext } from '../../../../../store/store/storeFilters';
import { ListStoreContext } from '../../../../../store/store/storeList';
import { config } from '../../../../../store/config';

import Select from 'react-select';

import { t } from '../../../../../pages/i18nFunctions';

export const SelectFilt = (props) => {
    const { head, type } = props;
	
    const { stateFilter, dispatchFilters } = useContext(FilterStoreContext);
	const { stateList, dispatchList } = useContext(ListStoreContext);

	const { dataList, dataMedicalPro } = stateList;
    const { orderByKey } = stateFilter;

	let [item, setItem] = useState({});

	const handleChange = (value, name, typeSelect) => {
		let dataSearch = {...item, [name]: value};
		let sSearchType;
		let sSearchProducts;
		let sSearchSupervision;
		let sSearchCRLastWeek;
		let sSearchCRCurrentWeek;
		let sSearchUserStatus;
		let sSearchUserRole;

		if(name === 'products') {
			sSearchProducts = dataSearch.products;
			dispatchFilters({type: 'setSearchProducts', sSearchProducts});
		}
		else if(name === 'type') {
			sSearchType = dataSearch.type;
			dispatchFilters({type: 'setSearchType', sSearchType});
		}
		else if(name === 'withSupervision') {
			sSearchSupervision = dataSearch.withSupervision;
			dispatchFilters({type: 'setSearchSupervision', sSearchSupervision});
		}
		else if(name === 'numCRlastweek') {
			sSearchCRLastWeek = dataSearch.numCRlastweek;
			dispatchFilters({type: 'setSearchCRLastWeek', sSearchCRLastWeek});
		}
		else if(name === 'numCRcurrentweek') {
			sSearchCRCurrentWeek = dataSearch.numCRcurrentweek;
			dispatchFilters({type: 'setSearchCRCurrentWeek', sSearchCRCurrentWeek});
		}
		else if(name === 'status' && type === "user") {
			sSearchUserStatus = dataSearch.status;
			dispatchFilters({type: 'setSearchUserStatus', sSearchUserStatus});
		}
		else if(name === 'roles' && type === "user") {
			sSearchUserRole = dataSearch.roles;
			dispatchFilters({type: 'setSearchUserRole', sSearchUserRole});
		}
		setItem(dataSearch)
	}

	const generateOptions = () => {
		if(head.name === 'type') {
			let options = [];
			if(typeof stateList.dataTypeContact !== 'undefined')
				stateList.dataTypeContact.forEach((el, i) => {
					options.push({
						value: el.id,
						label: el.type
					});
				});

			return options;
		}
		
		else if(head.name === 'products') {			
			let options = [];
			if(typeof stateList.dataHistoryPro.productsAutocompletion !== 'undefined')
				stateList.dataHistoryPro.productsAutocompletion.forEach((el, i) => {
					options.push({
						value: el.idProduct,
						label: el.nameProduct
					});
				});

			return options;
		}

		else if(head.name === 'withSupervision')
			return [
                { value: 1, label: t('COMMON.YES') },
                { value: 0, label: t('COMMON.NO') }
			]

		else if(head.name === 'numCRlastweek' || head.name === 'numCRcurrentweek')
			return [
                { value: 1, label: t('MISSION.LABEL.DONE') },
                { value: 0, label: t('MISSION.LABEL.NOT_DONE') }
			]

		else if(head.name === 'status' && type === "user")
			return [
                { value: 0, label: t('INSTITUTION.STATUS.AC') },
                { value: 1, label: t('INSTITUTION.STATUS.IN') }
			]
		
		else if(head.name === 'roles' && type === "user")
			return config.groupeList.map(el => ({
				value: el.val[0],
				label: el.name
			}))
	}
    return(
        <>
			<div>
				<Select
					className='selectSearchBar noHeight' 
					classNamePrefix='selectSearch' 
					placeholder={head.placeholder}
					isMulti={head.name === 'products'}
					isClearable={true}
					options={generateOptions()} 
					onChange={(value, type) => {
						handleChange(value, head.name, type)
					}}
					height={50}
				/>
			</div>
        </>
    )
}