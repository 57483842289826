import { useState, useEffect, useRef } from 'react';
import { t } from "../../../pages/i18nFunctions";
import Rating from '@material-ui/lab/Rating';
import { NotificationManager } from 'react-notifications';
import ReactLoading from 'react-loading';

// components
import { CustomSelect } from '../../UI/CustomSelect/CustomSelect';
import ProdAccordeon from "../../UI/ProdAccordeon/ProdAccordeon";

// images
import iconSave from "../../../assets/img/icons/icon-save.png";

//api
import {
    getPresentedProd,
    remplirCRProd,
    moveProd,
    autoComplete
} from "../../../store/index.service";

export const PopupRemplirCR = (props) => {
    const { item, crReady, readOnly, remplirCR, listTypeHisto, listObjectifs, listRP, htmlID } = props;

    const [idObj, setIdObj] = useState(-1);
    const [objectif, setObjectif] = useState(t('PLANNING.CR.SELECT_OBJECTIVE'));
    const [note, setNote] = useState(0);
    const [hover, setHover] = useState(-1);
    const [rp, setRP] = useState(false);
    const [idTypeRP, setIdTypeRP] = useState(-1);
    const [typeRP, setTypeRP] = useState(t('PLANNING.CR.SELECT_TYPE_RP'));
    const [idObjPro, setIdObjPro] = useState(-1);
    const [objectifPro, setObjectifPro] = useState(t('PLANNING.CR.SELECT_OBJECTIVE'));
    const [date, setDate] = useState("");
    const [comment, setComment] = useState("");
    const [prod1, setProd1] = useState(null);
    const [presentedProd, setPresentedProd] = useState([]);
    const [loadingPresentedProd, setLoadingPresentedProd] = useState(false);
    const [idTypeVisite, setIdTypeVisite] = useState(-1);

    const prods = useRef([]);
    const btnRef = useRef(null);

    // updates
    useEffect(() => {
        if(item.id) {
            handleGetPresentedProd();

            setObjectif(item.objectivetype.nameObjectivetype ? item.objectivetype.nameObjectivetype : t('PLANNING.CR.SELECT_OBJECTIVE'));
            setIdObj(item.objectivetype.idObjectivetype ? item.objectivetype.idObjectivetype : -1);
            setNote(item.objectivetypenote ? item.objectivetypenote : 0);
            setRP(item.productmeetingtype.idProductmeetingtype ? true : false);
            setIdTypeRP(item.productmeetingtype.idProductmeetingtype ? item.productmeetingtype.idProductmeetingtype : -1);
            setTypeRP(item.productmeetingtype.nameProductmeetingtype ? item.productmeetingtype.nameProductmeetingtype : t('PLANNING.CR.SELECT_TYPE_RP'));
            setObjectifPro(item.nextobjectivetype.name ? item.nextobjectivetype.name : t('PLANNING.CR.SELECT_OBJECTIVE'));
            setIdObjPro(item.nextobjectivetype.id ? item.nextobjectivetype.id : -1);
            setDate(item.nextAppointmentdate.id ? item.nextAppointmentdate.id.slice(0, 10) : "");
            setComment(item.comment);
            setIdTypeVisite(item.type.idAppointmentType ? item.type.idAppointmentType : -1);
        }
    }, [item]);

    const handleGetPresentedProd = () => {
        setLoadingPresentedProd(true);

        getPresentedProd(item.id).then(res => {

            const data = JSON.parse(res);
            setPresentedProd(data ? data : []);

            setLoadingPresentedProd(false);
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if(isSubmitable()) {
            remplirCR(
                item.id,
                {
                    idObj: idObj,
                    note: note,
                    idTypeRP: rp && idTypeRP !== -1 ? idTypeRP : null,
                    idObjPro: idObjPro,
                    comment: comment,
                    ignoreDate: date === "" ? true : false,
                    date: date !== "" ? new Date(date).toJSON() : "",
                    idTypeVisite: idTypeVisite
                }
            );

            btnRef.current.click();
        }
    }

    const isSubmitable = () => {
        let b = true;

        presentedProd.forEach(p => {
            if(p.status === 0) b = false;
        });

        return (
            idObj !== -1 && note !== 0 && idObjPro !== -1 && b && idTypeVisite !== -1 && (idTypeRP !== -1 || !rp)
            && new Date(date).getDay() !== 0 && new Date(date).getDay() !== 6
        )
    }

    const labels = {
        0: '?',
        1: t('PLANNING.CR.LABELS.NOT_REACHED'),
        2: t('PLANNING.CR.LABELS.PARTIALLY'),
        3: t('PLANNING.CR.LABELS.DEV_FURTHER'),
        4: t('PLANNING.CR.LABELS.REACHED')
    }
    
    return(
        <div className="modal fade in" id={`${htmlID ? htmlID : "modalRemplirCR"}`} tabIndex="-1" role="dialog" aria-labelledby="modalRemplirCRLabel" animate="animate">
            <div className="modal-dialog" role="document">
                {crReady ?
                <div className="modal-content">

                    <div className="modal-header">
                        <h1 className="modal-title" id="myModalLabel">
                            {t('PLANNING.CR.REPORT')} - {item.medicalpro.title} {item.medicalpro.fisrtname} {item.medicalpro.lastname} 
                            <small>{item.medicalpro.type.nameType}</small>
                        </h1>
                    </div>

                    <div className="modal-body">
                        <form id="formRemplirCR">

                            <label>{t('PLANNING.CR.TYPE_VISIT')}</label>
                            <CustomSelect
                                defaultText={
                                    idTypeVisite !== -1 && listTypeHisto.length > 0 ?
                                        listTypeHisto.find(el => el.id === idTypeVisite).type
                                    :
                                        t('PLANNING.CR.SELECT_TYPE_VISIT')
                                }
                                optionsList={listTypeHisto.map(type => (
                                    { id: type.id, name: type.type }
                                ))}
                                handleOptionClick={(id) => setIdTypeVisite(parseInt(id))}
                                disabled={readOnly}
                            />

                            <label>{t('PLANNING.VISIT_OBJECTIVE')}</label>
                            <CustomSelect
                                defaultText={objectif}
                                optionsList={listObjectifs.map(o => (
                                    { id: o.code, name: o.name }
                                ))}
                                handleOptionClick={(id, name) => {
                                    setIdObj(id);
                                    setObjectif(name);
                                }}
                                disabled={readOnly}
                            />
                            <small>{t('PLANNING.CR.MANDATORY')}</small>


                            <label>{t('PLANNING.CR.EVALUATION')}</label>
                            <div className="rating">
                                <Rating
                                    name="simple-controlled"
                                    size="large"
                                    max={4}
                                    value={note}
                                    onChange={(event, newValue) => setNote(newValue)}
                                    onChangeActive={(event, newHover) => setHover(newHover)}
                                    disabled={readOnly}
                                />
                                <span className={`tag hov${hover !== -1 ? hover : note}`}>{labels[hover !== -1 ? hover : note]}</span>
                            </div>
                            <small>{t('PLANNING.CR.MANDATORY')}</small>

                            <div className="prods">
                                {presentedProd.map(prod =>
                                    <ProdAccordeon
                                        key={`accordeon-cr-${prod.idProductPresented}`}
                                        prod={prod}

                                        remplirCRProd={(id, prod) => remplirCRProd(id, prod).then(() => {

                                            NotificationManager.success('', t('COMMON.NOTIFICATIONS.EDIT_PRODUCT'));
                                            handleGetPresentedProd();

                                        }).catch(() => NotificationManager.error('', "Error"))}

                                        handleClickMove={e => {
                                            e.stopPropagation();

                                            if(prod1 === null)
                                                setProd1(prod.idProductPresented);
                                            else if(prod1 === prod.idProductPresented)
                                                setProd1(null);
                                            else {
                                                e.target.blur()
                                                moveProd(prod1, prod.idProductPresented).then(() => {

                                                    NotificationManager.success('', t('COMMON.NOTIFICATIONS.MOVE_PRODUCT'));
                                                    handleGetPresentedProd();
                                                    setProd1(null);

                                                }).catch(() => NotificationManager.error('', "Error"))
                                            }
                                        }}
                                        moveBtnStyle={prod1 === prod.id ? { color: "#424343" } : {}}
                                        moveFa={prod1 === null || prod1 === prod.idProductPresented ? "fa-exchange" : "fa-check"}
                                        readOnly={readOnly}
                                        ref={el => el && prods.current.indexOf(el) === -1 && prods.current.push(el)}
                                    />
                                )}
                                { loadingPresentedProd &&
                                    <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader cr" />
                                }
                            </div>

                            {presentedProd.length > 0 && !readOnly && 
                                <div className="autoComplete">
                                    <button className="btn-save" onClick={e => {
                                        e.preventDefault();
                                        autoComplete(item.id).then(() => {

                                            NotificationManager.success('', t('COMMON.NOTIFICATIONS.AUTO_COMPLETE'));
                                            handleGetPresentedProd();

                                        }).catch(() => NotificationManager.error('', "Error"))
                                    }}>
                                        {t('PLANNING.CR.AUTO_COMPLETE')}
                                    </button>
                                </div>
                            }


                            <div className="check">
                                <input
                                    type="checkbox"
                                    id="rp"
                                    checked={rp}
                                    onChange={() => setRP(!rp)}
                                    disabled={readOnly}
                                />
                                <label htmlFor="rp">{t('PLANNING.CR.FUTURE')}</label>
                            </div>

                            {rp && (
                                <div>
                                    <label>{t('PLANNING.CR.TYPE_RP')}</label>
                                    <CustomSelect
                                        defaultText={typeRP}
                                        optionsList={listRP}
                                        handleOptionClick={(id, name) => {
                                            setIdTypeRP(id);
                                            setTypeRP(name);
                                        }}
                                        disabled={readOnly}
                                    />
                                </div>
                            )}


                            <label>{t('PLANNING.CR.NEXT_OBJECTIVE')}</label>
                            <CustomSelect
                                defaultText={objectifPro}
                                optionsList={listObjectifs.map(o => (
                                    { id: o.code, name: o.name }
                                ))}
                                handleOptionClick={(id, name) => {
                                    setIdObjPro(id);
                                    setObjectifPro(name);
                                }}
                                disabled={readOnly}
                            />
                            <small>{t('PLANNING.CR.MANDATORY')}</small>

                            <label>{t('PLANNING.CR.NEXT_DATE')}</label>
                            <input
                                type="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                disabled={readOnly}
                                min={new Date()}
                            />
                            {(new Date(date).getDay() === 0 || new Date(date).getDay() === 6) &&
                                <span style={{ color: 'red' }}>{t('PLANNING.CR.WEEKEND_WARNING')}</span>
                            }

                            <label>{t('PLANNING.CR.COMMENTS')}</label>
                            <textarea 
                                rows="5" 
                                placeholder={t('PLANNING.CR.COMMENTS_PLACEHOLDER')}
                                value={comment}
                                onChange={e => setComment(e.target.value)}
                                disabled={readOnly}
                            />

                        </form>
                    </div>
                    <div className="modal-footer">
                        {!readOnly &&
                            <button
                                type="submit"
                                form="formRemplirCR"
                                className="btn-save"
                                onClick={handleSubmit}
                                disabled={!isSubmitable()}
                            >
                                <img src={iconSave} alt=""/>
                                {t('COMMON.SAVE')}
                            </button>
                        }

                        <button
                            type="button"
                            className="btn-cancel"
                            data-dismiss="modal"
                            ref={btnRef}
                            onClick={() => {
                                prods.current.forEach(p => p.handleOpen());
                                prods.current = [];

                                setObjectif(item.objectivetype.nameObjectivetype ? item.objectivetype.nameObjectivetype : t('PLANNING.CR.SELECT_OBJECTIVE'));
                                setIdObj(item.objectivetype.idObjectivetype ? item.objectivetype.idObjectivetype : -1);
                                setNote(item.objectivetypenote ? item.objectivetypenote : 0);
                                setRP(item.productmeetingtype.idProductmeetingtype ? true : false);
                                setIdTypeRP(item.productmeetingtype.idProductmeetingtype ? item.productmeetingtype.idProductmeetingtype : -1);
                                setTypeRP(item.productmeetingtype.nameProductmeetingtype ? item.productmeetingtype.nameProductmeetingtype : t('PLANNING.CR.SELECT_TYPE_RP'));
                                setObjectifPro(item.nextobjectivetype.name ? item.nextobjectivetype.name : t('PLANNING.CR.SELECT_OBJECTIVE'));
                                setIdObjPro(item.nextobjectivetype.id ? item.nextobjectivetype.id : -1);
                                setDate(item.nextAppointmentdate.id ? item.nextAppointmentdate.id.slice(0, 10) : "");
                                setComment(item.comment);
                                setIdTypeVisite(item.type.idAppointmentType ? item.type.idAppointmentType : -1);
                            }}
                        >{readOnly ? "Ok" : t('COMMON.CANCEL')}</button>
                    </div>
                </div>
                :

                <div className="modal-content">
                    <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader cr" />
                </div>

                }
            </div>
        </div>
    )
}