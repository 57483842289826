import { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import { ActionsStoreContext } from '../../../../store/store/storeActions';
import { t } from "../../../../pages/i18nFunctions";
import {
    generateZGOptions,
} from "../../../../store/helpers";
import { el } from 'date-fns/locale';

export const ZoneGeoInput = (props) => {
    const { item, countries, actions, setInitParam, edit, action} = props;
    const { stateActions, dispatchActions } = useContext(ActionsStoreContext);
    
    let [dataState, setDataState] = useState();
    let [initCountry, setInitCountry] = useState();
    let [userData, setUserData] = useState();
    let [authRole, setAuthRole] = useState();

    useEffect(() => {
        let lsUser = localStorage.getItem('userData');
        let dataUser = JSON.parse(lsUser);   
        setUserData(dataUser);

        let lsCountry = localStorage.getItem('userCountries');
        let dataCountry = JSON.parse(lsCountry);
        setInitCountry(dataCountry[0]);

        const roleUser = localStorage.getItem("role");
        const restrict = [  //add role here to handle accesSearch
            'ROLE_DELEGUE',
            "ROLE_SUPERVISEUR",
            "ROLE_DP",
            "ROLE_DSM",
            "ROLE_MONITEUR"
        ]
        
        if(restrict.includes(roleUser)) 
            setAuthRole(false)
        else
            setAuthRole(true)
    }, []);


    let [dataRepetable, setDataRepetable] = useState({
        country: null,
        province: null,
        ville: null,
        commune: null,
        quartier: null,
    }); 

    useEffect(() => {
        if(edit === true && typeof userData !== 'undefined') {            
            let cleanDataRepetable;
            for (const [key, value] of Object.entries(item.fulldatazoneGeo)) {
                cleanDataRepetable = {
                    ...cleanDataRepetable,
                    [key]: value
                }
            }

            setDataRepetable(cleanDataRepetable);
            
            actions.getAllZG({countryName: item.fulldatazoneGeo.country.label}).then(res => {
                const data = JSON.parse(res);
                // let cleanData = generateZGOptions(Object.values(data.listZoneGeo)[0], item.fulldatazoneGeo.country.value, item.fulldatazoneGeo.country.label);
                let saveNameCountry = {
                    ...stateActions.dataActions,
                    country: item.fulldatazoneGeo.country.label,
                } 
                let aDataActions = saveNameCountry;
                return setDataState(data.listZoneGeo),
                    dispatchActions({type: 'setDataActions', aDataActions }),
                    setInitCountry(null),
                    setInitParam(true);
            });
        } else if(edit === false && typeof userData !== 'undefined') {
            // if(authRole) {
                getZG({value: initCountry.id, label: initCountry.name})
                setDataRepetable({
                    ...dataRepetable,
                    country: initCountry
                });
            // }
        }
    }, [userData, edit])
    
    const generateOptions = (name, data) => {
        let options = [];

        let pays;
        if(dataState && dataRepetable.country)
            pays = Object.values(dataState).find(zone => zone.id === dataRepetable.country.id || zone.id === dataRepetable.country.value);

        let prov;
        if(pays && dataRepetable.province)
            prov = Object.values(pays).filter(z => typeof z === "object").find(zone => zone.id === dataRepetable.province.value);

        let vil;
        if(prov && dataRepetable.ville)
            vil = Object.values(prov).filter(z => typeof z === "object").find(zone => zone.id === dataRepetable.ville.value);

        let com;
        if(vil && dataRepetable.commune)
            com = Object.values(vil).filter(z => typeof z === "object").find(zone => zone.id === dataRepetable.commune.value);

        if(name === "province" && pays)
            Object.values(pays).forEach(zone => {
                if(typeof zone === "object")
                    options.push({
                        value: zone.id, 
                        label: zone.name,
                        ad: name
                    })
            });
        else if(name === "ville" && prov)
            Object.values(prov).forEach(zone => {
                if(typeof zone === "object")
                    options.push({
                        value: zone.id, 
                        label: zone.name,
                        ad: name
                    })
            });
        else if(name === "commune" && vil)
            Object.values(vil).forEach(zone => {
                if(typeof zone === "object")
                    options.push({
                        value: zone.id, 
                        label: zone.name,
                        ad: name
                    })
            });
        else if(name === "quartier" && com)
            Object.values(com).forEach(zone => {
                if(typeof zone === "object")
                    options.push({
                        value: zone.id, 
                        label: zone.name,
                        ad: name
                    })
            });
        

        if(typeof data !== 'undefined') {
            if(name === 'country') 
                data.forEach((option, i) => {
                    options.push({
                        value: option.id, 
                        label: option.name,
                        ad: name
                    });
                });
            // else
            //     data.forEach((option, i) => {
            //         if(typeof option.value !== 'undefined')
            //             options.push({
            //                 value: option.value, 
            //                 label: option.label,
            //                 ad: name
            //             });
            //     });
        }

        return options;
    }

    const handleChange = (value, type, name, el = null) => {
        let params = {...dataRepetable};
        if(type.action === 'clear') {
            if(el !== null) {
                return el.delete.map( (e, i) => {
                    params = {
                        ...params,
                        [e]: null
                    }
                    return setDataRepetable(params);
                })
            }
            setDataRepetable({
                country: null,
                province: null,
                ville: null,
                commune: null,
                quartier: null
            });
        }
        if(name === 'country' && type.action !== 'clear' && value.ad === 'country') {
            getZG(value);

            setDataRepetable({
                country: value,
                province: null,
                ville: null,
                commune: null,
                quartier: null
            });
        }
        else if(name === 'province') {
            setDataRepetable({
                ...dataRepetable,
                province: value,
                ville: null,
                commune: null,
                quartier: null
            });
        }
        else if(name === 'ville') {
            setDataRepetable({
                ...dataRepetable,
                ville: value,
                commune: null,
                quartier: null
            });
        }
        else if(name === 'commune') {
            setDataRepetable({
                ...dataRepetable,
                commune: value,
                quartier: null
            });
        }
        else if(name === 'quartier') {
            setDataRepetable({
                ...dataRepetable,
                quartier: value
            });
        }
        else
            setDataRepetable({
                ...dataRepetable,
                [name]: value
            });

        let aDataActions = {
            ...stateActions.dataActions,
            zoneGeo: value.value
        }
        return dispatchActions({type: 'setDataActions', aDataActions });
    };

    const getZG = (value) => {
        actions.getAllZG({countryName: value.label}).then(res => {
            const data = JSON.parse(res);
            // let cleanData = generateZGOptions(Object.values(data.listZoneGeo)[0], value.value, value.label);
            let saveNameCountry = {
                ...stateActions.dataActions,
                country: value.label,
            } 
            let aDataActions = saveNameCountry;
            return setDataState(data.listZoneGeo),
                dispatchActions({type: 'setDataActions', aDataActions }),
                setInitParam(true),
                generateRepetableInput(typeInput);
        });
    }

    const typeInput = [
        {
            name: 'province',
            displayName: t('INSTITUTION.POP_EDIT.PROVINCE'),
            wait: ['country'],
            delete: ['province', 'ville', 'commune', 'quartier']
        },
        {
            name: 'ville',
            displayName: t('INSTITUTION.POP_EDIT.CITY'),
            wait: ['country', 'province'],
            delete: ['ville', 'commune', 'quartier']
        },
        {
            name: 'commune',
            displayName: t('INSTITUTION.POP_EDIT.COMMUNE'),
            wait: ['country', 'province', 'ville'],
            delete: ['commune', 'quartier']
        },
        {
            name: 'quartier',
            displayName: t('INSTITUTION.POP_EDIT.DISTRICT'),
            wait: ['country', 'province', 'ville', 'commune'],
            delete: ['quartier']
        },
    ];

    const generateRepetableInput = (typeInput) => {
        return typeInput.map((el, i) => {
            if(el.name === 'province') {
                if(dataRepetable.country !== null || typeof dataRepetable.country !== 'undefined')
                    return handleGenSelect(el, i);
            }else if(el.name === 'ville') {
                if(dataRepetable.country !== null && dataRepetable.province !== null)
                    return handleGenSelect(el, i);
            }else if(el.name === 'commune') {
                if(dataRepetable.country !== null && dataRepetable.province !== null  && dataRepetable.ville !== null)
                    return handleGenSelect(el, i);
            }else if(el.name === 'quartier') {
                if(dataRepetable.country !== null && dataRepetable.province !== null  && dataRepetable.ville !== null && dataRepetable.commune !== null)
                    return handleGenSelect(el, i);
            }
        })
    };

    const handleDefaultValue = (el) => {
        if(edit === false) {
            return ''
        }
        else if(edit === true) {
            if(dataRepetable[el.name] !== null) {
                return item.fulldatazoneGeo[el.name];
            }
        }
    }

    const handleGenSelect = (el, i) => {
        return <div key={i}>
            <label>{el.displayName}</label>
            <Select 
                isDisabled={action === 'visu' ? true : false}
                className='selectSearchBar' 
                classNamePrefix='selectSearch' 
                placeholder={`Sélectionner un(e) ${el.displayName.toLowerCase()}`}
                isClearable={true}
                options={generateOptions(el.name, /*dataState[i+1].options*/)} 
                onChange={(e, type) => handleChange(e, type, el.name, el)} 
                defaultValue={handleDefaultValue(el)}
                value={dataRepetable[el.name]}
            />
        </div>;
    }

    return(
        <div>
            <label>{t('INSTITUTION.LIST.SEARCH_BOX.FORM.COUNTRY')}</label>
            {
                (typeof initCountry !== 'undefined' || edit) &&
                <Select 
                    isDisabled={action === 'visu' || authRole === false ? true : false}
                    className={`selectSearchBar`}
                    // className={`selectSearchBar ${authRole ? 'show' : 'hide'}`}
                    classNamePrefix='selectSearch' 
                    placeholder={t('PROS.LIST.RESEARCH.COUNTRY_PLACEHOLDER')}
                    isClearable={false}
                    options={generateOptions('country', countries)} 
                    onChange={(e, type) => handleChange(e, type, 'country')} 
                    defaultValue={edit === false ? { value: initCountry.id, label: initCountry.name } : item.fulldatazoneGeo.country } 
                />
            }
            {typeof dataState !== 'undefined' && 
                generateRepetableInput(typeInput)
            }
        </div> 
    )
}