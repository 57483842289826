import './index.scss';
import { useContext } from 'react';

import { Delete } from './ActionsItems/Delete';
import { DeleteDirect } from './ActionsItems/DeleteDirect';
import { Edit } from './ActionsItems/Edit';
import { Lock } from './ActionsItems/Lock';
import { ShowReport } from './ActionsItems/ShowReport';
import { LinkPlanning } from './ActionsItems/LinkPlanning';
import { LinkCiblage } from './ActionsItems/LinkCiblage';
import { LoginAs } from './ActionsItems/LoginAs';

import { ActionsStoreContext } from '../../../../../store/store/storeActions';

export const ActionsRowManager = (props) => {
    const { actionsList, line, type, isAdd } = props;
    const { stateActions } = useContext(ActionsStoreContext);

    const genActionsInput = () => {
        return actionsList.map((el, i) => {
            if(el === 'edit')
                return <Edit key={i} action={el} line={line} type={type} stateActions={stateActions}/>;

            else if(el === 'lock')
                return <Lock key={i} action={el} line={line} type={type} stateActions={stateActions}/>;

            else if(el === 'delete' && type !== "missionTab")
                return <Delete key={i} action={el} line={line} type={type} stateActions={stateActions}/>;
                
            else if(el === 'delete' && type === "missionTab")
                return <DeleteDirect key={i} action={el} line={line} type={type} stateActions={stateActions}/>;

            else if(el === 'showReport')
                return <ShowReport key={i} action={el} line={line} type={type} stateActions={stateActions}/>;

            else if(el === 'planning')
                return <LinkPlanning key={i} action={el} line={line} type={type} stateActions={stateActions}/>;

            else if(el === 'ciblage')
                return <LinkCiblage key={i} action={el} line={line} type={type} stateActions={stateActions}/>;

            else if(el === 'loginAs' && type === "user" && line.roles && line.roles[0] !== "ROLE_ADMIN" && line.status === 0)
                return <LoginAs key={i} action={el} line={line} type={type} stateActions={stateActions}/>;
        })
    }

    return(
        <>
            <td className="inputTd">
                <div>
                    {genActionsInput()}
                </div>
            </td>
            {type === 'medicalProHisto' &&
                <td></td>
            }
        </>
    )
}