import { AddEtab } from './Type/AddEtab';
import { EditEtab } from './Type/EditEtab';

export const EtabPopUpManager = (props) => {
    const { data, dispatchPop } = props;
    const { action } = data;

    const generatePopUps = () => {
        switch (action) {
            case 'add':
                return <AddEtab data={data} dispatchPop={dispatchPop} />;

            case 'visu':
            case 'edit':
                return <EditEtab data={data} dispatchPop={dispatchPop} />;
                
            default:
                return false;
        }
    }

    return (
        <>
            {
                <div className="modal-body">
                    {generatePopUps()}
                </div>
            }
        </>
    )
}