import { useRef } from 'react';
import { t } from "../../pages/i18nFunctions";

export const PopupDelete = ({ id, title, handleDelete }) => {

    const btnRef = useRef(null);

    const handleSubmit = (e) => {
        if(id && id !== -1) {
            e.preventDefault();
            handleDelete(id);
            btnRef.current.click()
        }
    }

    return(
        <div className="modal fade in" id="modalDelete" tabIndex="-1" role="dialog" aria-labelledby="modalDeleteLabel" animate="animate">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title" id="myModalLabel">
                            {title}
                        </h1>
                    </div>
                    <div className="modal-body">
                        
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn-save" onClick={handleSubmit}>
                            {t('DELETE.LABEL')}
                        </button>
                        <button
                        type="button"
                        className="btn-cancel"
                        data-dismiss="modal"
                        ref={btnRef}
                        >{t('COMMON.CANCEL')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopupDelete;