import React, { useContext, useState, useRef } from 'react';
import "./CalendarElement.scss";
import { t } from '../../pages/i18nFunctions';
import { Droppable } from 'react-drag-and-drop';
import { NotificationManager } from 'react-notifications';
import { PopUpStoreContext } from '../../store/store/storePopUp';
import { ActionsStoreContext } from '../../store/store/storeActions';
import { ListStoreContext } from '../../store/store/storeList';

// components
import { CustomSelect } from '../UI/CustomSelect/CustomSelect';
import { ButtonEdit } from "../UI/Button/ButtonEdit/ButtonEdit";
import { Appointment } from './Appointment';

// images
import iconTel from "../../assets/img/icons/icon-tel.png";
import iconMail from "../../assets/img/icons/icon-mail.png";

// API
import {
    getOnePDS
} from "../../store/index.service";


export const CalendarElement = (props) => {

    // PROPS
    const {
        style, up, buttonText, off, path, idSchedule, date, bottomText, updateSelect, withDelegue, pdsIsDragged, pdsDragged, dropped, details,
        postActivite, editActivite, handleDuo, prepareEtab, postRDV, readOnlyCR, handleNextApp, cancelApp, deleteRDV, getCR
    } = props;

    // STATE
    const [showSelect, setShowSelect] = useState(false);

    // REF
    const btnElement = useRef();


    // CONTEXT

    const { dispatchActions } = useContext(ActionsStoreContext);

    const { statePopUp, dispatchPopUp } = useContext(PopUpStoreContext);
    const { dataPopUp } = statePopUp;
    
    const { stateList } = useContext(ListStoreContext);
    const { dataActivity } = stateList;


    // FUNCTIONS

    const voirPDS = (id) => {
        getOnePDS(id).then(res => {
            const data = JSON.parse(res);

            let dDataPopUp = {
                ...dataPopUp,
                action: 'visu',
                isActive: !dataPopUp.isActive
            }
            let aDataActions = data ? {
                ...data,
                name: `${data.reference} - ${data.title} ${data.firstName} ${data.lastName}`
            } : {};
    
            dispatchPopUp({ type: 'setPopUp', dDataPopUp });
            dispatchActions({ type: 'setDataActions', aDataActions });
        });
    }

    const generateOptions = () => {
        let list = dataActivity.map(a => ({ id: a.code, name: a.name }));

        // code VISS dans bdd mais non reconnu (surement tempo)
        // DUO que sous condition
        list = list.filter(el => el.id !== "VISS" && el.id !== "DUO");

        if(!off)   // PAS DE OFF LA SEMAINE
            list = list.filter(el => el.id !== "OFF");
        else    // PAS DE VISITE LE WEEKEND
            list = list.filter(el => el.id !== "VIST");

        // add back DUO for correct roles
        if(
            (roleUser === "ROLE_SUPERVISEUR" || roleUser === "ROLE_DSM" || roleUser === "ROLE_HP" || roleUser === "ROLE_DP" || roleUser === "ROLE_ADMIN" || roleUser === "ROLE_QADMIN")
            && path !== "/missions/:id/calendar"
        )
            list = list = [ ...list, { id: "DUO", name: "Duo" } ];

        return list;
    }

    const onDrop = (data) => {
        const dataPDS = JSON.parse(data.pds);

        if(dataPDS.medicalProsInstitutions.length > 1) {
            btnElement.current.click();
            prepareEtab(dataPDS.id, dataPDS.medicalProsInstitutions, date);
        } else
            postRDV({
                date: date,
                halfday: bottomText,
                idInstitution: dataPDS.medicalProsInstitutions && dataPDS.medicalProsInstitutions.length > 0 ?
                    dataPDS.medicalProsInstitutions[0].idInstitution : [],
                idMedicalPro: dataPDS.id
            });
    }


    // VARIABLES

    const roleUser = localStorage.getItem("role");

    const optionsList = generateOptions();

    const allOptionsList = [
        ...dataActivity.map(a => ({ id: a.code, name: a.name })),
        {id: "SMS", name: "SMS"},
        {id: "PHO", name: "Phoning"},
        {id: "EMA", name: "Email"},
        // {id: "VSTR", name: "Visite résidence"},
        // {id: "VSHR", name: "Visite hors résidence"},
    ];

    const defaultLabel = (
        buttonText === "" ?
            t('CAL.LABEL.TO_DEFINE')
        : allOptionsList.find(o => o.id === buttonText) ?
            allOptionsList.find(o => o.id === buttonText).name
            :
            t('CAL.LABEL.TO_DEFINE')
    )


    // RENDER

    return(
        <div
            className={`calendar-inner ${up ? "up": ""}`}
            style={style}
        >
            {showSelect &&
                <CustomSelect
                    defaultText={defaultLabel}
                    optionsList={optionsList} 
                    handleOptionClick={(id) => {
                        if(idSchedule === undefined)
                            postActivite(date, bottomText, id);
                        else
                            editActivite(idSchedule, id);

                        setShowSelect(!showSelect);
                    }}
                />
            }
            
            <ButtonEdit
                dark={buttonText === "OFF"}
                label={showSelect ? t('COMMON.CANCEL') : defaultLabel}
                code={buttonText}
                handleAdd={() => (
                        (!updateSelect && path === "/calendar/current") || roleUser === "ROLE_ADMIN" || roleUser === "ROLE_QADMIN"
                    ) &&
                        setShowSelect(!showSelect)
                }
            />

            {(
                buttonText === "DUO"
                || (withDelegue !== undefined && withDelegue.user !== "")
            ) &&
                <div className="duoWith">

                    {withDelegue.user === "" ?
                        <p
                            data-toggle="modal"
                            data-target="#modalAddEditDuo"
                            onClick={() => handleDuo({ idSchedule })}
                        >
                            {t('CAL.LABEL.TO_DEFINE')} <i className="fa fa-edit"></i>
                        </p>
                    :
                        <p
                            data-toggle={(roleUser === "ROLE_SUPERVISEUR" || roleUser === "ROLE_DSM" || roleUser === "ROLE_DP" || roleUser === "ROLE_HP") && path === "/calendar/current" ? "modal" : "" }
                            data-target={(roleUser === "ROLE_SUPERVISEUR" || roleUser === "ROLE_DSM" || roleUser === "ROLE_DP" || roleUser === "ROLE_HP") && path === "/calendar/current" ? "#modalAddEditDuo" : ""}
                            className="showOverflow"
                            onClick={() => handleDuo({
                                idSchedule,
                                value: withDelegue.mission.id,
                                label: `${withDelegue.mission.name} ${withDelegue.user}`,
                                pds: withDelegue.user,
                                mission: withDelegue.mission.name
                            })}
                        >
                            {t('COMMON.WITH')} {withDelegue.user} <span className="infobulle" aria-label={`Mission : ${withDelegue.mission.name}`}>
                                <i className="fa fa-user-circle"></i>
                            </span>
                        </p>
                    }
                </div>
            }

            <Droppable
                types={['pds']} // <= allowed drop types
                onDrop={(data) => (buttonText === "FER") ?
                        NotificationManager.error('', t('PLANNING.HOLIDAY_WARNING'))
                    : onDrop(data)
                }
                className={`calendar-droppable`}
                enabled={!off && (
                    buttonText === "VIST" || buttonText === "" || buttonText === "FER"
                    ||
                    (buttonText === "DUO" && roleUser === "ROLE_DELEGUE")
                )}
            >
                {dropped.map((item) => {
                    return item.status !== 3 && 
                        <Appointment
                            key={`dropped-${item.id}`}
                            item={item}
                            iconTel={iconTel}
                            iconMail={iconMail}
                            details={details}
                            voirPDS={voirPDS}
                            path={path}
                            roleUser={roleUser}
                            getCR={getCR}
                            readOnlyCR={readOnlyCR}
                            handleNextApp={handleNextApp}
                            updateSelect={updateSelect}
                            buttonText={buttonText}
                            cancelApp={cancelApp}
                            deleteRDV={deleteRDV}
                            withDelegue={withDelegue}
                        />
                })}
            </Droppable>

            {(
                buttonText === "VIST" || buttonText === "" || (buttonText === "DUO" && roleUser === "ROLE_DELEGUE")
            ) &&
                pdsIsDragged
                &&
                    <button
                        className="btn-add calendar-add"
                        onClick={() => onDrop({ pds: JSON.stringify(pdsDragged) })}
                    >
                        +
                    </button>
            }

            <div className="calendar-timing">
                {bottomText}
            </div>

            <input type="hidden" ref={btnElement} data-toggle="modal" data-target="#modalSelectEtab" />

        </div>
    )
}