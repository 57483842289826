import { useContext } from 'react';
import { ActionsStoreContext } from  '../../../../../../store/store/storeActions';
import { ListStoreContext } from '../../../../../../store/store/storeList';
import { FilterStoreContext } from '../../../../../../store/store/storeFilters';
import { PopUpStoreContext } from '../../../../../../store/store/storePopUp';
import { t } from "../../../../../../pages/i18nFunctions";

export const TdBool = (props) => {
    const { stateActions } = useContext(ActionsStoreContext);
    const { stateList, dispatchList } = useContext(ListStoreContext);
    const { stateFilter } = useContext(FilterStoreContext);
    const { statePopUp } = useContext(PopUpStoreContext);
    const { line, el, type } = props;
    const { dataActions } = stateActions;
    const { dataPopUp } = statePopUp;

    const roleUser = localStorage.getItem("role");

    const handleClickTd = () => {
        let dDataOtherTabs;
        
        if(type === 'medicalProsInstitutions') {
            let params = {
                idPro: dataActions.id,
                idEtab: line.id
            }
            return stateActions.disableEtabPro(params).then(res => {
                return stateActions.getEtabPro(dataActions.id).then(res => {
                    const data = JSON.parse(res);
                    dDataOtherTabs = data;   
                    return dispatchList({type: 'setDataOtherTabs', dDataOtherTabs }); 
                });
            })
        } else if(type === 'medicalProSoftPop') {
            let params = {
                idPro: dataActions.id,
                idLog: line.idSoftware
            }
            return stateActions.disableLogPro(params).then(res => {
                return stateActions.getLogPro(dataActions.id).then((res) => {
                    const data = JSON.parse(res);
                    dDataOtherTabs = data;    
                    return dispatchList({type: 'setDataOtherTabs', dDataOtherTabs });
                });
            });
        }
        
    }


    const genBool = () => {
        switch (el.name) {
            case 'status':
                if(line[el.name] === true)
                    return <td>
                        <div onClick={() => dataPopUp.action !== "visu" && roleUser !== "ROLE_DELEGUE" && handleClickTd()} className="statusDisplay actif">
                            <span>
                                {type === "medicalProsInstitutions" ?
                                    t('PROS.LIST.POPUP_VIEW.CONSULTATION_PLACES.STATUS_CURRENT')
                                :
                                    t('INSTITUTION.STATUS.AC')
                                }
                            </span>
                        </div>
                    </td>;
                else   
                    return <td>
                        <div onClick={() => dataPopUp.action !== "visu" && roleUser !== "ROLE_DELEGUE" && handleClickTd()} className="statusDisplay inactif">
                            <span>
                                {type === "medicalProsInstitutions" ?
                                    t('PROS.LIST.POPUP_VIEW.CONSULTATION_PLACES.STATUS_PREVIOUS')
                                :
                                    t('INSTITUTION.STATUS.IN')
                                }
                            </span>
                        </div>
                    </td>;
            
            case 'isMainMedicalProSpeciality':
                if(line[el.name] === true)
                    return <td>
                        <div className="statusDisplay actif">
                            <span>{t('COMMON.PRINCIPAL')}</span>
                        </div>
                    </td>;
                else   
                    return  <td>
                        <div className="statusDisplay inactif">
                            <span>{t('COMMON.SECONDARY')}</span>
                        </div>
                    </td>;
            case 'withSupervision':
                if(line[el.name] === true)
                    return <td><p>{t('COMMON.YES')}</p></td>;
                else   
                    return <td><p>{t('COMMON.NO')}</p></td>;

            default:
                return <td>{line[el.name]}</td>;
        }
    }

    return(
        <>
            {genBool()}
        </>
    )
}