import { useState, useRef, useEffect, useContext } from 'react';
import "./Laboratoires.scss";
import { t } from "../../i18nFunctions";
import { NotificationManager } from 'react-notifications';
import ReactLoading from 'react-loading';
import { StoreContext } from "../../../store/store";

// components
import { ListRowLabo } from './ListRowLabo';
import { ListRowLaboProd } from './ListRowLaboProd';
import { Pagination } from '../../../components/Pagination/Pagination';
import { ButtonAdd } from '../../../components/UI/Button/ButtonAdd/ButtonAdd';
import { PopupLabo } from '../../../components/Popups/Laboratoires/PopupLabo';
import { PopupDelete } from '../../../components/Popups/PopupDelete';
import { PopupConfirmation } from '../../../components/Popups/PopupConfirmation';
import { PopupProd } from '../../../components/Popups/Laboratoires/PopupProd';
import { PopupQuestions } from '../../../components/Popups/Laboratoires/PopupQuestions';

// images
import aToZ from "../../../assets/img/icons/icon-a-z.png";
import zToA from "../../../assets/img/icons/icon-z-a.png";
import arrowDown from "../../../assets/img/icons/icon-arrow-down.png";

import {
    getAllLabo,
    postLabo,
    deleteLabo,
    editLabo,

    getLaboProduct,
    postProduct,
    deleteProduct,
    editProduct
} from '../../../store/index.service';

export const Laboratoires = () => {

    const [laboInfo, setLaboInfo] = useState([]);
    const [selectedLab, setSelectedLab] = useState({});
    const [nameIdEdit, setNameIdEdit] = useState({});
    const [name, setName] = useState("");
    const [nameFilter, setNameFilter] = useState("");
    const [idDelete, setIdDelete] = useState(-1);
    const [deleteType, setDeleteType] = useState("");
    const [editedProd, setEditedProd] = useState({});
    const [selectedProd, setSelectedProd] = useState({});
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [maxPerPage, setMaxPerPage] = useState(50);
    const [confirmSubmit, setConfirmSubmit] = useState(() => () => {});
    const [orderKey, setOrderKey] = useState("name");
    const [orderByKey, setOrderByKey] = useState({ name: true });
    const [loadingProd, setLoadingProd] = useState(false);
    const [laboProduct, setLaboProduct] = useState([]);
    const [nbResultats, setNbResultats] = useState(0);

    const { setLoadingStore } = useContext(StoreContext);

    const laboProdRef = useRef(null);
    const timer = useRef(null);

    // mounted
    useEffect(() => {
        handleGetAllLabo();
        timer.current = setTimeout(() => {}, 1000);
        // unmounted
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    // updates
    useEffect(() => {
        if(selectedLab.id)
            handleGetLaboProduct(selectedLab.id, page, name);
    }, [page]);

    const handleGetAllLabo = (valName = "") => {
        setLoadingStore(true);
        getAllLabo({ laboName: valName }).then(res => {
            const data = JSON.parse(res);
            if(data) {
                setLaboInfo(data);
                const index = data.map(l => l.id).indexOf(selectedLab.id);
                if(index !== -1) setSelectedLab({ id: data[index].id, name: data[index].name});
            }
            setLoadingStore(false);
        });
    }

    const handleGetLaboProduct = (id, p, n) => {
        setLoadingProd(true);

        getLaboProduct(id, { page: p, maxPerPage: maxPerPage, name: n, pagination: false })
        .then(res => {
            const data = JSON.parse(res);

            if(data && data.listProduct) setLaboProduct(data.listProduct);
            else setLaboProduct([]);

            if(data && data.nbrPages) setMaxPage(data.nbrPages);
            else setMaxPage(0);

            if(data && data.nbResultats) setNbResultats(data.nbResultats);
            else setNbResultats(0);

            setLoadingProd(false);
        });
    }

    const handleDelete = (id) => {
        const deleteItem = deleteType === "laboratoire" ? deleteLabo : deleteType === "produit" ? deleteProduct : () => {};
        
        deleteItem(id).then(() => {
            NotificationManager.success('', `${deleteType === "laboratoire" ? "Laboratoire" : deleteType === "produit" ? "Produit" : ""} supprimé`);
            if(deleteType === "laboratoire") {
                handleGetAllLabo();
                if(id === selectedLab.id) setSelectedLab({});
            }
            else if(deleteType === "produit") handleGetLaboProduct(selectedLab.id, page, name);
        }).catch(() => NotificationManager.error('', "Error"));
    }

    // functions

    const handlePressFilterbyKey = (key) => {
        setOrderByKey({ [key] : !orderByKey[key] });
        setOrderKey(key);
    }

    const handleOrderByKey = (l) => {
        let liste = [...l];
        if(orderByKey.name === false) liste.reverse(); 
        else liste.sort((a, b) =>{
            if(orderKey && byString(a, orderKey) !== undefined){
                if(byString(a, orderKey) < byString(b, orderKey)) return -1;
                if(byString(a, orderKey) > byString(b, orderKey)) return 1;
            }
            return 0;
        });
        return liste;
    }

    const byString = (o, s) =>{
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');

        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                if(s === "name") o = o[k].toLowerCase();
                else o = o[k];               
            } else return;
        }

        return o;
    }

    const handleFilter = (value, setter, type) => {
        clearTimeout(timer.current);
        setter(value);
        timer.current = setTimeout(() => {
            if(type === "product")
                handleGetLaboProduct(selectedLab.id, 1, value);
            if(type === "nameLab")
                handleGetAllLabo(value);
        }, 1000);
    }

    const showProducts = (id, name) => {
        laboProdRef.current.scrollIntoView();
        setSelectedLab({ id: id, name: name });
        handleGetLaboProduct(id, 1);
    }

    return (
    <section className="content" id="list-laboratoires">
            
        <section className="content-header">
            <h1>
                {t('SETTINGS.LABORATORIES')}
                <small>{t('SETTINGS.MANAGEMENT')}</small>
            </h1>
        </section>
    
        <section className="content lab">

            <div className="row">
                <div className="col-md-4">
                    <div className="box tab-entete labo">
                        <div className="nameEntete">
                            <p>{t('SETTINGS.NAME')}</p>
                            <img
                                style={{ width: "20px", height: "20px"}}
                                src={!orderByKey.name ? zToA : aToZ}
                                alt={!orderByKey.name ? "z-a" : "a-z"}
                                onClick={() => handlePressFilterbyKey("name")}
                            />
                            <input 
                                type="text" 
                                placeholder="..." 
                                value={nameFilter} 
                                onChange={(e) => handleFilter(e.target.value, setNameFilter, "nameLab")} 
                            />
                        </div>
                        <ButtonAdd
                            modal="#modalLabo"
                            handleAdd={() => setNameIdEdit({})}
                        />
                    </div>

                    <table className="list labo">
                        <tbody>
                            {handleOrderByKey(laboInfo).map(lab =>
                                <ListRowLabo
                                    key={lab.id}
                                    item={lab}
                                    showProducts={showProducts}
                                    handleNameIdEdit={(name, id) => setNameIdEdit({ name: name, id: id })}
                                    handleIdDelete={(id, type) => { setIdDelete(id); setDeleteType(type); }}
                                />
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="col-md-8" ref={laboProdRef}>
                {Object.keys(selectedLab).length > 0 &&
                <>
                    <div className="box info">
                        <p><span className="uppercase">{selectedLab.name}</span> - Liste des produits</p>
                        {loadingProd && <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader" />}
                    </div>
                    
                    <table className="box tab-entete labo-prod">
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <p>{t('SETTINGS.NAME')}</p>
                                        <img src={arrowDown} alt=""/>
                                    </div>
                                </td>
                                <td className='pres'>
                                    <div>
                                        <p>Type de présentation</p>
                                    </div>
                                </td>
                                <td className="center questions">
                                    <div>
                                        <p>Questions</p>
                                    </div>
                                </td>
                                <td className="center countries">
                                    <div>
                                        <p>Absence</p>
                                    </div>
                                </td>
                                <td>
                                    <ButtonAdd
                                        modal="#modalAddEditProd"
                                        handleAdd={() => setEditedProd({})}
                                    />
                                </td>
                            </tr>                                        
                            <tr className="bottom">
                                <td>
                                    <div>
                                        <input 
                                        type="text" 
                                        placeholder="..." 
                                        value={name} 
                                        onChange={(e) => handleFilter(e.target.value, setName, "product")} />
                                    </div>
                                </td>
                                <td>
                                </td>
                                <td className="center">
                                </td>
                                <td className="center">
                                </td>
                                <td>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="list labo-prod">
                        <tbody>
                            {laboProduct && laboProduct.map(prod =>
                                <ListRowLaboProd
                                    key={prod.idProduct}
                                    item={prod}
                                    handleIdDelete={(id, type) => { setIdDelete(id); setDeleteType(type); }}
                                    handleEditProd={(p) => setEditedProd(p)}
                                    seeQuestions={(p) => setSelectedProd(p)}
                                />
                            )}
                        </tbody>
                    </table>

                    {maxPage > 1 && 
                    <Pagination
                        maxPage={maxPage}
                        onChangePage={(data) => setPage(data)}
                        nbCount={nbResultats}
                    ></Pagination>
                    }
                </>
                }
                </div>
            </div>

        </section>

        <PopupLabo
        addLabo={(name) => postLabo(name).then(() => {
            NotificationManager.success('', "Laboratoire ajouté");
            handleGetAllLabo();
        }).catch(() => NotificationManager.error('', "Error"))}
        handleSubmit={(submit) => setConfirmSubmit(() => () => submit())}
        nameId={nameIdEdit}
        editLabo={(name, id) => editLabo(name, id).then(() => {
            NotificationManager.success('', "Laboratoire modifié");
            handleGetAllLabo();
        }).catch(() => NotificationManager.error('', "Error"))}
        />

        <PopupProd
        handleSubmit={(submit) => setConfirmSubmit(() => () => submit())}
        addProduct={(nameProd, pres, area) => postProduct(selectedLab.id, nameProd, pres, area).then(() => {
            NotificationManager.success('', "Produit ajouté");
            handleGetLaboProduct(selectedLab.id, page, name);
        }).catch(() => NotificationManager.error('', "Error"))}
        product={editedProd}
        editProduct={(idProd, nameProd, pres, area) => editProduct(idProd, nameProd, pres, area).then(() => {
            NotificationManager.success('', "Produit modifié");
            handleGetLaboProduct(selectedLab.id, page, name);
        }).catch(() => NotificationManager.error('', "Error"))}
        />

        <PopupQuestions
        handleSubmit={(submit) => setConfirmSubmit(() => () => submit())}
        selectedProd={selectedProd}
        getLaboProduct={() => handleGetLaboProduct(selectedLab.id, page, name)}
        />

        <PopupDelete
        title={deleteType === "laboratoire" ? t('DELETE.LAB') : deleteType === "produit" ? t('DELETE.PRODUCT') : ""}
        handleDelete={handleDelete}
        id={idDelete}
        />
        
        <PopupConfirmation message={t('COMMON.CONFIRM_MSG')} handleSubmit={confirmSubmit}/>

    </section>
    )
}