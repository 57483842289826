import ReactDOM from 'react-dom';
import './app.scss';
import { App } from './App';
import { StoreProvider } from './store/store';
import { FilterStoreContainer } from './store/store/storeFilters';
import { ListStoreContainer } from './store/store/storeList';
import { ActionsStoreContainer } from './store/store/storeActions';
import { PopUpStoreContainer } from './store/store/storePopUp';
import * as serviceWorker from './serviceWorker';
import 'moment/locale/fr';
import ClearCache from "./ClearCache";
//import * as Sentry from "@sentry/react";
//import { Integrations } from "@sentry/tracing";

/*Sentry.init({
    dsn: "https://dd303a534f01442594b3ff33b0b87b28@o341294.ingest.sentry.io/5372340",
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
  
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});*/

ReactDOM.render(
    <ClearCache>
    {({ loading, isLatestBuildDate, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestBuildDate) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
        }

        return (
            <StoreProvider>
                <FilterStoreContainer>
                    <ListStoreContainer>
                        <ActionsStoreContainer>
                            <PopUpStoreContainer>
                                <App/>
                            </PopUpStoreContainer>
                        </ActionsStoreContainer>
                    </ListStoreContainer>
                </FilterStoreContainer>
            </StoreProvider>
        );
    }}
    </ClearCache>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
