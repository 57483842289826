import { useRef } from 'react';
import { t } from "../../pages/i18nFunctions";

export const PopupConfirmation = ({ message = t('COMMON.CONFIRM_MSG'), handleSubmit }) => {

    const btnRef = useRef(null);

    return (
    <div className="modal fade in" id="modalConfirmation"
    tabIndex="-1" role="dialog" aria-labelledby="modalEditLabel" animate="animate">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div>
                    <div className="modal-header">
                        <h1 className="modal-title" id="myModalLabel">{message}</h1>
                    </div>
                    <div className="modal-footer">
                        <button
                        type="submit"
                        className="btn-save"
                        onClick={(e) => {
                            handleSubmit(e);
                            btnRef.current.click();
                        }}>
                            {t('GEN.BUTTON.CONFIRM')}
                        </button>
                        <button
                        type="button"
                        className="btn-cancel"
                        data-dismiss="modal"
                        ref={btnRef}
                        >{t('COMMON.CANCEL')}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}