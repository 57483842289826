import { useState, useRef } from 'react';
import { t } from "../../../pages/i18nFunctions";

// images
import iconSave from "../../../assets/img/icons/icon-save.png";

export const PopupSelectEtab = ({ etabs, choseEtab }) => {

    const [idEtab, setIdEtab] = useState(0);

    const btnRef = useRef(null);

    const handleSubmit = e => {

        if(idEtab) {
            e.preventDefault();
            choseEtab(idEtab);
            btnRef.current.click();
        }
    }
        
    return(
        <div className="modal fade in" id="modalSelectEtab" tabIndex="-1" role="dialog" aria-labelledby="modalSelectEtabLabel" animate="animate">
            <div className="modal-dialog" role="document">
                <div className="modal-content">

                    <div className="modal-header">
                        <h1 className="modal-title" id="myModalLabel">{t('PLANNING.CR.SELECT_ETAB')}</h1>
                    </div>
                    
                    <div className="modal-body">
                        <form id="formSelectEtab">
                            <div className="radio-div">
                                <div className="radio-form">

                                    {etabs.map(etab =>
                                        <div className="radio" key={`radioEtab${etab.idInstitution}`}>
                                            <input
                                                type="radio"
                                                name="etab"
                                                checked={idEtab === etab.idInstitution}
                                                value={etab.idInstitution}
                                                onChange={e => setIdEtab(parseInt(e.target.value))}
                                                id={`selectEtab${etab.idInstitution}`}
                                            />
                                            <label htmlFor={`selectEtab${etab.idInstitution}`}>
                                                <b>{etab.nameInstitution}</b> <small>{etab.zoneGeoInstitution}</small>
                                            </label>
                                        </div>
                                    )}

                                </div>
                            </div>

                            
                        </form>
                    </div>

                    <div className="modal-footer">

                        <button
                            type="submit"
                            form="formSelectEtab"
                            className="btn-save"
                            onClick={handleSubmit}
                            disabled={!idEtab}
                        >
                            <img src={iconSave} alt=""/>
                            {t('COMMON.SAVE')}
                        </button>

                        <button
                            type="button"
                            className="btn-cancel"
                            data-dismiss="modal"
                            ref={btnRef}
                            onClick={() => setIdEtab(0)}
                        >{t('COMMON.CANCEL')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}