import { DeleteSpePro } from './Type/DeleteSpePro';

export const SpecialityProsPopUpManager = (props) => {
    const { data, dispatchPop } = props;
    const { action } = data;

    const generatePopUps = () => {
        switch (action) {
            case 'delete':
                return <DeleteSpePro data={data} dispatchPop={dispatchPop} />;
                
            default:
                return false;
        }
    }

    return (
        <>
            {
                <div className="modal-body">
                    {generatePopUps()}
                </div>
            }
        </>
    )
}