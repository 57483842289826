import i18n from "i18next";

function t(cell) {
    return (i18n.t(cell));
}

function switchlanguage(language) {
    i18n.changeLanguage(language);
}

export {t, switchlanguage}