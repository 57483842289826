import { useContext, useEffect, useState, useRef } from 'react';
import { SearchSelect } from './SearchSelect/SearchSelect';
import './index.scss';

import { ActionsStoreContext } from '../../../store/store/storeActions';
import { FilterStoreContext } from '../../../store/store/storeFilters';
import { ListStoreContext } from '../../../store/store/storeList';

export const SearchFilter = (props) => {
    const { dataFilter, title, type } = props;
    
	const { stateActions, dispatchActions } = useContext(ActionsStoreContext);
	const { stateFilter, dispatchFilters } = useContext(FilterStoreContext);
	const { stateList, dispatchList } = useContext(ListStoreContext);
    
    const [item, setItem] = useState({});

    const timer = useRef(null);

    useEffect(() => {
        timer.current = setTimeout(() => {}, 1000);
        // unmounted
        return () => {
            clearTimeout(timer.current);
            dispatchList({type: 'setDataParamSearch', dDataParamSearch: {} });
        };
    }, []);

    useEffect(() => {
        switch (type) {
            case 'etab':
                return setItem({
                    // country: stateFilter.memoSearchCountry !== null ? stateFilter.memoSearchCountry.country : null,//idCountry
                    country: JSON.parse(localStorage.getItem("userCountries"))[0].id,
                    label: null, //nom
                    type: null, //id
                    zoneGeo: null, //idCountry
                    mission: null,
                });
                
            case 'medicalProPage':
                return setItem({
                    // country: stateFilter.memoSearchCountry !== null ? stateFilter.memoSearchCountry.country : null,//idCountry
                    country: JSON.parse(localStorage.getItem("userCountries"))[0].id,
                    label: null, //nom
                    location: null,
                    zoneGeo: null, //idCountry
                    mission: null,
                    protype: null,
                    speciality: null
                });
        
            default:
                break;
        }
    }, [type]);

    useEffect(() => {
      let dDataParamSearch = item;
      return dispatchList({type: 'setDataParamSearch', dDataParamSearch });
    }, [item])
    
    const handleSearchInput = (value, name, type, eventType = null) => {
        clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            handleChange(value, name, type, eventType);
        }, 1000);
    }
    
    const roleUser = localStorage.getItem('role');

    const generateSearchFilter = () => {
        return dataFilter.map((el, i) => {
            switch (el.type) {
                case 'text':
                    return <div key={i} className="containerSearchInput">
                        <label>{el.displayName}</label>
                        <input
                            placeholder={el.placeholder}
                            type='text'
                            // onChange={(e) => { handleSearchInput(e.target.value, el.name, el.type, {action: e.target.value.length <= 2 ? 'clear' : undefined}) }}
                            onChange={(e) => handleSearchInput(e.target.value, el.name, el.type, { action: undefined })}
                        ></input>
                    </div>;

                case 'select':
                    if(typeof el.restrict === 'object')
                        if(el.restrict.includes(roleUser))
                            return false;
                        else if(el.name === "country" && (item.mission || item.zoneGeo))
                            return false;
                        else
                            return <SearchSelect 
                                type={props.type}
                                key={i}
                                el={el}
                                handleChange={(e, type) => { handleChange(e, el.name, el.type, type) }}
                            />

                    else if(el.restrict === false)
                        if(el.name === "country" && (item.mission || item.zoneGeo))
                            return false;
                        else
                            return <SearchSelect 
                                type={props.type}
                                key={i}
                                el={el}
                                handleChange={(e, type) => { handleChange(e, el.name, el.type, type) }}
                            />
                else
                    return false;

                default:
                    break;
            }
        })
    }

    const handleChange = (value, name, type, eventType = null) => {

        let dDataPagination = { ...stateList.dataPagination, page: 1 };
        dispatchList({ type: 'setDataPagination', dDataPagination });
        
        if(typeof eventType !== 'undefined' && eventType.action === 'clear') {
            let params;
            if(props.type === 'medicalProPage')
                params = {
                    ...item,
                    [name]: null,
                };
            else 
                params = {
                    ...item,
                    [name]: null,
                    ...stateFilter.memoSearchCountry,
                };
            
            if(name === 'country') {
                let mSearchCountry = { 
                    country: null,
                    countryName: ""
                };
                dispatchFilters({type: 'setMemoSearchCountry', mSearchCountry})
            }
            else if(name === "protype") {
                let sSearchTypePDS = null;
                dispatchFilters({type: 'setSearchTypePDS', sSearchTypePDS});
            }

            if(value !== null && value.length <= 2)
                return setItem(params);
            
            return setItem(params), stateActions.getAll(params, false);
        }
        let params = {                
            ...item,
            [name]: type !== 'text' ? value.value : value,
            ...stateFilter.memoSearchCountry
        }
        if(name === 'country') {
            let mSearchCountry = { 
                country: value.value,
                countryName: value.label
            };
            params = {
                ...params,
                country: value.value,
                countryName: value.label
            }
            dispatchFilters({type: 'setMemoSearchCountry', mSearchCountry})
        }
        else if(name === "protype") {
            let sSearchTypePDS = value.value;
            dispatchFilters({type: 'setSearchTypePDS', sSearchTypePDS});

            stateActions.getSpeByType(value.value).then((res) => {
                const data = JSON.parse(res);
                let dDataSpecialityByType = data ?? [];
                return dispatchList({type: 'setDataSpecialityByType', dDataSpecialityByType });
            });
        }

        setItem(params);
        return stateActions.getAll(params, false);
    }

    return(
        <>
            <section className="content searchBar">
                <div className="box info professionnels">
                    <p>{title}</p>
                    
                    {generateSearchFilter()}
                </div>
            </section>
        </>
    )
}
