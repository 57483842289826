import { useState, useEffect, useRef, useContext } from 'react';
import "./TypesPDS.scss";
import { t } from "../../i18nFunctions";
import { StoreContext } from "../../../store/store";

// images
import aToZ from "../../../assets/img/icons/icon-a-z.png";
import zToA from "../../../assets/img/icons/icon-z-a.png";

import {
    getAllProType
} from '../../../store/index.service';

export const TypesPDS = () => {

    const [listTypePro, setListTypePro] = useState([]);
    const [convOrder, setConvOrder] = useState(true);
    const [search, setSearch] = useState("");

    const { setLoadingStore } = useContext(StoreContext);

    // mounted
    useEffect(() => {
        setLoadingStore(true);

        getAllProType().then(res => {
            const data = JSON.parse(res);
            if(data) setListTypePro(data);
            else setListTypePro([]);
            setLoadingStore(false);
        });
    }, []);

    // functions

    const sortList = (l) => {
        let list = l.filter(el => el.name.toLowerCase().includes(search.toLowerCase()));

        list.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        });

        if(!convOrder) list.reverse();

        return list;
    }

    return(
        <section className="content">
        
            <section className="content-header">
                <h1>
                    {t('SETTINGS.PDS_TYPE')}
                    <small>{t('SETTINGS.PDS_MANAGEMENT')}</small>
                </h1>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-md-6">
                        <table className="box tab-entete protype">
                            <tbody>
                                <tr>
                                    <td>
                                        <div>
                                            <p>{t('SETTINGS.NAME')}</p>
                                            <img
                                            src={convOrder ? aToZ : zToA}
                                            alt={convOrder ? "a-z" : "z-a"}
                                            onClick={() => setConvOrder(!convOrder)}/>
                                        </div>
                                        <div>
                                            <input
                                            type="text"
                                            placeholder="..."
                                            value={search}
                                            className="input-sm form-control"
                                            onChange={(e) => setSearch(e.target.value)}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="list protype">
                            <tbody>
                                { listTypePro.length > 0 ?
                                    sortList(listTypePro).map(el => 
                                        <tr key={el.id}>
                                            <td>{el.name}</td>
                                        </tr>
                                    )
                                : <tr><td>{"Aucun type trouvé."}</td></tr> }
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

        </section>
    )
}