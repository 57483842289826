
import { Link } from 'react-router-dom';
import iconCiblage from "../../../../../../assets/img/icons/icon-cible.png";

export const LinkCiblage = (props) => {
    const { line } = props;

    return (
        <Link className="icon-btn" to={`/missions/${line.id}/ciblage`}>
            <img src={iconCiblage} alt="ciblage" className="inline-block"/>
        </Link>
    )
}