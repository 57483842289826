import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getAllUsers = (data) => {
    return new Promise((resolve, reject) => {
        try {
            let params = {};

            if(data !== undefined){
                params = {                    
                    page: (data.page !== undefined) ? data.page : 1,
                    maxPerPage: (data.maxPerPage !== undefined) ? data.maxPerPage : 50,
                    search: (data.search !== undefined && data.search !== "") ? data.search : "",
                    firstname: (data.firstName !== undefined && data.firstName !== "") ? data.firstName : "",
                    lastname: (data.lastName !== undefined && data.lastName !== "") ? data.lastName : "",
                    username: (data.username !== undefined && data.username !== "") ? data.username : "",
                    status: data.status ?? "",
                    roles: data.role ?? ""
                }
            }
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).post('api/list/user', params)
                .then((response) => {
                    resolve(JSON.stringify(response.data));    
                })
                .catch((error) => {
                    reject(error);
                });
            })
            .catch((error) => {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getSimpleUsers = (data) => {
    return new Promise((resolve, reject) => {
        try {
            let params = {};

            if(data !== undefined){
                params = {                    
                    page: (data.page !== undefined) ? data.page : 1,
                    maxPerPage: (data.maxPerPage !== undefined) ? data.maxPerPage : 50,
                    search: (data.search !== undefined && data.search !== "") ? data.search : "",
                    firstname: (data.firstName !== undefined && data.firstName !== "") ? data.firstName : "",
                    lastname: (data.lastName !== undefined && data.lastName !== "") ? data.lastName : "",
                    username: (data.username !== undefined && data.username !== "") ? data.username : "",
                    status: data.status ?? "",
                    roles: data.role ?? ""
                }
            }

            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json'
                    }
                }).post('api/list/simple/user', params)
                .then((response) => {
                    resolve(JSON.stringify(response.data));    
                })
                .catch((error) => {
                    reject(error);
                });
            })
            .catch((error) => {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const postUser = (user) => {
    if(typeof user.roles !== 'string') {
        user.roles = user.roles[0];
    }
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).post('api/user', {
                    "roles": user.roles,
                    "lastName": user.lastName,
                    "firstName": user.firstName,
                    "username": user.emailAsUsername ? user.email : user.username,
                    "email": user.email === "" ? null : user.email,
                    "password": user.pwd,
                    "restrictedArea": user.restrictedArea,
                    "isLoginEqualsEmail": user.isLoginEqualsEmail,
                    "labos": user.labos
                })
                .then((response) => {
                    resolve(JSON.stringify(response.data));    
                })
                .catch((error) => {
                    reject(error);
                });
            })
            .catch((error) => {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const editUser = (user) => {
    return new Promise((resolve, reject) => {
        try {
            let params = {};

            if(user !== undefined) {

                if(user.roles && typeof user.roles !== 'string') {
                    user.roles = user.roles[0];
                }

                params = {                    
                    "roles": user.roles,
                    "lastName": user.lastName,
                    "firstName": user.firstName,
                    "username": user.username,
                    "email": user.email === "" ? null : user.email,
                    "restrictedArea": user.restrictedArea,
                    "isLoginEqualsEmail": user.isLoginEqualsEmail,
                    "labos": user.labos,
                    "password": user.pwd !== "" ? user.pwd : undefined
                }

                if(user.modifyPwd)
                    params = Object.assign(params, { "password": user.pwd });

                if(user.passwordOnly === true)
                    params = {
                        id: user.id,
                        passwordonly: user.passwordOnly,
                        password: user.pwd
                    }
            }

            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).put('api/user/'+user.id, params)
                .then((response) => {
                    resolve(JSON.stringify(response.data));    
                })
                .catch((error) => {
                    reject(error);
                });
            })
            .catch((error) => {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const deleteUser = (id) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).delete('api/user/delete/'+id, {})
                .then((response) => {
                    resolve(JSON.stringify(response.data));    
                })
                .catch((error) => {
                    reject(error);
                });
            })
            .catch((error) => {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const disableUser = (id) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).delete('api/user/disable/'+id, {})
                .then((response) => {
                    resolve(JSON.stringify(response.data));    
                })
                .catch((error) => {
                    reject(error);
                });
            })
            .catch((error) => {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}