import { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import "./Rapport2.scss";
import { t } from "../../i18nFunctions";
import { StoreContext } from '../../../store/store';
import ReactLoading from 'react-loading';
import Select from 'react-select';
import { NotificationManager } from 'react-notifications';
import { ActionsStoreContext } from '../../../store/store/storeActions';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { format } from 'date-fns';
import { enUS, fr } from 'react-date-range/dist/locale';

import { Pagination } from '../../../components/Pagination/Pagination';
import { MultiCheckbox } from '../../../components/UI/Checkbox/MultiCheckbox/MultiCheckbox';
import { CallsLine } from '../../../components/CallsLine/CallsLine';
import { PopUpManager } from '../../../components/UI/PopUp/PopUpManager';
import { RowRapport2 } from './RowRapport2';
import { PopupRemplirCR } from '../../../components/Popups/Planning/PopupRemplirCR';
import { VisitFilter } from '../../../components/VisitFilter/VisitFilter';

import {
    generateEtabOptions
} from "../../../store/helpers";

// API
import {
    getProdLabByMission,
    getCycleMaxDate,
    getRapport2,
    getAllProType,
    getAllSpecialite,
    getTypeEtab,
    getAllEtabs,
    getCurrentCycle,
    getZonesByCountry,

    getHistoryPDS,
    getSpeByType,
    getSpePro,
    getEtabPro,
    getLogPro,
    getLogiciels,
    verifPro,
    getMedicalProsQualifProd,

    getRPTypes,
    getObjectiveList,
    getTypeContact,
    getCR
} from "../../../store/index.service";

export const Rapport2 = () => {

    const { setLoadingStore } = useContext(StoreContext);
    const { dispatchActions } = useContext(ActionsStoreContext);

    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const timer = useRef(null);

    const [pagination, setPagination] = useState({
        page: 1,
        maxPage: 1,
        maxPerPage: 50,
        nbResultats: 0
    });

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate()+1)));
    const [key, setKey] = useState("selection");
    const [calendarIsHidden, setCalendarIsHidden] = useState(true);
    const [enteringDate, setEnteringDate] = useState(false);
    const [cycleMaxDate, setCycleMaxDate] = useState({ list: null, loading: false });
    const [validCycle, setValidCycle] = useState(false);
    const [selectedPro, setSelectedPro] = useState({});
    const [loadingCurrentCycle, setLoadingCurrentCycle] = useState(true);
    const [rapportReady, setRapportReady] = useState(true);

    const [prodList, setProdList] = useState([]);
    const [loadingProdLabMission, setLoadingProdLabMission] = useState(true);
    const [onlyProds, setOnlyProds] = useState([]);
    const [prodTab, setProdTab] = useState([]);
    const [listProsActivite, setListProsActivite] = useState([]);

    const [dataMission, setDataMission] = useState({});
    const [statsActivityCalls, setStatsActivityCalls] = useState({});

    const [listVilles, setListVilles] = useState({ list: [], loading: true });
    const [etabList, setEtabList] = useState({ list: [], loading: true });
    const [typeEtabList, setTypeEtabList] = useState([]);
    const [listTypePDS, setListTypePDS] = useState({ list: [], loading: true });
    const [listSpe, setListSpe] = useState({ list: [], loading: true });

    const [filtres, setFiltres] = useState({
        name: "",
        lieuConsultation: "",
        fname: "",
        ref: "",
        pds: null,
        spe: null,
        city: null,
        etab: null
    });

    const [filtersVisit, setFiltersVisit] = useState({});

    let [crReady, setCrReady] = useState(false);
    let [cr, setCr] = useState();
    let [listRP, setListRP] = useState([]);
    let [listObjectifs, setListObjectifs] = useState([]);
    let [listTypeHisto, setListTypeHisto] = useState([]);

    const getData = (id) => {
        getRPTypes().then(res => {
            const data = JSON.parse(res);
            return setListRP(data)
        });
        getObjectiveList().then(res => {
            const data = JSON.parse(res);
            return setListObjectifs(data);
        });
        getTypeContact().then(res => {
            const data = JSON.parse(res);
            if(data) setListTypeHisto(data);
        });
        getCR(id).then(res => {
            const data = JSON.parse(res);
            setCr(data);
            if(data)
                return setCrReady(true);
            else 
                return setCrReady(false);
        });
    }

    let allActions = {
        getHistoryPDS: getHistoryPDS,
        getSpeByType: getSpeByType,
        getSpePro: getSpePro,
        getEtabPro: getEtabPro,
        getLogPro: getLogPro,
        getLogiciels: getLogiciels,
        verifPro: verifPro,
        getMedicalProsQualifProd: getMedicalProsQualifProd,
        getData: getData,
	};

    useEffect(() => {

        dispatchActions({ type: 'initAllActions', allActions });

        timer.current = setTimeout(() => {}, 1000);

        getProdLabByMission(id).then(res => {
            const data = JSON.parse(res);
            if(data) {
                let tab = [];
                data.forEach(lab => lab.products.forEach(prod => tab.push(prod)));
                setProdList(data);
                setOnlyProds(tab);
            }
            setLoadingProdLabMission(false);
        });

        getTypeEtab().then(res => {
            const data = JSON.parse(res);
            if(data) setTypeEtabList(data);
        });

        getAllProType().then(res => {
            const data = JSON.parse(res);
            if(data) setListTypePDS({
                list: data,
                loading: false
            });
        });

        handleGetAllSpecialite();

        getCurrentCycle(id).then(res => {
            const data = JSON.parse(res);
            let dataM = {};

            if(data.dataMission) {
                dataM = data.dataMission[0];

                if(dataMission.country === undefined) {
                    handleGetAllEtabs("", dataM.country.idCountry);
                    
                    getZonesByCountry(dataM.country.nameCountry).then(res => {
                        const data = JSON.parse(res);

                        if(data)
                            setListVilles({
                                list: generateVilleList(data),
                                loading: false
                            });
                    });
                }
            }

            if(data.code === "200" || data.code === 200) {
                handleGetRapport2(1, new Date(data.StartDate.slice(0, 10)), new Date(data.EndDate.slice(0, 10)));

                setValidCycle(true);
                setStartDate(new Date(data.StartDate.slice(0, 10)));
                setEndDate(new Date(data.EndDate.slice(0, 10)));
            } else {
                setValidCycle(false);
                NotificationManager.warning('', t('COMMON.NOTIFICATIONS.NO_CYCLE'));
            }

            setDataMission(dataM);
            setLoadingCurrentCycle(false);
        });

        // unmounted
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    // updates

    useEffect(() => {
        if(validCycle) {
            setPagination({ ...pagination, page: 1 });
            handleGetRapport2(1, startDate, endDate);
        }
    }, [prodTab, filtres.pds, filtres.spe, filtres.etab, filtres.city, filtersVisit]);

    const handleGetAllSpecialite = (body) => {

        setListSpe({ ...listSpe, loading: true})
        getAllSpecialite(body).then(res => {
            const data = JSON.parse(res);
            setListSpe({
                list: data.listSpeciality ?? [],
                loading: false
            });
        });
    }

    const handleGetAllEtabs = (label, idCountry) => {

        setEtabList({ ...etabList, loading: true});

        getAllEtabs({
            page: 1,
            maxPerPage: 100,
            label: label,
            country: idCountry
        }).then(res => {
            const data = JSON.parse(res);
            setEtabList({
                list: data ?? [],
                loading: false
            });
        });
    }

    const handleGetRapport2 = (page, start, end, params) => {

        setLoadingStore(true);
        setRapportReady(false);

        const reqBody = params ?
            { ...params, ...filtersVisit }
        : {
            page: page,
            maxPerPage: pagination.maxPerPage,
            pdsFirstName: filtres.fname,
            pdsLastName: filtres.name,
            pdsInstitution: filtres.etab === null ? null : parseInt(filtres.etab.value),
            pdsType: filtres.pds === null ? null : parseInt(filtres.pds.value),
            pdsSpeciality: filtres.spe === null ? null : parseInt(filtres.spe.value),
            products: prodTab.map(p => p.id),
            pdsReference: filtres.ref,
            startDate: `${format(start, 'yyyy-MM-dd')}T00:00:00.000Z`,
            endDate: `${format(end, 'yyyy-MM-dd')}T00:00:00.000Z`,
            city: filtres.city !== null ? filtres.city.value : null,
            ...filtersVisit
        }

        getRapport2(id, reqBody).then(res => {
            const data = JSON.parse(res);

            if(data) {
                let listProActi = [];
                let dataM = {};
                let stats = {};

                if(data.dataMission)
                    dataM = data.dataMission[0];

                if(data.listMissionMedicalPro)
                    listProActi = data.listMissionMedicalPro;

                if(data.statsActivityCalls)
                    stats = data.statsActivityCalls;

                setDataMission(dataM);
                setListProsActivite(listProActi);
                setStatsActivityCalls(stats);
                setPagination({ ...pagination,
                    maxPage: data.nbrPages,
                    nbResultats: data.nbResultats
                });
            }
            
            setLoadingStore(false);
            setRapportReady(true);
        });
    }

    // functions

    const generateVilleList = (zone) => {
        let table = [];

        Object.keys(zone).map((item, i) => {

            table.push({
                label: zone[item].name,
                options: []
            });

            Object.keys(zone[item]).map((ville) => {

                if((ville !== "name" && ville !== "id")) {
                    table[i].options.push(
                        {
                            value: zone[item][ville].id,
                            label: zone[item][ville].name
                        }
                    )
                }
            });
        });
        return table;
    }

    const changeDate = (item) => {
        if(!(item.selection.startDate !== item.selection.endDate)) {
            const formatedStartDate = format(item.selection.startDate, 'yyyy-MM-dd') + 'T00:00:00.000Z';
            setCycleMaxDate({ ...cycleMaxDate, loading: true });

            getCycleMaxDate(id, formatedStartDate).then(res => {
                const data = JSON.parse(res).endDatePossible;

                if(data) {
                    setCycleMaxDate({ list: new Date(data), loading: false });
                    setValidCycle(true);
                    setStartDate(new Date(item.selection.startDate) > new Date(data) ? new Date(data) : item.selection.startDate);
                    // setEndDate(new Date(endDate) > new Date(data) ? new Date(data) : endDate);
                    NotificationManager.info('', t('COMMON.NOTIFICATIONS.END_DATE'));
                }
                else {
                    setCycleMaxDate({ list: null, loading: false });
                    setValidCycle(false);
                    NotificationManager.warning('', t('COMMON.NOTIFICATIONS.NO_CYCLE'));
                }
            });
        }
        if(enteringDate && validCycle && item.selection.startDate && item.selection.endDate)
            handleGetRapport2(1, item.selection.startDate, item.selection.endDate);

        setStartDate(item.selection.startDate);
        setEndDate(item.selection.endDate);
        setKey(item.selection.key);
        setCycleMaxDate({ ...cycleMaxDate, list: null });
        setCalendarIsHidden(item.selection.startDate !== item.selection.endDate);
        setEnteringDate(!enteringDate);
        setValidCycle(!enteringDate ? false : validCycle);
        setStatsActivityCalls({});
        setListProsActivite([]);
    }

    const handleFilter = (value, key) => {
        clearTimeout(timer.current);

        const fil = { ...filtres, [key]: value };
        setFiltres(fil);
    
        timer.current = setTimeout(() => {

            if(key === "lieuConsultation")
                handleGetAllEtabs(value, dataMission.country ? dataMission.country.idCountry : null);
            
            else if(validCycle){
                setPagination({ ...pagination, page: 1 });
                handleGetRapport2(1, startDate, endDate, {
                    page: 1,
                    maxPerPage: pagination.maxPerPage,
                    pdsFirstName: fil.fname,
                    pdsLastName: fil.name,
                    pdsInstitution: fil.etab === null ? null : parseInt(fil.etab.value),
                    pdsType: fil.pds === null ? null : parseInt(fil.pds.value),
                    pdsSpeciality: fil.spe === null ? null : parseInt(fil.spe.value),
                    products: prodTab.map(p => p.id),
                    pdsReference: fil.ref,
                    startDate: `${format(startDate, 'yyyy-MM-dd')}T00:00:00.000Z`,
                    endDate: `${format(endDate, 'yyyy-MM-dd')}T00:00:00.000Z`,
                    city: fil.city !== null ? fil.city.value : null
                });
            }
            
        }, 1000);
    }

    const handleProds = tab => {
        clearTimeout(timer.current);
    
        timer.current = setTimeout(() => {
            setProdTab(tab);
        }, 1000);
    }

    const formatGroupLabel = data => (<div><span>{data.label}</span></div>);

    return (
    <>
        <section className='content'>
            <section className="content-header">
                {location.pathname.slice((location.pathname.length-8), location.pathname.length) !== "/delegue" &&
                    <span className="btn-back" onClick={() => navigate(-1)}>&lt; Retour</span>
                }

                <h1 style={{display: "inline-block", marginTop: 15}}>
                    {t('SUIVI.RAPPORT.ACTIVITY')} - {t('SUIVI.RAPPORT.TITLE')} 2 - &nbsp;
                    {dataMission.delegue && `${dataMission.delegue.fullNameDelegue}`}
                </h1>

                {location.pathname.slice((location.pathname.length-8), location.pathname.length) !== "/delegue" &&
                    <Link className="btn-rapport" to={`/rapport1/${id}`}>{t('SUIVI.SEE_REPORT')} 1</Link>
                }
            </section>

            <section className='content rapport2'>
                <div className="row">

                    <div className='col-md-3'>
                        <div className='box info ciblage'>
                            <p style={{display: "inline-block"}}>{t('SUIVI.PERIOD')}</p>
                            {(loadingCurrentCycle || !rapportReady) &&
                                <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={15} width={15} className="spinner-loader" />
                            }

                            <div className="inner-addon right-addon">
                                <i className="glyphicon glyphicon-calendar"></i>
                                <input
                                    className="form-control"
                                    onClick={() => {
                                        setCalendarIsHidden(!calendarIsHidden);
                                        setEnteringDate(false);
                                    }}
                                    value={format(startDate, 'dd/MM/yyyy') + ' - ' + format(endDate, 'dd/MM/yyyy')}
                                    type="text"
                                    onChange={() => {}}
                                    disabled={!rapportReady}
                                />
                            </div>

                            { !calendarIsHidden &&
                            <div className="calendar-wrapper">
                                { cycleMaxDate.loading &&
                                <div className="calendar-loading">
                                    <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={30} width={30} className="spinner-loader" />
                                </div>
                                }
                                <DateRange
                                    {...(cycleMaxDate.list && enteringDate ? { maxDate: cycleMaxDate.list } : {})}
                                    locale={localStorage.getItem('currentLanguage') === "en" ? enUS : fr}
                                    editableDateInputs={!cycleMaxDate.loading}
                                    onChange={item => !cycleMaxDate.loading && changeDate(item)}
                                    showDateDisplay={false}
                                    moveRangeOnFirstSelection={false}
                                    ranges={[{
                                        startDate: startDate,
                                        endDate: endDate,
                                        key: key
                                    }]}
                                    dragSelectionEnabled={false}
                                />
                            </div>
                            }
                        </div>
                    </div>

                    <div className='col-md-3'>
                        <div className='box info ciblage'>
                            <p>{t('SUIVI.RAPPORT.PROD')}</p>
                            {loadingProdLabMission &&
                                <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader" />
                            }
                            
                            <MultiCheckbox
                                data={prodList}
                                type="laboratory"
                                handleCheck={(t) => handleProds(t)}
                            />
                        </div>
                    </div>

                </div>

                <div className="row calls">
                    <div className="col-md-12">
                        <h2>{t('SUIVI.RAPPORT.CALLS_TITLE')}</h2>
                    </div>

                    <div id="calls-legend">

                        <div className='group protype'>
                            <div className="color"></div>
                            <span>{t('SUIVI.RAPPORT.LEGEND.PDS_TYPE')}</span>
                        </div>

                        <div className='group spe'>
                            <div className="color"></div>
                            <span>{t('SUIVI.RAPPORT.LEGEND.SPE')}</span>
                        </div>

                        <div className='group pds'>
                            <div className="color"></div>
                            <span>{t('SUIVI.RAPPORT.LEGEND.PDS')}</span>
                        </div>

                        <div className='group remaining'>
                            <div className="color"></div>
                            <span>{t('SUIVI.RAPPORT.LEGEND.REMAINING_CALLS')}</span>
                        </div>

                        <div className='group hc'>
                            <div className="color"></div>
                            <span>{t('SUIVI.RAPPORT.LEGEND.OOT')}</span>
                        </div>

                        <div className='group hcP'>
                            <div className="color"></div>
                            <span>{t('SUIVI.RAPPORT.LEGEND.OOT_PRODUCT')}</span>
                        </div>

                    </div>

                    <div className="col-md-12 calls-tab">
                        {statsActivityCalls.MedicalProTypes ?

                            statsActivityCalls.MedicalProTypes.map((pds, i) => {
                                if(!(pds.callsRealized === 0 && pds.callsToBeRealized <= 0 && pds.nbrHorsCible === 0 && pds.horsCibleProduit === 0)) {
                                    return (
                                        <CallsLine
                                            data={pds}
                                            key={`calls-line-type-${i}`}
                                        />
                                    )
                                }
                            })
                        :
                            <p>{t('SUIVI.RAPPORT.NO_DATA')}</p>
                        }

                        {statsActivityCalls.SpecialityMed &&

                        statsActivityCalls.SpecialityMed.map((spe, i) => {
                            if(!(spe.callsRealized === 0 && spe.callsToBeRealized <= 0 && spe.nbrHorsCible === 0 && spe.horsCibleProduit === 0)) {
                                return (
                                    <CallsLine
                                        data={spe}
                                        key={`calls-line-spe-${i}`}
                                        spe
                                    />
                                )
                            }
                        })}
                    </div>
                </div>

                <div className='row'>

                    <div className='col-md-12'>
                        <VisitFilter
                            handleGetRapport2={(params) => setFiltersVisit(params)}
                        />
                    </div>

                </div>

                <div className="row cibles-list">
                    <div className="col-md-12">
                        <h2>{t('SUIVI.RAPPORT.LIST')}</h2>
                    </div>

                    <div className="col-md-12 cibles-tab">

                        <table className="box tab-entete rapport2">
                            <tbody>
                                <tr className="top">
                                    <td className="visit fixedTD">
                                        <div><p>{t('SUIVI.RAPPORT.LAST_VISIT')}</p></div>
                                    </td>
                                    <td className="visit fixedTD col2">
                                        <div><p>{t('SUIVI.RAPPORT.NEXT_VISIT')}</p></div>
                                    </td>
                                    <td className="ref fixedTD col3">
                                        <div><p>{t('SUIVI.RAPPORT.REF')}</p></div>
                                    </td>
                                    <td className="ref fixedTD col4">
                                        <div><p>{t('SUIVI.RAPPORT.NAME')}</p></div>
                                    </td>
                                    <td className="ref fixedTD col5">
                                        <div><p>{t('SUIVI.RAPPORT.FNAME')}</p></div>
                                    </td>
                                    <td className="ref fixedTD col6">
                                        <div>
                                            <p>{t('SUIVI.RAPPORT.TYPE')}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <p>{t('SUIVI.RAPPORT.SPE')}</p>
                                        </div>
                                    </td>
                                    <td className="ville">
                                        <div><p>{t('SUIVI.RAPPORT.CITY')}</p></div>
                                    </td>
                                    <td className="etab">
                                        <div><p>{t('SUIVI.RAPPORT.ETAB')}</p></div>
                                    </td>
                                    {onlyProds.map((prod, i) => {
                                        return(
                                            <td className="prod" key={`entete-prod-${i}`}>
                                                {prod.name}
                                            </td>
                                        )
                                    })}
                                </tr>

                                <tr className="bottom">
                                    <td className="visit fixedTD"></td>
                                    <td className="visit fixedTD col2"></td>
                                    <td className="ref fixedTD col3">
                                        <input
                                            type="text"
                                            value={filtres.ref}
                                            onChange={(e) => handleFilter(e.target.value, "ref")}
                                        />
                                    </td>
                                    <td className="ref fixedTD col4">
                                        <input
                                            type="text"
                                            value={filtres.name}
                                            onChange={(e) => handleFilter(e.target.value, "name")}
                                        />
                                    </td>
                                    <td className="ref fixedTD col5">
                                        <input
                                            type="text"
                                            value={filtres.fname}
                                            onChange={(e) => handleFilter(e.target.value, "fname")}
                                        />
                                    </td>
                                    <td className="ref fixedTD col6">
                                        <Select
                                            options={listTypePDS.list.map(type => ({ value: type.id, label: type.name }))}
                                            value={filtres.pds}
                                            onChange={(newValue) => {
                                                setFiltres({ ...filtres, pds: newValue });
                                                handleGetAllSpecialite({ protype: newValue !== null ? newValue.value : "" });
                                            }}
                                            placeholder="Entrez un type de PDS"
                                            formatOptionLabel={(option, { context }) => {
                                                return context === 'menu' ? (
                                                    <div className="select-react-option villes">
                                                        <p>{option.label}</p>
                                                    </div>
                                                ) : option.label;
                                            }}
                                            isClearable
                                            className="react-select-container"
                                            isLoading={listTypePDS.loading}
                                        />
                                    </td>
                                    <td>
                                        <Select
                                            options={listSpe.list.map(spe => ({ value: spe.id, label: spe.name }))}
                                            value={filtres.spe}
                                            onChange={(newValue) => setFiltres({ ...filtres, spe: newValue })}
                                            placeholder="Entrez une spécialité"
                                            formatOptionLabel={(option, { context }) => {
                                                return context === 'menu' ? (
                                                    <div className="select-react-option villes">
                                                        <p>{option.label}</p>
                                                    </div>
                                                ) : option.label;
                                            }}
                                            isClearable
                                            className="react-select-container"
                                            isLoading={listSpe.loading}
                                        />
                                    </td>
                                    <td className="ville">
                                        <Select
                                            options={listVilles.list}
                                            formatGroupLabel={formatGroupLabel}
                                            value={filtres.city}
                                            onChange={(newValue) => setFiltres({ ...filtres, city: newValue })}
                                            placeholder="Entrez une ville"
                                            formatOptionLabel={(option, { context }) => {
                                                return context === 'menu' ? (
                                                    <div className="select-react-option villes">
                                                        <p>{option.label}</p>
                                                    </div>
                                                ) : option.label;
                                            }}
                                            isClearable
                                            className="react-select-container"
                                            isLoading={listVilles.loading || dataMission.country === undefined}
                                        />
                                    </td>
                                    <td className="etab">
                                        <Select
                                            options={typeEtabList.length !== 0 ? generateEtabOptions(etabList.list, typeEtabList) : []}
                                            formatGroupLabel={formatGroupLabel}
                                            value={filtres.etab}
                                            onChange={(newValue) => setFiltres({ ...filtres, etab: newValue})}
                                            onInputChange={(value) => handleFilter(value, "lieuConsultation")}
                                            placeholder="Entrez le nom d'un établissement"
                                            formatOptionLabel={(option, { context }) => {
                                                return context === 'menu' ? (
                                                    <div className="select-react-option etab">
                                                        <div>
                                                            <div className={`tag ${option.isPrivate === 1 ? "valid" : option.isPrivate === 2 ? "prive" : option.isPrivate === 3 ? "warning" : "inconnu"}`}>
                                                                <p>
                                                                    {option.isPrivate === 1 ? "Public"
                                                                    : option.isPrivate === 2 ? "Privé"
                                                                    : option.isPrivate === 3 ? "Semi-privé"
                                                                    : "Inconnu"}
                                                                </p>
                                                            </div>
                                                            <p>{option.ref} - {option.label}</p>
                                                        </div>
                                                        <small>{option.ad}</small>
                                                    </div>
                                                ) : option.label;
                                            }}
                                            isClearable
                                            className="react-select-container"
                                            isLoading={etabList.loading || dataMission.country === undefined}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="list rapport2">
                            <tbody>
                                {listProsActivite.map(pro => {
                                return (
                                    <RowRapport2
                                        key={pro.id}
                                        pro={pro}
                                        onlyProds={onlyProds}
                                    />
                                )
                                })}
                                {listProsActivite.length === 0 &&
                                    <tr><td>Aucun résultat trouvé</td></tr>
                                }
                            </tbody>
                        </table>

                    </div>
                </div>

                {pagination.maxPage > 1 && !loadingProdLabMission && 
                    <Pagination
                        maxPage={pagination.maxPage}
                        page={pagination.page}
                        nbCount={pagination.nbResultats}
                        onChangePage={data => {
                            setPagination({ ...pagination, page: data });
                            handleGetRapport2(data, startDate, endDate);
                        }}
                    ></Pagination>
                }
            </section>

            <PopUpManager 
                type="medicalProPage"
                displayTitle="Professionnel"
            />

        </section>

        <PopupRemplirCR
            crReady={crReady}
            item={crReady && cr}
            listObjectifs={listObjectifs}
            listRP={listRP}
            listTypeHisto={listTypeHisto}
            remplirCR={() => {}}
            readOnly={true}
        />
    </>
    );
}