import { useState, useRef, useEffect } from "react";
import { t } from "../../pages/i18nFunctions";

export const VisitFilter = ({ handleGetRapport2 }) => {

    const [lastVisit, setLastVisit] = useState({ date: "", infOrSup: 0 });
    const [nextVisit, setNextVisit] = useState({ date: "", infOrSup: 0 });
    const [mounted, setMounted] = useState(false);

    const lastVisitInput = useRef();
    const nextVisitInput = useRef();

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {

        if(mounted) {
            let params;

            if(lastVisit.date)
                params = {
                    lastVisitDate: lastVisit.date,
                    lastVisitInfOrSup: lastVisit.infOrSup,
                    nextVisitDate: nextVisit.date,
                    nextVisitInfOrSup: nextVisit.infOrSup
                }

            if(params)
                handleGetRapport2(params);
        }

    }, [lastVisit.infOrSup]);

    useEffect(() => {

        if(mounted) {
            let params;
            
            if(nextVisit.date)
                params = {
                    lastVisitDate: lastVisit.date,
                    lastVisitInfOrSup: lastVisit.infOrSup,
                    nextVisitDate: nextVisit.date,
                    nextVisitInfOrSup: nextVisit.infOrSup
                }

            if(params)
                handleGetRapport2(params);
        }

    }, [nextVisit.infOrSup]);

    useEffect(() => {

        if(mounted) {
            let params = {
                lastVisitDate: lastVisit.date,
                lastVisitInfOrSup: lastVisit.infOrSup,
                nextVisitDate: nextVisit.date,
                nextVisitInfOrSup: nextVisit.infOrSup
            }

            handleGetRapport2(params);
        }

    }, [lastVisit.date, nextVisit.date]);

    return (
        <div className='box info visitFilter'>

            <div className="boxContent">

                <p>{t('SUIVI.RAPPORT.LAST_VISIT')}</p>

                <div className="infOrSup">

                    <span
                        className={`${lastVisit.infOrSup === 0 ? "active" : ""}`}
                        onClick={() => setLastVisit({ ...lastVisit, infOrSup: 0 })}
                    >
                        {t('SUIVI.RAPPORT.INFERIOR')}
                    </span>
                    <span
                        className={`${lastVisit.infOrSup === 2 ? "active" : ""}`}
                        onClick={() => setLastVisit({ ...lastVisit, infOrSup: 2 })}
                    >
                        {t('SUIVI.RAPPORT.EQUAL')}
                    </span>
                    <span
                        className={`${lastVisit.infOrSup === 1 ? "active" : ""}`}
                        onClick={() => setLastVisit({ ...lastVisit, infOrSup: 1 })}
                    >
                        {t('SUIVI.RAPPORT.SUPERIOR')}
                    </span>

                </div>

                <input
                    className="form-control"
                    ref={lastVisitInput}
                    value={lastVisit.date}
                    type="date"
                    onChange={(e) => setLastVisit({ ...lastVisit, date: e.target.value })}
                    onClick={() => lastVisitInput.current && lastVisitInput.current.showPicker()}
                />

            </div>

            <div className="boxContent">

                <p>{t('SUIVI.RAPPORT.NEXT_VISIT')}</p>

                <div className="infOrSup">

                    <span
                        className={`${nextVisit.infOrSup === 0 ? "active" : ""}`}
                        onClick={() => setNextVisit({ ...nextVisit, infOrSup: 0 })}
                    >
                        {t('SUIVI.RAPPORT.INFERIOR')}
                    </span>
                    <span
                        className={`${nextVisit.infOrSup === 2 ? "active" : ""}`}
                        onClick={() => setNextVisit({ ...nextVisit, infOrSup: 2 })}
                    >
                        {t('SUIVI.RAPPORT.EQUAL')}
                    </span>
                    <span
                        className={`${nextVisit.infOrSup === 1 ? "active" : ""}`}
                        onClick={() => setNextVisit({ ...nextVisit, infOrSup: 1 })}
                    >
                        {t('SUIVI.RAPPORT.SUPERIOR')}
                    </span>

                </div>

                <input
                    className="form-control"
                    ref={nextVisitInput}
                    value={nextVisit.date}
                    type="date"
                    onChange={(e) => setNextVisit({ ...nextVisit, date: e.target.value })}
                    onClick={() => nextVisitInput.current && nextVisitInput.current.showPicker()}
                />
                
            </div>


        </div>
    )
}