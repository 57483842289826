// import { useEffect } from 'react';
import "./Pagination.scss";
import ReactPaginate from 'react-paginate';

export const Pagination = ({ maxPage = 1, onChangePage, nbCount = 0, page = 1 }) => {
    
    return(
        <div className="pagination-container">
            <ReactPaginate
                pageCount={maxPage}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}                    
                previousLabel={'<'}
                nextLabel={'>'}                    
                onPageChange={(data) => onChangePage(data.selected + 1)}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={page-1}
            ></ReactPaginate>
            { nbCount && <div className="nbCount">{nbCount}</div> }
        </div>
    )
}