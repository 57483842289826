import { useContext } from 'react';
import { ActionsStoreContext } from '../../../../../../store/store/storeActions';
import { ListStoreContext } from '../../../../../../store/store/storeList';
// import { FilterStoreContext } from '../../../../../../store/store/storeFilters';
import { PopUpStoreContext } from '../../../../../../store/store/storePopUp';
import { t } from "../../../../../../pages/i18nFunctions";

export const TdNumber = (props) => {
    const { stateActions, dispatchActions } = useContext(ActionsStoreContext);
    const { stateList, dispatchList } = useContext(ListStoreContext);
    // const { stateFilter } = useContext(FilterStoreContext);
    const { statePopUp, dispatchPopUp } = useContext(PopUpStoreContext);
    const { line, el, type } = props;
    const { dataActions } = stateActions;
    const { dataPopUp } = statePopUp;
    const { dataTypeContact } = stateList;

    const roleUser = localStorage.getItem("role");

    const handleClickTd = () => {
        let dDataOtherTabs;
        let params = {
            idPro: dataActions.id,
            idSpe: line.idMedicalProSpeciality
        }
        stateActions.disableSpePro(params).then(res => {
            return stateActions.getSpePro(dataActions.id).then((res) => {
                const data = JSON.parse(res);
                dDataOtherTabs = data;    
                return dispatchList({type: 'setDataOtherTabs', dDataOtherTabs });
            });
        });
    }

    const handleDisplayPopup = () => {
        let dDataPopUp = {
            type: type,
            action: 'visu',
            isActive: !dataPopUp.isActive
        }
        let aDataActions = line;

        dispatchPopUp({type: 'setPopUp', dDataPopUp });
        dispatchActions({type: 'setDataActions', aDataActions });
    };

    const genNumber = () => {
        switch (el.name) {
            case 'statusMedicalProSpeciality':
                if(line[el.name] === 0)
                    return <td>
                        <div onClick={() => dataPopUp.action !== "visu" && roleUser !== "ROLE_DELEGUE" && handleClickTd()} className="statusDisplay actif">
                            <span>{t('PROS.LIST.POPUP_VIEW.SPECIALITIES.STATUS_CURRENT')}</span>
                        </div>
                    </td>;
                else if(line[el.name] === 1)
                    return <td>
                        <div onClick={() => dataPopUp.action !== "visu" && roleUser !== "ROLE_DELEGUE" && handleClickTd()} className="statusDisplay inactif">
                            <span>{t('PROS.LIST.POPUP_VIEW.SPECIALITIES.STATUS_PREVIOUS')}</span>
                        </div>
                    </td>;
                else
                    return <td>
                        <div className="statusDisplay inconnu">
                            <span>{t('PROS.LIST.POPUP_VIEW.SPECIALITIES.TO_BE_DELETED')}</span>
                        </div>
                    </td>;
            
            case 'status':
                if(type === "medicalProPage")
                    return <td className="clickableTab" onClick={() => handleDisplayPopup()}>
                        { line[el.name] === 0 ?
                            <div className="statusDisplay semiPrivate"><span>{t('PROS.LIST.POPUP_VIEW.GENERAL.NEW')}</span></div>
                        : line[el.name] === 2 ?
                            <div className="statusDisplay actif"><span>{t('INSTITUTION.STATUS.AC')}</span></div>
                        :
                            <div className="statusDisplay red"><span>{t('INSTITUTION.STATUS.IN')}</span></div>
                        }
                    </td>
                else if(type === "user")
                    if(line[el.name] !== 0)
                        return <td><div className="statusDisplay inactif"><span>{t('INSTITUTION.STATUS.IN')}</span></div></td>;
                    else
                        return <td><div className="statusDisplay actif"><span>{t('INSTITUTION.STATUS.AC')}</span></div></td>;
                else if(type === "etab")
                    return <td className="clickableTab" onClick={() => handleDisplayPopup()}>
                        { line[el.name] !== 0 ?
                            <div className="statusDisplay actif"><span>{t('INSTITUTION.STATUS.AC')}</span></div>
                        :
                            <div className="statusDisplay red"><span>{t('INSTITUTION.STATUS.IN')}</span></div>
                        }
                    </td>
                else
                    if(line[el.name] !== 0)
                        return <td><div className="statusDisplay actif"><span>{t('INSTITUTION.STATUS.AC')}</span></div></td>;
                    else   
                        return <td><div className="statusDisplay inactif"><span>{t('INSTITUTION.STATUS.IN')}</span></div></td>;

            case 'state':
                if(line[el.name] === 0)
                    return <td><div className="pastille red"></div></td>;
                else if(line[el.name] === 2)
                    return <td><div className="pastille valid"></div></td>;
                else if(line[el.name] === 3)
                    return <td><div className="pastille orange"></div></td>;
                else
                    return <td><div className="pastille grey"></div></td>;
               
            case 'withInternetConn':
            case 'isHeadDept':
            case 'hasSecretary':
            case 'onAppointment':
                if(line[el.name] === 0)
                    return <td><div className="statusDisplay inconnu"><span>{t('COMMON.UNKNOWN')}</span></div></td>;
                else if(line[el.name] === 1)
                    return <td><div className="statusDisplay valid"><span>{t('COMMON.YES')}</span></div></td>;
                else if(line[el.name] === 2)
                    return <td><div className="statusDisplay prive"><span>{t('COMMON.NO')}</span></div></td>;

            case 'isPrivateInstitution':
            case 'isPrivate':
                if(type === "etab")
                    return <td className="clickableTab" onClick={() => handleDisplayPopup()}>
                        { line[el.name] === 0 ?
                            <div className="statusDisplay inconnu"><span>{t('COMMON.UNKNOWN')}</span></div>
                        : line[el.name] === 1 ?
                            <div className="statusDisplay public"><span>{t('PROS.LIST.RESEARCH.PUBLIC')}</span></div>
                        : line[el.name] === 2 ?
                            <div className="statusDisplay private"><span>{t('PROS.LIST.RESEARCH.PRIVATE')}</span></div>
                        : line[el.name] === 3 &&
                            <div className="statusDisplay semiPrivate"><span>{t('PROS.LIST.RESEARCH.SEMI_PRIVATE')}</span></div>
                        }
                    </td>
                break;

            case "targetCount":
                return <td><div className={`targetCount ${line[el.name] > 0 ? 'valid' : 'alert'}`}><span>{line[el.name]}</span></div></td>

            case 'type':
                if(type === "medicalProHisto")
                    if(line[el.name] === 1)
                        return <td><div className="statusDisplay valid">
                            <span>
                                {dataTypeContact.find(tp => tp.id === line[el.name]) && dataTypeContact.find(tp => tp.id === line[el.name]).type}
                            </span>
                        </div></td>;
                    else if(line[el.name] === 2)
                        return <td><div className="statusDisplay warning">
                            <span>
                                {dataTypeContact.find(tp => tp.id === line[el.name]) && dataTypeContact.find(tp => tp.id === line[el.name]).type}
                            </span>
                        </div></td>;
                else
                    return false;
        
            default:
                return <td>{line[el.name]}</td>;
        }
    }

    return(
        <>
            {genNumber()}
        </>
    )
}