import { ButtonAdd } from "../../../../components/UI/Button/ButtonAdd/ButtonAdd";

// images
import iconEdit from "../../../../assets/img/icons/icon-edit.png";
import iconDelete from "../../../../assets/img/icons/icon-delete.png";

export const ProdConfig = (props) => {

    const { prodList, updateSelectedProd, handleMove, prod1, handleDelete } = props;

    return(
        <div className="reseauxProd">
            <table className="box tab-entete reseauxProd defaut">
                <tbody>
                    <tr>
                        <td>
                            <div>
                                <p>Ordre</p>
                            </div>
                        </td>
                        <td><div><p>Label</p></div></td>
                        <td><div><p>Type de présentation</p></div></td>
                        <td>
                            <ButtonAdd
                                modal="#modalAddEditResProd"
                                handleAdd={() => updateSelectedProd({})}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>

            <table className="list reseauxProd defaut">
                <tbody>
                    {prodList.map((prod, i) => {
                    return (
                        <tr className="list-row" key={`prodDef${i}`}>
                            <td>
                                {prod.ordre}
                            </td>
                            <td>
                                {prod.product.nameProduct}
                            </td>
                            <td>
                                {prod.presentation === 1 ? "Présentation" : prod.presentation === 0 ? "Rappel" : "Non présenté"}
                            </td>
                            <td className="icons">
                                <div
                                    className="icon-btn"
                                    data-toggle="modal"
                                    data-target="#modalAddEditResProd"
                                    onClick={() => updateSelectedProd(prod)}
                                >
                                    <img src={iconEdit} alt="edit"/>
                                </div>

                                <button
                                    type="button"
                                    className="bouton moveProd"
                                    onClick={e => handleMove(e, prod.idNetworkProduct)}
                                >
                                    <i
                                        className={`fa ${
                                            (prod1 === null) || (prod1 === prod.idNetworkProduct) ?
                                                "fa-exchange"
                                            :
                                                "fa-check"
                                        }`}
                                        aria-hidden="true"
                                        style={prod1 === prod.idNetworkProduct ? { color: "#424343" } : {}}
                                    ></i>
                                </button>

                                <div
                                    className="icon-btn"
                                    data-toggle="modal"
                                    data-target="#modalDelete"
                                    onClick={() => handleDelete(prod.idNetworkProduct)}
                                >
                                    <img src={iconDelete} alt="x"/>
                                </div>
                            </td>
                        </tr>
                    )
                    })}
                </tbody>
            </table>
        </div>
    )
}