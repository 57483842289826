import { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import "./MissionsCiblage.scss";
import { t } from "../i18nFunctions";
import Select from 'react-select';
import ReactLoading from 'react-loading';
import { NotificationManager} from 'react-notifications';
import { StoreContext } from "../../store/store";

// components
import { Pagination } from '../../components/Pagination/Pagination';
// import { PopupChooseZoneGeo } from '../../components/Popups/PopupChooseZoneGeo';
import { CustomSelect } from '../../components/UI/CustomSelect/CustomSelect';
import { PopupConfirmation } from '../../components/Popups/PopupConfirmation';

// helpers
import {
    generateEtabOptions,
    generateZGOptions
} from "../../store/helpers";

// API
import {
    getAllProType,
    getAllSpecialite,
    getZonesByCountry,
    getTypeEtab,
    getAllEtabs,

    getCiblage,
    editCiblage,
    untargetAll,

    getProdsByConfig
} from '../../store/index.service';

export const MissionsCiblage = () => {

    const [filtres, setFiltres] = useState({
        name: "",
        lieuConsultation: "",
        zoneGeoSearch: "",
        fname: "",
        ref: "",
        idPDS: "ALL",
        idSpe: "ALL",
        ciblage: "ALL",
        qualifie: "ALL"
    });

    const [etabList, setEtabList] = useState({
        list: [],
        loading: false
    });
    const [typeEtabList, setTypeEtabList] = useState([]);
    const [etab, setEtab] = useState(null);

    const [typePDS, setTypePDS] = useState({
        list: [],
        loading: false
    });
    const [spe, setSpe] = useState({
        list: [],
        loading: false
    });
    const [zgList, setZgList] = useState([]);
    const [zoneGeo, setZoneGeo] = useState(null);

    const [prods, setProds] = useState({
        prodA: true,
        prodB: true,
        prodC: true,
        prodD: true
    });
    const [prodTab, setProdTab] = useState([]);

    const [dataMission, setDataMission] = useState({});
    const [listProsCiblage, setListProsCiblage] = useState([]);
    const [prodListDefault, setProdListDefault] = useState([]);
    const [statsMission, setStatsMission] = useState({});
    const [selectedProd, setSelectedProd] = useState({ name: t('PROS.LIST.RESEARCH.SELECT_PROD') });

    const [pagination, setPagination] = useState({
        page: 1,
        maxPerPage: 50,
        maxPage: 1,
        nbResultats: 0
    });

    const params = useParams();
    const timer = useRef(null);
    const { setLoadingStore } = useContext(StoreContext);

    // mounted
    useEffect(() => {

        getTypeEtab().then(res => {
            const data = JSON.parse(res);
            if(data) setTypeEtabList(data);
        });

        setTypePDS({ ...typePDS, loading: true })
        getAllProType().then(res => {
            const data = JSON.parse(res);
            if(data) setTypePDS({
                list: data,
                loading: false
            });
        });

        handleGetAllSpecialite();

        timer.current = setTimeout(() => {}, 1000);
        // unmounted
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    // updates
    const handleGetAllSpecialite = (body) => {

        setSpe({ ...spe, loading: true})
        getAllSpecialite(body).then(res => {
            const data = JSON.parse(res);
            setSpe({
                list: data.listSpeciality ? data.listSpeciality : [],
                loading: false
            });
        });
    }

    const handleGetAllEtabs = (body) => {

        setEtabList({ ...etabList, loading: true});
        getAllEtabs({
            ...body,
            page: 1,
            maxPerPage: 100
        }).then(res => {
            const data = JSON.parse(res);
            setEtabList({
                list: data ? data : [],
                loading: false
            });
        });
    }

    const handleGetCiblage = (body) => {
        
        setLoadingStore(true);

        getCiblage(params.id, body).then(res => {
            let listPC = [];
            let dataM = {};
            let stats = {};
            const data = JSON.parse(res);

            if(data.dataMission) {
                if(dataMission.country === undefined) {

                    getProdsByConfig(data.dataMission[0].network.idNetwork, "default", undefined, data.dataMission[0].country.idCountry).then(res => {
                        const d = JSON.parse(res);
                        if(d) setProdListDefault(d);
                    });

                    handleGetAllEtabs({
                        label: "",
                        country: data.dataMission[0].country.idCountry
                    });

                    getZonesByCountry(data.dataMission[0].country.nameCountry).then(res => {
                        const d = JSON.parse(res);
                        if(d) setZgList(d);
                    });
                }

                dataM = data.dataMission[0];
            }

            if(data.listMissionMedicalPro)
                listPC = data.listMissionMedicalPro;

            if(data.statsMission)
                stats = data.statsMission;

            setDataMission(dataM);
            setListProsCiblage(listPC);
            setStatsMission(stats);
            setPagination({ ...pagination,
                page: data.page,
                maxPage: data.nbrPages,
                nbResultats: data.nbResultats
            })

            setLoadingStore(false);
        });
    }

    const updateCiblage = (fil = {}) => {
        let prods = {};
        prodTab.forEach(p => prods = {
            ...prods,
            [p.idProduct]: p.potentiels
        });

        handleGetCiblage({
            page: fil.page !== undefined ? fil.page : pagination.page,
            maxPerPage: pagination.maxPerPage,
            pdsFirstName: fil.fname !== undefined ? fil.fname : filtres.fname,
            pdsLastName: fil.name !== undefined ? fil.name : filtres.name,
            pdsInstitution: etab === null ? null : parseInt(etab.value),
            pdsArea: zoneGeo === null ? null : parseInt(zoneGeo.value),
            pdsType: filtres.idPDS === "ALL" ? null : parseInt(filtres.idPDS),
            pdsSpeciality: filtres.idSpe === "ALL" ? null : parseInt(filtres.idSpe),
            targetingStatus: filtres.ciblage === "ALL" ? null : filtres.ciblage,
            isQualified: filtres.qualifie === "ALL" ? null : filtres.qualifie,
            qualifiedProducts: prods,
            pdsReference: fil.ref !== undefined ? fil.ref : filtres.ref
        });
    }

    useEffect(() => {

        updateCiblage();

    }, [
        filtres.idPDS, filtres.idSpe, filtres.ciblage, filtres.qualifie,
        etab, zoneGeo, prodTab
    ]);

    const addProduct = () => {
        let t = [...prodTab];
        let p = "";

        if(prods.prodA) p += "A";
        if(prods.prodB) p += "B";
        if(prods.prodC) p += "C";
        if(prods.prodD) p += "D";

        if(selectedProd.id) {
            t.push({
                idProduct: selectedProd.id,
                name: selectedProd.name,
                potentiels: p
            });

            setProdTab(t);
            setSelectedProd({ name: t('PROS.LIST.RESEARCH.SELECT_PROD') })
        }
    }

    const removeProduct = (idProd) => {
        let t = [...prodTab];

        let selectI = t.map(p => p.idProduct).indexOf(idProd);
        t.splice(selectI, 1);

        setProdTab(t);
    }

    const affichePotentiel = (pro, prod) => {
        const tab = pro.medicalProQualifiedProducts;
        const index = tab.map(p => p.idProduct).indexOf(prod.product.idProduct);
        if(index !== -1) return tab[index].nameQualificationProdcut;
    }

    const manageProducts = () => {
        let t = [...prodListDefault];
        let pTab = [...prodTab];

        pTab.forEach(prod => {
            let selectI = t.map(p => p.product.idProduct.toString()).indexOf(prod.idProduct);
            if(selectI !== -1) t.splice(selectI, 1);
        })

        return t;
    }

    const handleFilter = (value, key) => {
        
        clearTimeout(timer.current);
        setFiltres({ ...filtres, [key]: value });

        timer.current = setTimeout(() => {

            if(key === "lieuConsultation")
                handleGetAllEtabs({
                    label: filtres.lieuConsultation,
                    country: dataMission.country ? dataMission.country.idCountry : null
                });
            else if(key === "zoneGeoSearch") {
                // this.props.getAllZonesGeo({
                //     "search": this.state.zoneGeoSearch,
                //     "country": this.state.dataMission.country !== undefined ? this.state.dataMission.country.idCountry : null
                // });
            }
            else updateCiblage({ [key]: value, page: 1 });

        }, 1000);
    }

    const handleUntarget = () => {
        setLoadingStore(true);

        untargetAll(dataMission.id)
            .then(() => {
                NotificationManager.success('', t('COMMON.NOTIFICATIONS.UNTARGET'));
                updateCiblage();
            })
            .catch(err => {
                console.error(err);
                NotificationManager.error('', t('COMMON.ERROR'));
            })
    }

    const formatGroupLabel = data => (<div><span>{data.label}</span></div>);

    return(
        <section className="content">
        
            <section className="content-header">
                <h1>
                    {dataMission.name}
                    <small>{t('TARGET.TARGET_PDS')}</small>
                </h1>
            </section>

            <section className="content filtreCiblage">
                <div className="row">
                    
                    <div className="col-md-6">
                        <div className="row box info ciblage">
                            <div className="col-md-12">
                                <p>{t('PROS.LIST.RESEARCH.TITLE')}</p>
                            </div>

                            <div className="col-md-4">

                                <label>{t('PROS.LIST.RESEARCH.NAME')}</label>
                                <input
                                    type="text"
                                    value={filtres.name}
                                    onChange={(e) => handleFilter(e.target.value, "name")}
                                />

                                <label>{t('PROS.LIST.RESEARCH.PDS_TYPE')}</label>
                                { typePDS.loading &&
                                    <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader" />
                                }
                                <CustomSelect
                                    defaultText={t('PROS.LIST.RESEARCH.PDS_TYPE_PLACEHOLDER')}
                                    optionsList={[
                                        { id: "ALL", name: t('PROS.LIST.RESEARCH.ALL') },
                                        ...typePDS.list
                                    ]}
                                    handleOptionClick={(id) => {
                                        handleGetAllSpecialite({ protype: id !== "ALL" ? id : "" });
                                        setFiltres({ ...filtres, idPDS: id });
                                    }}
                                />

                                <label>{t('PROS.LIST.RESEARCH.TARGET_STATE')}</label>
                                <CustomSelect
                                    defaultText={t('PROS.LIST.RESEARCH.ALL')}
                                    optionsList={[
                                        { id: "ALL", name: t('PROS.LIST.RESEARCH.ALL') },
                                        { id: "TGT", name: t('PROS.LIST.RESEARCH.CIBLE') },
                                        { id: "NTGT", name: t('PROS.LIST.RESEARCH.HORS_CIBLE') }
                                    ]}
                                    handleOptionClick={(id) => setFiltres({ ...filtres, ciblage: id})}
                                />
                            </div>

                            <div className="col-md-4">
                                <label>{t('PROS.LIST.RESEARCH.FIRSTNAME')}</label>
                                <input
                                    type="text"
                                    value={filtres.fname}
                                    onChange={(e) => handleFilter(e.target.value, "fname")}
                                />

                                <label>{t('PROS.LIST.RESEARCH.SPECIALITY')}</label>
                                { spe.loading &&
                                    <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader" />
                                }
                                <CustomSelect
                                    defaultText={t('PROS.LIST.RESEARCH.SPECIALITY_PLACEHOLDER')}
                                    optionsList={[
                                        { id: "ALL", name: t('PROS.LIST.RESEARCH.ALL_SPE') },
                                        ...spe.list
                                    ]}
                                    handleOptionClick={(id) => setFiltres({ ...filtres, idSpe: id })}
                                />

                                <label>{t('PROS.LIST.RESEARCH.REFERENCE')}</label>
                                <input
                                    type="text"
                                    value={filtres.ref}
                                    onChange={(e) => handleFilter(e.target.value, "ref")}
                                />
                            </div>

                            <div className="col-md-4">

                                <label>{t('PROS.LIST.RESEARCH.CONSULTATION_PLACE')}</label>
                                <Select
                                    options={typeEtabList.length !== 0 ? generateEtabOptions(etabList.list, typeEtabList) : []}
                                    formatGroupLabel={formatGroupLabel}
                                    value={etab}
                                    onChange={(newValue) => setEtab(newValue)}
                                    onInputChange={(value) => handleFilter(value, "lieuConsultation")}
                                    placeholder={t('PROS.LIST.RESEARCH.CONSULTATION_PLACE_PLACEHOLDER')}
                                    formatOptionLabel={(option, { context }) => {
                                        /* context can be either `menu` or `value`
                                            menu - dropdown
                                            value - value displayed
                                        */
                                        return context === 'menu' ? (
                                            <div className="select-react-option etab">
                                                <div>
                                                    <div className={`tag ${option.isPrivate === 1 ? "valid" : option.isPrivate === 2 ? "prive" : option.isPrivate === 3 ? "warning" : "inconnu"}`}>
                                                        <p>
                                                            {option.isPrivate === 1 ? t('INSTITUTION.LABEL.PUBLIC')
                                                            : option.isPrivate === 2 ? t('INSTITUTION.LABEL.PRIVATE')
                                                            : option.isPrivate === 3 ? t('INSTITUTION.LABEL.SEMI_PRIVATE')
                                                            : t('COMMON.UNKNOWN')}
                                                        </p>
                                                    </div>
                                                    <p>{option.ref} - {option.label}</p>
                                                </div>
                                                <small>{option.ad}</small>
                                            </div>
                                        ) : option.label;
                                    }}
                                    isClearable
                                    className="react-select-container"
                                    isLoading={etabList.loading || dataMission.country === undefined}
                                />

                                <label>{t('PROS.LIST.RESEARCH.GEOGRAPHICAL_AREA')}</label>
                                <div className="zoneGeo-select">
                                    <Select
                                        options={dataMission.country ? generateZGOptions(zgList, dataMission.country.idCountry, dataMission.country.nameCountry) : []}
                                        formatGroupLabel={formatGroupLabel}
                                        value={zoneGeo}
                                        onChange={(newValue) => setZoneGeo(newValue)}                                        
                                        onInputChange={(value) => handleFilter(value, "zoneGeoSearch")}
                                        placeholder={t('PROS.LIST.RESEARCH.GEOGRAPHICAL_AREA_PLACEHOLDER')}
                                        formatOptionLabel={(option, { context }) => {
                                            /* context can be either `menu` or `value`
                                                menu - dropdown
                                                value - value displayed
                                            */
                                            return context === 'menu' ? (
                                                <div className="select-react-option">
                                                    <p>{option.label}</p>
                                                    <small>{option.ad}</small>
                                                </div>
                                            ) : option.label;
                                        }}
                                        isClearable
                                        className="react-select-container"
                                        isLoading={dataMission.country === undefined}
                                    />
                                    {/* <div
                                        className="searchZoneGeo"
                                        data-toggle="modal"
                                        data-target="#modalChooseZoneGeo"
                                    >
                                        <i
                                            className="fa fa-search"
                                            aria-hidden="true"
                                        ></i>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="row box info ciblage">
                            <p>{t('TARGET.QUALIFIED_PRODUCTS')}</p>

                            <label>{t('TARGET.PRODUCTS')}</label>
                            {dataMission.network === undefined &&
                                <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader" />
                            }
                            <CustomSelect
                                defaultText={selectedProd.name}
                                optionsList={manageProducts().map(prod => ({
                                    id: prod.product.idProduct,
                                    name: prod.product.nameProduct
                                }))}
                                handleOptionClick={(id, name) => setSelectedProd({ id, name })}
                            />

                            <div className="checkboxes">
                                <input type="checkbox" checked={prods.prodA} onChange={() => setProds({ ...prods, prodA: !prods.prodA})}/>
                                <label>A</label>

                                <input type="checkbox" checked={prods.prodB} onChange={() => setProds({ ...prods, prodB: !prods.prodB})}/>
                                <label>B</label>

                                <input type="checkbox" checked={prods.prodC} onChange={() => setProds({ ...prods, prodC: !prods.prodC})}/>
                                <label>C</label>

                                <input type="checkbox" checked={prods.prodD} onChange={() => setProds({ ...prods, prodD: !prods.prodD})}/>
                                <label>D</label>
                            </div>

                            <button
                                type="button"
                                className="btn-valid"
                                onClick={() => addProduct()}
                            >
                                {t('COMMON.ADD')}
                            </button>

                            {prodTab.map((prod, i) => {
                                return(
                                    <div className="prodTab" key={`qualif-prod-${i}`}>
                                        <span>{prod.name}</span>
                                        <div>
                                            <span className="tag">
                                                {prod.potentiels.split("").map((pot, i) => {
                                                    let l = pot;
                                                    if(i !== prod.potentiels.length-1) l += ", ";
                                                    return l;
                                                })}
                                            </span>
                                            <i className="fa fa-times" onClick={() => removeProduct(prod.idProduct)}></i>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <div style={{ marginLeft: -15, marginRight: 15 }}>
                            <button
                                className='untargetAll'
                                data-toggle={'modal'}
                                data-target={'#modalConfirmation'}
                            >
                                {t("MISSION.BUTTON.UNTARGET_ALL")}
                            </button>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="row">
                            <div className="info-box">
                                <span className="info-box-icon bg-blue"><i className="fa fa-calendar"></i></span>
                                <div className="info-box-content">
                                    <span className="info-box-text">{t('TARGET.NUMBER_VISIT')}</span>
                                    <span className="info-box-number">{statsMission.numberOfPlannedVisitsWithFilters}</span>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="info-box">
                                <span className="info-box-icon bg-green"><i className="fa fa-bullseye"></i></span>
                                <div className="info-box-content">
                                    <span className="info-box-text">{t('TARGET.TARGETS')}</span>
                                    <span className="info-box-number">{statsMission.numberOfTargetWithFilters}</span>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="info-box">
                                <span className="info-box-icon bg-gray"><i className="fa fa-dot-circle-o"></i></span>
                                <div className="info-box-content">
                                    <span className="info-box-text">{t('TARGET.OUT_OF_TARGETS')}</span>
                                    <span className="info-box-number">{statsMission.numberOfNotTargetsWithoutFilters}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <div className="nbResultats">
                <span>
                    <span className="tag warning">{pagination.nbResultats}</span> {t('TARGET.RESULTS')}
                </span>
            </div>
        
            <section className="content ciblagePros">

                <div>
                    <table className="box tab-entete ciblage">
                        <tbody>
                            <tr>
                                <td className="check">
                                </td>
                                <td className="stat">
                                    <div><p>{t('PROS.LIST.TABLE_HEADERS.STATUS')}</p></div>
                                </td>
                                <td className="ref">
                                    <div><p>{t('PROS.LIST.TABLE_HEADERS.REF')}</p></div>
                                </td>
                                <td>
                                    <div><p>{t('PROS.LIST.TABLE_HEADERS.LASTNAME')}</p></div>
                                </td>
                                <td>
                                    <div><p>{t('PROS.LIST.TABLE_HEADERS.FIRSTNAME')}</p></div>
                                </td>
                                <td>
                                    <div><p>{t('PROS.LIST.TABLE_HEADERS.TYPE')}</p></div>
                                </td>
                                <td>
                                    <div><p>{t('PROS.LIST.TABLE_HEADERS.SPECIALITY')}</p></div>
                                </td>
                                <td className="etab">
                                    <div><p>{t('INSTITUTION.LIST.TITLE')}</p></div>
                                </td>
                                {prodListDefault.map((prod, i) => {
                                    return(
                                        <td className="prod" key={`entete-prod-${i}`}>
                                            {prod.product.nameProduct}
                                        </td>
                                    )
                                })}
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <table className="list proMission">
                    <tbody>
                    {listProsCiblage.map((pro, i) => {
                        return (
                            <tr key={i} className="list-row">
                                <td className="check">
                                    <input 
                                        type="checkbox"
                                        checked={pro.isTarget}
                                        onChange={() => editCiblage(dataMission.id, !pro.isTarget, pro.id).then(() => {

                                            NotificationManager.success('', t('COMMON.NOTIFICATIONS.EDIT_CIBLAGE'));
                                            updateCiblage();
                                        })}
                                    />
                                </td>
                                <td className="planning stat">
                                    <div className={`tag ${pro.isTarget ? "valid" : "inconnu"}`}>
                                        <p>
                                            {pro.isTarget ? t('PRO.LABEL.TARGET') : t('PRO.LABEL.NOT_TARGET')}
                                        </p>
                                    </div>
                                </td>
                                <td className="ref">
                                    {pro.reference}
                                </td>
                                <td>
                                    {pro.lastName}
                                </td>
                                <td>
                                    {pro.firstName}
                                </td>
                                <td>
                                    {pro.medicalProType.nameMedicalProType}
                                </td>
                                <td>
                                    {pro.medicalProSpeciality.map((spe, i) => {
                                        let endStr = (i+1) !== pro.medicalProSpeciality.length ? ", " : "";
                                        return spe.nameMedicalProSpeciality + endStr;
                                    })}
                                </td>
                                <td className="etab">
                                    {typeof pro.medicalProsInstitutions !== 'undefined' && pro.medicalProsInstitutions.map((etab, i) => {
                                        let endStr = (i+1) !== pro.medicalProsInstitutions.length ? ", " : "";
                                        return etab.nameInstitution + endStr;
                                    })}
                                </td>
                                {prodListDefault.map((prod, i) => {
                                    return(
                                        <td className="prod" key={`prodQualif-${i}`}>
                                            {affichePotentiel(pro, prod)}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                </div>

                {pagination.maxPage > 1 && 
                    <Pagination
                        maxPage={pagination.maxPage}
                        page={pagination.page}
                        nbCount={pagination.nbResultats}
                        onChangePage={data => {
                            updateCiblage({ page: data });
                            setPagination({ ...pagination, page: data });
                        }}
                    ></Pagination>
                }
            </section>

            {/* <PopupChooseZoneGeo
                submitZone={(country, province, ville, commune, zoneID) => handleZoneSearch(country, province, ville, commune, zoneID)}
            /> */}

            <PopupConfirmation
                message={t('COMMON.CONFIRM_MSG')}
                handleSubmit={handleUntarget}
            />

        </section>
    )
}