import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getMissionUser = () => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).get('api/mission/user', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.MissionByUser));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getMissionPro = (idMission, data) => {

    return new Promise((resolve, reject) => {
        try {
            let params = {};
            if( data !== undefined ){
                params = {
                    "page" : data.page,
                    "maxPerPage": 25,
                    "location" : data.etab,
                    "label" : data.label,
                    "protype" : data.type,
                    "speciality" : data.spe,
                    "zonegeo" : data.zonegeo,
                }
            }
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/mission/'+idMission+'/planning/professionnels', params)
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getPlanningWeek = (numWeek, idMission, year) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.request({
                    method: 'GET',
                    baseURL: config.baseUrl,
                    url: 'api/planning/week',
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    },
                    params: {
                        "numweek": numWeek,
                        "idMission": idMission,
                        "year": year
                    } 
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listAppointmentByWeek));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const postRDV = (idMission, numWeek, rdv) => {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).post('api/appointment/visit', {
                    "idMission": idMission,
                    "appointmentDate": rdv.date,
                    "halfday": rdv.halfday,
                    "activityCode": "VIST",
                    "idInstitution": rdv.idInstitution,
                    "idMedicalPro": rdv.idMedicalPro,
                    "numWeek": numWeek
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getPreVisite = (idRDV) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).get('api/appointment/'+idRDV+'/pre_visite', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const deleteRDV = (idRDV) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).delete('api/appointment/'+idRDV+'/delete', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const cancelRDV = (idRDV, idMotif) => {
    
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).put('api/appointment/'+idRDV+'/cancel', {
                    idCancellationReason: idMotif
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

// CR

export const getCR = (idRDV) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/appointment/'+idRDV+'/cr', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.compterendu[0]));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const remplirCR = (idRDV, cr, nextApp) => {
    
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).put('api/appointment/'+idRDV+'/complete/cr', {
                    "idObjectiveType": cr.idObj,
                    "ObjectiveTypeNote": cr.note,
                    "idProductMeetingType": cr.idTypeRP,
                    "idNextObjectiveType": cr.idObjPro,
                    "comment": cr.comment,
                    "ignoreNextAppointmentDate": cr.ignoreDate,
                    "nextAppointmentDate": cr.date,
                    "idMission": nextApp.idMission,
                    "halfday": nextApp.halfday,
                    "idInstitution": nextApp.idInstitution,
                    "idMedicalPro": nextApp.idMedicalPro,
                    "idAppointmentType": cr.idTypeVisite
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getPresentedProd = (idRDV) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).get('api/appointment/'+idRDV+'/presented-product', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.presentedProducts));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const remplirCRProd = (idPresProd, prod) => {
    
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).put('api/appointment/presented-product', {
                    idPresentedProduct: idPresProd,
                    presentation: prod.pres,
                    adoptionlevel: prod.note,
                    answers: prod.reponse
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const moveProd = (idP1, idP2) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).put('api/appointment/presented-product/ordre', {
                    idPresentedProduct1: idP1,
                    idPresentedProduct2: idP2
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const autoComplete = (idRDV) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).put('api/appointment/'+idRDV+'/presented-product/quick-filling', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getTypeContact = () => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/appointment/types', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listAppointmentType));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

// ACTIVITY

export const postActivity = (idMission, date, halfday, code) => {
    
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).post('api/schedule/activity', {
                    "idMission": idMission,
                    "appointmentDate": date,
                    "halfday": halfday,
                    "activityCode": code
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const editActivity = (idSchedule, code, numWeek) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).put('api/schedule/'+idSchedule+'/activity', {
                    "newActivityCode": code,
                    "numWeek": numWeek
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const defineDuo = (idMission, idSchedule) => {
    
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).post('api/appointment/duo', {
                    "idMission": idMission,
                    "idSchedule": idSchedule
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}


// CANCELLATION

export const getCancelReasonList = () => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/cancellation-reason', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listCancellationReason));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}


// OBJECTIVE TYPES

export const getObjectiveList = () => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/appointment/objective_type', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listObjectiveType));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}


// RP TYPES

export const getRPTypes = () => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/product-meeting-type', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listProductMeetingType));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}


// ACTIVITY

export const getActivityList = () => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/activity', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listActivity));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}