import { useState, useEffect, useRef } from 'react';

// images
import iconSave from "../../../assets/img/icons/icon-save.png";

// components
import { CustomSelect } from '../../UI/CustomSelect/CustomSelect';

export const PopupConfig = ({ list, postConfig, config }) => {

    const [id, setId] = useState(-1);
    const [placeholder, setPlaceholder] = useState(`Sélectionnez...`);

    const btnRef = useRef(null);

    // updates
    useEffect(() => {
        setPlaceholder(`Sélectionnez ${
            config === "country" ? "un pays"
            : config === "protype" ? "un type de PDS"
            : config === "speciality" ? "une spécialité"
            : "..." }`
        );
    }, [config]);

    // functions

    const handleSubmit = e => {

        if(id !== -1) {
            e.preventDefault();

            postConfig(id);
            
            btnRef.current.click();
        }
    }

        return(
            <div className="modal fade in" id="modalAddResConfigPays" tabIndex="-1" role="dialog" aria-labelledby="modalAddResConfigPaysLabel" animate="animate">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title" id="myModalLabel">
                                Ajouter une configuration sur un{config === "protype" ? " type de PDS" : config === "speciality" ? "e spécialité" : config === "country" ? " pays" : ""}
                            </h1>
                        </div>
                        <div className="modal-body">
                            <form id="formAjout">
                                <label>
                                    {config === "country" ? "Pays"
                                    : config === "protype" ? "Type de PDS"
                                    : config === "speciality" && "Spécialité"}
                                </label>

                                <CustomSelect
                                    defaultText={placeholder}
                                    optionsList={list}
                                    handleOptionClick={id => setId(id)}
                                />
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="submit"
                                form="formAjout"
                                className="btn-save"
                                disabled={id === -1}
                                onClick={handleSubmit}
                            >
                                <img src={iconSave} alt=""/>
                                Sauvegarder
                            </button>
                            <button
                                type="button"
                                className="btn-cancel"
                                data-dismiss="modal"
                                onClick={() => {
                                    setId(-1);
                                    setPlaceholder(`Sélectionnez ${
                                        config === "country" ? "un pays"
                                        : config === "protype" ? "un type de PDS"
                                        : config === "speciality" ? "une spécialité"
                                        : "..." }`
                                    );
                                }}
                                ref={btnRef}
                            >Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
        )
}