// import { useContext } from 'react';
import { Link } from 'react-router-dom';
// import { LoginAs } from '../../components/UI/Table/Row/Actions/ActionsItems/LoginAs';
// import { ActionsStoreContext } from '../../store/store/storeActions';

import iconRapport1 from "../../assets/img/icons/icon-report1.png";
import iconRapport2 from "../../assets/img/icons/icon-report2.png";

export const ListRowSuiviActivite = (props) => {
	const { item } = props;

    // const { stateActions } = useContext(ActionsStoreContext);

    // const role = localStorage.getItem("role");
    // const originalRole = localStorage.getItem("originalRole");

    const getColorFromDataValue = (data) => {
        
        const splitted = data.split('/');
        const n = parseFloat(splitted[0]);
        const d = parseFloat(splitted[1]);
        let value;

        if(d)
            value = parseFloat(n / d);
        else if(d === 0)
            return "valid";
        else
            return "";

        if(value >= 1)
            return 'valid';
        else if(value >= 0.9)
            return 'warning';
            
        return 'danger';
    }

    return (
        <tr className="infos">
            <td className='last fixedTD'>
                <div className='icon-btn' title={"Rapport 1"}>
                    <Link to={`/rapport1/${item.missionId}`}>
                        <img src={iconRapport1} alt="Rapport 1" />
                    </Link>
                </div>
            </td>
            <td className='last fixedTD col2'>
                <div className='icon-btn' title={"Rapport 2"}>
                    <Link to={`/rapport2/${item.missionId}`}>
                        <img src={iconRapport2} alt="Rapport 2" />
                    </Link>
                </div>
            </td>

            {/* {!originalRole && role === "ROLE_COORDINATEUR" &&
                <td className='last fixedTD col3'>
                    <LoginAs
                        line={item.delegue}
                        stateActions={stateActions}
                    />
                </td>
            } */}

            <td
                className='fixedTD col4'
                style={{ width: '180px', minWidth: '180px', fontSize: "10px" }}
            >
                <div>
                    <p>{item.delegue}</p>
                </div>
            </td>
            <td>
                <div>
                    <p>{item.pays}</p>
                </div>
            </td>
            <td>
                <div>
                    <p>{item.NbJoursTravailles}</p>
                </div>
            </td>
            <td>
                <div>
                    <p>{item.Calls_total}</p>
                </div>
            </td>
            <td>
                <div className={getColorFromDataValue(item['Moy.Contacts/Jour_Cibles'])}>
                    <p>{item['Moy.Contacts/Jour_Cibles'].split("/")[0]}<b>/{item['Moy.Contacts/Jour_Cibles'].split("/")[1]}</b></p>
                </div>
            </td>
            <td>
                <div className={getColorFromDataValue(item['NbHcpUnique_Cibles'])}>
                    <p>{item.NbHcpUnique_Cibles.split("/")[0]}<b>/{item.NbHcpUnique_Cibles.split("/")[1]}</b></p>
                </div>
            </td>
            <td>
                <div className={getColorFromDataValue(item['Frequences_Cibles'])}>
                    <p>{item.Frequences_Cibles}</p>
                </div>
            </td>
            <td className="border-left">
                <div className={getColorFromDataValue(item['NbContacts_potentielA'])}>
                    <p>{item.NbContacts_potentielA.split("/")[0]}<b>/{item.NbContacts_potentielA.split("/")[1]}</b></p>
                </div>
            </td>
            <td className="border-right">
                <div className={getColorFromDataValue(item['Frequences_potentielA'])}>
                    <p>{item.Frequences_potentielA}</p>
                </div>
            </td>
            <td className="border-left">
                <div className={getColorFromDataValue(item['NbContacts_potentielB'])}>
                    <p>{item.NbContacts_potentielB.split("/")[0]}<b>/{item.NbContacts_potentielB.split("/")[1]}</b></p>
                </div>
            </td>
            <td className="border-right">
                <div className={getColorFromDataValue(item['Frequences_potentielB'])}>
                    <p>{item.Frequences_potentielB}</p>
                </div>
            </td>
            <td className="border-left">
                <div className={getColorFromDataValue(item['NbContacts_potentielC'])}>
                    <p>{item.NbContacts_potentielC.split("/")[0]}<b>/{item.NbContacts_potentielC.split("/")[1]}</b></p>
                </div>
            </td>
            <td className="border-right">
                <div className={getColorFromDataValue(item['Frequences_potentielC'])}>
                    <p>{item.Frequences_potentielC}</p>
                </div>
            </td>
            <td className="border-left">
                <div className={getColorFromDataValue(item['NbContacts_potentielD'])}>
                    <p>{item.NbContacts_potentielD.split("/")[0]}<b>/{item.NbContacts_potentielD.split("/")[1]}</b></p>
                </div>
            </td>
            <td className="border-right">
                <div className={getColorFromDataValue(item['Frequences_potentielD'])}>
                    <p>{item.Frequences_potentielD}</p>
                </div>
            </td>
            <td>
                <div>
                    <p>{item.NbContacts_HorsCibles}</p>
                </div>
            </td>
        </tr>
    );
}