import { AddUser } from './Type/AddUser';
import { EditUser } from './Type/EditUser';
import { DeleteUser } from './Type/DeleteUser';

export const UserPopUpManager = (props) => {
    const { data, dispatchPop } = props;
    const { action } = data;

    const generatePopUps = () => {
        switch (action) {
            case 'add':
                return <AddUser data={data} dispatchPop={dispatchPop} />;

            case 'edit':
                return <EditUser data={data} dispatchPop={dispatchPop} />;
            
            case 'delete':
                return <DeleteUser data={data} dispatchPop={dispatchPop} />;
        
            default:
                return false;
        }
    }

    return (
        <>
            {
                <div className="modal-body">
                    {generatePopUps()}
                </div>
            }
        </>
    )
}