import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getSectorByCountry = (idCountry, idLaboratory) => {

    return new Promise((resolve, reject) => {
        let params = {};
        if(idLaboratory !== null)
            params = Object.assign(params, { "idLaboratory": idLaboratory });
            
        try {
            authHeader()
            .then(res => {
                axios.request({
                    method: 'GET',
                    baseURL: config.baseUrl,
                    url: 'api/sector/'+idCountry,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    },
                    params: params
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listSector));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const postSecteur = (name, idCountry, labo, zoneGeo) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).post('api/sector', {
                    "name": name,
                    "area": idCountry,
                    "lab": labo,
                    "zonegeo": zoneGeo
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const editSecteur = (idSecteur, name, idCountry, labo, zoneGeo) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).put('api/sector/'+idSecteur, {
                    "name": name,
                    "area": idCountry,
                    "lab": labo,
                    "zonegeo": zoneGeo
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const deleteSecteur = (idSecteur) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).delete('api/sector/'+idSecteur, {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}