import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import amiEn from "./locales/en/ami.json";
import amiFr from "./locales/fr/ami.json";
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import moment from 'moment';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                ami: amiEn,
            },
            fr: {
                ami: amiFr,
            },
        },
        lng: "fr",
        fallbackLng: "fr",
        defaultNS: 'ami',
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

let currentLanguage = localStorage.getItem('currentLanguage') || 'fr';
i18n.changeLanguage(currentLanguage);
moment.locale("fr");

export default i18n;