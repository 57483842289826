import { useState, useEffect, useContext } from 'react';
import './Professionnels.scss';
import { t } from '../i18nFunctions';

import { FilterStoreContext } from '../../store/store/storeFilters';
import { ListStoreContext } from '../../store/store/storeList';
import { ActionsStoreContext } from '../../store/store/storeActions';
import { StoreContext } from '../../store/store';

import { Pagination } from '../../components/Pagination/Pagination';

import { TableManager } from '../../components/UI/Table/TableManager';
import { PopUpManager } from '../../components/UI/PopUp/PopUpManager';
import { SearchFilter } from '../../components/UI/SearchFilter/SearchFilter';

import { PopupRemplirCR } from '../../components/Popups/Planning/PopupRemplirCR';

import {
    medicalProService,
    getOneInstitutionEmployee,

    getEtablissements,
    getAllEtabs,
    getTypeEtab,
    getAllCountries,
    getAllSpecialite,
    getAllProType,
    getAllMissions,
    getAllZonesGeo,
    getHistoryPDS,

    getCR,
    getRPTypes,
    getObjectiveList,
    getTypeContact
} from '../../store/index.service';

export const Professionnels = () => {
	const { stateFilter, dispatchFilters } = useContext(FilterStoreContext);
	const { stateList, dispatchList } = useContext(ListStoreContext);
	const { dispatchActions } = useContext(ActionsStoreContext);
	const { setLoadingStore } = useContext(StoreContext);

	let [maxPage, setMaxPage] = useState(0);
    let [page, setPage] = useState(1);
	let [nbResultats, setNbResultats] = useState(0);
	let [maxPerPage, setMaxPerPage] = useState(20);
	let [loadingEtabs, setLoadingEtabs] = useState(true);
	let [userCountries, setUserCountries] = useState(JSON.parse(localStorage.getItem('userCountries')));	
    
    let [countrySearchState, setCountrySearchState] = useState({
        id: userCountries[0].id,
        name: userCountries[0].name
    });

    let [crReady, setCrReady] = useState(false);
    let [cr, setCr] = useState();
    let [listRP, setListRP] = useState([]);
    let [listObjectifs, setListObjectifs] = useState([]);
    let [listTypeHisto, setListTypeHisto] = useState([]);
	let [mounted, setMounted] = useState(false);

    const getCRData = (id) => {
        getRPTypes().then(res => {
            const data = JSON.parse(res);
            return setListRP(data)
        });
        getObjectiveList().then(res => {
            const data = JSON.parse(res);
            return setListObjectifs(data);
        });
        getTypeContact().then(res => {
            const data = JSON.parse(res);
            if(data) setListTypeHisto(data);
        });
        getCR(id).then(res => {
            const data = JSON.parse(res);
            setCr(data);
            if(data)
                return setCrReady(true);
            else 
                return setCrReady(false);
        });
    }

    const handleGetAllMedicalPros = (params, isStart) => {
        setLoadingStore(true);

        medicalProService.getMedicalPros(params).then(res => {
            const data = JSON.parse(res);
            let dDataList = data.listMedicalPro;
            let mSearchList = data.listMedicalPro;

            setNbResultats(data.nbResultats);
            setMaxPerPage(data.maxPerPage);
            setMaxPage(data.nbrPages);

            dispatchFilters({type: 'setMemoSearchList', mSearchList });
            dispatchList({type: 'setList', dDataList });

            setLoadingStore(false);
        });

        // if(isStart !== false)
            params = {
                country: params.country,
                countryName: params.countryName
            };
        
        getAllZonesGeo(params).then(res => {
            const data = JSON.parse(res);
            let dDataZoneGeos = Object.values(data.listZoneGeo)[0];
            dispatchList({type: 'setDataZoneGeos', dDataZoneGeos });
        });
        getAllMissions(params).then(res => {
            const data = JSON.parse(res);
            let dDataMissions = data.listMission;
            dispatchList({type: 'setDataMissions', dDataMissions });
        });
        // getEtablissements(params).then(res => {
        //     const data = JSON.parse(res);
        //     let dDataEtabs = data.listInstitution;
        //     dispatchList({type: 'setDataEtabs', dDataEtabs });
        // });
        
        if(isStart === true) {
            handleGetAllEtabs(params);
            // getAllCountries().then(res => {
            //     const data = JSON.parse(res);
            //     let dDataCountries = data;
            //     dispatchList({type: 'setDataCountries', dDataCountries });
            // });
            // getAllProType().then(res => {
            //     const data = JSON.parse(res);
            //     let dDataProType = data;
            //     dispatchList({type: 'setDataProType', dDataProType });
            // });
            // getAllSpecialite().then(res => {
            //     const data = JSON.parse(res);
            //     let dDataSpeciality = data.listSpeciality;
            //     dispatchList({type: 'setDataSpeciality', dDataSpeciality });
            // });
            // getTypeEtab().then(res => {
            //     const data = JSON.parse(res);
            //     let dDataTypeEtabs = data;
            //     dispatchList({type: 'setDataTypeEtabs', dDataTypeEtabs });
            // });
            // getTypeContact().then(res => {
            //     const data = JSON.parse(res);
            //     setListObjectifs(data);
            //     let dDataTypeContact = data;
            //     dispatchList({type: 'setDataTypeContact', dDataTypeContact });
            // });
        }
    }

    const handleGetAllEtabs = (params) => {
        setLoadingEtabs(true);

        getEtablissements({ ...params, ...{
            country: params.overrideCountry ?? countrySearchState.id,
            countryName: params.overrideCountryName ?? countrySearchState.name
        } }).then(res => {
            const data = JSON.parse(res);
            let dDataEtabs = data.listInstitution;
            dispatchList({type: 'setDataEtabs', dDataEtabs });
            setLoadingEtabs(false);
        });
    }
    
	let allActions = {
		getAll: handleGetAllMedicalPros,
		editAction: medicalProService.editMedicalPro,
		addAction: medicalProService.postMedicalPro,
        
        getAllZG: getAllZonesGeo,
        getOneInstitEmp: getOneInstitutionEmployee,
        getEtabs: getAllEtabs,
        
        verifPro: medicalProService.verifPro, 

        getSpeByType: medicalProService.getSpeByType,
        getSpePro: medicalProService.getSpePro,
        postSpePro: medicalProService.postSpePro,
        disableSpePro: medicalProService.disableSpePro,
        deleteSpePro: medicalProService.deleteSpePro,

        postEtabPro: medicalProService.postEtabPro,
        editEtabPro: medicalProService.editEtabPro,
        disableEtabPro: medicalProService.disableEtabPro,
        getEtabPro: medicalProService.getEtabPro,

        getMedicalProsQualifProd: medicalProService.getMedicalProsQualifProd,

        getHistoryPDS: getHistoryPDS,
        
        getLogiciels: medicalProService.getLogiciels,
        getLogPro: medicalProService.getLogPro,
        postLogPro: medicalProService.postLogPro,
        disableLogPro: medicalProService.disableLogPro,
        deleteLogPro: medicalProService.deleteLogPro,

        getCRData: getCRData,
	}

    useEffect(() => {
        let sSearchTypePDS = null;
        dispatchFilters({type: 'setSearchTypePDS', sSearchTypePDS});
        dispatchActions({type: 'initAllActions', allActions});
        dispatchList({type: 'setList', dDataList: [] });

        getAllCountries().then(res => {
            const data = JSON.parse(res);
            let dDataCountries = data;
            dispatchList({type: 'setDataCountries', dDataCountries });
        });
        getAllProType().then(res => {
            const data = JSON.parse(res);
            let dDataProType = data;
            dispatchList({type: 'setDataProType', dDataProType });
        });
        getAllSpecialite().then(res => {
            const data = JSON.parse(res);
            let dDataSpeciality = data.listSpeciality;
            dispatchList({type: 'setDataSpeciality', dDataSpeciality });
        });
        getTypeEtab().then(res => {
            const data = JSON.parse(res);
            let dDataTypeEtabs = data;
            dispatchList({type: 'setDataTypeEtabs', dDataTypeEtabs });
        });
        getTypeContact().then(res => {
            const data = JSON.parse(res);
            setListObjectifs(data);
            let dDataTypeContact = data;
            dispatchList({type: 'setDataTypeContact', dDataTypeContact });
        });

        setMounted(true);
    }, []);

    useEffect(() => {
        if(stateFilter.memoSearchCountry !== null) {
            handleGetAllEtabs({
                overrideCountry: stateFilter.memoSearchCountry.value ?? stateFilter.memoSearchCountry.country,
                overrideCountryName: stateFilter.memoSearchCountry.label ?? stateFilter.memoSearchCountry.countryName
            });
            setCountrySearchState({
                id: stateFilter.memoSearchCountry.value ?? stateFilter.memoSearchCountry.country,
                name: stateFilter.memoSearchCountry.label ?? stateFilter.memoSearchCountry.countryName
            });
        }
    }, [stateFilter.memoSearchCountry])
	
    useEffect(() => {
		let params = {
			page: page,
			maxPerPage: maxPerPage,
		}
        let mSearchCountry = { 
            country: countrySearchState.id,
            countryName: countrySearchState.name
        };
        if(!stateFilter.memoSearchCountry){
            dispatchFilters({type: 'setMemoSearchCountry', mSearchCountry})
        }
        else {
            setCountrySearchState({
                id: stateFilter.memoSearchCountry.value ?? stateFilter.memoSearchCountry.country,
                name: stateFilter.memoSearchCountry.label ?? stateFilter.memoSearchCountry.countryName
            });
        }

		let dDataPagination = params;
		let sOrderByKey = {};

		dispatchList({type: 'setDataPagination', dDataPagination });
        dispatchFilters({type: 'setOrderByKey', sOrderByKey });	

        if(stateList.dataParamSearch) {
            let dataCountryState = {
                country: countrySearchState.id,
                countryName: countrySearchState.name
            };

            if(mounted)
                params = {
                    ...params,
                    ...stateList.dataParamSearch
                };
            
            if(stateList.dataParamSearch.country !== null)
                params = {
                    ...params,
                    ...(stateFilter.memoSearchCountry ?? dataCountryState),
                }
        }

		return handleGetAllMedicalPros(params);
	}, [page]);
	
	const dataHeader = [
		{
			name: 'nameArea',
			displayName: t('PROS.LIST.TABLE_HEADERS.COUNTRY'), 
		},
		{
			name: 'reference', 
			displayName: t('PROS.LIST.TABLE_HEADERS.REF'), 
			isFilterable: true, 
		},
		{
			name: 'lastName', 
			displayName: t('PROS.LIST.TABLE_HEADERS.LASTNAME'), 
			isFilterable: true, 
		},
		{
			name: 'firstName', 
			displayName: t('PROS.LIST.TABLE_HEADERS.FIRSTNAME'), 
			isFilterable: true, 
		},
		{
			name: 'medicalProType',
			displayName: t('PROS.LIST.TABLE_HEADERS.TYPE'), 
			isFilterable: true, 
		},
		{
			name: 'medicalProSpeciality',
			displayName: t('PROS.LIST.TABLE_HEADERS.SPECIALITY'), 
			isFilterable: true, 
		},
		{
			name: 'status', 
			displayName: t('PROS.LIST.TABLE_HEADERS.STATUS'), 
			isFilterable: true, 
		},
	];
    
    const searchFilterParams = [
        {
            name: 'country',
            displayName: t('PROS.LIST.RESEARCH.COUNTRY'),
            placeholder: t('PROS.LIST.RESEARCH.COUNTRY_PLACEHOLDER'),
            restrict: [
                'ROLE_DELEGUE',
                "ROLE_SUPERVISEUR",
                "ROLE_DSM",
                "ROLE_MONITEUR"
            ],
            type: 'select',
            data: JSON.parse(localStorage.getItem('userCountries'))
        },
        {
            name: 'mission',
            displayName: t('PROS.LIST.RESEARCH.MISSION'),
            placeholder: t('PROS.LIST.RESEARCH.MISSION_PLACEHOLDER'),
            restrict: false,
            type: 'select',
            data: stateList.dataMissions
        },
        {
            name: 'label',
            displayName: t('PROS.LIST.RESEARCH.LABEL'),
            placeholder: t('PROS.LIST.RESEARCH.LABEL_PLACEHOLDER'),
            restrict: false,
            type: 'text',
        },
        {
            name: 'location',
            displayName: t('PROS.LIST.RESEARCH.CONSULTATION_PLACE'),
            placeholder: t('PROS.LIST.RESEARCH.CONSULTATION_PLACE_PLACEHOLDER'),
            restrict: false,
            type: 'select',
            data: stateList.dataEtabs,
            dataUtil: stateList.dataTypeEtabs,
            getListFilter: handleGetAllEtabs,
            loading: loadingEtabs
        },
        {
            name: 'protype',
            displayName: t('PROS.LIST.RESEARCH.PDS_TYPE'),
            placeholder: t('PROS.LIST.RESEARCH.PDS_TYPE_PLACEHOLDER'),
            restrict: false,
            type: 'select',
            data: stateList.dataProType
        },
        {
            name: 'speciality',
            displayName: t('PROS.LIST.RESEARCH.SPECIALITY'),
            placeholder: t('PROS.LIST.RESEARCH.SPECIALITY_PLACEHOLDER'),
            restrict: false,
            type: 'select',
            data: stateFilter.searchTypePDS ? stateList.dataSpecialityByType : stateList.dataSpeciality
        },
        {
            name: 'zoneGeo',
            displayName: t('PROS.LIST.RESEARCH.GEOGRAPHICAL_AREA'),
            placeholder: t('PROS.LIST.RESEARCH.GEOGRAPHICAL_AREA_PLACEHOLDER'),
            restrict: false,
            type: 'select',
            data: stateList.dataZoneGeos
        }
    ];

    const role = localStorage.getItem('role');

    return (
        <>
            <section className="content-header">
                <h1>
                    {t('PROS.LIST.TITLE')}
                    <small>{t('PROS.LIST.SUBTITLE')}</small>
                </h1>
            </section>
            <section className="pdsContainer">
                <SearchFilter type="medicalProPage" dataFilter={searchFilterParams} title={t('PROS.LIST.RESEARCH.TITLE')}/>

                <section className="content" id="listProfessionnels">            
                    <section className="sectionTable etab">
                        <TableManager 
                            dataHeader={dataHeader} 
                            actionsList={role !== "ROLE_COORDINATEUR" ? ['edit'] : []}
                            type="medicalProPage"
                            isAdd={role !== "ROLE_COORDINATEUR"}
                        />
                        <br/>
                        <Pagination
                            maxPage={maxPage}
                            onChangePage={(data) => setPage(data)}
                            nbCount={nbResultats}
                            page={stateList.dataPagination.page}
                        ></Pagination>
                    </section>

                    <PopUpManager 
                        type="medicalProPage"
                        displayTitle="Professionnel de santé"
                    />

                </section>
            </section>

            <PopupRemplirCR
                crReady={crReady}
                item={crReady && cr}
                listObjectifs={listObjectifs}
                listRP={listRP}
                listTypeHisto={listTypeHisto}
                remplirCR={() => {}}
                readOnly={true}
            />
        </>
	)
}
