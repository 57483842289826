import { useState, useEffect, useContext } from 'react';
import "./Secteurs.scss";
import { t } from "../../i18nFunctions";
import { NotificationManager } from 'react-notifications';
import { StoreContext } from "../../../store/store";

// components
import { CustomSelect } from '../../../components/UI/CustomSelect/CustomSelect';
import { ButtonAdd } from '../../../components/UI/Button/ButtonAdd/ButtonAdd';
import { PopupSecteur } from '../../../components/Popups/Secteurs/PopupSecteur';
import { PopupDelete } from '../../../components/Popups/PopupDelete';
import { PopupConfirmation } from '../../../components/Popups/PopupConfirmation';

// images
import iconEdit from "../../../assets/img/icons/icon-edit.png";
import iconDelete from "../../../assets/img/icons/icon-delete.png";

import {
    getAllCountries,
    getAllLabo,

    getSectorByCountry,
    postSecteur,
    editSecteur,
    deleteSecteur
} from '../../../store/index.service';

export const Secteurs = () => {

    const [countryList, setCountryList] = useState([]);
    const [idCountry, setIdCountry] = useState(-1);
    const [nameCountry, setNameCountry] = useState("");
    const [secteursList, setSecteursList] = useState([]);
    const [countryLock, setCountryLock] = useState({});
    const [selectedSecteur, setSelectedSecteur] = useState({});
    const [idDelete, setIdDelete] = useState(-1);
    const [idLabo, setIdLabo] = useState(-1);
    const [propharmed, setPropharmed] = useState(false);
    const [laboList, setLaboList] = useState([]);
    const [confirmSubmit, setConfirmSubmit] = useState(() => () => {});

    const { setLoadingStore } = useContext(StoreContext);

    // mounted
    useEffect(() => {
        setLoadingStore(true);

        Promise.all([
            getAllCountries().then(res => {
                const data = JSON.parse(res);
                if(data) setCountryList(data);
            }),
            getAllLabo().then(res => {
                const data = JSON.parse(res);
                if(data) setLaboList(data);
            })
        ]).then(() => setLoadingStore(false));
    }, []);

    // updates

    const handleGetSectorByCountry = (idC, idL) => {
        setLoadingStore(true);
        getSectorByCountry(idC, idL).then(res => {
            const data = JSON.parse(res);

            if(data) setSecteursList(data);
            else setSecteursList([]);

            setLoadingStore(false);
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if(idCountry !== -1 && (propharmed || idLabo !== -1)) {
            setCountryLock({ id: idCountry, name: nameCountry });
            handleGetSectorByCountry(idCountry, propharmed ? null : idLabo);
        }
    }

    return(
        <section className="content">
        
            <section className="content-header">
                <h1>
                    {t('SETTINGS.SECTORS')}
                    <small>{t('SETTINGS.MANAGEMENT')}</small>
                </h1>
            </section>
        
            <section className="content sect">

                <div className="row">
                    <div className="col-md-4">
                        <div className="box info secteurs">
                            <form id="secteursForm">
                                <div>
                                    <CustomSelect
                                    defaultText={t('SETTINGS.SELECT_COUNTRY')}
                                    optionsList={countryList}
                                    handleOptionClick={(idCountry, nameCountry) => {
                                        setIdCountry(idCountry);
                                        setNameCountry(nameCountry);
                                    }}
                                    />

                                    <div className="check">
                                        <input
                                        type="checkbox"
                                        id="defaultSector"
                                        checked={propharmed}
                                        onChange={() => setPropharmed(!propharmed)}
                                        />
                                        <label htmlFor="defaultSector">{t('SETTINGS.DEFAULT_SECTOR')}</label>
                                    </div>

                                    {!propharmed &&
                                        <CustomSelect
                                        defaultText={t('SETTINGS.SELECT_LABORATORY')}
                                        optionsList={laboList}
                                        handleOptionClick={(id) => setIdLabo(id)}
                                        />
                                    }
                                </div>
                                <div className="appliquer">
                                    <button
                                    type="submit"
                                    className="btn-save"
                                    disabled={!(idCountry !== -1 && (propharmed || idLabo !== -1))}
                                    onClick={handleSubmit}
                                    >
                                        {t('COMMON.APPLY')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                    {Object.keys(countryLock).length > 0 &&
                    <div className="col-md-8">
                        <table className="box tab-entete secteurs">
                            <tbody>
                                <tr>
                                    <td>
                                        <div>
                                            <p>{t('SETTINGS.ENTITLED')}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <p>{t('SETTINGS.COUNTRY')}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <ButtonAdd modal="#modalAddEditSecteur" handleAdd={() => setSelectedSecteur({})}/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="list secteurs">
                            <tbody>
                            {secteursList.length > 0 ?
                                secteursList.map(sec => 
                                    <tr key={sec.idSector} className="list-row">
                                        <td>{sec.nameSector}</td>
                                        <td>{sec.area && sec.area.nameAreaSector}</td>
                                        <td className="icons">
                                            <div
                                            className="icon-btn"
                                            data-toggle="modal"
                                            data-target="#modalAddEditSecteur"
                                            onClick={() => setSelectedSecteur(sec)}>
                                                <img src={iconEdit} alt="edit"/>
                                            </div>
                                            <div
                                            className="icon-btn"
                                            data-toggle="modal"
                                            data-target="#modalDelete"
                                            onClick={() => setIdDelete(sec.idSector)}>
                                                <img src={iconDelete} alt="x"/>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            : <tr><td className="text-center">{t('SETTINGS.NO_SECTOR_FOUND')}</td></tr>
                            }
                            </tbody>
                        </table>
                    </div>
                    }
                </div>

            </section>

            <PopupSecteur
                secteur={selectedSecteur}
                countryLock={countryLock}
                countryList={countryList}
                laboList={laboList}

                handleSubmit={(submit) => setConfirmSubmit(() => () => submit())}

                addSecteur={(name, idCountry, labo, zoneGeo) => postSecteur(name, idCountry, labo, zoneGeo).then(() => {
                    NotificationManager.success('', "Secteur ajouté");
                    handleGetSectorByCountry(idCountry, propharmed ? null : idLabo);
                }).catch(() => NotificationManager.error('', "Error"))}

                editSecteur={(idSecteur, name, idCountry, labo, zoneGeo) => editSecteur(idSecteur, name, idCountry, labo, zoneGeo).then(() => {
                    NotificationManager.success('', "Secteur modifié");
                    handleGetSectorByCountry(idCountry, propharmed ? null : idLabo);
                }).catch(() => NotificationManager.error('', "Error"))}
            />

            <PopupDelete
                title="Supprimer un secteur"
                id={idDelete}

                handleDelete={id => deleteSecteur(id).then(() => {
                    NotificationManager.success('', "Secteur supprimé");
                    handleGetSectorByCountry(idCountry, propharmed ? null : idLabo);
                }).catch(() => NotificationManager.error('', "Error"))}
            />

            <PopupConfirmation message={t('COMMON.CONFIRM_MSG')} handleSubmit={confirmSubmit}/>

        </section>
    )
}