import { useState, useEffect, useRef } from 'react';
import { t } from "../../../pages/i18nFunctions";
import { config } from "../../../store/config";

// components
import Select from 'react-select';

// images
import iconSave from "../../../assets/img/icons/icon-save.png";

export const PopupOrga = (props) => {

    const { poste, getAllUsers, listUsers, changeUser, removeUser, handleSubmit } = props;

    const [user, setUser] = useState(null);
    const [usersList, setUsersList] = useState(listUsers);
    const [loadingUsers, setLoadingUsers] = useState(false);

    const groupeList = config.groupeList;
    const btnRef = useRef(null);
    const edit = Object.keys(poste).length > 0;
    const timer = useRef(null);

    // mounted
    useEffect(() => {
        timer.current = setTimeout(() => {}, 1000);
        // unmounted
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    // updates
    useEffect(() => {
        if(poste.id)
            if(poste.delegue.idDelegue === null)
                setUser(null);
            else
                setUser({
                    value: poste.delegue.idDelegue,
                    label: poste.delegue.fullname,
                    role: poste.delegue.role[0],
                    fullname: poste.delegue.fullname
                });
    }, [poste]);

    useEffect(() => {
        setUsersList(listUsers);
    }, [listUsers]);

    const handleGetAllUsers = (search) => {

        clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            setLoadingUsers(true);

            getAllUsers({ search: search }).then(res => {
                const data = JSON.parse(res);

                if(data.listUser) setUsersList(data.listUser);
                else setUsersList([]);

                setLoadingUsers(false);
            });
        }, 1000);
    }

    // functions

    const submitForm = () => {
        if(user)
            changeUser(poste.id, user.value);
        else
            removeUser(poste.id);

        btnRef.current.click();
    }

    return(
        <div className="modal fade in" id="modalAddPoste" tabIndex="-1" role="dialog" aria-labelledby="modalAddPosteLabel" animate="animate">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title" id="myModalLabel">Affectation d'un utilisateur</h1>
                    </div>
                    <div className="modal-body">
                        <form id="formAjout">

                            <label style={{ display: "inline-block" }}>Utilisateur</label>

                            <Select
                                options={usersList.map(user => ({
                                    value: user.id,
                                    label: `${user.firstName} ${user.lastName} ${user.username}`,
                                    role: user.roles[0],
                                    fullname: `${user.firstName} ${user.lastName}`
                                }))}
                                value={user}
                                onInputChange={newValue => handleGetAllUsers(newValue)}
                                onChange={newValue => setUser(newValue)}
                                placeholder="Entrez un nom d'utilisateur..."
                                formatOptionLabel={(option, { context }) => {
                                    /* context can be either `menu` or `value`
                                        menu - dropdown
                                        value - value displayed
                                    */
                                    return context === 'menu' ? (
                                        <div className="select-react-option">
                                            <p>{option.fullname}</p>
                                        </div>
                                    ) : `${option.fullname} (${groupeList[groupeList.map((r) => r.val[0]).indexOf(option.role)].name})`;
                                }}
                                isClearable
                                className="react-select-container"
                                isLoading={loadingUsers}
                            />

                            <p className="small-info">Si ce champ est vide, cela signifie que le poste est actuellement inoccupé.</p>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="submit"
                            form="formAjout"
                            className="btn-save"
                            disabled={false}
                            data-toggle={ edit ? 'modal' : '' }
                            data-target={'#modalConfirmation'}
                            onClick={e => {
                                e.preventDefault();
                                if(edit) handleSubmit(submitForm);
                                else submitForm();
                            }}
                        >
                            <img src={iconSave} alt=""/>
                            Sauvegarder
                        </button>
                        <button
                            type="button"
                            className="btn-cancel"
                            data-dismiss="modal"
                            onClick={() => {}}
                            ref={btnRef}
                        >Annuler</button>
                    </div>
                </div>
            </div>
        </div>
    )
}