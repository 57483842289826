import { useState } from 'react';
import "./TabElements.scss";

export const TabElements = ({ options, values, handleFocus = () => {} }) => {

    const [focused, setFocused] = useState(Object.keys(options)[0]);

    const focus = (key) => {
        handleFocus(key);
        setFocused(key);
    }

    const l = Object.keys(options).length ? Object.keys(options).length : 1;
    
    return(
        <>
        <div className="tab-elements">
            {Object.keys(options).map(k =>
                <div
                    style={{ width: (1/l * 100)+"%" }}
                    onClick={() => focus(k)}
                    key={k}
                    className={`tab-element ${focused === k ? "focused" : ""}`}
                >
                    {options[k]}
                </div>
            )}
        </div>
        <div>
            {Object.keys(values).map(k =>
                <div
                    key={k}
                    style={{ display: focused === k ? "block" : "none" }}
                >
                    {values[k]}
                </div>
            )}
        </div>
        </>
    )
}