import React, { useState } from "react";
import { t } from '../../pages/i18nFunctions';
import moment from 'moment';

export const Appointment = (props) => {

    const {
        item, iconTel, iconMail, details, path, roleUser, updateSelect, buttonText, bottomText, withDelegue,
        voirPDS, getCR, readOnlyCR, handleNextApp, deleteRDV, cancelApp
    } = props;
    
    const [showMenu, setShowMenu] = useState(false);

    const cancelled = item.status === 1;
    const valid = item.status === 2;

    const canSetActivityToDUO = (
        buttonText === "DUO"
        && (roleUser === "ROLE_SUPERVISEUR" || roleUser === "ROLE_DSM" || roleUser === "ROLE_HP" || roleUser === "ROLE_DP")
    )

    return (
        <div
            className={`draggable-pds ${
                cancelled ?
                    "cancelled"
                : item.noProductPresented ?
                    "semi"
                : valid ?
                    "valid"
                : item.isopportunistic ?
                    "opportuniste"
                : ""
            }`}
            onClick={() => setShowMenu(!showMenu)}
        >
            <div className="row">

                <div className="col-md-8">
                    <span>{item.medicalpro.fisrtname} {item.medicalpro.lastname}</span>
                </div>

                <div className="col-md-4 showOverflow">
                    {cancelled ?
                        <div
                            className="infobulle"
                            aria-label={item.cancellationreason.name}
                        >
                            <i className="fa fa-calendar-times-o"></i>
                        </div>
                    :
                        <div>

                            {/* not ?? cause it will think empty string is true */}
                            <div
                                className="infobulle"
                                aria-label={item.medicalpro.mobile ? item.medicalpro.mobile : t('PLANNING.CALENDAR.TEL_RECUP')}
                            >
                                <img src={iconTel} alt="tel"/>
                            </div>

                            <div
                                className="infobulle"
                                aria-label={item.medicalpro.email ? item.medicalpro.email : t('PLANNING.CALENDAR.EMAIL_RECUP')}
                            >
                                <img src={iconMail} alt="mail"/>
                            </div>

                            {item.isopportunistic &&
                                <div
                                    className="infobulle"
                                    aria-label={t('PLANNING.CALENDAR.OPPORTUNISTE')}
                                >
                                    <i className="fa fa-bolt"></i>
                                </div>
                            }

                        </div>
                    }
                </div>

            </div>

            {details &&
                <div>

                    <div className="row">

                        <div className="col-md-12 showOverflow">
                            <small
                                className="tag info infobulle"
                                aria-label={`${item.medicalpro.type.nameType} ${
                                    item.medicalpro.speciality ?
                                        item.medicalpro.speciality.nameSpeciality
                                    : ""
                                }`}
                            >
                                {item.medicalpro.type.codeType} {item.medicalpro.speciality && item.medicalpro.speciality.codeSpeciality}
                            </small>

                            <small
                                className="tag info infobulle"
                                style={{ marginLeft: 5 }}
                                aria-label={item.medicalpro.isTarget === 1 ? t('PLANNING.CALENDAR.TARGET') : t('PLANNING.CALENDAR.OFF_TARGET')}
                            >
                                {item.medicalpro.isTarget === 1 ? t('PLANNING.CALENDAR.TARGET_ABREV') : t('PLANNING.CALENDAR.OFF_TARGET_ABREV')}
                            </small>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <small>{item.institution.name}</small>
                        </div>
                    </div>

                </div>
            }

            <div className="drag-menu" style={{ display: showMenu ? "block" : "none" }}>

                <p
                    data-toggle="modal"
                    data-target="#modalAddEditPro"
                    onClick={() => voirPDS(item.medicalpro.id)}
                >
                    <i className="fa fa-user-md"></i> {t('PLANNING.CALENDAR.SEE_CARD')}
                </p>
                
                {/* EDIT/FILL REPORT */}
                {
                    !cancelled && path === "/missions/:id/calendar" && roleUser === "ROLE_ADMIN"
                    &&
                        moment(item.schedule.dateSchedule).startOf('day').diff(moment().startOf('day'), 'days') <= 0
                    &&
                        <p
                            data-toggle="modal"
                            data-target="#modalRemplirCR"
                            onClick={() => {
                                getCR(item.id);
                                readOnlyCR(false);
                                handleNextApp(bottomText, item.institution.id, item.medicalpro.id)
                            }}
                        >
                            <i className="fa fa-file-text-o"></i> {valid || item.status === 4 ? t('COMMON.EDIT') : t('COMMON.FILL')} {t('PLANNING.CALENDAR.THE_REPORT')}
                        </p>  
                }

                {/* EDIT/FILL REPORT (else if) */}
                {
                    !updateSelect && !cancelled && path === "/calendar/current" && !canSetActivityToDUO
                    &&
                        moment(item.schedule.dateSchedule).startOf('day').diff(moment().startOf('day'), 'days') <= 0
                    &&
                        <p
                            data-toggle="modal"
                            data-target="#modalRemplirCR"
                            onClick={() => {
                                getCR(item.id);
                                readOnlyCR(false);
                                handleNextApp(bottomText, item.institution.id, item.medicalpro.id)
                            }}
                        >
                            <i className="fa fa-file-text-o"></i> {valid || item.status === 4 ? t('COMMON.EDIT') : t('COMMON.FILL')} {t('PLANNING.CALENDAR.THE_REPORT')}
                        </p>
                }

                {/* DELETE APPOINTMENT */}
                {
                    !updateSelect && !cancelled && path === "/calendar/current" && !canSetActivityToDUO
                    &&
                        moment(item.schedule.dateSchedule).startOf('day').diff(moment().startOf('day'), 'day') > 0
                    &&
                        <p
                            data-toggle="modal"
                            data-target="#modalDelete"
                            onClick={() => deleteRDV(item.id)}
                        >
                            <i className="fa fa-trash"></i> {t('PLANNING.CALENDAR.DELETE')}
                        </p>
                }

                {/* CANCEL APPOINTMENT */}
                {
                    !updateSelect && !cancelled && path === "/calendar/current" && !canSetActivityToDUO
                    &&
                        moment(item.schedule.dateSchedule).startOf('day').diff(moment().startOf('day'), 'week') <= 0
                    &&
                        moment(item.schedule.dateSchedule).startOf('day').diff(moment().startOf('day'), 'day') <= 0
                    &&
                        <p
                            data-toggle="modal"
                            data-target="#modalCancelAppointment"
                            onClick={() => cancelApp(item.id)}
                        >
                            <i className="fa fa-calendar-times-o"></i> {t('PLANNING.CALENDAR.CANCEL')}
                        </p>
                }

                {/* READ ONLY DUO REPORT */}
                {
                    !cancelled && path === "/calendar/current" && withDelegue.user !== "" && roleUser !== "ROLE_DELEGUE"
                    &&
                        moment(item.schedule.dateSchedule).startOf('day').diff(moment().startOf('day'), 'weeks') <= 0
                    &&
                        <p
                            data-toggle="modal"
                            data-target="#modalRemplirCR"
                            onClick={() => {
                                getCR(item.id);
                                readOnlyCR(true);
                            }}
                        >
                            <i className="fa fa-file-text-o"></i> {t('PLANNING.CALENDAR.CONSULT')}
                        </p>
                }

                {/* READ ONLY OTHER'S REPORTS */}
                {
                    !cancelled && path === "/missions/:id/calendar" && roleUser !== "ROLE_ADMIN"
                    &&
                        moment(item.schedule.dateSchedule).startOf('day').diff(moment().startOf('day'), 'days') <= 0
                    && (
                        <p
                            data-toggle="modal"
                            data-target="#modalRemplirCR"
                            onClick={() => {
                                getCR(item.id);
                                readOnlyCR(true);
                            }}
                        >
                            <i className="fa fa-file-text-o"></i> {t('PLANNING.CALENDAR.CONSULT')}
                        </p>
                )}

                {/* READ ONLY REPORT */}
                {
                    updateSelect && !cancelled && path === "/calendar/current"
                    &&
                        moment(item.schedule.dateSchedule).startOf('day').diff(moment().startOf('day'), 'days') <= 0
                    &&
                        <p
                            data-toggle="modal"
                            data-target="#modalRemplirCR"
                            onClick={() => {
                                getCR(item.id);
                                readOnlyCR(true);
                            }}
                        >
                            <i className="fa fa-file-text-o"></i> {t('PLANNING.CALENDAR.CONSULT')}
                        </p>
                }
            </div>
        </div>
    )
}