import React, { useReducer, createContext } from 'react';

export const initialFilterState = {
    orderByKey: {},
    searchValue: '',
    memoSearchList: [],
    memoSearchCountry: null,
    searchFilters: {},
    searchType: '',
    searchTypePDS: null,
    searchDate: {},
    searchProducts: '',
    searchSupervision: null,
    searchCRLastWeek: null,
    searchCRCurrentWeek: null,
    searchUserStatus: null,
    searchUserRole: null
}

export const FilterStoreContext = createContext(initialFilterState);

export function reducer(state, action) {
    switch (action.type) {
        case 'setOrderByKey':
            return {...state, orderByKey: action.sOrderByKey};
        case 'setSearchValue':
            return {...state, searchValue: action.sSearchValue};
        case 'setMemoSearchList':
            return {...state, memoSearchList: action.mSearchList};
        case 'setMemoSearchCountry':
            return {...state, memoSearchCountry: action.mSearchCountry};
        case 'setSearchFilters':
            return {...state, searchFilters: action.sSearchFilters};
        case 'setSearchType':
            return {...state, searchType: action.sSearchType};
        case 'setSearchTypePDS':
            return {...state, searchTypePDS: action.sSearchTypePDS};
        case 'setSearchProducts':
            return {...state, searchProducts: action.sSearchProducts};
        case 'setSearchDate':
            return {...state, searchDate: action.sSearchDate};
        case 'setSearchSupervision':
            return {...state, searchSupervision: action.sSearchSupervision};
        case 'setSearchCRLastWeek':
            return {...state, searchCRLastWeek: action.sSearchCRLastWeek};
        case 'setSearchCRCurrentWeek':
            return {...state, searchCRCurrentWeek: action.sSearchCRCurrentWeek};
        case 'setSearchUserStatus':
            return {...state, searchUserStatus: action.sSearchUserStatus};
        case 'setSearchUserRole':
            return {...state, searchUserRole: action.sSearchUserRole};
        default:
            throw new Error();
    } 
}

export const FilterStoreContainer = ({children}) => {
    const [stateFilter, dispatchFilters] = useReducer(reducer, initialFilterState);
    
    return (
        <FilterStoreContext.Provider value={{stateFilter, dispatchFilters}}>
            {children}
        </FilterStoreContext.Provider>
    )
}
