import { useState, useContext } from 'react';
import { ActionsStoreContext } from '../../../../../store/store/storeActions';
import { ListStoreContext } from '../../../../../store/store/storeList';

import { config } from "../../../../../store/config";

import { t } from "../../../../../pages/i18nFunctions";

import { CustomSelect } from '../../../../UI/CustomSelect/CustomSelect';
import { RoleInput } from './RoleInput';

export const AddUser = (props) => {
    const { stateActions, dispatchActions } = useContext(ActionsStoreContext);
    const { stateList } = useContext(ListStoreContext);
    const { dataActions } = stateActions;

    const { dataCountries, dataLab } = stateList;

    const [item, setItem] = useState({
        lastName: '',
        firstName: '',
        roles: '',
        username: '',
        email: '',
        pwd: '',
        pwdConfirm: '',
        emailAsUsername: false,
        restrictedArea: [],
        labos: []
    });
    const [role, setRole] = useState(item.roles)

    const optionList = config.groupeList;

    const handleChange = (name, value) => {  
        if(name === 'roles')
            setRole(value)
        
        let aDataActions = { ...item, [name]: value};
        setItem(aDataActions)
        return dispatchActions({type: 'setDataActions', aDataActions})
    }
    
    return(
        <>
            <form>
                <div>
                    <label>{t('SETTINGS.GROUP')}</label>
                    <CustomSelect
                        defaultText={'Sélectionner un groupe'}
                        optionsList={optionList}
                        handleOptionClick={(id, name, value) => handleChange('roles', value)}
                    />   
                </div>

                <div className='flex-row two-cols'>
                    <div>
                        <label htmlFor="lastName">{t('SETTINGS.NAME')}</label>
                        <input id="lastName" type="text" required value={item.lastName} onChange={(e) => handleChange('lastName', e.target.value)}/>
                    </div>
                    <div>
                        <label htmlFor="firstName">{t('SETTINGS.FIRSTNAME')}</label>
                        <input id="firstName" type="text" required value={item.firstName} onChange={(e) => handleChange('firstName', e.target.value)}/>
                    </div>
                </div>

                <div className="check">
                    <input id="emailAsUsername" type="checkbox" checked={item.emailAsUsername} onChange={(e) => handleChange('emailAsUsername', e.target.checked)}/>
                    <label htmlFor="emailAsUsername">{t('SETTINGS.USE_EMAIL_PWD')}</label>
                </div>

                {
                    item.emailAsUsername === false &&
                    <div>
                        <label htmlFor="userName">{t('SETTINGS.IDENTIFIER')}</label>
                        <input id="userName" type="text" required value={item.username} onChange={(e) => handleChange('username', e.target.value)}/>
                    </div>
                }

                <div>
                    <label htmlFor="email">{t('SETTINGS.EMAIL')}</label>
                    <input id="email" type="text" required value={item.email} onChange={(e) => handleChange('email', e.target.value)}/>
                </div>

                <div className='flex-row two-cols'>
                    <div>
                        <label htmlFor="pwd">{t('SETTINGS.MDP')}</label>
                        <input id="pwd" type="password" required value={item.pwd} onChange={(e) => handleChange('pwd', e.target.value)}/>
                    </div>
                    <div>
                        <label htmlFor="pwdConf">{t('SETTINGS.CONFIRM_PWD')}</label>
                        <input id="pwdConf" type="password" required value={item.pwdConfirm} onChange={(e) => handleChange('pwdConfirm', e.target.value)}/>
                    </div>
                </div>

                <RoleInput
                    role={role}
                    item={item}
                    countries={dataCountries}
                    labos={dataLab}
                    handleChange={(name, value) => handleChange(name, value)}
                />
            </form>
        </>
    )
}
