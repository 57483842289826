import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getEtablissements = (data) => {
    return new Promise((resolve, reject) => {
        try { 
            let params = {};
            if( data !== undefined ){
                params = {
                    "page": (data.page !== undefined) ? data.page : 1,
                    "maxPerPage": (data.maxPerPage !== undefined) ? data.maxPerPage : 50,
                    "country": (data.country !== undefined) ? data.country : null,
                    "mission": (data.mission !== undefined) ? data.mission : null,
                    "label": (data.label !== undefined) ? data.label : null,
                    "type": (data.type !== undefined) ? data.type : null,
                    "zoneGeo": (data.zoneGeo !== undefined) ? data.zoneGeo : null  
                }
            }else{                
                params = {
                    "page": (data.page !== undefined) ? data.page : 1,
                    "maxPerPage": (data.maxPerPage !== undefined) ? data.maxPerPage : 50,
                    "country": (data.country !== undefined) ? data.country : null,
                }
            }
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/list/institution', params)
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const verifEtablissement = (data) => {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).post('api/verif-name/institution', {
                    "name": data.name,
                    "page": data.page,
                    "maxPerPage": data.maxPerPage,
                    "id": data.id ? data.id : null,
                    "areaName": data.idCountry
                })
                .then(function (response) {
                    try {
                        resolve(JSON.stringify(response.data));
                    } catch (error) {
                        reject(error);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const postEtablissement = (etab)  =>{
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).post('api/institution/'+etab.country, {
                    idInstitutionType: etab.type,
                    name: etab.name,
                    mail: etab.mail,
                    landLine: etab.phone,
                    faxNumber: etab.faxNumber,
                    isPrivate: 0,
                    withInternetConn: 0,
                    withInsurance: 0,
                    address1: "",
                    address2: "",
                    coordLat: null,
                    coordLong: null,
                    zoneGeo: etab.zoneGeo
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const editEtablissement = (etab) => {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).put('api/institution/'+etab.id, {
                    idInstitutionType: etab.idInstitutionType,
                    name: etab.name,
                    mail: etab.mail,
                    landLine: etab.landLine,
                    faxNumber: etab.faxNumber,
                    isPrivate: etab.isPrivate,
                    withInternetConn: etab.withInternetConn,
                    withInsurance: etab.withInsurance,
                    address1: etab.address1,
                    address2: etab.address2,
                    coordLat: etab.CoordLat !== "" ? etab.CoordLat : null,
                    coordLong: etab.CoordLong !== "" ? etab.CoordLong : null,
                    zoneGeo: etab.zoneGeo === undefined ? etab.idzoneGeo : etab.zoneGeo
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getTypeEtab = () => {
    
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/institutiontype', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listInstitutionType));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getAllEtabs = (data) => {
    
    return new Promise((resolve, reject) => {
        try {
            let params = {};
            if( data !== undefined ){
                params = {
                    "page": data.page,
                    "maxPerPage": data.maxPerPage,
                    "label": data.label
                }
                if(data.country !== undefined) params = Object.assign(params, { country: data.country })
            }
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/list/institution', params)
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listInstitution));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getOneInstitutionEmployee = (data) => {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get(`api/institution/${data}`)
                .then(function (response) {
                    resolve(JSON.stringify(response.data.institution[0].medicalPro));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}
