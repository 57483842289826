import { useState, useEffect, useContext } from 'react';
import "./Historique.scss";
import { t } from '../i18nFunctions';
import { StoreContext } from "../../store/store";
import Moment from 'react-moment';

// components
import { Pagination } from '../../components/Pagination/Pagination';

import {
    getHistory
} from '../../store/index.service';

export const Historique = () => {

    const [history, setHistory] = useState([]);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [maxPerPage, setMaxPerPage] = useState(50);
    const [nbResultats, setNbResultats] = useState(0);

    const { setLoadingStore } = useContext(StoreContext);

    // mounted
    useEffect(() => {
        handleGetHistory();
    }, [page]);

    // functions

    const handleGetHistory = () => {
        setLoadingStore(true);

        getHistory({
            page: page,
            maxPerPage: maxPerPage
        }).then(res => {
            const data = JSON.parse(res);

            if(data.listAction) setHistory(data.listAction);
            else setHistory([]);

            if(data.nbrPages) setMaxPage(data.nbrPages);
            else setMaxPage(1);

            if(data.nbResultats) setNbResultats(data.nbResultats);
            else setNbResultats(0);

            setLoadingStore(false);
        });
    }

    return(
        <section className="content">
        
            <section className="content-header">
                <h1>
                    {t('HISTORY.TITLE')}
                    <small>{t('HISTORY.LATEST_USER_ACTIONS')}</small>
                </h1>
            </section>
        
            <section className="content histo">

                <table className="box tab-entete historique">
                    <tbody>
                        <tr>
                            <td className="action">
                                <div>
                                    <p>{t('HISTORY.ACTION')}</p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p>{t('HISTORY.BY')}</p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p>{t('HISTORY.DATE')}</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table className="list historique">
                    <tbody>
                    { history.map(action =>
                        <tr key={action.id} className="list-row">
                            <td className="action">
                                {action.action}
                            </td>
                            <td>
                                {action.par.fullname}
                            </td>
                            <td>
                                <Moment locale="fr" fromNow>{new Date(action.date)}</Moment>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>

            </section>

            { maxPage > 1 && 
                <Pagination
                    maxPage={maxPage}
                    nbCount={nbResultats}
                    onChangePage={(data) => setPage(data)}
                ></Pagination>
            }
        </section>
    )
}