import { useState } from 'react';
import "./Login.scss";
import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';

import {
    login,
    forgotPassword,
    loginHistory,
    getUserConnected
} from '../../store/index.service';

export const Login = ({ userLogin }) => {
    let navigate = useNavigate();

    const [submitted, setSubmitted] = useState(false);
    const [submittedPassword, setSubmittedPassword] = useState(false);
    const [errorLogin, setErrorLogin] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [forgotPwd, setForgotPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [emailForgot, setEmailForgot] = useState("");
    const [password, setPassword] = useState("");
    const [logingIn, setLogingIn] = useState(false);

    const handleForgotPassword = (event) => {
        event.preventDefault();
        setSubmittedPassword(false);
        setForgotPassword(!forgotPwd);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        setLogingIn(true);
        setErrorLogin(false);

        login(email, password)
        .then(() => {
            // store data in localStorage before redirect
            getUserConnected()
            .then(() => {

                loginHistory();
                userLogin()
                navigate("/");

            }).catch(err => {
                console.error(err);
                setErrorLogin(true);
                setErrorMsg("Erreur serveur");
            });

        }).catch(err => {
            console.error(err);
            setErrorLogin(true);
            setErrorMsg("Erreur de login ou mot de passe");
        })
        .finally(() => setLogingIn(false))
    }

    const handleSubmitForgot = (event) => {
        event.preventDefault();
        setSubmittedPassword(true);
        forgotPassword(emailForgot);
    }

    return (
      <section className="content" id="login-section">
          <div className="login-box">
            <div className="login-logo">
                Ami<b>VM</b> 
            </div>
            {!forgotPwd ? (
            <div className="login-box-body">
                <p className="login-box-msg" translate="">Connectez-vous à votre compte</p>

                <form onSubmit={handleSubmit}>

                    { (!logingIn && errorLogin && submitted) && <p className='error'>{errorMsg}</p> }   

                    <div className="input-group">
                        <input
                        type="text"
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value);
                            if(submitted) setSubmitted(false);
                        }}
                        placeholder="Identifiant"
                        required
                        />
                        <span className="input-group-addon"><i className="fa fa-user"></i></span>
                    </div>
                    { (submitted && email === "") && <p className='error'>Identifiant requis</p> }

                    <div className="input-group">
                        <input
                        type="password"
                        value={password}
                        onChange={(event) => {
                            setPassword(event.target.value);
                            if(submitted) setSubmitted(false);
                        }}
                        placeholder="Mot de passe"
                        required
                        />
                        <span className="input-group-addon"><i className="fa fa-lock"></i></span>
                    </div>
                    { (submitted && password === "") && <p className='error'>Mot de passe requis</p> }

                    <div className="row">
                        <div className="col-xs-4 pull-right">
                            <button type="submit" className="btn btn-primary btn-block btn-flat" disabled={email === "" ||  password === ""}>
                                { logingIn ?
                                <ReactLoading type={"spinningBubbles"} color={"#ffffff"} height={20} width={20} className="spinner-loader" />
                                : <span>Connexion</span> }
                            </button>
                        </div>
                    </div>
                </form>
                <p className="link_password" onClick={handleForgotPassword}>J'ai oublié mon mot de passe</p>
            </div>
            ) 
            : 
            (
                <div className="login-box-body">
                    <p className="login-box-msg" translate="">Si vous avez lié une adresse électronique avec votre compte, vous pouvez l'utiliser afin de changer votre mot de passe. Si ce n'est pas le cas ou si vous rencontrez des difficultés, contactez l'un des administrateurs de votre entreprise.</p>
                    <form onSubmit={handleSubmitForgot}>

                        { (!logingIn && errorLogin && submittedPassword) && <p className='error'>Nous ne reconnaissons pas cette adresse email</p> }   

                        <div className="input-group">
                            <input
                            type="email"
                            value={emailForgot}
                            onChange={(event) => setEmailForgot(event.target.value)}
                            placeholder="email"
                            required
                            />
                            <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                        </div>
                        { submittedPassword && emailForgot === "" && <p className='error'>Email requis</p> }

                        <div className="row">
                            <div className="col-xs-4 pull-right">
                                <button type="submit" className="btn btn-primary btn-block btn-flat" disabled={emailForgot === ""}>
                                    <span>Envoyer</span>
                                </button>
                            </div>
                        </div>
                    </form>
                    <p className="link_password" onClick={handleForgotPassword}>Je me rappelle de mon mot de passe</p>
                </div>
            )
            }
          </div>
      </section>
    )
}