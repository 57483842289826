import iconEdit from "../../../assets/img/icons/icon-edit.png";
import iconLock from "../../../assets/img/icons/icon-lock.png";

export const ListRowQuestion = ({ item, handleQuestionEdit, disableQuestion }) => {

    return(
        <tr className="list-row">
            <td className="quest">{item.name}</td>
            <td className="stat planning">
                <div className={`tag ${item.status ? "valid" : "alert"}`}>
                    <p>{item.status ? "Active" : "Désactivée"}</p>
                </div>
            </td>
            <td className="icons last">
                <div className="icon-btn"
                data-toggle="modal"
                data-target="#modalAddEditQuestion"
                onClick={() => handleQuestionEdit(item)}
                >
                    <img src={iconEdit} alt=""/>
                </div>
                <div
                className="icon-btn"
                onClick={() => disableQuestion(item.id)}>
                    <img src={iconLock} alt=""/>
                </div>
            </td>
        </tr>
    )
}