import React from "react";
import packageJson from "../package.json";
import moment from "moment";

const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  console.log("clear cache : ", momLatestDateTime, momCurrentDateTime, momLatestDateTime.isAfter(momCurrentDateTime));

  if (momLatestDateTime.isAfter(momCurrentDateTime)) {
    return true;
  } else {
    return false;
  }
};

export default class ClearCache extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        isLatestBuildDate: false,
        refreshCacheAndReload: () => {
          console.log('Clearing cache and hard reloading...')
          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function(names) {
              for (let name of names) caches.delete(name);
            });
          }
          // delete browser cache and hard reload
          window.location.reload(true);
        }
      };
    }
  
    componentDidMount() {
        fetch("/meta.json", { cache: 'no-store' })
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate
          );
          if (shouldForceRefresh) {
            console.log(`New date - ${latestVersionDate}. Should force refresh`);
            this.setState({ loading: false, isLatestBuildDate: false });
          } else {
            console.log(`You already have the latest version - ${latestVersionDate}. No cache refresh needed.`);
            this.setState({ loading: false, isLatestBuildDate: true });
          }
        });
    }
  
    render() {
      const { loading, isLatestBuildDate, refreshCacheAndReload } = this.state;
      return this.props.children({ loading, isLatestBuildDate, refreshCacheAndReload });
    }
}