import { useContext, useEffect, useState } from "react";
import { t } from "../../../pages/i18nFunctions";
import { TableManager } from "../../UI/Table/TableManager";
import { Pagination } from "../../Pagination/Pagination";
import { ListStoreContext } from "../../../store/store/storeList";
import { StoreContext } from "../../../store/store";
import { ActionsStoreContext } from "../../../store/store/storeActions";
import { PopupRemplirCR } from "../Planning/PopupRemplirCR";
import { NotificationManager } from "react-notifications";
import { PopUpStoreContext } from "../../../store/store/storePopUp";

import { getRemainingCR, getRPTypes, getObjectiveList, getTypeContact, getCR, remplirCR } from "../../../store/index.service";

export const PopupRemainingCR = () => {

	const { dispatchActions } = useContext(ActionsStoreContext);
    const { missionUser } = useContext(StoreContext)
    const { stateList, dispatchList } = useContext(ListStoreContext)
    const { paginationRemainingCR } = stateList;
    const { page, maxPerPage, nbResultats, nbrPages } = paginationRemainingCR;
    
    const { statePopUp } = useContext(PopUpStoreContext);
    const { dataPopUp } = statePopUp;

    const [mounted, setMounted] = useState(false);
    const [crReady, setCrReady] = useState(false);
    const [cr, setCr] = useState();
    const [listRP, setListRP] = useState([]);
    const [listObjectifs, setListObjectifs] = useState([]);
    const [listTypeHisto, setListTypeHisto] = useState([]);

    useEffect(() => {

        setMounted(true);
        dispatchActions({type: 'initAllActions', allActions});

    }, []);

    useEffect(() => {

        if(mounted)
            handleGetRemainingCR(missionUser.id);

    }, [page]);

    const handleGetRemainingCR = (idM) => {

        getRemainingCR(idM, page).then(res => {
            const data = JSON.parse(res) ? JSON.parse(res)[0] : [];

            dispatchList({ type: 'setDataRemainingCR', dDataRemainingCR: data.listVisits ?? [] });

            const pPaginationRemainingCR = {
                maxPerPage: data.maxPerPage,
                nbResultats: data.nbResultats,
                nbrPages: data.nbrPages,
                page
            }
            return dispatchList({ type: 'setPaginationRemainingCR', pPaginationRemainingCR });
        });
    }

    const handlePageChange = (n) => {

        const pPaginationRemainingCR = {
            ...paginationRemainingCR,
            page: n
        }
        return dispatchList({ type: 'setPaginationRemainingCR', pPaginationRemainingCR });
    }

    const getUnfilledCRData = (id) => {
        getRPTypes().then(res => {
            const data = JSON.parse(res);
            return setListRP(data)
        });
        getObjectiveList().then(res => {
            const data = JSON.parse(res);
            return setListObjectifs(data);
        });
        getTypeContact().then(res => {
            const data = JSON.parse(res);
            if(data) setListTypeHisto(data);
        });
        getCR(id).then(res => {
            const data = JSON.parse(res);
            setCr(data);
            return setCrReady(!!data);
        });
    }

    let allActions = {
        getUnfilledCRData: getUnfilledCRData,
	}

    const dataHeaderMedicalProHistory = [
        {
            name: 'date', 
            displayName: t('PROS.LIST.POPUP_VIEW.HISTORY.DATE')
        },
        {
            name: 'type', 
            displayName: t('PROS.LIST.POPUP_VIEW.HISTORY.TYPE')
        },
        {
            name: 'pds', 
            displayName: t('PROS.LIST.POPUP_VIEW.HISTORY.WHO')
        },
        {
            name: 'state', 
            displayName: t('PROS.LIST.POPUP_VIEW.HISTORY.STATE')
        }
    ];

    return (<>
        <div
            className="modal fade in"
            id="modalRemainingCR"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modalRemainingCRLabel"
            animate="animate"
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">

                    <div className="modal-header">
                        <h1 className="modal-title" id="myModalRemainingCRLabel">
                            {t("DB.remaining_cr")}
                        </h1>
                    </div>

                    <div className="modal-body">
                        <TableManager 
                            dataHeader={dataHeaderMedicalProHistory} 
                            actionsList={['showReport']}
                            type="remainingCR"
                            isAdd={false}
                        />
                        {paginationRemainingCR &&
                            <Pagination
                                page={page}
                                maxPage={nbrPages}
                                onChangePage={handlePageChange}
                                nbCount={nbResultats}
                            ></Pagination>
                        }
                    </div>

                    <div className="modal-footer">

                        <button
                            type="button"
                            className="btn-cancel"
                            data-dismiss="modal"
                        >
                            {t('COMMON.CANCEL')}
                        </button>

                    </div>

                </div>
            </div>
        </div>

        <PopupRemplirCR
            htmlID="modalRemplirCRMenu"
            crReady={crReady}
            item={crReady && cr}
            listObjectifs={listObjectifs}
            listRP={listRP}
            listTypeHisto={listTypeHisto}
            remplirCR={(idRDV, cr) => remplirCR(idRDV, cr, {
                idMission: missionUser.id,
                halfday: dataPopUp.optionalData.halfday,
                idInstitution: dataPopUp.optionalData.idInstitution,
                idMedicalPro: dataPopUp.optionalData.idMedicalPro
            })
                .then(() => {
                    NotificationManager.success('', t('COMMON.NOTIFICATIONS.COMPLETE_APPOINTMENT'));
                    handleGetRemainingCR(missionUser.id);
                })
                .catch(() => NotificationManager.error('', "Error"))
            }
            readOnly={false}
        />
    </>)
}