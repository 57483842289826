import { useContext, useState } from 'react';
import { ActionsStoreContext } from '../../../../store/store/storeActions';
import { ListStoreContext } from '../../../../store/store/storeList';
import { PopUpStoreContext } from '../../../../store/store/storePopUp';
import { FilterStoreContext } from '../../../../store/store/storeFilters';
import { StoreContext } from '../../../../store/store';
import ReactLoading from 'react-loading';
import { t } from 'i18next';

import iconSave from "../../../../assets/img/icons/icon-save.png";
import { isSupLength, isDiff } from '../../utilsValidator';

export const BtnSavePopUp = (props) => {
    const { action, handleClosePopUp, type } = props;

    const [loading, setLoading] = useState(false);
    
    const { stateList, dispatchList } = useContext(ListStoreContext);
    const { statePopUp, dispatchPopUp } = useContext(PopUpStoreContext);
	const { stateFilter } = useContext(FilterStoreContext);
	const { setLoadingStore } = useContext(StoreContext);

    const { stateActions } = useContext(ActionsStoreContext);
    let { dataActions } = stateActions;

    let modelNotif = {
        response: '',
        code: 0
    }

    const displayNotif = (nNotif) => {
        dispatchPopUp({type: 'setNotif', nNotif});

        let v = setTimeout(() => {
            let nNotif = {};
            dispatchPopUp({type: 'setNotif', nNotif});
            return clearTimeout(v)
        }, 3000);
    } 

    const refreshList = () => {//todo => handle refresh by type ? by action defined ? => by type & check data res, maybe easier
        let dDataList;
        let dDataOtherTabs;
        let params = {
            ...stateList.dataPagination,
            ...stateList.dataParamSearch,
            ...stateFilter.memoSearchCountry
        }
        let regularParams = {
            ...stateList.dataPagination,
            ...stateFilter.searchFilters
        }

        setLoadingStore(true);

        if(type === 'specialityProPop') {
            setLoadingStore(false);
            stateActions.getSpePro(dataActions.id).then((res) => {
                const data = JSON.parse(res);
                dDataOtherTabs = data;
                dispatchList({type: 'setDataOtherTabs', dDataOtherTabs });
            });
            return stateActions.getAll(params, false);
        }else if(type === 'user') {
            stateActions.getAll(regularParams).then((res) => {
                const data = JSON.parse(res);
                dDataList = data.listUser;
                setLoadingStore(false); 
                return dispatchList({type: 'setList', dDataList });
            });
        }else if(type === 'mission') {
            setLoadingStore(false); 
            return stateActions.getAll(regularParams);

        }else if(type === 'etab' || type === 'medicalProPage') {
            setLoadingStore(false); 
            return stateActions.getAll(params, false);

        }else if(type === 'medicalProSoftPop') {
            let idPro = dataActions.id ?? statePopUp.dataPopUp.optionalData.id;
            return stateActions.getLogPro(idPro).then((res) => {
                const data = JSON.parse(res);
                dDataOtherTabs = data;   
                setLoadingStore(false);  
                return dispatchList({type: 'setDataOtherTabs', dDataOtherTabs });
            });   

        }else if(type === 'medicalProsInstitutions') {
            return stateActions.getEtabPro(dataActions.idPro).then(res => {
                const data = JSON.parse(res);
                dDataOtherTabs = data;   
                setLoadingStore(false); 
                return dispatchList({type: 'setDataOtherTabs', dDataOtherTabs }); 
            });
        }
    }

    const validateUser = () => {
        const role = dataActions.roles;

        if( (action === 'edit' && dataActions.modifyPwd === true) || action === "add" ) 
            if(typeof dataActions.pwd !=='undefined' || typeof dataActions.pwdConfirm !=='undefined' )
                if(!isDiff(dataActions.pwd, dataActions.pwdConfirm) || !isSupLength(dataActions.pwdConfirm, 5) ) {
                    displayNotif({ ...modelNotif, 
                        code: '900',
                        response: 'Veuillez vérifiez votre mot de passe'
                    });
                    return false;
                }

        if( !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(dataActions.email)) && dataActions.email){
            displayNotif({
                ...modelNotif,
                response: "Veuillez saisir une adresse e-mail valide"
            });
            return false;
        } else if(role === ""){
            displayNotif({
                ...modelNotif,
                response: "Vous devez sélectionner un groupe"
            });
            return false;
        } else if(dataActions.lastName === "" || dataActions.firstName === ""){
            displayNotif({
                ...modelNotif,
                response: "Veuillez saisir votre nom"
            });
            return false;
        } else if (!dataActions.emailAsUsername && dataActions.username === ""){
            displayNotif({
                ...modelNotif,
                response: "Vous devez renseigner un identifiant"
            });
            return false;
        } else if(role === "ROLE_COORDINATEUR" && dataActions.labos.length <= 0){
            displayNotif({
                ...modelNotif,
                response: "Les coordinateurs labos doivent avoir au minimum un laboratoire"
            });
            return false;
        } else if((role === "ROLE_HP" || role === "ROLE_DP" || role === "ROLE_DDV" || role === "ROLE_SUPPORT") && dataActions.restrictedArea.length <= 0){
            displayNotif({
                ...modelNotif,
                response: "Vous devez sélectionner au moins un pays"
            });
            return false;
        } else if((role === "ROLE_DELEGUE" || role === "ROLE_DSM") && dataActions.restrictedArea.length !== 1){
            displayNotif({
                ...modelNotif,
                response: "Vous devez sélectionner un pays"
            });
            return false;
        }

        return true;
    }

    const validateMission = () => {
        if(!!dataActions.name === false) {
            displayNotif({
                ...modelNotif,
                response: t("ERRORS.MISSION.NAME")
            });
            return false;
        }
        else if(dataActions.ugvs.length === 0) {
            displayNotif({
                ...modelNotif,
                response: t("ERRORS.MISSION.UGV")
            });
            return false;
        }
        else if(!!dataActions.network === false) {
            displayNotif({
                ...modelNotif,
                response: t("ERRORS.MISSION.NETWORK")
            });
            return false;
        }
        else if(!!dataActions.affectation === false) {
            displayNotif({
                ...modelNotif,
                response: t("ERRORS.MISSION.AFFECT")
            });
            return false;
        }

        return true;
    }

    const validatePDS = () => {

        if(action === "add" && !!dataActions.country === false) {
            displayNotif({
                ...modelNotif,
                response: t("ERRORS.PDS.COUNTRY")
            });
            return false;
        }
        else if(!!dataActions.medicalProType.idMedicalProType === false) {
            displayNotif({
                ...modelNotif,
                response: t("ERRORS.PDS.TYPE_PDS")
            });
            return false;
        }
        else if(stateList.dataSpecialityByType.length > 0 &&
            (!!dataActions.medicalProMainSpeciality === false || !!dataActions.medicalProMainSpeciality.idMainSpeciality === false)
        ) {
            displayNotif({
                ...modelNotif,
                response: t("ERRORS.PDS.SPE")
            });
            return false;
        }
        else if(!!dataActions.lastName === false || !!dataActions.firstName === false) {
            displayNotif({
                ...modelNotif,
                response: t("ERRORS.PDS.NAME")
            });
            return false;
        }
        else if(action === "add" && !!dataActions.idInstitution === false) {
            displayNotif({
                ...modelNotif,
                response: t("ERRORS.PDS.INSTIT")
            });
            return false;
        }

        return true;
    }

    const validateEtabPro = () => {

        if(action === "add" && !!dataActions.idInstitution === false) {
            displayNotif({
                ...modelNotif,
                response: t("ERRORS.PDS.INSTIT")
            });
            return false;
        }

        return true;
    }

    const validateEtab = () => {

        if(action === "add" && !!dataActions.zoneGeo === false) {
            displayNotif({
                ...modelNotif,
                response: t("ERRORS.ETAB.ZONEGEO")
            });
            return false;
        }
        else if(action === "edit" && !!dataActions.idzoneGeo === false) {
            displayNotif({
                ...modelNotif,
                response: t("ERRORS.ETAB.ZONEGEO")
            });
            return false;
        }
        else if(!!dataActions.name === false) {
            displayNotif({
                ...modelNotif,
                response: t("ERRORS.PDS.NAME")
            });
            return false;
        }
        else if(action === "add" && !!dataActions.type === false) {
            displayNotif({
                ...modelNotif,
                response: t("ERRORS.ETAB.TYPE")
            });
            return false;
        }
        else if(action === "edit" && !!dataActions.idInstitutionType === false) {
            displayNotif({
                ...modelNotif,
                response: t("ERRORS.ETAB.TYPE")
            });
            return false;
        }

        return true;
    }

    //methods
    const postMeth = (methods, action) => {
            
        if(type === 'medicalProsInstitutions') {

            if(!validateEtabPro())
                return false;

            let idPro = statePopUp.dataPopUp.optionalData
            dataActions = {
                ...dataActions,
                idPro: idPro.id
            }
        }
        else if(type === 'user' && !validateUser())
            return false;

        else if(type === 'mission') {
            
            if(!validateMission())
                return false;

            let spe = statePopUp.dataPopUp.optionalData.specialities;
            let pds = statePopUp.dataPopUp.optionalData.protypes;
            dataActions = {
                ...dataActions,
                specialities: spe,
                protypes: pds
            }
        }
        else if(type === "medicalProPage" && !validatePDS())
            return false;
        else if(type === "etab" && !validateEtab())
            return false;

        setLoading(true);

        methods(dataActions)
        .then((res) => {
            const data = JSON.parse(res);
            let nNotif = data;
            displayNotif(nNotif);
            
            if(data.code === 200 || data.code === '200') {
                handleClosePopUp();
                return refreshList();
            }
        })
        .catch(err => {
            console.error(err);
            const errRes = err.response;

            if(errRes.status === 409 && errRes.data && errRes.data.error)
                displayNotif({ ...modelNotif, response: errRes.data.error });
            else
                displayNotif({ ...modelNotif, response: "Une erreur est survenue" });
                
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const deleteMeth = (methods) => {
        let params;
        if(type === 'specialityProPop') {
            params = {
                idPro: dataActions.id ?? statePopUp.dataPopUp.optionalData.id,
                idSpe: dataActions.idMedicalProSpeciality
            }
        }
        else if(type === 'medicalProSoftPop') {
            params = {
                idPro: dataActions.id ?? statePopUp.dataPopUp.optionalData.id,
                idLog: dataActions.idSoftware
            }
        }
        else {
            params = dataActions.id
        }

        methods(params).then((res) => {
            const data = JSON.parse(res);
            let nNotif = data;
            displayNotif(nNotif);

            if(data.code === 200 || data.code === '200') {
                handleClosePopUp();
                return refreshList();
            }
        });
    }
        
    const handleSave = () => {
        switch (action) {
            case 'add':
                if(type === 'medicalProsInstitutions')
                    return postMeth(stateActions.postEtabPro, action);
                    
                return postMeth(stateActions.addAction, action);

            case 'edit':
                if(type === 'medicalProsInstitutions')
                    return postMeth(stateActions.editEtabPro, action);

                return postMeth(stateActions.editAction, action);
            
            case 'delete':
                if(type === 'medicalProSoftPop') 
                    return deleteMeth(stateActions.deleteLogPro);

                if(type === 'specialityProPop')
                    return deleteMeth(stateActions.deleteSpePro);
                
                return deleteMeth(stateActions.deleteAction);
                
            default:
                break;
        }
    }

    return(
        <>
            {action !== 'visu' &&
                <button className="btn-save" onClick={() => handleSave()}>
                    { loading ?
                        <ReactLoading type={"spinningBubbles"} color={"#ffffff"} height={20} width={20} className="spinner-loader" />
                    :
                    <>
                        <img src={iconSave} alt=""/>Sauvegarder
                    </>}
                </button>
            }
        </>
    )
}
