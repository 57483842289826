import { useState, useContext, useEffect, useRef } from 'react';
import Select from 'react-select';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import fr from 'react-phone-input-2/lang/fr.json';

import { Pagination } from '../../../../../components/Pagination/Pagination';
import { ActionsStoreContext } from '../../../../../store/store/storeActions';
import { ListStoreContext } from '../../../../../store/store/storeList';
import { PopUpStoreContext } from '../../../../../store/store/storePopUp';
import { FilterStoreContext } from '../../../../../store/store/storeFilters';
import { getSavedOn } from '../../../../../store/index.service';

import { PopUpSupManager } from '../../PopUpSupManager';

import { t } from "../../../../../pages/i18nFunctions";

import { ButtonAddDirect } from '../../../Button/ButtonAddDirect/ButtonAddDirect';
import { TableManager } from '../../../Table/TableManager';

export const EditMedicalPro = (props) => {
    const { data, dispatchPop } = props;
    const { stateActions, dispatchActions } = useContext(ActionsStoreContext);
    const { stateList, dispatchList} = useContext(ListStoreContext);
    const { statePopUp, dispatchPopUp} = useContext(PopUpStoreContext);
    const { stateFilter, dispatchFilters} = useContext(FilterStoreContext);
   
    const { dataActions } = stateActions;

    //let [initParam, setInitParam] = useState(false);
    let [tabsActive, setTabsActive] = useState(0);
    
    const [item, setItem] = useState({
        ...dataActions,
    });

    let [gender, setGender] = useState(dataActions.gender)
    let [isComputerizedState, setIsComputerizedState] = useState(dataActions.isComputerized)
    let [isThoughtLeaderState, setIsThoughtLeaderState] = useState(dataActions.isThoughtLeader)
    let [isVisitDistanceState, setVisitDistanceState] = useState(dataActions.visitDistance)
    let [isRetiredState, setIsRetiredState] = useState(dataActions.isRetired)
    let [isDeathState, setIsDeathState] = useState(dataActions.isDeath)
    let [isSavedOn, setIsSavedOn] = useState()

    let [actionPopUp, setActionPopUp] = useState(data.action)
    
    let [valueAddState, setValueAddState] = useState({});

    const formatDate = (date) => {
        if(typeof date !== 'undefined') {
            const padTo2Digits = (num) => {
                return num.toString().padStart(2, '0');
            }
            
            return [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('-');
        }
	}

    let [dataNameSearch, setDataNameSearch] = useState('');
    let [dataProductIdSearch, setDataProductIdSearch] = useState(''); //stateFilter.searchProducts
    let [dataTypeContactSearch, setDataTypeContactSearch] = useState('');
    let [dataDateMinSearch, setDataDateMinSearch] = useState(formatDate(new Date(new Date().setDate(new Date().getDate()-7))));
    let [dataDateMaxSearch, setDataDateMaxSearch] = useState(formatDate(new Date()));

    let [maxPage, setMaxPage] = useState(0);
    let [page, setPage] = useState(1);
	let [nbResultats, setNbResultats] = useState(0);

    let [similarPro, setSimilarPro] = useState(0);
    let [maxPageVerif, setMaxPageVerif] = useState(0);
    let [pageVerif, setPageVerif] = useState(1);
	let [nbResultatsVerif, setNbResultatsVerif] = useState(0);
    
    const timer = useRef(null);

    useEffect(() => {
        setDataProductIdSearch(stateFilter.searchProducts)
        if(stateFilter.searchProducts) {
            if(typeof stateFilter.searchProducts[0] !== 'undefined') {
                let idProductsStr = ''; 
                stateFilter.searchProducts.forEach((el) => {
                    idProductsStr = idProductsStr + el.value + '/';
                })
                return setDataProductIdSearch(idProductsStr);
            }
        }
        if(stateFilter.searchFilters.who !== undefined)
            setDataNameSearch(stateFilter.searchFilters.who)

        setDataTypeContactSearch(stateFilter.searchType ? stateFilter.searchType.label : stateFilter.searchType)

        if(stateFilter.searchDate.startDate !== undefined)
            setDataDateMinSearch(formatDate(stateFilter.searchDate.startDate));

        if(stateFilter.searchDate.endDate !== undefined)
            setDataDateMaxSearch(formatDate(stateFilter.searchDate.endDate));

    }, [stateFilter.searchFilters.who, stateFilter.searchProducts, stateFilter.searchType, stateFilter.searchDate.startDate, stateFilter.searchDate.endDate]);

    useEffect(() => {
        if(tabsActive === 5)
            return getHistory();
    }, [page, dataNameSearch, dataProductIdSearch, dataTypeContactSearch, dataDateMinSearch, dataDateMaxSearch])

    useEffect(() => {
        if(tabsActive === 0) {
            return getVerif();
        }
    }, [pageVerif]) 

    useEffect(() => {
        if(item.id !== dataActions.id)
            setItem(dataActions)
    }, [dataActions]) 

    const getVerif = (value = null, name = null) => {
        let data = {
            page: pageVerif,
            maxPerPage: 5,
            lastName: item.lastName ?? "",
            firstName: item.firstName ?? "",
            id: item.id,
            idCountry: item.idArea
        };
        if(value !== null && name !== null) {
            data = {
                ...data,
                [name]: value
            }
        }

        stateActions.verifPro(data).then(res => {
            let data = JSON.parse(res);
            let dDataVerifPro = data.similaireMedicalPro;
            
            setSimilarPro(data);
            setNbResultatsVerif(data.nbResultats)
            setMaxPageVerif(data.nbrPages);
            return dispatchList({type: 'setDataVerifPro', dDataVerifPro });
        });
    }
    
    useEffect(() => {
        let dDataPopUpSup = {
            type: 'medicalProsInstitutions',
            action: 'edit',
            isActive: false,
        }
        dispatchPopUp( {type: 'setPopUpSup', dDataPopUpSup } );

        timer.current = setTimeout(() => {}, 1000);
        // unmounted
        return () => {
            clearTimeout(timer.current);
        };
    }, [])

    useEffect(() => {
        setActionPopUp(actionPopUp);
    }, [data.action]);

    useEffect(() => {
        if(tabsActive === 0) {
            getSavedOn({
                email: dataActions.mail,
                firstName: dataActions.firstName,
                lastName: dataActions.lastName
            }).then(res => {
                const data = JSON.parse(res);
                return setIsSavedOn(data.savedOnEthica);
            });
            return getVerif();
        }

        else if(tabsActive === 1) {
            return stateActions.getEtabPro(dataActions.id).then(res => {
                const data = JSON.parse(res);
                let dDataOtherTabs = data;   
                return dispatchList({type: 'setDataOtherTabs', dDataOtherTabs }); 
            });
        }
        else if(tabsActive === 2) {
            return stateActions.getSpePro(dataActions.id).then((res) => {
                const data = JSON.parse(res);
                let dDataOtherTabs = data;    
                return dispatchList({type: 'setDataOtherTabs', dDataOtherTabs });
            });
        }
        else if(tabsActive === 3) {
            let dDataOtherTabs;
            stateActions.getLogPro(dataActions.id).then((res) => {
                const data = JSON.parse(res);
                dDataOtherTabs = data;    
                return dispatchList({type: 'setDataOtherTabs', dDataOtherTabs });
            });
            stateActions.getLogiciels().then(res => {
                const data = JSON.parse(res);
                let dDataLogProSoft = data;    
                return dispatchList({type: 'setDataLogProSoft', dDataLogProSoft });
            });
        }
        else if(tabsActive === 4) {
            stateActions.getMedicalProsQualifProd(item.id).then(res => {
                const data = JSON.parse(res);
                let dDataOtherTabs = data.qualifiedProducts;    
                return dispatchList({type: 'setDataOtherTabs', dDataOtherTabs });
            });
        }
        else if(tabsActive === 5) {
            // setDataNameSearch('')
            // setDataProductIdSearch('')
            // setDataTypeContactSearch('')
            // setDataDateMinSearch('')
            // setDataDateMaxSearch('')
            return getHistory();
        }
    }, [tabsActive])

    useEffect(() => {
        if(item.medicalProType)
            stateActions.getSpeByType(item.medicalProType.idMedicalProType).then((res) => {
                const data = JSON.parse(res);
                let dDataSpecialityByType = data;
                return dispatchList({type: 'setDataSpecialityByType', dDataSpecialityByType });
            });
    }, [item.medicalProType])

    const getHistory = () => {
        let dDataOtherTabs;
        let params = {
            ...stateList.dataPagination,
            page: page,
            idPDS: dataActions.id,
            name: dataNameSearch,
            productId: dataProductIdSearch, 
            typeContact: dataTypeContactSearch, 
            // dateMin: dataDateMinSearch, 
            // dateMax: dataDateMaxSearch,
        }
        stateActions.getHistoryPDS(params).then((res) => {
            const data = JSON.parse(res);
            let dDataHistoryPro = data;    
            dDataOtherTabs = data.history;    
            setMaxPage(data.nbrPage)
            setNbResultats(data.nbResultats)
            dispatchList({type: 'setDataHistoryPro', dDataHistoryPro });
            return dispatchList({type: 'setDataOtherTabs', dDataOtherTabs })
        });
    }

    const generateOptions = (data) => {
        if(typeof data !== 'undefined' && data !== null) {
            let options = [];
            data.forEach((option, i) => {
                options.push({
                    value: option.id, 
                    label: option.name,
                });
            });
            return options;
        }
    } 

    const handleChange = (value, name, setRadio = null) => { 
        if(setRadio !== null) {
            if(typeof value === "boolean"){
                setRadio(value);}
            else {
                value = parseInt(value);
                setRadio(parseInt(value));
            }
        }
        let aDataActions = {...item, [name]: value};

        if(name === 'medicalProType')// if medicalProMainSpeciality
            aDataActions = {
                ...aDataActions,
                [name]: {
                    idMedicalProType: value.value,
                    nameMedicalProType: value.label  
                },
                medicalProMainSpeciality: null
            }
        else if(name === 'medicalProMainSpeciality') {
            aDataActions = {
                ...aDataActions,
                [name]: {
                    idMainSpeciality: value.value,
                    nameMainSpeciality: value.label,
                }
            }
        }
        else if(name === 'medicalProSpeciality') {
            setValueAddState({
                idSpeciality: value.value,
                nameSpeciality: value.label, 
            });
            aDataActions = {
                ...aDataActions,
                [name]: {
                    idSpeciality: value.value,
                    nameSpeciality: value.label,
                }
            }
        }else if(name === 'medicalProSoftware') {
            setValueAddState({
                idSoftware: value.value,
                nameSoftware: value.label,
            });
            aDataActions = {
                ...aDataActions,
                [name]: {
                    idSoftware: value.value,
                    nameSoftware: value.label,
                }
            }
        }

        setItem(aDataActions);
        dispatchActions({type: 'setDataActions', aDataActions})

        if(name === 'lastName' || name === 'firstName') {
            clearTimeout(timer.current);

            if(value.length >= 3)
                timer.current = setTimeout(() => {
                    getVerif(value, name);
                }, 1000);
            
            // else if(value.length < 3)
            //     return getVerif('', '');
            
            else if(value.length <= 2)
                return;
        }
    }

    const actionsListMedicalProsInstitutions = [
        'edit'
    ];

    const dataHeaderMedicalProsInstitutions = [
        {
            name: 'isPrivateInstitution', 
            displayName: t('PROS.LIST.POPUP_VIEW.CONSULTATION_PLACES.IS_PRIVATE'), 
        },
        {
            name: 'name', 
            displayName: t('PROS.LIST.POPUP_VIEW.CONSULTATION_PLACES.INSTITUTION'), 
        },
        {
            name: 'zoneGeoInstitution', 
            displayName: t('PROS.LIST.POPUP_VIEW.CONSULTATION_PLACES.AREA'), 
        },
        {
            name: 'typeInstitution', 
            displayName: t('PROS.LIST.POPUP_VIEW.CONSULTATION_PLACES.TYPE'), 
        },
        {
            name: 'status', 
            displayName: t('PROS.LIST.POPUP_VIEW.CONSULTATION_PLACES.STATUS'),
        },
        {
            name: 'nbPatientsDay', 
            displayName: t('PROS.LIST.POPUP_VIEW.CONSULTATION_PLACES.PATIENTS_SEM'), 
        },
        {
            name: 'withInternetConn', 
            displayName: t('PROS.LIST.POPUP_VIEW.CONSULTATION_PLACES.INTERNET'),
        },
        {
            name: 'isHeadDept', 
            displayName: t('PROS.LIST.POPUP_VIEW.CONSULTATION_PLACES.HEAD_OF_SERVICE'),
        },
        {
            name: 'hasSecretary', 
            displayName: t('PROS.LIST.POPUP_VIEW.CONSULTATION_PLACES.SECRETARY'),
        },
        {
            name: 'onAppointment', 
            displayName: t('PROS.LIST.POPUP_VIEW.CONSULTATION_PLACES.BY_APPOINTMENT'),
        },
    ];
    
    const generateTabMedicalProsInstitutions = () => {
        return <>
            <TableManager 
                dataHeader={dataHeaderMedicalProsInstitutions} 
                actionsList={actionsListMedicalProsInstitutions}
                type="medicalProsInstitutions"
                isAdd={true}
                action={data.action}
            /> 
            <PopUpSupManager 
                type="medicalProsInstitutions"
                displayTitle="Lieu de consultation"
                isSup={true}
            />
        </>
    };

    const dataHeaderSpecialityProPop = [
        {
            name: 'nameMedicalProSpeciality', 
            displayName: t('PROS.LIST.POPUP_VIEW.SPECIALITIES.SPECIALITY'),
        },
        {
            name: 'statusMedicalProSpeciality', 
            displayName: t('PROS.LIST.POPUP_VIEW.SPECIALITIES.STATUS'),
        },
        {
            name: 'isMainMedicalProSpeciality', 
            displayName: t('PROS.LIST.POPUP_VIEW.SPECIALITIES.TYPE'),
        },
    ];

    const dataHeaderQualifiedProducts = [
        {
            name: 'nameProduct',
            displayName: t('PROS.LIST.POPUP_VIEW.QUALIF.PRODUCT'),
        },
        {
            name: 'nameQualificationProdcut',
            displayName: t('PROS.LIST.POPUP_VIEW.QUALIF.POTENTIAL'),
        },
    ];

    const dataHeaderMedicalProSoftPop = [
        {
            name: 'nameSoftware',
            displayName: t('PROS.LIST.POPUP_VIEW.SOFTWARE.SOFTWARE'),
        },
        {
            name: 'status', 
            displayName: t('PROS.LIST.POPUP_VIEW.SOFTWARE.STATUS'),
        },
    ]

    const dataHeaderMedicalProHistory = [
        {
            name: 'date', 
            displayName: t('PROS.LIST.POPUP_VIEW.HISTORY.DATE'),
            // isDateFilter: true,
            // startDate: dataDateMinSearch,
            // endDate: dataDateMaxSearch
        },
        {
            name: 'type', 
            displayName: t('PROS.LIST.POPUP_VIEW.HISTORY.TYPE'),
            isSelectable: true,
            placeholder: t('PROS.LIST.POPUP_VIEW.HISTORY.SELECT_TYPE_CONTACT')
        },
        {
            name: 'products', 
            displayName: t('PROS.LIST.POPUP_VIEW.HISTORY.PROD'),
            isSelectable: true,
            placeholder: t('PROS.LIST.POPUP_VIEW.HISTORY.SELECT_PRODUCTS')
        },
        {
            name: 'who', 
            displayName: t('PROS.LIST.POPUP_VIEW.HISTORY.WHO'),
            isSearchable: true,
        },
        {
            name: 'state', 
            displayName: t('PROS.LIST.POPUP_VIEW.HISTORY.STATE'),
        },
        {
            name: 'rapport', 
            displayName: t('PROS.LIST.POPUP_VIEW.HISTORY.REPORT'),
        },
    ];

    const dataHeaderSimilar = [
        {
            name: 'reference', 
            displayName: t('PROS.LIST.RESEARCH.REFERENCE'), 
        },
        {
            name: 'lastName', 
            displayName: t('PROS.LIST.RESEARCH.NAME'), 
        },
        {
            name: 'firstName', 
            displayName: t('PROS.LIST.RESEARCH.FIRSTNAME'), 
        },
        {
            name: 'medicalProType', 
            displayName: t('PROS.LIST.RESEARCH.PDS_TYPE'), 
        },
        {
            name: 'medicalProMainSpeciality', 
            displayName: t('PROS.LIST.RESEARCH.SPECIALITY'), 
        },
    ];

    const generateOptionTitle = (typeMed) => {
        return (
            typeMed === 7 || typeMed === 3 || typeMed === 4 || typeMed === 6 ?
                [
                    {
                        value: 0,
                        label: t('PROS.LIST.POPUP_VIEW.GENERAL.MR'),
                    },
                    {
                        value: 1,
                        label: t('PROS.LIST.POPUP_VIEW.GENERAL.MS'),
                    },
                    {
                        value: 2,
                        label: t('PROS.LIST.POPUP_VIEW.GENERAL.MISS')
                    }
                ]
            :
                [
                    {
                        value: 3,
                        label: t('PROS.LIST.POPUP_VIEW.GENERAL.DOCTOR')
                    },
                    {
                        value: 4,
                        label: t('PROS.LIST.POPUP_VIEW.GENERAL.PROFESSOR')
                    }
                ]
        );
    }

    return(
        <>
            <div className="navTabsContainer nav nav-tabs">
                <div className={`elementTabs ${tabsActive === 0 ? 'selected' : ''}`} onClick={() => setTabsActive(0)}>
                    <span>
                        {t('PROS.LIST.POPUP_VIEW.TABS.GENERAL')}
                    </span>
                </div>
                <div className={`elementTabs ${tabsActive === 1 ? 'selected' : ''}`} onClick={() => setTabsActive(1)}>
                    <span>
                        {t('PROS.LIST.POPUP_VIEW.TABS.CONSULTATION_PLACES')}
                    </span>
                </div>
                <div className={`elementTabs ${tabsActive === 2 ? 'selected' : ''}`} onClick={() => setTabsActive(2)}>
                    <span>
                        {t('PROS.LIST.POPUP_VIEW.TABS.SPECIALITIES')}
                    </span>
                </div>
                <div className={`elementTabs ${tabsActive === 3 ? 'selected' : ''}`} onClick={() => setTabsActive(3)}>
                    <span>
                        {t('PROS.LIST.POPUP_VIEW.TABS.SOFTWARES')}
                    </span>
                </div>
                {actionPopUp === 'visu' && 
                    <div className={`elementTabs ${tabsActive === 4 ? 'selected' : ''}`} onClick={() => setTabsActive(4)}>
                        <span>
                            {t('PROS.LIST.POPUP_VIEW.TABS.QUALIFIED_PRODUCTS')}
                        </span>
                    </div>
                }
                {actionPopUp === 'visu' && 
                    <div className={`elementTabs ${tabsActive === 5 ? 'selected' : ''}`} onClick={() => setTabsActive(5)}>
                        <span>
                            {t('PROS.LIST.POPUP_VIEW.TABS.HISTORY')}
                        </span>
                    </div>
                }
            </div>
            <br/>

            {tabsActive === 0 && 
                (actionPopUp !== "visu" ?
                    <div className={`tabs`}>
                        <form>
                            <div className='flex-row three-cols'>

                                {typeof item.medicalProType !== 'undefined' && 
                                    
                                    (item.medicalProType.idMedicalProType !== 8 || 
                                    item.medicalProType.idMedicalProType !== 12 || 
                                    item.medicalProType.idMedicalProType !== 10 || 
                                    item.medicalProType.idMedicalProType !== 11 || 
                                    item.medicalProType.idMedicalProType !== 9 || 
                                    item.medicalProType.idMedicalProType !== 13 ) &&
                                    
                                    <div>
                                        <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.TITLE')}</label>            
                                        <Select
                                            isDisabled={data.action === 'visu'}
                                            id="medicalProMainSpeciality"
                                            className='selectSearchBar' 
                                            classNamePrefix='selectSearch' 
                                            placeholder={t('PROS.LIST.POPUP_VIEW.GENERAL.TITLE')}
                                            isClearable={false}
                                            options={generateOptionTitle(item.medicalProType.idMedicalProType)} 
                                            onChange={(value, type) => {handleChange(value, 'title') } } 
                                            defaultValue={generateOptionTitle(item.medicalProType.idMedicalProType).find(el => el.label === item.title)}
                                        />
                                    </div>
                                }

                                <div>
                                    <label htmlFor="lastName">{t('PROS.LIST.POPUP_VIEW.GENERAL.NAME')}</label>
                                    <input id="lastName" type="text" required readOnly={data.action === 'visu' ? true : false}
                                        value={item.lastName ?? ''} 
                                        onChange={ (e) => {handleChange(e.target.value, 'lastName')} }
                                    />
                                </div>

                                <div>
                                    <label htmlFor="firstName">{t('PROS.LIST.POPUP_VIEW.GENERAL.FIRSTNAME')}</label>
                                    <input id="firstName" type="text" required readOnly={data.action === 'visu' ? true : false}
                                        value={item.firstName ?? ''} 
                                        onChange={ (e) => {handleChange(e.target.value, 'firstName')} }
                                    />
                                </div>
                            </div>
                            {
                                (typeof similarPro.similaireMedicalPro !== 'undefined' && similarPro.similaireMedicalPro !== null && Array.from(similarPro.similaireMedicalPro).length > 0) &&
                                <div className="containerRedBorder">
                                    <div>{t('PROS.LIST.TABLE_HEADERS.SIMILAR_PDS')}</div>
                                    <TableManager 
                                        dataHeader={dataHeaderSimilar} 
                                        actionsList={[]}
                                        type="verifPro"
                                        isAdd={false}
                                    />
                                    <br/>
                                    <Pagination
                                        maxPage={maxPageVerif}
                                        onChangePage={(data) => setPageVerif(data)}
                                        nbCount={nbResultatsVerif}
                                    ></Pagination>
                                </div>
                            }

                            <div className='flex-row two-cols'>
                                <div>
                                    <label htmlFor="medicalProType">{t('PROS.LIST.POPUP_VIEW.GENERAL.PDS_TYPE')}</label>
                                    <Select 
                                        isDisabled={data.action === 'visu' ? true : false}
                                        id="medicalProType"
                                        className='selectSearchBar' 
                                        classNamePrefix='selectSearch' 
                                        placeholder={t('PROS.LIST.RESEARCH.PDS_TYPE_PLACEHOLDER')}
                                        isClearable={false}
                                        options={generateOptions(stateList.dataProType)} 
                                        onChange={(value, type) => {handleChange(value, 'medicalProType')} } 
                                        defaultValue={typeof item.medicalProType !== 'undefined' ? {value: item.medicalProType.idMedicalProType, label: item.medicalProType.nameMedicalProType} : ''}
                                    />
                                </div>
                                
                                {(item.medicalProType && stateList.dataSpecialityByType !== null) &&
                                    <div>
                                        <label htmlFor="medicalProMainSpeciality">{t('PROS.LIST.POPUP_VIEW.GENERAL.MAIN_SPECIALITY')}</label>
                                        <Select 
                                            isDisabled={data.action === 'visu' ? true : false}
                                            id="medicalProMainSpeciality"
                                            className='selectSearchBar' 
                                            classNamePrefix='selectSearch' 
                                            placeholder={t('PROS.LIST.RESEARCH.SPECIALITY_PLACEHOLDER')}
                                            isClearable={false}
                                            options={generateOptions(stateList.dataSpecialityByType)} 
                                            onChange={(value, type) => {handleChange(value, 'medicalProMainSpeciality')} } 
                                            defaultValue={item.medicalProMainSpeciality ? {value: item.medicalProMainSpeciality.idMainSpeciality, label: item.medicalProMainSpeciality.nameMainSpeciality} : ''}
                                            value={item.medicalProMainSpeciality ? {
                                                value: item.medicalProMainSpeciality.idMainSpeciality,
                                                label: item.medicalProMainSpeciality.nameMainSpeciality
                                            } : null}
                                        />
                                    </div>
                                }
                            </div>


                            <div className="containerInline">
                                <div className="containerLabelRadioInline">
                                    <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.GENDER')}</label>
                                </div>
                                <div>
                                    <div className="containerInline">
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="gender" id="gender" disabled={data.action === 'visu' ? true : false}
                                                onChange={(e) => {handleChange(e.target.value, 'gender', setGender(0))} }
                                                checked={gender === 0}
                                                value={0}
                                            />
                                            <label htmlFor="gender">{t('COMMON.UNKNOWN')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="gender" id="gender1" disabled={data.action === 'visu' ? true : false}
                                                onChange={(e) => {handleChange(e.target.value, 'gender', setGender(1))} }
                                                checked={gender === 1}
                                                value={1}
                                            />
                                            <label htmlFor="gender1">{t('PROS.LIST.POPUP_VIEW.GENERAL.MAN')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="gender" id="gender2" disabled={data.action === 'visu' ? true : false}
                                                onChange={(e) => {handleChange(e.target.value, 'gender', setGender(2))} }
                                                checked={gender === 2}
                                                value={2}
                                            />
                                            <label htmlFor="gender2">{t('PROS.LIST.POPUP_VIEW.GENERAL.WOMAN')}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <label htmlFor="mail">{t('PROS.LIST.POPUP_VIEW.GENERAL.EMAIL')}</label>
                                <input id="mail" type="text" required readOnly={data.action === 'visu' ? true : false}
                                    value={item.mail ?? ''} 
                                    onChange={ (e) => {handleChange(e.target.value, 'mail')} }
                                />
                            </div>

                            <div className='flex-row two-cols'>
                                <div className="phoneInputContainer">
                                    <label htmlFor="phoneMobile">{t('PROS.LIST.POPUP_VIEW.GENERAL.PHONE_NUMBER_MOBILE')}</label>
                                    <PhoneInput
                                        id="phoneMobile"
                                        disabled={data.action === 'visu' ? true : false}
                                        // country={'bj'}
                                        value={item.mobile ?? ''}
                                        onChange={ (value) => {handleChange(value, 'mobile')} }
                                        localization={fr}
                                    />
                                </div>

                                <div className="phoneInputContainer">
                                    <label htmlFor="phone">{t('PROS.LIST.POPUP_VIEW.GENERAL.PHONE_NUMBER')}</label>
                                    <PhoneInput
                                        id="phone"
                                        disabled={data.action === 'visu' ? true : false}
                                        // country={'bj'}
                                        value={item.landLine ?? ''}
                                        onChange={ (value) => {handleChange(value, 'landLine')} }
                                        localization={fr}
                                    />
                                </div>
                            </div>


                            <div className="containerInline">
                                <div className="containerLabelRadioInline">
                                    <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.Computerized')}</label>
                                    <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.OPINION_LEADER')}</label>
                                    <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.REMOTE_VISIT')}</label>
                                    <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.REGISTRED_ON_ETHICA')}</label>
                                    <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.RETIREMENT')}</label>
                                    <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.DECEASED')}</label>
                                </div>
                                <div>

                                    <div>
                                        <div className="containerInline">
                                            <div className="containerInline containerRadioInput">
                                                <input type="radio" name="isComputerized" id="isComputerized" disabled={data.action === 'visu' ? true : false}
                                                    onChange={(e) => {handleChange(parseInt(e.target.value), 'isComputerized', setIsComputerizedState)} }
                                                    checked={isComputerizedState === 0}
                                                    value={0}
                                                />
                                                <label htmlFor="isComputerized">{t('COMMON.UNKNOWN')}</label>
                                            </div>
                                            <div className="containerInline containerRadioInput">
                                                <input type="radio" name="isComputerized" id="isComputerized1" disabled={data.action === 'visu' ? true : false}
                                                    onChange={(e) => {handleChange(e.target.value, 'isComputerized', setIsComputerizedState)} }
                                                    checked={isComputerizedState === 1}
                                                    value={1}
                                                />
                                                <label htmlFor="isComputerized1">{t('COMMON.YES')}</label>
                                            </div>
                                            <div className="containerInline containerRadioInput">
                                                <input type="radio" name="isComputerized" id="isComputerized2" disabled={data.action === 'visu' ? true : false}
                                                    onChange={(e) => {handleChange(e.target.value, 'isComputerized', setIsComputerizedState)} }
                                                    checked={isComputerizedState === 2}
                                                    value={2}
                                                />
                                                <label htmlFor="isComputerized2">{t('COMMON.NO')}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="containerInline">
                                            <div className="containerInline containerRadioInput">
                                                <input type="radio" name="isThoughtLeader" id="isThoughtLeader" disabled={data.action === 'visu' ? true : false}
                                                    onChange={(e) => {handleChange(e.target.value, 'isThoughtLeader', setIsThoughtLeaderState)} }
                                                    checked={isThoughtLeaderState === 0}
                                                    value={0}
                                                />
                                                <label htmlFor="isThoughtLeader">{t('COMMON.UNKNOWN')}</label>
                                            </div>
                                            <div className="containerInline containerRadioInput">
                                                <input type="radio" name="isThoughtLeader" id="isThoughtLeader1" disabled={data.action === 'visu' ? true : false}
                                                    onChange={(e) => {handleChange(e.target.value, 'isThoughtLeader', setIsThoughtLeaderState)} }
                                                    checked={isThoughtLeaderState === 1}
                                                    value={1}
                                                />
                                                <label htmlFor="isThoughtLeader1">{t('COMMON.YES')}</label>
                                            </div>
                                            <div className="containerInline containerRadioInput">
                                                <input type="radio" name="isThoughtLeader" id="isThoughtLeader2" disabled={data.action === 'visu' ? true : false}
                                                    onChange={(e) => {handleChange(e.target.value, 'isThoughtLeader', setIsThoughtLeaderState)} }
                                                    checked={isThoughtLeaderState === 2}
                                                    value={2}
                                                />
                                                <label htmlFor="isThoughtLeaderState2">{t('COMMON.NO')}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="containerInline">

                                            <div className="containerInline containerRadioInput">
                                                <input type="radio" name="visitDistance" id="visitDistance1"
                                                    onChange={(e) => {handleChange(true, 'visitDistance', setVisitDistanceState, null)} }
                                                    checked={isVisitDistanceState}
                                                    value={true}
                                                />
                                                <label htmlFor="visitDistance1">{t('COMMON.YES')}</label>
                                            </div>
                                            <div className="containerInline containerRadioInput">
                                                <input type="radio" name="visitDistance" id="visitDistance2"
                                                    onChange={(e) => {handleChange(false, 'visitDistance', setVisitDistanceState, null)} }
                                                    checked={!isVisitDistanceState}
                                                    value={false}
                                                />
                                                <label htmlFor="visitDistance2">{t('COMMON.NO')}</label>
                                            </div>

                                        </div>
                                    </div>

                                    <div>
                                        <div className="containerInline">

                                            <div className="containerInline containerRadioInput">
                                                <input type="radio" name="savedOn" id="savedOn1"
                                                    checked={isSavedOn}
                                                    value={true}
                                                    disabled
                                                />
                                                <label htmlFor="savedOn1">{t('COMMON.YES')}</label>
                                            </div>
                                            <div className="containerInline containerRadioInput">
                                                <input type="radio" name="savedOn" id="savedOn2"
                                                    checked={!isSavedOn}
                                                    value={false}
                                                    disabled
                                                />
                                                <label htmlFor="savedOn2">{t('COMMON.NO')}</label>
                                            </div>

                                        </div>
                                    </div>

                                    <div>
                                        <div className="containerInline">
                                            <div className="containerInline containerRadioInput">
                                                <input type="radio" name="isRetired" id="isRetired" disabled={data.action === 'visu' ? true : false}
                                                    onChange={(e) => {handleChange(e.target.value, 'isRetired', setIsRetiredState)} }
                                                    checked={isRetiredState === 0}
                                                    value={0}
                                                />
                                                <label htmlFor="isRetiredState">{t('COMMON.UNKNOWN')}</label>
                                            </div>
                                            <div className="containerInline containerRadioInput">
                                                <input type="radio" name="isRetired" id="isRetired1" disabled={data.action === 'visu' ? true : false}
                                                    onChange={(e) => {handleChange(e.target.value, 'isRetired', setIsRetiredState)} }
                                                    checked={isRetiredState === 1}
                                                    value={1}
                                                />
                                                <label htmlFor="isRetired1">{t('COMMON.YES')}</label>
                                            </div>
                                            <div className="containerInline containerRadioInput">
                                                <input type="radio" name="isRetired" id="isRetired2" disabled={data.action === 'visu' ? true : false}
                                                    onChange={(e) => {handleChange(e.target.value, 'isRetired', setIsRetiredState)} }
                                                    checked={isRetiredState === 2}
                                                    value={2}
                                                />
                                                <label htmlFor="isRetired2">{t('COMMON.NO')}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="containerInline">
                                            <div className="containerInline containerRadioInput">
                                                <input type="radio" name="isDeath" id="isDeath" disabled={data.action === 'visu' ? true : false}
                                                    onChange={(e) => {handleChange(e.target.value, 'isDeath', setIsDeathState)} }
                                                    checked={isDeathState === 0}
                                                    value={0}
                                                />
                                                <label htmlFor="isDeath">{t('COMMON.UNKNOWN')}</label>
                                            </div>
                                            <div className="containerInline containerRadioInput">
                                                <input type="radio" name="isDeath" id="isDeath1" disabled={data.action === 'visu' ? true : false}
                                                    onChange={(e) => {handleChange(e.target.value, 'isDeath', setIsDeathState)} }
                                                    checked={isDeathState === 1}
                                                    value={1}
                                                />
                                                <label htmlFor="isDeath1">{t('COMMON.YES')}</label>
                                            </div>
                                            <div className="containerInline containerRadioInput">
                                                <input type="radio" name="isDeath" id="isDeath2" disabled={data.action === 'visu' ? true : false}
                                                    onChange={(e) => {handleChange(e.target.value, 'isDeath', setIsDeathState)} }
                                                    checked={isDeathState === 2}
                                                    value={2}
                                                />
                                                <label htmlFor="isDeathState2">{t('COMMON.NO')}</label>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </form>
                    </div>
                :
                    <div className="row pdsReadOnly">
                        <div className="col-md-4">
                            <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.NAME')}</label>
                            <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.PDS_TYPE')}</label>
                            <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.GENDER')}</label>
                            <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.EMAIL')}</label>
                            <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.PHONE_NUMBER_MOBILE')}</label>
                            <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.PHONE_NUMBER')}</label>
                            <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.REGISTRED_ON_ETHICA')}</label>
                            <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.Computerized')}</label>
                            <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.OPINION_LEADER')}</label>
                            <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.REMOTE_VISIT')}</label>
                            <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.RETIREMENT')}</label>
                            <label>{t('PROS.LIST.POPUP_VIEW.GENERAL.DECEASED')}</label>
                        </div>
                        <div className="col-md-8">
                            <p>{item.title} {item.firstName} {item.lastName}</p>
                            <p>{item.medicalProType ? item.medicalProType.nameMedicalProType : <i>Undefined</i>}</p>
                            <p>
                            {item.gender === 1 ? t('PROS.LIST.POPUP_VIEW.GENERAL.MAN')
                            : item.gender === 2 ? t('PROS.LIST.POPUP_VIEW.GENERAL.WOMAN')
                            : t('COMMON.UNKNOWN')}
                            </p>
                            <p>{item.mail}</p>
                            <p>{item.mobile ? `+${item.mobile}` : t('COMMON.NC')}</p>
                            <p>{item.landLine ? `+${item.landLine}` : t('COMMON.NC')}</p>
                            <p>{isSavedOn === 1 ? t('COMMON.YES') : isSavedOn === 0 ? t('COMMON.NO') : t('COMMON.UNKNOWN')}</p>
                            <p>{item.isComputerized === 1 ? t('COMMON.YES') : item.isComputerized === 2 ? t('COMMON.NO') : t('COMMON.UNKNOWN')}</p>
                            <p>{item.isThoughtLeader === 1 ? t('COMMON.YES') : item.isThoughtLeader === 2 ? t('COMMON.NO') : t('COMMON.UNKNOWN')}</p>
                            <p>{item.visitDistance ? t('COMMON.YES') : t('COMMON.NO')}</p>
                            <p>{item.isRetired === 1 ? t('COMMON.YES') : item.isRetired === 2 ? t('COMMON.NO') : t('COMMON.UNKNOWN')}</p>
                            <p>{item.isDeath === 1 ? t('COMMON.YES') : item.isDeath === 2 ? t('COMMON.NO') : t('COMMON.UNKNOWN')}</p>
                        </div>
                    </div>
                )
            }
            {tabsActive === 1 &&
                <div className={`tabs`}>
                    {generateTabMedicalProsInstitutions()}
                </div>
            }
            {tabsActive === 2 && 
                <div className={`tabs`}>
                    {actionPopUp !== 'visu' &&
                        <div className="selectDirectAdd">
                            <Select 
                                isDisabled={data.action === 'visu' ? true : false}
                                className='selectSearchBar' 
                                classNamePrefix='selectSearch' 
                                placeholder={t('PROS.LIST.RESEARCH.SPECIALITY_PLACEHOLDER')}
                                isClearable={false}
                                options={generateOptions(stateList.dataSpecialityByType)} 
                                onChange={(value, type) => {handleChange(value, 'medicalProSpeciality')} } 
                            />
                            <ButtonAddDirect type={'specialityProPop'} value={valueAddState}/>
                        </div>
                    }
                    <br/>
                    <TableManager 
                        dataHeader={dataHeaderSpecialityProPop}
                        actionsList={actionPopUp === "visu" ? [] : ['delete']}
                        type="specialityProPop"
                        isAdd={false}
                    />
                </div>
            }
            {tabsActive === 3 && 
                <div className={`tabs`}>
                    {actionPopUp !== 'visu' &&
                        <div className="selectDirectAdd">
                            <Select 
                                isDisabled={data.action === 'visu' ? true : false}
                                className='selectSearchBar' 
                                classNamePrefix='selectSearch' 
                                placeholder={t('PROS.LIST.RESEARCH.SOFTWARE_PLACEHOLDER')}
                                isClearable={false}
                                options={generateOptions(stateList.dataLogProSoft)} 
                                onChange={(value, type) => {handleChange(value, 'medicalProSoftware')} } 
                            />
                            <ButtonAddDirect type={'medicalProSoftPop'} value={valueAddState}/>
                        </div>
                    }
                    <br/>
                    <TableManager 
                        dataHeader={dataHeaderMedicalProSoftPop}
                        actionsList={actionPopUp === "visu" ? [] : ['delete']}
                        type="medicalProSoftPop"
                        isAdd={false}
                    />
                </div>
            }
            {(tabsActive === 4 && actionPopUp === 'visu') &&
                <div className={`tabs`}>
                    <TableManager 
                        dataHeader={dataHeaderQualifiedProducts} 
                        actionsList={[]}
                        type="medicalProQualifiedProducts"
                        isAdd={false}
                    />
                </div>
            }
            {(tabsActive === 5 && actionPopUp === 'visu') && 
                <div className={`tabs littleInput histoContact`}>
                    <TableManager 
                        dataHeader={dataHeaderMedicalProHistory} 
                        actionsList={['showReport']}
                        type="medicalProHisto"
                        isAdd={false}
                    />
                    <Pagination
                        maxPage={maxPage}
                        onChangePage={(data) => setPage(data)}
                        nbCount={nbResultats}
                    ></Pagination>
                </div>
            }

        </>
    )
}
