import { useState, createContext } from 'react';

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
    const [missionUser, setMissionUser] = useState({});
    const [loadingStore, setLoadingStore] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [lang, setLang] = useState(
        localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage")
        :
        navigator.language.toLocaleLowerCase().slice(0, 2) === "en" ?
            "en" : "fr"
    );

    return (
        <StoreContext.Provider value={{
            missionUser, setMissionUser,
            loadingStore, setLoadingStore,
            countryList, setCountryList,
            lang, setLang
        }}>
            { children }
        </StoreContext.Provider>
    );
}