import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getFrequences = (id) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).get('api/cycle/'+id+'/frequency', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.tableProductFrequencyByCycle));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const editFrequences = (id, data) => {
    
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).put('api/cycle/'+id+'/frequency', {
                    name: data.name,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    tabFrequency: data.tabFrequency
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

// CYCLES

export const getCycles = (idRes) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).get('api/cycle/network/'+idRes, {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listCycleByNetwork));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const postCycle = (idRes, data) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).post('api/cycle/network/'+idRes, {
                    "name": data.name,
                    "startDate": data.startDate,
                    "endDate": data.endDate
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}