import React from 'react';
import "./ProdAccordeon.scss";
import { t } from "../../../pages/i18nFunctions";
import Rating from '@material-ui/lab/Rating';

// components
import { CustomSelect } from "../CustomSelect/CustomSelect";

class ProdAccordeon extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            noteP: (this.props.prod.note !== "null" && this.props.prod !== undefined) ? this.props.prod.note : 0,
            hover: -1,
            pres: this.props.prod.presentationtype,
            reponse: "",
            nameP: this.props.prod.product.nameProduct,
            nameLab: this.props.prod.laboratory,
            questions: this.props.prod.question,
            idProd: this.props.prod.idProductPresented
        }
    }

    componentDidMount() {
        let state = { ...this.state };
        for(let i=0; i<this.props.prod.question.length; i++) {
            let r = {
                idQ: this.props.prod.question[i].idQuestionPresented,
                id: this.props.prod.question[i].answertypeQuestionPresented === "CTM" ? this.props.prod.question[i].answerQuestionPresented : this.props.prod.question[i].answerQuestionPresented.id ? this.props.prod.question[i].answerQuestionPresented.id : ""
            }
            if(this.props.prod.question[i].answertypeQuestionPresented === "LIST") {
                Object.assign(r, {
                    name: this.props.prod.question[i].answerQuestionPresented.name ? this.props.prod.question[i].answerQuestionPresented.name : "Sélectionnez une réponse"
                });
            }
            Object.assign(state, { [`reponse${i}`]: r });
        }
        this.setState({ ...this.state, ...state });
    }

    componentDidUpdate(prevProps) {

        if(this.props.prod !== prevProps.prod) {
            let state = {
                ...this.state,
                nameP: this.props.prod.product.nameProduct,
                questions: this.props.prod.question,
                idProd: this.props.prod.idProductPresented,
                pres: this.props.prod.presentationtype,
                nameLab: this.props.prod.laboratory,
                noteP: this.props.prod.note !== "null" ? this.props.prod.note : 0
            }
            for(let i=0; i<this.props.prod.question.length; i++) {
                let r = {
                    idQ: this.props.prod.question[i].idQuestionPresented,
                    id: this.props.prod.question[i].answertypeQuestionPresented === "CTM" ? this.props.prod.question[i].answerQuestionPresented : this.props.prod.question[i].answerQuestionPresented.id ? this.props.prod.question[i].answerQuestionPresented.id : ""
                }
                if(this.props.prod.question[i].answertypeQuestionPresented === "LIST") {
                    Object.assign(r, {
                        name: this.props.prod.question[i].answerQuestionPresented.name ? this.props.prod.question[i].answerQuestionPresented.name : "Sélectionnez une réponse"
                    });
                }
                Object.assign(state, { [`reponse${i}`]: r });
            }
            this.setState({ ...this.state, ...state });
        }
    }

    handleSubmit = (e) => {
        if(/*(this.state.reponse !== "") && */(this.state.noteP !== 0)) {
            e.preventDefault();
            let rep = [];

            for(let i=0; i<this.state.questions.length; i++) {
                for(let j=0; j<Object.keys(this.state).length; j++) {
                    if(Object.keys(this.state)[j] === `reponse${i}`) {
                        rep.push(
                            `${this.state[Object.keys(this.state)[j]].idQ}_${this.state[Object.keys(this.state)[j]].id}`
                        )
                    }
                }
            }

            this.props.remplirCRProd(this.state.idProd, {
                note: this.state.noteP,
                pres: this.state.pres,
                reponse: rep
            });

            this.header.click();
        }
    }

    handleOpen = () => this.state.open && this.header.click();

    render() {
        //const edit = Object.keys(this.props.prod).length > 0
        const edit = !this.props.readOnly;
        const { hover, noteP } = this.state;
        const labels = {
            0: '?',
            1: t('PLANNING.CR.PROD_ACCORD.LABELS.REFRACTORY'),
            2: t('PLANNING.CR.PROD_ACCORD.LABELS.INTERESTED'),
            3: t('PLANNING.CR.PROD_ACCORD.LABELS.TRYING'),
            4: t('PLANNING.CR.PROD_ACCORD.LABELS.REGULAR'),
            5: t('PLANNING.CR.PROD_ACCORD.LABELS.REFERENCE')
        }
        
        return(
            <div className={`accordeon ${this.props.prod.status === 1 ? "complete" : "incomplete"}`}>
                <div className="accordeon-head" onClick={() => this.setState({open: !this.state.open})} ref={el => this.header = el}>
                    {this.props.moveFa && (
                    <button
                    type="button"
                    className="moveProd"
                    onClick={(e) => this.props.handleClickMove(e)}
                    >
                        <i
                        className={`fa ${this.props.moveFa}`}
                        aria-hidden="true"
                        style={this.props.moveBtnStyle}
                        ></i>
                    </button>
                    )}
                    <p><i className={`fa fa-${this.state.pres === 0 ? "check-square-o" : this.state.pres === 1 ? "eye" : "eye-slash"}`}></i> {this.state.nameP} - {this.state.nameLab}</p>
                    <i className={`pull-right fa fa-chevron-${this.state.open ? "down" : "right"}`}></i>
                </div>

                {this.state.open &&
                    <div className="accordeon-body">
                        <div className="row">
                            <div className={`col-md-${edit ? "4" : "6"} ${this.state.pres === 1 ? "valid" : ""}`} onClick={() => (edit) ? this.setState({pres: 1}) : {}}>
                                <p><i className="fa fa-eye"></i> {t('PLANNING.CR.PROD_ACCORD.PRESENTATION')}</p>
                            </div>

                            <div className={`col-md-${edit ? "4" : "6"} ${this.state.pres === 0 ? "valid" : ""}`} onClick={() => (edit) ? this.setState({pres: 0}) : {}}>
                                <p><i className="fa fa-check-square-o"></i> {t('PLANNING.CR.PROD_ACCORD.REMINDER')}</p>
                            </div>

                            {edit && (
                                <div className={`col-md-4 ${this.state.pres === 2 ? "valid" : ""}`} onClick={() => (edit) ? this.setState({pres: 2}) : {}}>
                                    <p><i className="fa fa-eye-slash"></i> {t('PLANNING.CR.PROD_ACCORD.NOT_PRESENTED')}</p>
                                </div>
                            )}

                            {edit && (
                                <div className="col-md-12">
                                    <label>{t('PLANNING.CR.PROD_ACCORD.ADHERENCE')}</label>
                                    <div className="rating">
                                        <Rating
                                        name={`simple-controlled-prod-${this.state.idProd}`}
                                        size="large"
                                        max={5}
                                        value={this.state.noteP}
                                        onChange={(event, newValue) => this.setState({noteP: newValue})}
                                        onChangeActive={(event, newHover) => this.setState({hover: newHover})}
                                        />
                                        <span className={`tag hov${hover !== -1 ? hover : noteP}`}>{labels[hover !== -1 ? hover : noteP]}</span>
                                    </div>
                                </div>
                            )}

                            {this.state.questions.map((q, i) =>
                            <div className="col-md-12" key={`question-prod-${this.state.idProd}-${i}`}>
                                <label>{q.nameQuestionPresented}</label>
                                {edit ? (
                                    <div>
                                        {q.answertypeQuestionPresented === "LIST" ? (
                                            <CustomSelect
                                            defaultText={this.state[`reponse${i}`] ? this.state[`reponse${i}`].name : "Sélectionnez un réponse"}
                                            optionsList={q.answerspossible}
                                            handleOptionClick={(id, name) => this.setState({[`reponse${i}`]: {
                                                idQ: q.idQuestionPresented,
                                                id: id,
                                                name: name
                                            }})}
                                            />
                                        ) : (
                                            <input
                                            type="number"
                                            required
                                            min={0}
                                            value={this.state[`reponse${i}`] ? this.state[`reponse${i}`].id : ""}
                                            onChange={(e) => this.setState({[`reponse${i}`]: {
                                                idQ: q.idQuestionPresented,
                                                id: e.target.value
                                            }})}
                                            placeholder="Réponse obligatoire"
                                            />
                                        )}
                                        
                                        <small>{t('PLANNING.CR.PROD_ACCORD.ANSWER_MANDATORY')}</small>
                                    </div>
                                ) : (
                                    <p>
                                        {q.lastanswerQuestionPresented === "" ?
                                        "Cette question n'a jamais été posée."
                                        : `La dernière réponse était ${q.lastanswerQuestionPresented}`}
                                        
                                    </p>
                                )}
                            </div>
                            )}
                            {this.state.questions.length === 0 && <div className="col-md-12">
                                <p>{t('PLANNING.CR.PROD_ACCORD.NO_QUESTION')}</p>
                            </div>}

                            {edit && (
                            <div className="col-md-12">
                                <button className="btn-save pull-right" onClick={this.handleSubmit}>
                                    <img src="/dist/img/icons/icon-save.png" alt=""/>
                                    {t('COMMON.SAVE')}
                                </button>
                            </div>
                            )}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ProdAccordeon;