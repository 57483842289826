import "./index.scss";
import { useNavigate } from "react-router-dom";

const ProgressBarTable = ({ completed }) => {
	return (
		<div className="progress-bar-table">
			<div
				className={`progress-filler ${completed >= 100 ? "valid" : completed >= 90 ? "warning" : "danger"} ${completed > 50 ? "sup50" : "inf50"}`}
				style={{
					width: `${completed > 100 ? 100 : completed}%`
				}}
			>
				<span
					className={`progress-value`}
					style={{ left: completed <= 50 ? `calc(${completed}% + 10px)` : "50%" }}
				>
					{completed}%
				</span>
			</div>
		</div>
	)
}

const RowTemplate = ({ data, keyName, period, labData, countriesData }) => {

	const navigate = useNavigate();

	const navStartDate =
		period === "ytd" ? // jan 1
			new Date(new Date().getFullYear(), 0, 1)
		: // start of month
			new Date(new Date().getFullYear(), new Date().getMonth(), 1)
	;

	const navEndDate =
		period === "ytd" ? // today
			new Date()
		: // end of month
			new Date(new Date().getFullYear(), new Date().getMonth()+1, 0)
	;

	return (
		<tr onClick={() => navigate("/suivi-activite", { state: { navStartDate, navEndDate, labData, countriesData } })}>
			<th scope="row">
				<span className="tag">{keyName.replaceAll("_", " ")}</span>
			</th>
			{Object.values(data).map((el, i) =>
				<td key={i}>
					<ProgressBarTable
						completed={Math.round(parseFloat(el) * 100)}
					/>
				</td>
			)}
		</tr>
	)
}

export const TabContacts = (props) => {
    const { data, period, labs = [], countries = [] } = props;

	const generateTab = () => {
		let tab = [];

		tab.push(
			<RowTemplate key={"TOTAL"} data={data["TOTAL"]} keyName={"TOTAL"} period={period} />
		);

		for (const [key, value] of Object.entries(data)) {
			if(key !== "TOTAL")
				tab.push(
					<RowTemplate
						key={key}
						data={value}
						keyName={key}
						period={period}
						labData={labs.find(el => el.name === key.replaceAll("_", " "))}
						countriesData={countries.find(el => el.name === key.replaceAll("_", " "))}
					/>
				);
		}

		return tab;
	}
  
    return (
		<table className="tab-contacts">
			<thead>
				<tr>
					<td></td>
					<th scope="col">
						<span className="tag">Total</span>
					</th>
					<th scope="col">
						<span className="tag">A</span>
					</th>
					<th scope="col">
						<span className="tag">B</span>
					</th>
					<th scope="col">
						<span className="tag">C</span>
					</th>
					<th scope="col">
						<span className="tag">D</span>
					</th>
				</tr>
			</thead>
			<tbody>
				{generateTab()}
			</tbody>
		</table>
    );
};