import { useContext } from 'react';
import { FilterStoreContext } from '../../../../../store/store/storeFilters';
import { CustomSelect } from '../../../CustomSelect/CustomSelect';

export const CustomSelectFilt = (props) => {
    const { head } = props;
    
    const { stateFilter, dispatchFilters } = useContext(FilterStoreContext);
    const { searchFilters } = stateFilter;

    const handleSearchInput = (value) => {

        let sSearchFilters = { ...searchFilters,
            [head.filterName]: value
        };

        dispatchFilters({ type: 'setSearchFilters', sSearchFilters });
    }
    
    return(
        <CustomSelect
            defaultText={head.defaultText ?? "Sélectionnez"}
            optionsList={head.optionsList ?? []}
            handleOptionClick={(id, name) => handleSearchInput(id)}
        />
    )
}