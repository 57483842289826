import { useState, useEffect } from "react";
import "./CustomSelect.scss";

export const CustomSelect = (props) => {
  const { optionsList, defaultText, disabled = false, handleOptionClick } = props;

  const [defaultSelectText, setDefaultSelectText] = useState(defaultText);
  const [showOptionList, setShowOptionList] = useState(false);

  useEffect(() => {
    setDefaultSelectText(defaultText);
  }, [defaultText])

  // const handleClickOutside = e => {
  //   if(!e.target.classList.contains("custom-select-option") && !e.target.classList.contains("selected-text"))
  //     setShowOptionList(false);
  // }

  const handleListDisplay = () => {
    if(!disabled) setShowOptionList(!showOptionList);
  };

  const handleClick = e => {
    const id = e.target.getAttribute("data-id");
    const name = e.target.getAttribute("data-name");
    const label = e.target.getAttribute("data-label");

    handleOptionClick(id, name, label);

    setDefaultSelectText(name);
    setShowOptionList(false);
  }
  
  return (
    <div className={`custom-select-container ${disabled ? "disabled" : ""}`}>
      <div
        className={showOptionList ? "selected-text active" : "selected-text"}
        onClick={handleListDisplay}
      >
        {defaultSelectText}
      </div>
      {showOptionList && (
        <ul className="select-options">
          {optionsList.map(option => {
            return (
              <li
                className="custom-select-option"
                data-name={option.name}
                data-id={option.id}
                data-label={option.val}
                key={option.val ? option.val : option.id}
                onClick={handleClick}
              >
                {option.name}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}