import { t } from "../../pages/i18nFunctions";

export const TablePosition = (props) => {
    const { data } = props;

	const generatePositionTable = (data) => {
        let table = [];

        for(const [index, [key, value]] of Object.entries(data).entries()) {

            const max = Math.max(...Object.values(value).map(el => parseInt(el)));

            table.push(
                <tr key={key}>
                    <th scope="row" className='fixedCell'>{key}</th>

                    {Object.values(value).map((el, i) =>
                        <td key={i} className={`${index === i ? parseInt(el) < 50 ? "danger" : parseInt(el) === max ? "valid" : "" : ""}`}>
                            {parseInt(el)}%
                        </td>
                    )}
                </tr>
            );
        }

        return table;
    }
  
    return (
        <table>
            <thead>
                <tr>
                    <td></td>
                    {Object.keys(Object.values(data)[0]).map((el, i) =>
                        <th scope="col" key={i}>P{i+1}</th>    
                    )}
                </tr>
            </thead>
            <tbody>
                {generatePositionTable(data)}
            </tbody>
        </table>
    );
};