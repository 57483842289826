import { useState, useContext, useEffect, useRef } from 'react';
import Select from 'react-select';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import fr from 'react-phone-input-2/lang/fr.json';

import { ActionsStoreContext } from '../../../../../store/store/storeActions';
import { ListStoreContext } from '../../../../../store/store/storeList';

import { t } from "../../../../../pages/i18nFunctions";

import { ZoneGeoInput } from '../../../Input/ZoneGeoInput/ZoneGeoInput';
import { TableManager } from '../../../Table/TableManager';

import { Pagination } from '../../../../Pagination/Pagination';

export const EditEtab = (props) => {
    const { data, dispatchPop } = props;
    const { stateActions, dispatchActions } = useContext(ActionsStoreContext);
    const { stateList, dispatchList} = useContext(ListStoreContext);
   
    const { dataActions } = stateActions;

    let [initParam, setInitParam] = useState(false);
    let [tabsActive, setTabsActive] = useState(0);
    let [similarInstit, setSimilarInstit] = useState(0);

    let [maxPage, setMaxPage] = useState(0);
    let [page, setPage] = useState(1);
	let [nbResultats, setNbResultats] = useState(0);

    
    const [item, setItem] = useState({
        ...dataActions,
    });

    let [isPrivateState, setIsPrivateState] = useState(dataActions.isPrivate);
    let [withInternetConnState, setWithInternetConnState] = useState(dataActions.withInternetConn);
    let [withInsuranceState, setWithInsuranceState] = useState(dataActions.withInsurance);

    const timer = useRef(null);
    
    useEffect(() => {
        if(data.action === 'visu')
            stateActions.getOneInstitEmp(item.id).then((res) => {
                let dDataMedicalPro = JSON.parse(res);
                dispatchList({type: 'setDataMedicalPro', dDataMedicalPro });
            });

        timer.current = setTimeout(() => {}, 1000);
        // unmounted
        return () => {
            clearTimeout(timer.current);
        };
    }, [])

    useEffect(() => {
        if(tabsActive === 0)
            return getVerif();

    }, [tabsActive]) 

    useEffect(() => {
        if(tabsActive === 0)
            return getVerif();

    }, [page]) 

    const getVerif = (value = null) => {
        let data = {
            page: page,
            maxPerPage: 5,
            name: item.name,
            id: item.id,
            idCountry: item.fulldatazoneGeo.country.label
        };
        if(value !== null) {
            data = {
                ...data,
                name: value
            }
        }

        stateActions.verifEtab(data).then(res => {
            let data = JSON.parse(res);
            let dDataVerifEtab = data.similaireInstitution;
            setSimilarInstit(data);
            setNbResultats(data.nbResultats)
            setMaxPage(data.nbrPages);
            return dispatchList({type: 'setDataVerifEtab', dDataVerifEtab });
        });
    }

    const generateOptions = (data) => {
        if(typeof data !== 'undefined') {
            let options = [];
            data.forEach((option, i) => {
                options.push({
                    value: option.id, 
                    label: option.name,
                });
            });
            return options;
        }
    }

    const handleChange = (value, name, setRadio = null) => { 
        let aDataActions = {...item, [name]: value};
        if(setRadio !== null)
            setRadio(parseInt(value));

        if(name === 'idInstitutionType')
            aDataActions = {
                ...aDataActions, 
                idInstitutionType: value.value,
                nameInstitutionType: value.label,
            };

        setItem(aDataActions);
        dispatchActions({type: 'setDataActions', aDataActions});
        
        if(name === 'name' ) {
            clearTimeout(timer.current);

            if(value.length >= 3)
                timer.current = setTimeout(() => {
                    getVerif(value);
                }, 1000);
            
            // else if(value.length < 3)
            //     return getVerif('');
            
            else if(value.length <= 2)
                return;
        }
    }

    const actionsList = []
    const dataHeader = [
        {
            name: 'referenceMedicalPro', 
            displayName: t('INSTITUTION.LIST.HEADER.REF'), 
            isFilterable: true, 
        },
        {
            name: 'lastnameMedicalPro', 
            displayName: t('INSTITUTION.LIST.HEADER.NAME'), 
            isFilterable: true, 
        },
        {
            name: 'firstnameMedicalPro', 
            displayName: t('INSTITUTION.LIST.HEADER.FIRSTNAME'), 
            isFilterable: true, 
        },
        {
            name: 'typeMedicalPro', 
            displayName: t('INSTITUTION.LIST.HEADER.TYPE'), 
            isFilterable: true, 
        },
        {
            name: 'specialityMedicalPro', 
            displayName: t('INSTITUTION.LIST.HEADER.SPECIALITY'), 
            isFilterable: true, 
        },
    ];

    const dataHeaderSimilar = [
        {
            name: 'reference', 
            displayName: t('INSTITUTION.LIST.REFERENCE'), 
        },
        {
            name: 'name', 
            displayName: t('INSTITUTION.LIST.NAME'), 
        },
        {
            name: 'addresszoneGeo', 
            displayName: t('INSTITUTION.LIST.ADDRESS'), 
        },
    ];

    return(
        <>
            <div className="navTabsContainer nav nav-tabs">
                <div className={`elementTabs ${tabsActive === 0 ? 'selected' : ''}`} onClick={() => setTabsActive(0)}>
                    <span>
                        {t('INSTITUTION.POP_EDIT.TAB_GENERAL')}
                    </span>
                </div>
                <div className={`elementTabs ${tabsActive === 1 ? 'selected' : ''}`} onClick={() => setTabsActive(1)}>
                    <span>
                        {t('INSTITUTION.POP_EDIT.TAB_ADDRESS')}
                    </span>
                </div>
                {data.action === 'visu' && 
                    <div className={`elementTabs ${tabsActive === 2 ? 'selected' : ''}`} onClick={() => setTabsActive(2)}>
                        <span>
                            {t('INSTITUTION.POP_EDIT.TAB_PDS')}
                        </span>
                    </div>
                }
            </div>
            <br/>

            {tabsActive === 0 && 
                <div className={`tabs`}>
                    <form>
                        <div>
                            <label htmlFor="name">{t('INSTITUTION.POP_EDIT.NAME')}</label>
                            <input id="name" type="text" required readOnly={data.action === 'visu' ? true : false}
                                value={item.name ?? ''} 
                                onChange={ (e) => {handleChange(e.target.value, 'name')} }
                            />
                        </div>
                        <div>
                            <label htmlFor="type">{t('INSTITUTION.POP_EDIT.TYPE')}</label>
                            <Select 
                                isDisabled={data.action === 'visu' ? true : false}
                                id="type"
                                className='selectSearchBar' 
                                classNamePrefix='selectSearch' 
                                placeholder={t('INSTITUTION.POP_EDIT.TYPE_PLACEHOLDER')}
                                isClearable={false}
                                options={generateOptions(stateList.dataTypeEtabs)} 
                                onChange={(value, type) => {handleChange(value, 'idInstitutionType')} } 
                                defaultValue={typeof item.nameInstitutionType !== 'undefined' ? {value: item.idInstitutionType, label: item.nameInstitutionType} : ''} 
                            />
                        </div>
                        <div>
                            <label htmlFor="mail">{t('INSTITUTION.POP_EDIT.EMAIL')}</label>
                            <input id="mail" type="text" required readOnly={data.action === 'visu' ? true : false}
                                value={item.mail ?? ''} 
                                onChange={ (e) => {handleChange(e.target.value, 'mail')} }
                            />
                        </div>
                        <div className="containerInline">
                            <div className="phoneInputContainer">
                                <label htmlFor="phone">{t('INSTITUTION.POP_EDIT.PHONE_NUMBER')}</label>
                                <PhoneInput
                                    disabled={data.action === 'visu' ? true : false}
                                    country={'bj'}
                                    value={item.landLine ?? ''}
                                    onChange={ (value) => {handleChange(value, 'landLine')} }
                                    localization={fr}
                                />
                            </div>
                            <div>
                                <label htmlFor="faxNumber">{t('INSTITUTION.POP_EDIT.FAX')}</label>
                                <input id="faxNumber" type="text" required readOnly={data.action === 'visu' ? true : false}
                                    value={item.faxNumber ?? ''} 
                                    onChange={ (e) => {handleChange(e.target.value, 'faxNumber')} }
                                />
                            </div>
                        </div>
                        {
                            (typeof similarInstit.similaireInstitution !== 'undefined' && similarInstit.similaireInstitution !== null) &&
                            <div className="containerRedBorder">
                                <TableManager 
                                    dataHeader={dataHeaderSimilar} 
                                    actionsList={[]}
                                    type="verifEtab"
                                    isAdd={false}
                                />
                                <br/>
                                <Pagination
                                    maxPage={maxPage}
                                    onChangePage={(data) => setPage(data)}
                                    nbCount={nbResultats}
                                ></Pagination>
                            </div>
                        }
                        <div className="containerInline">
                            <div className="containerLabelRadioInline">
                                <label>{t('INSTITUTION.POP_EDIT.PRIVATE_PUBLIC')}</label>
                                <label>{t('INSTITUTION.POP_EDIT.INTERNET')}</label>
                                <label>{t('INSTITUTION.POP_EDIT.INSURANCE')}</label>
                            </div>
                            <div>
                                <div>
                                    <div className="containerInline">
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="isPrivate" id="isPrivate" disabled={data.action === 'visu' ? true : false}
                                                onChange={(e) => {handleChange(e.target.value, 'isPrivate', setIsPrivateState)} }
                                                checked={isPrivateState === 0}
                                                value={0}
                                            />
                                            <label htmlFor="isPrivate">{t('COMMON.UNKNOWN')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="isPrivate" id="isPrivate1" disabled={data.action === 'visu' ? true : false}
                                                onChange={(e) => {handleChange(e.target.value, 'isPrivate', setIsPrivateState)} }
                                                checked={isPrivateState === 1}
                                                value={1}
                                            />
                                            <label htmlFor="isPrivate1">{t('INSTITUTION.LABEL.PUBLIC')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="isPrivate" id="isPrivate2" disabled={data.action === 'visu' ? true : false}
                                                onChange={(e) => {handleChange(e.target.value, 'isPrivate', setIsPrivateState)} }
                                                checked={isPrivateState === 2}
                                                value={2}
                                            />
                                            <label htmlFor="isPrivate2">{t('INSTITUTION.LABEL.PRIVATE')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="isPrivate" id="isPrivate3" disabled={data.action === 'visu' ? true : false}
                                                onChange={(e) => {handleChange(e.target.value, 'isPrivate', setIsPrivateState)} }
                                                checked={isPrivateState === 3}
                                                value={3}
                                            />
                                            <label htmlFor="isPrivate3">{t('INSTITUTION.LABEL.SEMI_PRIVATE')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="containerInline">
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="withInternetConn" id="withInternetConn" disabled={data.action === 'visu' ? true : false}
                                                onChange={(e) => {handleChange(e.target.value, 'withInternetConn', setWithInternetConnState)} }
                                                checked={withInternetConnState === 0}
                                                value={0}
                                            />
                                            <label htmlFor="withInternetConn">{t('COMMON.UNKNOWN')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="withInternetConn" id="withInternetConn1" disabled={data.action === 'visu' ? true : false}
                                                onChange={(e) => {handleChange(e.target.value, 'withInternetConn', setWithInternetConnState)} }
                                                checked={withInternetConnState === 1}
                                                value={1}
                                            />
                                            <label htmlFor="withInternetConn1">{t('COMMON.YES')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="withInternetConn" id="withInternetConn2" disabled={data.action === 'visu' ? true : false}
                                                onChange={(e) => {handleChange(e.target.value, 'withInternetConn', setWithInternetConnState)} }
                                                checked={withInternetConnState === 2}
                                                value={2}
                                            />
                                            <label htmlFor="withInternetConn2">{t('COMMON.NO')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="containerInline">
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="withInsurance" id="withInsurance" disabled={data.action === 'visu' ? true : false}
                                                onChange={(e) => {handleChange(e.target.value, 'withInsurance', setWithInsuranceState)} }
                                                checked={withInsuranceState === 0}
                                                value={0}
                                            />
                                            <label htmlFor="withInsurance">{t('COMMON.UNKNOWN')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="withInsurance" id="withInsurance1" disabled={data.action === 'visu' ? true : false}
                                                onChange={(e) => {handleChange(e.target.value, 'withInsurance', setWithInsuranceState)} }
                                                checked={withInsuranceState === 1}
                                                value={1}
                                            />
                                            <label htmlFor="withInsurance1">{t('COMMON.WITH')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="withInsurance" id="withInsurance2" disabled={data.action === 'visu' ? true : false}
                                                onChange={(e) => {handleChange(e.target.value, 'withInsurance', setWithInsuranceState)} }
                                                checked={withInsuranceState === 2}
                                                value={2}
                                            />
                                            <label htmlFor="withInsurance2">{t('COMMON.WITHOUT')}</label>
                                        </div>
                                        <div className="containerInline containerRadioInput">
                                            <input type="radio" name="withInsurance" id="withInsurance3" disabled={data.action === 'visu' ? true : false}
                                                onChange={(e) => {handleChange(e.target.value, 'withInsurance', setWithInsuranceState)} }
                                                checked={item.withInsurance === 3}
                                                value={3}
                                            />
                                            <label htmlFor="withInsurance3">{t('COMMON.MIXED')}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            }
            {tabsActive === 1 &&
                <div className={`tabs`}>
                    <form>
                        <ZoneGeoInput item={item}
                            countries={stateList.dataCountries}
                            actions={stateActions}
                            setInitParam={(e) => {setInitParam(e)}}
                            edit={true}
                            action={data.action}
                        />
                        
                        <div className='flex-row two-cols'>
                            <div>
                                <label htmlFor="cplt1">{t('INSTITUTION.POP_EDIT.COMPLEMENT_1')}</label>
                                <input id="cplt1" type="text" required readOnly={data.action === 'visu' ? true : false}
                                    value={item.address1} 
                                    onChange={ (e) => {handleChange(e.target.value, 'address1')} }
                                />
                            </div>
                            <div>
                                <label htmlFor="cplt2">{t('INSTITUTION.POP_EDIT.COMPLEMENT_2')}</label>
                                <input id="cplt2" type="text" required readOnly={data.action === 'visu' ? true : false}
                                    value={item.address2} 
                                    onChange={ (e) => {handleChange(e.target.value, 'address2')} }
                                />
                            </div>
                        </div>

                        <h2><b>{t('INSTITUTION.POP_EDIT.GPS')}</b></h2>

                        <div className='flex-row two-cols'>
                            <div>
                                <label htmlFor="lat">{t('INSTITUTION.POP_EDIT.LATITUDE')}</label>
                                <input id="lat" type="text" required readOnly={data.action === 'visu' ? true : false}
                                    value={item.CoordLat} 
                                    onChange={ (e) => {handleChange(e.target.value, 'CoordLat')} }
                                />
                            </div>
                            <div>
                                <label htmlFor="long">{t('INSTITUTION.POP_EDIT.LONGITUDE')}</label>
                                <input id="long" type="text" required readOnly={data.action === 'visu' ? true : false}
                                    value={item.CoordLong} 
                                    onChange={ (e) => {handleChange(e.target.value, 'CoordLong')} }
                                />
                            </div>
                        </div>
                    </form>
                </div>
            }
            {(tabsActive === 2 && data.action === 'visu') &&
                <div className={`tabs`}>
                    <TableManager 
                        dataHeader={dataHeader} 
                        actionsList={actionsList}
                        type="medicalProPop"
                        isAdd={false}
                    />
                </div>
            }

        </>
    )
}
