import { useContext } from "react";
import { StoreContext } from "../../../../../../store/store";
import { getUserConnected } from "../../../../../../store/index.service";

import loginAsIcon from "../../../../../../assets/img/icons/loginAs.svg";

export const LoginAs = (props) => {
    const { action, stateActions, type, line } = props;

	const { setLoadingStore } = useContext(StoreContext);

    const handleClick = () => {
        setLoadingStore(true);

        return stateActions.loginAsUser(line.username)
            .then(res => {
                getUserConnected().then(res => {
                    window.location.replace("/");
                });
            })
            .catch(err => console.error(err))
            .finally(() => setLoadingStore(false))
    }

    return (
        <>
            <div className="icon-btn loginAs" onClick={() => handleClick()} title="Se connecter en tant que">
                <img src={loginAsIcon} alt="connexion"/>
            </div>
        </>
    )
}