import { useState, useEffect, useContext } from 'react';
import './Users.scss';
import { t } from "../../i18nFunctions";

import { FilterStoreContext } from '../../../store/store/storeFilters';
import { ListStoreContext } from '../../../store/store/storeList';
import { ActionsStoreContext } from '../../../store/store/storeActions';
import { StoreContext } from '../../../store/store';

import { Pagination } from '../../../components/Pagination/Pagination';

import { TableManager } from '../../../components/UI/Table/TableManager';
import { PopUpManager } from '../../../components/UI/PopUp/PopUpManager';

import {
    getAllUsers,
    disableUser,
    deleteUser,
	postUser,
    editUser,
	loginAsUser,

    getAllCountries,
    getAllLabo,
} from '../../../store/index.service';

export const Users = () => {
	const { stateFilter, dispatchFilters } = useContext(FilterStoreContext);
	const { dispatchList } = useContext(ListStoreContext);
	const { dispatchActions } = useContext(ActionsStoreContext);
	const { setLoadingStore } = useContext(StoreContext);

    const { searchFilters, searchUserStatus, searchUserRole } = stateFilter;

	let [maxPage, setMaxPage] = useState(0);
    let [page, setPage] = useState(1);
	let [nbResultats, setNbResultats] = useState(0);
	let [maxPerPage, setMaxPerPage] = useState(20);
	let [mounted, setMounted] = useState(false);
    
	let allActions = {
		editAction: editUser,
		addAction: postUser,
		deleteAction: deleteUser,
		lockAction: disableUser,
		getAll: getAllUsers,
		loginAsUser: loginAsUser,
	}

	// mounted
	useEffect(() => {
		let sSearchFilters = {};
		dispatchFilters({type: 'setSearchUserStatus', sSearchFilters});
		dispatchFilters({type: 'setSearchUserRole', sSearchFilters});
        dispatchFilters({type: 'setSearchFilters', sSearchFilters });
		dispatchActions({type: 'initAllActions', allActions});

		let dDataList = [];
		dispatchList({type: 'setList', dDataList });

		setMounted(true);

		getAllCountries().then(res => {
			const data = JSON.parse(res);
			let dDataCountries = data;
			dispatchList({type: 'setDataCountries', dDataCountries });
		});

		getAllLabo().then(res => {
			const data = JSON.parse(res);
			let dDataLab = data;
			dispatchList({type: 'setDataLab', dDataLab });
		});
	}, []);
	
	// updates
	useEffect(() => {
		let params = {
			page: page,
			maxPerPage: maxPerPage
		}
		let dDataPagination = params;
		let sOrderByKey = {};

		dispatchList({type: 'setDataPagination', dDataPagination });
        dispatchFilters({type: 'setOrderByKey', sOrderByKey });

	}, [page]);

	useEffect(() => {
		if(mounted) {
			setPage(1);
			handleGetAllUsers({
				page: 1,
				maxPerPage: maxPerPage
			});
		}
	}, [searchFilters, searchUserStatus, searchUserRole]);

	const handleGetAllUsers = (params) => {

		setLoadingStore(true);

		const status = searchUserStatus ? searchUserStatus.value : null;
		const role = searchUserRole ? searchUserRole.value : null;

		getAllUsers({ ...params, ...searchFilters, status, role }).then(res => {
			const data = JSON.parse(res);
			let dDataList = data.listUser ? data.listUser : [];
			let mSearchList = data.listUser ? data.listUser : [];

			setNbResultats(data.nbResultats);
			setMaxPerPage(data.maxPerPage);
			setMaxPage(data.nbrPages);

			dispatchFilters({type: 'setMemoSearchList', mSearchList });
			dispatchList({type: 'setList', dDataList });

			setLoadingStore(false);
		});
	}
	
	const dataHeader = [
		{
			name: 'status', 
			displayName: t('SETTINGS.STATUS'),
			isSelectable: true
		},
		{
			name: 'lastName', 
			displayName: t('SETTINGS.NAME'), 
			isFilterable: true, 
			isSearchable: true,
		},
		{
			name: 'firstName', 
			displayName: t('SETTINGS.FIRSTNAME'), 
			isFilterable: true, 
			isSearchable: true,
		},
		{
			name: 'roles', 
			displayName: t('SETTINGS.GROUP'), 
			isFilterable: true,
			isSelectable: true
		},
		{
			name: 'username', 
			displayName: t('SETTINGS.IDENTIFIER'), 
			isFilterable: true, 
			isSearchable: true,
		},
		{
			name: 'lastpwddatechange', 
			displayName: t('SETTINGS.CHGM_MDP'), 
			isFilterable: true, 
		},
		{
			name: 'lastactivity', 
			displayName: t('SETTINGS.LAST_ACTIVITY'), 
			isFilterable: true, 
		}
	];

	const actionsList = [
		'edit',
		'lock',
		'loginAs',
		'delete',
	]

	return (
		<section className="content" id="list-users">
			<section className="content-header">
				<h1>
					{t('SETTINGS.USERS')}
					<small>{t('SETTINGS.MANAGEMENT')}</small>
				</h1>
			</section>
		
			<section className="content users sectionTable">
				<TableManager 
					dataHeader={dataHeader} 
					actionsList={actionsList}
					type="user"
					isAdd={true}
				/>
				<br/>
				{ maxPage > 1 &&
					<Pagination
						maxPage={maxPage}
						onChangePage={(data) => {
							setPage(data);
							handleGetAllUsers({
								page: data,
								maxPerPage: maxPerPage
							});
						}}
						nbCount={nbResultats}
						page={page}
					></Pagination>
				}
			</section>

			<PopUpManager 
				type="user"
				displayTitle="Utilisateur"
			/>

		</section>
	)
}
