import { AddMedicalProInstits } from './Type/AddMedicalProInstits';
import { EditMedicalProInstits } from './Type/EditMedicalProInstits';

export const MedicalProsInstitsPopUpManager = (props) => {
    const { data, dispatchPop } = props;
    const { action } = data;

    const generatePopUps = () => {
        switch (action) {
            case 'add':
                return <AddMedicalProInstits data={data} dispatchPop={dispatchPop} />;

            case 'visu':
            case 'edit':
                return <EditMedicalProInstits data={data} dispatchPop={dispatchPop} />;
                
            default:
                return false;
        }
    }

    return (
        <>
            {
                <div className="modal-body">
                    {generatePopUps()}
                </div>
            }
        </>
    )
}