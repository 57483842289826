import Moment from 'react-moment';
import { useContext } from 'react';
import { PopUpStoreContext } from '../../../../../../store/store/storePopUp';
import { ActionsStoreContext } from '../../../../../../store/store/storeActions';

export const TdString = (props) => {
    const { line, el, type } = props;
    const { statePopUp, dispatchPopUp } = useContext(PopUpStoreContext);
    const { dispatchActions } = useContext(ActionsStoreContext);
    const { dataPopUp } = statePopUp;
    
    const handleDisplayPopup = () => {
        let dDataPopUp = {
            type: type,
            action: 'visu',
            isActive: !dataPopUp.isActive
        }
        let aDataActions = line;

        dispatchPopUp({type: 'setPopUp', dDataPopUp });
        dispatchActions({type: 'setDataActions', aDataActions });
    };

    const genString = () => {
        if(el.name === 'lastactivity' || el.name === 'lastpwddatechange') {
            if(el.name === 'lastactivity' && line[el.name] === "")
                return <td><p>Aucune activité</p></td>;
                
            return <td><p><Moment locale="fr" fromNow>{new Date(line[el.name])}</Moment></p></td>;
        }
        else if(type === 'etab')
            return <td className='clickableTab' onClick={() => handleDisplayPopup()}><p>{line[el.name]}</p></td>;
        
        else if(type === 'medicalProPage')
            return <td className='clickableTab lala' onClick={() => handleDisplayPopup()}><p>{line[el.name]}</p></td>;

        else if(el.name === 'name' && type === 'mission')
            return <td className='bold'><p>{line[el.name]}</p></td>;

        else if(el.name === 'numCRlastweek' || el.name === 'numCRcurrentweek'){
            const cr = line[el.name].split("_");
            return(
            <td className={`cr ${cr[0] === "0" ? cr[1] === "0" ? "valid" : "alert" : cr[1]/cr[0] <= 2 ? "valid" : "alert"}`}>
                <p>{cr[0]}/{cr[1]}</p>
            </td>
            )
        }
        
        else if(line[el.name] === null)
            return <td><p>--</p></td>;
        
        else
            return <td><p>{line[el.name]}</p></td>;
    }

    
    return(
        <>
            {genString()}
        </>
    )
}