import { useState, useEffect, useRef } from 'react';
import { t } from "../../../pages/i18nFunctions";

// images
import iconSave from "../../../assets/img/icons/icon-save.png";

export const PopupReseau = (props) => {

    const { reseau, handleSubmit, addReseau, editReseau } = props;

    const [name, setName] = useState("");

    const edit = Object.keys(reseau).length > 0;
    const btnRef = useRef(null);

    // updates
    useEffect(() => {
        setName(edit ? reseau.name : "");
    }, [reseau]);

    // functions

    const submitForm = () => {
        if(name !== "") {

            if(edit)
                editReseau(reseau.id, name);
            else
                addReseau(name);
            
            btnRef.current.click();
        }
    }

    return(
        <div className="modal fade in" id="modalAddEditReseau" tabIndex="-1" role="dialog" aria-labelledby="modalAddEditReseauLabel" animate="animate">
            <div className="modal-dialog" role="document">
                <div className="modal-content">

                    <div className="modal-header">
                        <h1 className="modal-title" id="myModalLabel">{edit ? t('SETTINGS.EDIT_NETWORK') : t('SETTINGS.ADD_NETWORK')}</h1>
                    </div>

                    <div className="modal-body">
                        <form id="formAjout">
                            <label htmlFor="name">{t('SETTINGS.NAME')}</label>
                            <input
                                type="text"
                                required
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </form>
                    </div>

                    <div className="modal-footer">
                        <button
                            type="submit"
                            form="formAjout"
                            className="btn-save"
                            disabled={name === "" || name === reseau.name}
                            data-toggle={ edit ? 'modal' : '' }
                            data-target={'#modalConfirmation'}
                            onClick={e => {
                                e.preventDefault();
                                if(edit) handleSubmit(submitForm);
                                else submitForm();
                            }}
                        >
                            <img src={iconSave} alt=""/>
                            {t('COMMON.SAVE')}
                        </button>
                        <button
                            type="button"
                            className="btn-cancel"
                            data-dismiss="modal"
                            onClick={() => setName(edit ? reseau.name : "")}
                            ref={btnRef}
                        >
                            {t('COMMON.CANCEL')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}