import { config } from '../config';
import axios from 'axios';
import { authHeader } from '../authHeader';

export const getSuiviActivite = (data) => {
    
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/mission/synthese', {
                    ...data,
                    // mission: null,
                    // affectation: null,
                    // network: null,
                    // sector: null,
                    // filter: null
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getCycleMaxDate = (idMission, startDatePeriod) => {

    return new Promise((resolve, reject) => {
        try {
            let params = {};
            if(startDatePeriod !== undefined) params = {
                "startDatePeriod": (startDatePeriod !== undefined) ? startDatePeriod : null
            };
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).post(`api/mission/${idMission}/date`, params)
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getProdAllMission = () => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).get(`api/mission/products/activity1`, {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.ProductsForAllMission));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getProdMission = (idMission) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).get(`api/mission/${idMission}/activity/products`, {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.ProductsForOneMission));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getRapport1 = (idMission, data) => {

    return new Promise((resolve, reject) => {
        try {
            let params = {};
            if(data !== undefined) params = {
                "products": (data.products !== undefined) ? data.products : [],
                "startDate": (data.startDate !== undefined) ? data.startDate : null,
                "endDate": (data.endDate !== undefined) ? data.endDate : null
            };
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post(`api/mission/${idMission}/activity1`, params)
                .then(function (response) {
                    resolve(JSON.stringify(response.data.activitydatabymission));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getRapport2 = (idMission, data) => {

    return new Promise((resolve, reject) => {
        try {
            let params = {};
            if(data !== undefined) params = {
                "page": (data.page !== undefined) ? data.page : 1,
                "maxPerPage": (data.page !== undefined) ? data.maxPerPage : 50,
                "pdsFirstName": (data.pdsFirstName !== undefined) ? data.pdsFirstName : null,
                "pdsLastName": (data.pdsLastName !== undefined) ? data.pdsLastName : null,
                "pdsInstitution": (data.pdsInstitution !== undefined) ? data.pdsInstitution : null,
                "pdsType": (data.pdsType !== undefined) ? data.pdsType : null,
                "pdsSpeciality": (data.pdsSpeciality !== undefined) ? data.pdsSpeciality : null,
                "products": (data.products !== undefined) ? data.products : [],
                "pdsReference": (data.pdsReference !== undefined) ? data.pdsReference : null,
                "startDate": (data.startDate !== undefined) ? data.startDate : null,
                "endDate": (data.endDate !== undefined) ? data.endDate : null,
                "city": (data.city !== undefined) ? data.city : null,
                "lastVisitDate": (data.lastVisitDate !== undefined) ? data.lastVisitDate : null,
                "lastVisitInfOrSup": (data.lastVisitInfOrSup !== undefined) ? data.lastVisitInfOrSup : null,
                "nextVisitDate": (data.nextVisitDate !== undefined) ? data.nextVisitDate : null,
                "nextVisitInfOrSup": (data.nextVisitInfOrSup !== undefined) ? data.nextVisitInfOrSup : null
            };
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post(`api/mission/${idMission}/activity2`, params)
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getCurrentCycle = (idMission) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).get(`api/mission/${idMission}/current-date`, {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getProdNet = () => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).get(`api/mission/networks/activity`, {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.NetworksForAllMission));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getProdLab = () => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).get(`api/mission/products/activity`, {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.ProductsForAllMission));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

export const getProdLabByMission = (id) => {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json' 
                    }
                }).get(`api/mission/${id}/activity/products`, {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.ProductsForOneMission));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}