import { useContext } from 'react';
import { PopUpStoreContext } from '../../../../../../store/store/storePopUp';
import { ActionsStoreContext } from '../../../../../../store/store/storeActions';
import { ListStoreContext } from '../../../../../../store/store/storeList';

import iconDelete from "../../../../../../assets/img/icons/icon-delete.png";

export const DeleteDirect = (props) => {
    const { type, line, action } = props;
    const { statePopUp, dispatchPopUp } = useContext(PopUpStoreContext);
    const { stateActions, dispatchActions } = useContext(ActionsStoreContext);
    const { stateList } = useContext(ListStoreContext);
    const { dataPopUp } = statePopUp;
    
    const handleClickDelete = (event) => {
        const key = typeof line.idSpeciality !== "undefined" ? "idSpeciality" : "idProType";

        stateActions.deleteDirect(line[key], key, stateActions.dataActions, stateList.dataOtherTabs);
    }
    
    return(
        <>
            <div className="icon-btn" data-toggle="modal" data-target="#modalDelete" onClick={(e) => handleClickDelete(e)}>
                <img src={iconDelete} alt="delete"/>
            </div>
        </>
    )
}