import "./Footer.scss";

export const Footer = () => (
    <footer className="main-footer">
        <div className="pull-right hidden-xs">
            {/* <b>Version</b> 1.4.0.3 • 2018-08-24 16:47 */}
            <b>Version</b> 2.0 • {new Date().getFullYear()}
        </div>
        <strong>Copyright © Ressourcethica SCA.</strong> Tous droits réservés.
    </footer>
)