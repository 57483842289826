import { useState, useEffect, useRef, useContext } from 'react';
import "./Specialite.scss";
import { t } from "../../i18nFunctions";
import { StoreContext } from "../../../store/store";

// components
import { Pagination } from '../../../components/Pagination/Pagination';
import { CustomSelect } from '../../../components/UI/CustomSelect/CustomSelect';

// images
import aToZ from "../../../assets/img/icons/icon-a-z.png";
import zToA from "../../../assets/img/icons/icon-z-a.png";

import {
    getAllSpecialite,
    getAllProType
} from '../../../store/index.service';

export const Specialite = () => {

    const [listSpecialite, setListSpecialite] = useState([]);
    const [listTypePro, setListTypePro] = useState([]);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [maxPerPage, setMaxPerPage] = useState(100);
    const [protype, setProtype] = useState(0);
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [convOrder, setConvOrder] = useState(true);
    const [nbResultats, setNbResultats] = useState(0);

    const timer = useRef(null);
    const { setLoadingStore } = useContext(StoreContext);

    // mounted
    useEffect(() => {
        handleGetAllSpecialites({ page: 1 });

        getAllProType().then(res => {
            const data = JSON.parse(res);
            if(data) setListTypePro(data);
            else setListTypePro([]);
        });

        timer.current = setTimeout(() => {}, 1000);
        // unmounted
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    // updates
    useEffect(() => {
        handleGetAllSpecialites({
            page: page,
            protype: protype !== 0 ? protype : undefined,
            name: name !== "" ? name : undefined,
            code: code !== "" ? code : undefined
        });
    }, [page]);

    // functions

    const handleGetAllSpecialites = (data) => {
        setLoadingStore(true);

        getAllSpecialite({
            page: data.page,
            maxPerPage: maxPerPage,
            protype: data.protype,
            name: data.name,
            code: data.code
        }).then(res => {
            const data = JSON.parse(res);

            if(data.listSpeciality) setListSpecialite(data.listSpeciality);
            else setListSpecialite([]);

            if(data.nbrPages) setMaxPage(data.nbrPages);
            else setMaxPage(0);

            if(data && data.nbResultats) setNbResultats(data.nbResultats);
            else setNbResultats(0);

            setLoadingStore(false);
        });
    }

    const handleFilter = (value, key, setter) => {
        clearTimeout(timer.current);
        setter(value);
        timer.current = setTimeout(() => {
            let params = {
                page: 1,
                protype: protype !== 0 ? protype : undefined,
                name: name !== "" ? name : undefined,
                code: code !== "" ? code : undefined
            }
            params[key] = value !== "" && value !== 0 ? value : undefined;
            handleGetAllSpecialites(params);
        }, 1000);
    }

    const sortList = (l) => {
        let list = [...l];

        list.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        });

        if(!convOrder) list.reverse();

        return list;
    }

    const listSelectPro = () => {
        let l =  [...listTypePro];
        l.unshift({ id: 0, code: "Tous", name: "Tous" });
        return l;
    }

    return(
        <section className="content">
        
            <section className="content-header">
                <h1>  
                    {t('SETTINGS.SPECIALTIES')}
                    <small>{t('SETTINGS.SPECIALITY_MANAGEMENT')}</small>
                </h1>
            </section>
            <section className="content spe">
                <div className="row">
                    <div className="col-md-6">
                        <table className="box tab-entete spe">
                            <tbody>
                                <tr>
                                    <td>
                                        <div><p>Code</p></div>
                                        <div>
                                            <input 
                                            type="text" 
                                            placeholder="..."
                                            value={code}
                                            className="input-sm form-control" 
                                            onChange={(e) => handleFilter(e.target.value, "code", setCode)} 
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <p>{t('SETTINGS.NAME')}</p>
                                            <img
                                            src={convOrder ? aToZ : zToA}
                                            alt={convOrder ? "a-z" : "z-a"}
                                            onClick={() => setConvOrder(!convOrder)}/>
                                        </div>
                                        <div>
                                            <input
                                            type="text"
                                            placeholder="..."
                                            value={name}
                                            className="input-sm form-control"
                                            onChange={(e) => handleFilter(e.target.value, "name", setName)}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div><p>{t('SETTINGS.PDS_TYPE')}</p></div>
                                        <div>
                                            <CustomSelect
                                            defaultText={t('SETTINGS.SELECT_PDS_TYPE')}
                                            optionsList={listSelectPro()}
                                            handleOptionClick={id => handleFilter(parseInt(id), "protype", setProtype)}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="list spe">
                            <tbody>
                                {listSpecialite.length > 0 ?
                                    sortList(listSpecialite).map(spe =>
                                        <tr key={spe.id}>
                                            <td>{spe.code}</td>
                                            <td>{spe.name}</td>
                                            <td>{spe.medicalprotype.nameMedicalProType}</td>
                                        </tr>
                                    )
                                : <tr><td>{t('SETTINGS.NO_SPECIALITY_FOUND')}</td></tr> }
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

            {maxPage > 1 &&
            <Pagination
                maxPage={maxPage}
                page={page}
                onChangePage={(data) => setPage(data)}
                nbCount={nbResultats}
            ></Pagination>
            }

        </section>
    )
}