import { useEffect, useState, useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate  } from "react-router-dom";
import { NotificationManager, NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './i18n';
import LoadingBar from 'react-top-loading-bar';

/* --- COMPONENTS --- */
import { NavigationMenu } from './components/NavigationMenu/NavigationMenu';
import { HeaderBar } from './components/HeaderBar/HeaderBar';
import { Footer } from './components/Footer/Footer';
import { InfoSticky } from './components/InfoSticky/InfoSticky';
import { PopupRemainingCR } from './components/Popups/RemainingCR/PopupRemainingCR';

/* --- PAGES --- */
import { Login } from './pages/Login/Login';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Missions } from './pages/Missions/Missions';
import { MissionsCiblage } from './pages/Missions/MissionsCiblage';
import { Planning } from "./pages/Planning/Planning";
import { Historique } from './pages/Historique/Historique';
import { JoursFeries } from './pages/JoursFeries/JoursFeries';
import { Documentation } from './pages/Documentation/Documentation';

// parametres
import { Laboratoires } from './pages/Parametres/Laboratoires/Laboratoires';
import { TypesPDS } from './pages/Parametres/TypesPDS/TypesPDS';
import { Specialite } from './pages/Parametres/Specialite/Specialite';
import { Users } from './pages/Parametres/Users/Users';
import { ZonesGeo } from './pages/Parametres/ZonesGeo/ZonesGeo';
import { Secteurs } from './pages/Parametres/Secteurs/Secteurs';
import { Organigramme } from './pages/Parametres/Organigramme/Organigramme';
import { Reseaux } from './pages/Parametres/Reseaux/Reseaux';
import { ReseauxProduits } from './pages/Parametres/Reseaux/Produits/ReseauxProduits';
import { Frequences } from './pages/Parametres/Reseaux/Frequences';

import { Etablissements } from './pages/Annuaire/Etablissements';
import { Professionnels } from './pages/Annuaire/Professionnels';
import { Account } from './pages/Account/Account';
import { SuiviActivite } from './pages/SuiviActivite/SuiviActivite';
import { Rapport1 } from './pages/SuiviActivite/Rapport1/Rapport1';
import { Rapport2 } from './pages/SuiviActivite/Rapport2/Rapport2';
/* ------------- */

import { StoreContext } from "./store/store";

import {
  logout,
  getMissionUser
} from './store/index.service';

export const App = () => {
  const { missionUser, setMissionUser, loadingStore, setLang } = useContext(StoreContext);

  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('user'));
  const [role, setRole] = useState(localStorage.getItem('role'));
  const [progress, setProgress] = useState(0);
  const [mounted, setMounted] = useState(false);

  // mounted
  useEffect(() => {
    setMounted(true);
  }, []);

  // updates
  useEffect(() => {
    if(
      role === "ROLE_DELEGUE"
      || role === "ROLE_HP"
      || role === "ROLE_DP"
      || role === "ROLE_DSM"
      || role === "ROLE_SUPERVISEUR"
    ) {
      getMissionUser()
        .then(res => {
          setMissionUser(JSON.parse(res)[0]);
        })
        .catch(err => {
          console.error(err);
          if(err.response.status === 404)
            NotificationManager.error("", "L'utilisateur n'a pas de mission");
        })
    }
  }, [role]);

  useEffect(() => {
    if(mounted)
      if(loadingStore > 0) setProgress(75);
      else setProgress(100);
  }, [loadingStore]);

  // functions
  const logoutAfterTime = () => {
    setTimeout(() => {
      NotificationManager.success("", "Déconnecté");
      logout();
      window.location = "/";
      setLoggedIn(false);
    }, 7200000);
  } 

  const userLogin = () => {
    logoutAfterTime();
    setRole(localStorage.getItem('role'));
    setLoggedIn(true);
  }

  const handleLogout = () => {
    setLoggedIn(false);
    setRole("");
    setMissionUser({});
  }

  // locale
  if(!localStorage.getItem('currentLanguage')) {
    if(navigator.language.toLocaleLowerCase().slice(0, 2) === "en") {
      localStorage.setItem('currentLanguage', "en");
      setLang("en");
    } else {
      localStorage.setItem('currentLanguage', "fr");
      setLang("fr");
    }
  }

  return (
    <BrowserRouter>
      {loggedIn ? (
      <div>
        <HeaderBar userLogOut={handleLogout}/>
        <div className="loading-bar">
          <LoadingBar
            color="#6dbf3c"
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
            height={progress > 0 ? 4 : 0}
            background="#fff"
            containerStyle={{position: "static"}}
            shadow={false}
            waitingTime={200}
          />
        </div>
        <NavigationMenu idMission={missionUser.id} />
        
        <div className="content-wrapper">
          <NotificationContainer/>

          {/* <InfoSticky text="Le site sera en maintenance et sera donc inaccessible du dimanche 18/12 à 22h au lundi 19/12 à 4h du matin (heure de Paris)" /> */}

          <Routes>
            <Route path="/" element={ <Dashboard/> } />

            {/* Planning personnel */}
            <Route path='/calendar/current' element={ role === "ROLE_ADMIN" && role === "ROLE_QADMIN" ? <Navigate to="/" /> : <Planning /> } />

            {/* Visionner un planning */}
            <Route path='/missions/:id/calendar' element={ role === "ROLE_DELEGUE" ? <Navigate to="/" /> : <Planning /> } />

            {/* Liste des missions */}
            <Route path='/missions' element={ role === "ROLE_DELEGUE" ? <Navigate to="/" /> : <Missions/> } />

            {/* Ciblage d'une mission */}
            <Route path='/missions/:id/ciblage' element={ role === "ROLE_DELEGUE" ? <Navigate to="/" /> : <MissionsCiblage/> } />

            {/* Jours feries */}
            <Route path='/jours-feries' element={ role !== "ROLE_ADMIN" ? <Navigate to="/" /> : <JoursFeries/> } />

            {/* Historique */}
            <Route path='/historique' element={ role !== "ROLE_ADMIN" ? <Navigate to="/" /> : <Historique/> } />


            {/* Annuaire */}
            <Route path='/etablissements' element={<Etablissements/>} />
            <Route path='/professionnels' element={<Professionnels/>} />
            

            {/* Laboratoires */}
            <Route path='/laboratoires' element={ role !== "ROLE_ADMIN" ? <Navigate to="/" /> : <Laboratoires/> } />

            {/* Zones Geo */}
            <Route path='/zones-geographiques' element={ role !== "ROLE_ADMIN" ? <Navigate to="/" /> : <ZonesGeo/> }/>

            {/* Organigramme */}
            <Route path='/organigramme' element={ role !== "ROLE_ADMIN" ? <Navigate to="/" /> : <Organigramme/> } />

            {/* Secteurs */}
            <Route path='/secteurs' element={ role !== "ROLE_ADMIN" ? <Navigate to="/" /> : <Secteurs/> } />

            {/* Utilisateurs */}
            <Route path='/utilisateurs' element={role !== "ROLE_ADMIN" ? <Navigate to="/" /> : <Users/>} />

            {/* Types de PDS */}
            <Route path='/types-pds' element={ role !== "ROLE_ADMIN" ? <Navigate to="/" /> : <TypesPDS/> } />

            {/* Specialites */}
            <Route path='/specialites' element={ role !== "ROLE_ADMIN" ? <Navigate to="/" /> : <Specialite/> } />

            {/* Reseaux */}
            <Route path='/reseaux' element={ role !== "ROLE_ADMIN" ? <Navigate to="/" /> : <Reseaux/> } />

            {/* Produits d'un reseau */}
            <Route path='/reseaux/:idRes/config/:nameRes' element={ role !== "ROLE_ADMIN" ? <Navigate to="/" /> : <ReseauxProduits/> } />
            
            {/* Frequences */}
            <Route path='/frequences-visites' element={ role !== "ROLE_ADMIN" ? <Navigate to="/" /> : <Frequences/> } />


            {/* Suivi activite des delegues */}
            <Route path='/suivi-activite' element={ role === "ROLE_DELEGUE" ? <Navigate to="/" /> : <SuiviActivite/> } />
            <Route path='/rapport1/:id/*' element={<Rapport1/>} />
            <Route path='/rapport2/:id/*' element={<Rapport2/>} />

            {/* Mon compte */}
            <Route path="/profile/edit" element={ <Account /> }  />

            <Route path="/documentation" element={ <Documentation /> }  />

          </Routes>

          <PopupRemainingCR />
        </div>

        <Footer/>
      </div>
      ) : (
        <Login userLogin={userLogin} />
      )   
      }
    </BrowserRouter>
  )
}