import { useState, useEffect, useContext, useRef } from 'react';
import "./Reseaux.scss";
import { t } from "../../i18nFunctions";
import { NotificationManager} from 'react-notifications';
import { Link } from 'react-router-dom';
import { StoreContext } from "../../../store/store";

// components
import { ButtonAdd } from '../../../components/UI/Button/ButtonAdd/ButtonAdd';
import { PopupReseau } from '../../../components/Popups/Reseaux/PopupReseau';
import { PopupDelete } from '../../../components/Popups/PopupDelete';
import { Pagination } from '../../../components/Pagination/Pagination';
import { PopupConfirmation } from '../../../components/Popups/PopupConfirmation';

// images
import iconEdit from "../../../assets/img/icons/icon-edit.png";
import iconDelete from "../../../assets/img/icons/icon-delete.png";
import iconProduct from "../../../assets/img/icons/icon-produit.png";

// api
import {
    getAllReseaux,
    postReseau,
    editReseau,
    deleteReseau
} from '../../../store/index.service';

export const Reseaux = () => {

    const [reseauxList, setReseauxList] = useState([]);
    const [nameFilter, setNameFilter] = useState("");
    const [selectedReseau, setSelectedReseau] = useState({});
    const [idDelete, setIdDelete] = useState(-1);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [maxPerPage, setMaxPerPage] = useState(50);
    const [nbResultats, setNbResultats] = useState(0);
    const [confirmSubmit, setConfirmSubmit] = useState(() => () => {});

    const { setLoadingStore } = useContext(StoreContext);

    const timer = useRef(null);

    // updates
    useEffect(() => {
        handleGetAllReseaux();
        timer.current = setTimeout(() => {}, 1000);
        // unmounted
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const handleGetAllReseaux = (valName = nameFilter, p = page) => {
        setLoadingStore(true);

        getAllReseaux({ page: p, maxPerPage: maxPerPage, networkName: valName }).then(res => {
            const data = JSON.parse(res);

            if(data.listNetwork) setReseauxList(data.listNetwork);
            else setReseauxList([]);

            if(data.nbrPages) setMaxPage(data.nbrPages);
            else setMaxPage(1);

            if(data.nbResultats) setNbResultats(data.nbResultats);
            else setNbResultats(0);

            setLoadingStore(false);
        });
    }

    const handleFilter = (value, setter) => {
        clearTimeout(timer.current);
        setter(value);
        setPage(1);
        timer.current = setTimeout(() => {
            handleGetAllReseaux(value, 1);
        }, 1000);
    }

    return(
        <section className="content">
        
            <section className="content-header">
                <h1>
                    {t('SETTINGS.NETWORKS')}
                    <small>{t('SETTINGS.MANAGEMENT')}</small>
                </h1>
            </section>
        
            <section className="content">

                <table className="box tab-entete reseaux">
                    <tbody>
                        <tr>
                            <td>
                                <div>
                                    <p>{t('SETTINGS.NAME')}</p>
                                    <input
                                        type="text" 
                                        placeholder="..." 
                                        value={nameFilter} 
                                        onChange={(e) => handleFilter(e.target.value, setNameFilter)} 
                                    />
                                </div>
                            </td>
                            <td>
                                <ButtonAdd
                                    modal="#modalAddEditReseau"
                                    handleAdd={() => setSelectedReseau({})}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table className="list reseaux">
                    <tbody>
                        { reseauxList.length > 0 ?
                            reseauxList.map(res => {
                                return (
                                    <tr key={res.id} className="list-row">
                                        <td>
                                            {res.name}
                                        </td>
                                        <td className="icons">
                                            <Link
                                                className="icon-btn"
                                                to={`${res.id}/config/${encodeURIComponent(res.name)}`}
                                            >
                                                <img src={iconProduct} alt="" className="inline-block"/>
                                                <p>{t('SETTINGS.PRODUCTS')}</p>
                                            </Link>
                                            <div
                                                className="icon-btn"
                                                data-toggle="modal"
                                                data-target="#modalAddEditReseau"
                                                onClick={() => setSelectedReseau(res)}
                                            >
                                                <img src={iconEdit} alt="edit"/>
                                            </div>
                                            <div
                                                className="icon-btn"
                                                data-toggle="modal"
                                                data-target="#modalDelete"
                                                onClick={() => setIdDelete(res.id)}
                                            >
                                                <img src={iconDelete} alt="x"/>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        :
                            <tr><td className="text-center">Aucun réseau trouvé</td></tr>
                        }
                    </tbody>
                </table>

                { maxPage > 1 &&
                    <Pagination
                        maxPage={maxPage}
                        page={page}
                        onChangePage={(data) => {
                            setPage(data);
                            handleGetAllReseaux(nameFilter, data);
                        }}
                        nbCount={nbResultats}
                    ></Pagination>
                }
            </section>

            <PopupReseau
                reseau={selectedReseau}
                handleSubmit={submit => setConfirmSubmit(() => () => submit())}

                addReseau={name => postReseau(name).then(() => {
                    NotificationManager.success('', "Poste ajouté");
                    handleGetAllReseaux();
                }).catch(() => NotificationManager.error('', "Error"))}

                editReseau={(id, name) => editReseau(id, name).then(() => {
                    NotificationManager.success('', "Poste modifié");
                    handleGetAllReseaux();
                }).catch(() => NotificationManager.error('', "Error"))}
            />

            <PopupDelete
                title="Supprimer un réseau"
                id={idDelete}
                handleDelete={id => deleteReseau(id).then(() => {
                    NotificationManager.success('', "Poste supprimé");
                    handleGetAllReseaux();
                }).catch(() => NotificationManager.error('', "Error"))}
            />

            <PopupConfirmation message={t('COMMON.CONFIRM_MSG')} handleSubmit={confirmSubmit}/>

        </section>
    )
}