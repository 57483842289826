import React, { useReducer, createContext } from 'react';

export const initialListState = {
    dataList: [],
    dataOtherTabs: [],
    dataPagination: {
        page: 1,   
        maxPerPage: 20
    },
    dataParamSearch: {},
    
    dataLab: [],
    dataMedicalPro: [],
    dataCountries: [],
    dataEtabs: [],
    dataTypeEtabs: [],
    dataMissions: [],
    dataZoneGeos: [],
    dataProType: [],
    dataSpeciality: [],
    dataNetwork: [],
    dataPositions: [],
    dataSpecialityByType: [],
    dataLogProSoft: [],
    dataHistoryPro: [],
    dataTypeContact: [],
    dataActivity: [],
    
    dataVerifEtab: [],
    dataVerifPro: [],

    dataRemainingCR: [],
    paginationRemainingCR: []
}

export const ListStoreContext = createContext(initialListState);

export function reducer(state, action) {
    switch (action.type) {
        case 'setList':
            return {...state, dataList: action.dDataList};
        case 'setDataOtherTabs':
            return {...state, dataOtherTabs: action.dDataOtherTabs};

        case 'setDataPagination':
            return {...state, dataPagination: action.dDataPagination};

        case 'setDataParamSearch':
            return {...state, dataParamSearch: action.dDataParamSearch};
            
        case 'setDataLab':
            return {...state, dataLab: action.dDataLab};
        case 'setDataEtabs':
            return {...state, dataEtabs: action.dDataEtabs};
        case 'setDataCountries':
            return {...state, dataCountries: action.dDataCountries};
        case 'setDataTypeEtabs':
            return {...state, dataTypeEtabs: action.dDataTypeEtabs};
        case 'setDataMissions':
            return {...state, dataMissions: action.dDataMissions};
        case 'setDataZoneGeos':
            return {...state, dataZoneGeos: action.dDataZoneGeos};
        case 'setDataMedicalPro':
            return {...state, dataMedicalPro: action.dDataMedicalPro};
        case 'setDataProType':
            return {...state, dataProType: action.dDataProType};
        case 'setDataSpeciality':
            return {...state, dataSpeciality: action.dDataSpeciality};
        case 'setDataNetwork':
            return {...state, dataNetwork: action.dDataNetwork};
        case 'setDataPositions':
            return {...state, dataPositions: action.dDataPositions};
        
        case 'setDataSpecialityByType':
            return {...state, dataSpecialityByType: action.dDataSpecialityByType};
        case 'setDataLogProSoft':
            return {...state, dataLogProSoft: action.dDataLogProSoft};
        case 'setDataHistoryPro':
            return {...state, dataHistoryPro: action.dDataHistoryPro};
        case 'setDataTypeContact':
            return {...state, dataTypeContact: action.dDataTypeContact};
        case 'setDataActivity':
            return {...state, dataActivity: action.dDataActivity};
        
        case 'setDataVerifEtab':
            return {...state, dataVerifEtab: action.dDataVerifEtab};
        case 'setDataVerifPro':
            return {...state, dataVerifPro: action.dDataVerifPro};

        case 'setDataRemainingCR':
            return {...state, dataRemainingCR: action.dDataRemainingCR};
        case 'setPaginationRemainingCR':
            return {...state, paginationRemainingCR: action.pPaginationRemainingCR};
        
        default:
            throw new Error();
    } 
}

export const ListStoreContainer = ({children}) => {
    const [stateList, dispatchList] = useReducer(reducer, initialListState);
    
    return (
        <ListStoreContext.Provider value={{stateList, dispatchList}}>
            {children}
        </ListStoreContext.Provider>
    )
}
